import React, { useContext } from 'react';
import { useHasAccess } from 'cp-client-auth!sofe';
import { CpButton, CpDropdown, CpTooltip } from 'canopy-styleguide!sofe';
import { CreditContext } from 'src/credits/credit-context';

export const CreditOverlayActions = ({ onApplyCredit, onDeleteCredit, onRefundCredit }) => {
  const { credit, actions } = useContext(CreditContext);

  const canAccessInvoices = useHasAccess('billing_invoices_create_edit');
  const canDelete = useHasAccess('billing_archive_delete');

  return (
    <div className="cp-flex">
      {credit.status !== 'closed' && (
        <CpTooltip text="Edit">
          <CpButton aria-label="Edit" icon="crud-pencil" className="cp-mr-8" onClick={() => actions.edit()} />
        </CpTooltip>
      )}
      <CpTooltip text="Download">
        <CpButton aria-label="Download" icon="af-open-down" className="cp-mr-8" onClick={() => actions.download()} />
      </CpTooltip>
      <CpTooltip text="Print">
        <CpButton aria-label="Print" icon="misc-printer" className="cp-mr-8" onClick={() => actions.print()} />
      </CpTooltip>
      {canDelete && !credit.payment_id && (
        <CpTooltip text="Delete">
          <CpButton aria-label="Delete" icon="crud-trash-large" className="cp-mr-8" onClick={() => onDeleteCredit()} />
        </CpTooltip>
      )}
      {credit.status === 'void' && !credit.payment_id ? (
        <CpButton btnType="primary" onClick={() => actions.open()}>
          Open this credit
        </CpButton>
      ) : (
        <>
          {!credit.payment_id && (
            <CpDropdown
              renderTrigger={({ toggle }) => (
                <CpButton aria-label="Menu" icon="misc-kabob" className="cp-mr-8" onClick={toggle} />
              )}
              renderContent={() => (
                <div className="cp-select-list">
                  <a onClick={() => actions.void()}>Void</a>
                  <a onClick={() => onRefundCredit()}>Refund</a>
                </div>
              )}
            />
          )}
          {canAccessInvoices && (credit.status === 'open' || credit.status === 'pending') && (
            <CpTooltip
              disabled={credit.settlement_status !== 'pending'}
              text={
                <div className="cp-text-center">
                  Payment is still processing <br /> and cannot be applied yet
                </div>
              }
              position="bottom-end">
              <div>
                <CpButton
                  btnType="primary"
                  onClick={() => {
                    onApplyCredit();
                  }}
                  disabled={credit.settlement_status === 'pending'}>
                  Add to invoice
                </CpButton>
              </div>
            </CpTooltip>
          )}
        </>
      )}
    </div>
  );
};
