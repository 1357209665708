import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { CpIcon } from 'canopy-styleguide!sofe';
import { getRemaining, getFrequency, getTerms } from 'src/invoices/recurrences.helper';
import { luxonDefaultDateFormat } from 'src/billing-helpers';
import { convertToCurrencyText } from 'src/payments/payments.helper';

import styles from './select-recurring-series.styles.css';

export const SelectRecurringSeries = ({ recurringInvoices, selectedRecurrence, handleRecurrenceSelected, isEdit }) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const onRecurrenceSelected = id => {
    setSelectOpen(false);
    handleRecurrenceSelected(id);
  };

  return (
    <div className={`cps-well__light cp-p-16 cp-mb-24`}>
      <div>
        <div className="cps-subheader-sm">Select recurring series</div>
        <div>
          Any previously sent invoices will not be included in the recurring payment series. Those will need to be paid
          separately.
        </div>
        <div className="cp-mt-16">
          <div className="cps-well__light" style={{ position: 'relative' }}>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', padding: '6px 11px' }}
              onClick={() => {
                !isEdit && setSelectOpen(!selectOpen);
              }}>
              <div className="cps-wt-semibold" style={{ maxWidth: '220px' }}>
                {selectedRecurrence.description}
              </div>
              <div style={{ fontStyle: 'italic' }}>{getRemaining(selectedRecurrence)}</div>
              <div>
                <span className="cp-mr-8">Invoice amount:</span>
                <span>{convertToCurrencyText(selectedRecurrence.total)}</span>
                {!isEdit && <CpIcon name="caret-small-down" className="cp-ml-32" />}
              </div>
            </div>
            {selectOpen && (
              <ul
                className="cps-well__light"
                style={{
                  position: 'absolute',
                  top: '36px',
                  width: '100%',
                  padding: '8px 0',
                  zIndex: '99',
                  maxHeight: '400px',
                  overflowY: 'auto',
                }}>
                {recurringInvoices.map(recurrence => {
                  return (
                    <li
                      key={recurrence.id}
                      className={styles.recurrenceItem}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => onRecurrenceSelected(recurrence.id)}>
                      <div style={{ width: '220px' }} className="cps-wt-semibold">
                        {recurrence.description}
                      </div>
                      <div style={{ width: '80px', fontStyle: 'italic' }}>{getRemaining(recurrence)}</div>
                      <div style={{ width: '240px' }}>
                        <span className="cp-mr-8">Invoice amount:</span>
                        <span>{convertToCurrencyText(recurrence.total)}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="cp-mt-16" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="cp-pr-16">
            <span className="cp-mr-8 cps-color-monsoon">Start date: </span>
            <span>{DateTime.fromISO(selectedRecurrence.schedule.start_date).toFormat(luxonDefaultDateFormat)}</span>
          </div>
          <div className="cp-pr-16">
            <span className="cp-mr-8 cps-color-monsoon">Frequency:</span>
            <span>{getFrequency(selectedRecurrence)}</span>
          </div>
          <div className="cp-pr-16">
            <span className="cp-mr-8 cps-color-monsoon">Terms:</span>
            <span>{getTerms(selectedRecurrence)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
