// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.available-credits-styles__availableCredits--GgFRZ {
  display: flex;
  padding: 0 32px 16px 24px;
}
.available-credits-styles__availableCredits--GgFRZ table {
  table-layout: fixed;
  width: 100%;
}

.available-credits-styles__availableCredits--GgFRZ thead tr {
  text-align: left;
  line-height: 42px;
}

.available-credits-styles__availableCredits--GgFRZ th,
.available-credits-styles__availableCredits--GgFRZ td {
  padding: initial;
}

.available-credits-styles__availableCredits--GgFRZ td {
  border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/invoices/components/invoice-builder/available-credits.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".availableCredits {\n  display: flex;\n  padding: 0 32px 16px 24px;\n}\n.availableCredits table {\n  table-layout: fixed;\n  width: 100%;\n}\n\n.availableCredits thead tr {\n  text-align: left;\n  line-height: 42px;\n}\n\n.availableCredits th,\n.availableCredits td {\n  padding: initial;\n}\n\n.availableCredits td {\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"availableCredits": `available-credits-styles__availableCredits--GgFRZ`
};
export default ___CSS_LOADER_EXPORT___;
