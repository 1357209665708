import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { map, debounce } from 'lodash';
import { DateTime } from 'luxon';
import { CpCard, CpDropdown, CpButton, CpLoader, CpSelectSingle, CpIcon } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper.js';
import { convertToCurrencyText } from 'src/payments/payments.helper.js';
import { getClientOwners } from 'src/resources/clients.resources.js';
import ReportWrapper from '../common/report-wrapper/report-wrapper.component.js';
import styles from 'src/reports/common/simple/common.styles.css';

import { DateRangeFilter } from 'src/common/components/filters/date-range-filter.component';
import { paymentDateRanges } from 'src/payments/payments.helper';
import { getTimeReport, exportReportCSV } from './time-report.resource.js';

export default function GenericTimeReport({ reportUrl, title, isCurrency, leftColumnTitle, leftColumnLink }) {
  const [fetchReport, setFetchReport] = useState(true);
  const [timeReport, setTimeReport] = useState({});
  const [dateFilter, setDateFilter] = useState({
    filter_params: 'currentMonth',
    after: DateTime.local().startOf('month').toISODate(), //first day of the month
    before: DateTime.local().endOf('month').toISODate(), //last day of the month
  });
  const [selectedOwner, setSelectedOwner] = useState();
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMemberSearch, setTeamMemberSearch] = useState('');
  const [teamMemberSearchTrigger, setTeamMemberSearchTrigger] = useState(true);
  const [interval, setInterval] = useState('week');
  const containerEl = useRef(null);
  const minColWidth = 160;
  const [columnWidth, setColumnWidth] = useState(minColWidth);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const prevSelectedOwnerRef = useRef(selectedOwner);

  useEffect(() => {
    const { after, before } = dateFilter;
    if (prevSelectedOwnerRef.current !== selectedOwner || fetchReport) {
      const subscription = getTimeReport(after, before, interval, reportUrl, selectedOwner?.id).subscribe(
        timeReport => {
          setTimeReport(timeReport);
          setFetchReport(false);
        },
        handleError
      );

      return () => subscription.unsubscribe();
    }
    prevSelectedOwnerRef.current = selectedOwner;
  }, [fetchReport, selectedOwner]);

  useEffect(() => {
    if (!teamMemberSearchTrigger) return;
    const subscription = getClientOwners(teamMemberSearch).subscribe(res => {
      setTeamMembers(res);
      setTeamMemberSearchTrigger(false);
    }, handleError);
    return () => subscription.unsubscribe();
  }, [teamMemberSearchTrigger]);

  const searchTeamMembers = () => setTeamMemberSearchTrigger(true);
  const debouncedTeamMemberSearch = useCallback(debounce(searchTeamMembers, 1000), []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const handleWindowResize = evt => {
    setWindowWidth(evt.target.innerWidth);
  };

  useEffect(() => {
    setColumnWidth(
      containerEl.current && timeReport.columns
        ? (containerEl.current.getBoundingClientRect().width - 52) / (timeReport.columns.length + 2)
        : minColWidth
    );
  }, [containerEl, timeReport, windowWidth]);

  const formatValueCell = (value, isCurrency) => {
    return value !== 0 ? convertToCurrencyText(value, isCurrency, true) : '—';
  };

  const handleFilterChanged = dateFilter => {
    setDateFilter(dateFilter);
    setFetchReport(true);
  };

  const exportCSV = () => {
    const { after, before } = dateFilter;
    exportReportCSV(after, before ? before : after, interval, reportUrl, selectedOwner?.id).subscribe(response => {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response).replace(/#/g, '%23');
      hiddenElement.target = '_blank';
      hiddenElement.download = `${after}-${before}-by-${interval}-${title.replace(/\s+/g, '-').toLowerCase()}.csv`;
      hiddenElement.click();
    }, handleError);
  };

  return (
    <ReportWrapper>
      <div className="cps-flexible-focus">
        <CpCard ref={containerEl} style={{ maxHeight: '1120px' }}>
          {fetchReport ? (
            <CpLoader size="lg" />
          ) : (
            <>
              <CpCard.Header className={`${styles.headerBox}`}>
                <div className={'cps-subheader'}>{title}</div>
              </CpCard.Header>
              <div className="cp-flex-spread cp-pv-12 cp-ph-16">
                <div className="cp-flex">
                  <CpSelectSingle
                    data={['week', 'month', 'quarter', 'year']}
                    transformData={option => ({ id: option, name: option.capitalize() })}
                    placeholder="Interval"
                    onChange={key => {
                      setInterval(key);
                      setFetchReport(true);
                    }}
                    value={interval}
                  />
                  <div className="cp-ml-16">
                    <DateRangeFilter
                      dateFilter={dateFilter}
                      onFilterChanged={handleFilterChanged}
                      sortParam={'reports'}
                      dateRanges={paymentDateRanges}
                      hideClear={true}
                    />
                  </div>
                  {['client_revenue', 'client_hours'].includes(reportUrl) && (
                    <div style={{ width: '212px' }}>
                      <CpSelectSingle
                        className="cp-pl-16"
                        clearable
                        contentWidth={300}
                        data={teamMembers}
                        onChange={setSelectedOwner}
                        placeholder="Owner"
                        searchFilter
                        searchOnChange={value => {
                          setTeamMemberSearch(value);
                          debouncedTeamMemberSearch();
                        }}
                        searchValue={teamMemberSearch}
                        triggerIsBlock
                        value={selectedOwner}
                      />
                    </div>
                  )}
                </div>
                <div className="cp-ml-16">
                  <CpDropdown
                    renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" onClick={toggle} aria-label="Menu" />}
                    renderContent={() => (
                      <div className="cp-select-list">
                        <button onClick={exportCSV}>
                          <CpIcon className="cp-select-list__icon-left" name="af-line-square-up" />
                          Export CSV
                        </button>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className={styles.gridContainer}>
                <div className={`${styles.grid}`}>
                  {/* The sticky left column */}
                  <div className={`${styles.gridCol__fixedLeft}`} style={{ width: `${columnWidth}px` }}>
                    <div className={`${styles.gridItem__header}`}>{leftColumnTitle}</div>
                    {map(timeReport.rows, (row, rowIndex) => (
                      <div key={'row' + rowIndex} className={`${styles.gridItem} cp-ellipsis`}>
                        {leftColumnLink && row.is_active ? (
                          <Link to={`/client/${row.id}${leftColumnLink}`} target="_blank" rel="noopener noreferrer">
                            {row.name}
                          </Link>
                        ) : (
                          row.name
                        )}
                      </div>
                    ))}
                    <div className={`cp-ellipsis ${styles.gridItem__footer}`}>Total</div>
                  </div>
                  {/* The scrollable columns */}
                  {map(timeReport.columns, (column, colIndex) => (
                    <div key={column + colIndex} style={{ width: `${columnWidth}px`, minWidth: minColWidth }}>
                      <div className={`${styles.gridItem__header}`}>{column.name}</div>
                      {map(timeReport.rows, (row, rowIndex) => (
                        <div key={'row' + rowIndex} className={`${styles.gridItem} cp-ellipsis`}>
                          {formatValueCell(row.values[colIndex], isCurrency)}
                        </div>
                      ))}
                      <div className={`${styles.gridItem__footer} cp-ellipsis `}>
                        {formatValueCell(timeReport.column_totals.values[colIndex], isCurrency)}
                      </div>
                    </div>
                  ))}
                  {/* sticky right total column */}
                  <div
                    className={`${styles.gridCol__fixedRight}`}
                    style={{ width: `${columnWidth}px`, minWidth: minColWidth }}>
                    <div className={`${styles.gridItem__header}`}>Total</div>
                    {map(timeReport.rows, (row, rowIndex) => (
                      <div key={'row' + rowIndex} className={`cp-ellipsis ${styles.gridItem} ${styles.farRightColumn}`}>
                        {formatValueCell(row.total, isCurrency)}
                      </div>
                    ))}
                    <div className={`${styles.gridItem__footer} cp-ellipsis`}>
                      {formatValueCell(timeReport.column_totals.grand_total, isCurrency)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </CpCard>
      </div>
    </ReportWrapper>
  );
}
