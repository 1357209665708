// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-styles__leftPadding--lJIpa {
  padding-left: 24px;
}

.report-styles__totalRow--cyJri {
  font-weight: 600;
  border-top: 1px solid var(--cps-color-athens);
  background-color: var(--cps-color-background);
}

.report-styles__rowContents--zNs7L {
  display: flex;
}

.report-styles__rowItemPadding--TOK3v {
  padding: 16px 0px;
}

.report-styles__headerItem--ZdIDH {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-styles__noWidth--l7wke {
  flex: 0;
}

.report-styles__flex1--pO_aR {
  flex: 1;
}

.report-styles__flex2--VneST {
  flex: 2;
}

.report-styles__flex3--MzMhd {
  flex: 3;
}

.report-styles__flex4--feI4r {
  flex: 4;
}
`, "",{"version":3,"sources":["webpack://./src/reports/aged-receivables-detail/report/report.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,6CAA6C;EAC7C,6CAA6C;AAC/C;;AAEA;EAEE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,OAAO;EACP,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT","sourcesContent":[".leftPadding {\n  padding-left: 24px;\n}\n\n.totalRow {\n  font-weight: 600;\n  border-top: 1px solid var(--cps-color-athens);\n  background-color: var(--cps-color-background);\n}\n\n.rowContents {\n  composes: leftPadding;\n  display: flex;\n}\n\n.rowItemPadding {\n  padding: 16px 0px;\n}\n\n.headerItem {\n  flex: 1;\n  min-width: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.noWidth {\n  flex: 0;\n}\n\n.flex1 {\n  flex: 1;\n}\n\n.flex2 {\n  flex: 2;\n}\n\n.flex3 {\n  flex: 3;\n}\n\n.flex4 {\n  flex: 4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftPadding": `report-styles__leftPadding--lJIpa`,
	"totalRow": `report-styles__totalRow--cyJri`,
	"rowContents": `report-styles__rowContents--zNs7L report-styles__leftPadding--lJIpa`,
	"rowItemPadding": `report-styles__rowItemPadding--TOK3v`,
	"headerItem": `report-styles__headerItem--ZdIDH`,
	"noWidth": `report-styles__noWidth--l7wke`,
	"flex1": `report-styles__flex1--pO_aR`,
	"flex2": `report-styles__flex2--VneST`,
	"flex3": `report-styles__flex3--MzMhd`,
	"flex4": `report-styles__flex4--feI4r`
};
export default ___CSS_LOADER_EXPORT___;
