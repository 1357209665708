import React, { useEffect, useState, useContext } from 'react';
import { CpModal, CpButton, CpSelectSingle, currency, CpWell, CpIcon } from 'canopy-styleguide!sofe';
import { useCss, k } from 'kremling';

import { handleError } from 'src/common/handle-error.helper';
import { CurrencyInput } from 'src/payments/components/add-payment/currency-input.component';
import { getCreditInvoices } from 'src/resources/credits.resources';
import { outstandingWithDrafts } from 'src/invoices/invoices.helper';
import { CreditContext } from 'src/credits/credit-context';

export const ApplyCreditModal = ({ show, onClose, onApplyCredit, qboIntegrationInfo }) => {
  const scope = useCss(css);
  const { credit, creditRemaining } = useContext(CreditContext);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const [amountToCredit, setAmountToCredit] = useState(0);
  const [isCreditSyncedToThirdParty, setIsCreditSyncedToThirdParty] = useState(false);

  useEffect(() => {
    if (!show) return;
    const { id } = credit?.relationships?.for ?? {};
    const subscription = getCreditInvoices(id, outstandingWithDrafts).subscribe(res => {
      setInvoices(res.invoices);
      setLoadingInvoices(false);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [show]);

  useEffect(() => {
    if (!show) return;
    const creditAmountRemaining = Number(creditRemaining);
    const selectedInvoiceBalance = Number(selectedInvoice?.balance || 0);
    if (!selectedInvoice) {
      setAmountToCredit(0);
    } else {
      let amount = Number(amountToCredit);
      if (amount > creditAmountRemaining || amount > selectedInvoiceBalance) {
        setAmountToCredit(Math.min(creditAmountRemaining, selectedInvoiceBalance));
      }
    }
  }, [selectedInvoice, amountToCredit, show]);

  useEffect(() => {
    if (!show) return;

    if (qboIntegrationInfo?.connected) {
      setIsCreditSyncedToThirdParty(!!credit.third_party_id);
    } else {
      setIsCreditSyncedToThirdParty(false);
    }
  }, [show, qboIntegrationInfo?.connected, credit.id, credit.payment_id]);

  const reset = () => {
    setInvoices([]);
    setSelectedInvoice(null);
    setLoadingInvoices(true);
    setAmountToCredit(0);
    setIsCreditSyncedToThirdParty(false);
  };

  const filteredInvoices = invoices.filter(invoice => {
    if (!isCreditSyncedToThirdParty) return true;
    return !!invoice.third_party_id;
  });

  return (
    <CpModal width={824} show={show} onClose={onClose} onAfterClose={reset}>
      <CpModal.Header title="Apply Credit" />
      <CpModal.Body {...scope}>
        {qboIntegrationInfo?.connected && !!qboIntegrationInfo.payments?.sync_status?.first_synced_at && (
          <CpWell level={2} className="integration-sync-box">
            <div className="cp-flex-center">
              <img
                src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
                height="24px"
                alt="QuickBooks Online Logo"
              />
              <strong className="cp-ml-8">Credit is{!isCreditSyncedToThirdParty && ' NOT'} synced</strong>
            </div>
            {isCreditSyncedToThirdParty && !!qboIntegrationInfo.payments?.sync_status?.synced_at && (
              <div className="cp-caption cp-mr-8">
                Invoices must be synced to apply this credit
                {!filteredInvoices.length && '. You have no synced invoices'}
              </div>
            )}
            {!qboIntegrationInfo.payments?.sync_status?.synced_at && (
              <div className="cp-caption cp-flex-center">
                <div style={{ width: '48rem' }}>
                  <strong>Payment syncing is currently paused.</strong> Credits applied to an invoice during the pause
                  will not be reflected in QBO. An admin may resume syncing in{' '}
                  <a href="/#/global-settings/billing/integrations" target="_blank">
                    billing settings.
                  </a>
                </div>
                <CpIcon name="alert-triangle-open-large" fill="var(--cp-color-app-icon)" className="cp-ml-8" />
              </div>
            )}
          </CpWell>
        )}
        <table className="cps-inset-table apply-credit-table">
          <thead>
            <tr>
              <th style={{ minWidth: '150px' }}>Credit Number</th>
              <th style={{ minWidth: '150px' }}>Credit Available</th>
              <th style={{ minWidth: '180px' }}>Invoice #</th>
              <th>Amount to Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr className="cp-apply-credit-line">
              <td style={{ borderBottom: 'none' }}>{credit?.credit_number}</td>
              <td style={{ borderBottom: 'none' }}>{currency(creditRemaining)}</td>
              <td style={{ borderBottom: 'none' }}>
                <CpSelectSingle
                  placeholder="Select One"
                  contentWidth={200}
                  loading={loadingInvoices}
                  data={filteredInvoices}
                  transformData={invoice => ({
                    ...invoice,
                    name: invoice.invoice_number,
                  })}
                  onChange={setSelectedInvoice}
                  value={selectedInvoice}
                  triggerIsBlock
                  disabled={!filteredInvoices.length && isCreditSyncedToThirdParty}
                />
              </td>
              <td style={{ borderBottom: 'none' }}>
                <CurrencyInput onChange={setAmountToCredit} value={amountToCredit} allowNegative={false} />
              </td>
              <td style={{ borderBottom: 'none' }}>{currency(selectedInvoice?.balance - amountToCredit || 0)}</td>
            </tr>
          </tbody>
        </table>
        {isCreditSyncedToThirdParty &&
          !!filteredInvoices.length &&
          !!qboIntegrationInfo.payments?.sync_status?.first_synced_at && (
            <div className="cp-flex-center justify-end">
              <CpIcon name="information-circle-open-small" fill="var(--cp-color-app-icon)" />
              <em className="cp-ml-8 cp-caption cp-color-app-secondary-text">Only displaying synced invoice numbers</em>
            </div>
          )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => onApplyCredit(amountToCredit, selectedInvoice)}
          className="cp-mr-8"
          disabled={!Number(amountToCredit)}>
          Apply credits
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};

const css = k`
 .apply-credit-table {
    border: 1px solid var(--cp-color-app-border);
    border-radius: 0.8rem;
    border-spacing: 0;
    padding-top: 0.8rem;
  }

  .integration-sync-box {
    height: 6.6rem;
    padding: 0 2.4rem;
    margin-bottom: 1.6rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
