import React, { useState } from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';

import styles from './time-entry.styles.css';

export default function TimeEntryServiceSelector(props) {
  const {
    disableEdit,
    onServiceItemChange,
    selectedServiceItem,
    showLabel = true,
    categorizedServices,
    allServiceItems,
    qboCategories,
  } = props;
  const [searchVal, setSearchVal] = useState('');

  return (
    <div className={showLabel ? `${styles.editLabelAndInput} cps-form-group` : ''}>
      {showLabel && (
        <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
          <label htmlFor="serviceSelect">Service</label>
        </div>
      )}
      <div id="serviceSelect">
        <CpSelectSingle
          clearable
          contentWidth="block"
          data={categorizedServices || allServiceItems}
          isGroupData={!!qboCategories?.length}
          disabled={disableEdit}
          onChange={service => onServiceItemChange(service)}
          placeholder="Select a service item"
          searchFilter
          searchOnChange={val => setSearchVal(val)}
          searchValue={searchVal}
          triggerIsBlock
          value={selectedServiceItem}
        />
      </div>
    </div>
  );
}
