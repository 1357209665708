// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-recurring-series-styles__recurrenceItem--VqZSc:hover {
  background-color: var(--cps-color-ash);
}
`, "",{"version":3,"sources":["webpack://./src/payments/components/add-payment/select-recurring-series.styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC","sourcesContent":[".recurrenceItem:hover {\n  background-color: var(--cps-color-ash);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recurrenceItem": `select-recurring-series-styles__recurrenceItem--VqZSc`
};
export default ___CSS_LOADER_EXPORT___;
