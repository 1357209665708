import { getAgedReceivableData, getAgedReceivableDataCSV } from '../common/receivables.resource.js';
import { dueDateFunctionMap, termsMap, statiMap } from './ard-resource-helper';

export function getAgedReceivableDetail(options = {}) {
  const { schema, filters } = options;
  const includeInvoices = true;
  return getAgedReceivableData(includeInvoices, getSchemaOverrides(schema), getFilters(filters));
}

export function getAgedReceivableDetailCSV(options = {}) {
  const { schema, filters } = options;
  const includeInvoices = true;
  return getAgedReceivableDataCSV(includeInvoices, getSchemaOverrides(schema), getFilters(filters));
}

function getSchemaOverrides() {
  return undefined;
}

function getFilters(filters) {
  if (!filters) return;

  let returnObj = {};

  // duedate

  let dueDateValue = filters.DueDateKey;

  if (typeof dueDateValue === 'string') {
    if (dueDateValue !== 'all') {
      returnObj.filter_by_invoice_due_dates = dueDateFunctionMap[filters.DueDateKey]();
    }
  } else if (dueDateValue.constructor === Array) {
    returnObj.filter_by_invoice_due_dates = [dueDateValue]; // custom
  }

  // terms & stati (plural status)

  if (filters.TermsKeys.length && !(filters.TermsKeys.length === 1 && filters.TermsKeys[0] === 'all')) {
    const terms = filters.TermsKeys.map(term => termsMap[term]);
    returnObj.filter_by_invoice_terms = terms;
  }

  if (filters.StatiKeys.length && !(filters.StatiKeys.length === 1 && filters.StatiKeys[0] === 'all')) {
    const stati = filters.StatiKeys.map(status => statiMap[status]);
    returnObj.filter_by_invoice_status = stati;
  }

  if (filters.OwnerKey) {
    returnObj.filter_by_owner_ids = [filters.OwnerKey];
  }

  return returnObj;
}
