// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-builder-styles__creditBuilderLineItems--S3E8Q {
  overflow-x: auto;
}

.credit-builder-styles__creditBuilderLineItems--S3E8Q table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
}

.credit-builder-styles__creditBuilderLineItems--S3E8Q table thead th {
  text-align: left;
  padding: 0 0 16px 16px;
}

.credit-builder-styles__creditBuilderLineItems--S3E8Q table thead th div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 8px;
}

.credit-builder-styles__creditBuilderLineItems--S3E8Q table tbody td {
  padding: 0;
}

.credit-builder-styles__creditBuilderLineItems--S3E8Q table tbody .credit-builder-styles__dataCell--QVEwX {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.credit-builder-styles__creditBuilderLineItems--S3E8Q table tbody .credit-builder-styles__totalCell--RyqPP {
  height: 48px;
  padding: 0 16px;
}

.credit-builder-styles__creditBuilderLineItems--S3E8Q table tr {
  border-bottom: 1px solid var(--cp-color-app-border);
}

.credit-builder-styles__subTotalContainer--qgEGc {
  display: flex;
  text-align: right;
  margin-right: 32px;
}

.credit-builder-styles__descriptionCell--rsLN3 {
  width: 210px !important;
}
`, "",{"version":3,"sources":["webpack://./src/credits/components/credit-builder/credit-builder.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".creditBuilderLineItems {\n  overflow-x: auto;\n}\n\n.creditBuilderLineItems table {\n  position: relative;\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.creditBuilderLineItems table thead th {\n  text-align: left;\n  padding: 0 0 16px 16px;\n}\n\n.creditBuilderLineItems table thead th div {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 8px 0 8px;\n}\n\n.creditBuilderLineItems table tbody td {\n  padding: 0;\n}\n\n.creditBuilderLineItems table tbody .dataCell {\n  height: 48px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 16px;\n}\n\n.creditBuilderLineItems table tbody .totalCell {\n  height: 48px;\n  padding: 0 16px;\n}\n\n.creditBuilderLineItems table tr {\n  border-bottom: 1px solid var(--cp-color-app-border);\n}\n\n.subTotalContainer {\n  display: flex;\n  text-align: right;\n  margin-right: 32px;\n}\n\n.descriptionCell {\n  width: 210px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creditBuilderLineItems": `credit-builder-styles__creditBuilderLineItems--S3E8Q`,
	"dataCell": `credit-builder-styles__dataCell--QVEwX`,
	"totalCell": `credit-builder-styles__totalCell--RyqPP`,
	"subTotalContainer": `credit-builder-styles__subTotalContainer--qgEGc`,
	"descriptionCell": `credit-builder-styles__descriptionCell--rsLN3`
};
export default ___CSS_LOADER_EXPORT___;
