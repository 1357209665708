import React, { useState, useContext } from 'react';
import { CpButton, CpModal } from 'canopy-styleguide!sofe';
import { ColumnVisibility, LineItemGrouped } from '../invoice-builder/form-components';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { invoiceTypes } from 'src/invoices/invoices.helper';

export const InvoiceConfigurationModal = ({ hasGroupBilling, showConfigureModal, hideConfigureModal, disabled }) => {
  const { invoice, invoiceType, groupLineItems, actions, options } = useContext(InvoiceContext);
  const [visibleColumns, setVisibleColumns] = useState(invoice.columnVisibility);
  const [grouped, setGrouped] = useState(groupLineItems);

  const isRecurringInvoice = invoiceType === invoiceTypes.recurring;

  return (
    <CpModal show={showConfigureModal} onClose={hideConfigureModal}>
      <CpModal.Header title="Layout" />
      <CpModal.Body>
        <div>
          <ColumnVisibility
            hasGroupBilling={hasGroupBilling}
            value={visibleColumns}
            update={setVisibleColumns}
            disabled={disabled}
            none={options.singleLine}
          />
        </div>
        {!isRecurringInvoice && (
          <div className="cp-mt-16">
            <LineItemGrouped
              value={grouped}
              update={setGrouped}
              disabled={disabled}
              warn={groupLineItems !== grouped && invoice.lineItems.fromTime.length > 0}
            />
          </div>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            hideConfigureModal();
            actions.configure(visibleColumns, grouped);
          }}>
          Save
        </CpButton>
        <CpButton btnType="flat" onClick={hideConfigureModal}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};
