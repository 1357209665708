import { DateTime } from 'luxon';

export const dateRangeTypes = {
  all: 'all',
  today: 'today',
  yesterday: 'yesterday',
  tomorrow: 'tomorrow',
  currentWeek: 'currentWeek',
  lastWeek: 'lastWeek',
  nextWeek: 'nextWeek',
  currentMonth: 'currentMonth',
  lastMonth: 'lastMonth',
  currentQuarter: 'currentQuarter',
  lastQuarter: 'lastQuarter',
  yearToDate: 'yearToDate',
  lastYear: 'lastYear',
  custom: 'custom',
};

export const dateRanges = {
  [dateRangeTypes.all]: {
    label: 'All due dates',
    filter: {},
  },
  [dateRangeTypes.today]: {
    label: 'Today',
    filter: {
      before: DateTime.local().toISODate(), // today
      after: DateTime.local().toISODate(),
    },
  },
  [dateRangeTypes.yesterday]: {
    label: 'Yesterday',
    filter: {
      before: DateTime.local().minus({ days: 1 }).toISODate(), // yesterday
      after: DateTime.local().minus({ days: 1 }).toISODate(),
    },
  },
  [dateRangeTypes.tomorrow]: {
    label: 'Tomorrow',
    filter: {
      before: DateTime.local().plus({ days: 1 }).toISODate(), // tomorrow
      after: DateTime.local().plus({ days: 1 }).toISODate(), // tomorrow
    },
  },
  [dateRangeTypes.currentWeek]: {
    label: 'This week',
    filter: {
      after: DateTime.local().set({ weekday: 0 }).toISODate(), // sunday of current week
      before: DateTime.local().set({ weekday: 6 }).toISODate(),
    },
  },
  [dateRangeTypes.lastWeek]: {
    label: 'Last week',
    filter: {
      after: DateTime.local().set({ weekday: -7 }).toISODate(), // sunday of last week
      before: DateTime.local().set({ weekday: -1 }).toISODate(), // saturday of last week
    },
  },
  [dateRangeTypes.nextWeek]: {
    label: 'Next week',
    filter: {
      after: DateTime.local().set({ weekday: 7 }).toISODate(), // sunday of next week
      before: DateTime.local().set({ weekday: 13 }).toISODate(), // saturday of next week
    },
  },
  [dateRangeTypes.currentMonth]: {
    label: 'This month',
    filter: {
      after: DateTime.local().startOf('month').toISODate(), // first day of this month
      before: DateTime.local().endOf('month').toISODate(), //last day of the month
    },
  },
  [dateRangeTypes.lastMonth]: {
    label: 'Last month',
    filter: {
      after: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(), // first day of last month
      before: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
    },
  },
  [dateRangeTypes.currentQuarter]: {
    label: 'This quarter',
    filter: {
      after: DateTime.local().startOf('quarter').toISODate(),
      before: DateTime.local().endOf('quarter').toISODate(),
    },
  },
  [dateRangeTypes.lastQuarter]: {
    label: 'Last quarter',
    filter: {
      after: DateTime.local().minus({ quarters: 1 }).startOf('quarter').toISODate(),
      before: DateTime.local().minus({ quarters: 1 }).endOf('quarter').toISODate(),
    },
  },
  [dateRangeTypes.yearToDate]: {
    label: 'Year to date',
    filter: {
      after: DateTime.local().startOf('year').toISODate(),
      before: DateTime.local().toISODate(),
    },
  },
  [dateRangeTypes.lastYear]: {
    label: 'Last year',
    filter: {
      after: DateTime.local().minus({ years: 1 }).startOf('year').toISODate(),
      before: DateTime.local().minus({ years: 1 }).endOf('year').toISODate(),
    },
  },
  // custom
  [dateRangeTypes.custom]: {
    label: 'Custom',
    filter: {
      after: DateTime.local().toISODate(), // today
      before: DateTime.local().toISODate(), // today
    },
  },
};

export const defaultDateFilterRanges = {
  [dateRangeTypes.today]: dateRanges[dateRangeTypes.today],
  [dateRangeTypes.yesterday]: dateRanges[dateRangeTypes.yesterday],
  [dateRangeTypes.currentWeek]: dateRanges[dateRangeTypes.currentWeek],
  [dateRangeTypes.lastWeek]: dateRanges[dateRangeTypes.lastWeek],
  [dateRangeTypes.currentMonth]: dateRanges[dateRangeTypes.currentMonth],
  [dateRangeTypes.lastMonth]: dateRanges[dateRangeTypes.lastMonth],
  [dateRangeTypes.lastYear]: dateRanges[dateRangeTypes.lastYear],
  [dateRangeTypes.yearToDate]: dateRanges[dateRangeTypes.yearToDate],
  [dateRangeTypes.custom]: dateRanges[dateRangeTypes.custom],
};
