import React from 'react';
import { DateTime } from 'luxon';
// project
import styles from './invoice.styles.css';
import SchemaItems from 'src/reports/common/schema-items/schema-items.component';
import { showPaymentModal } from 'src/payments/components/add-payment/add-payment.component';
import { showInvoiceOverlay } from 'src/invoices/components/invoice-overlay/invoice-overlay-wrapper.component';

export default class AgedReceivableInvoice extends React.Component {
  render() {
    const { invoice, schema, checkboxes } = this.props;
    const { number, date, due_date, bucket_values, payment_terms } = invoice;

    return (
      <div className={`${styles.invoice}`}>
        <div className={`${styles.invoiceItem}`}>
          <a onClick={() => this.viewInvoice()}>{number}</a>
        </div>
        {checkboxes.get('invoiceDate') && <div className={`${styles.invoiceItem}`}>{this.formatDate(date)}</div>}

        <div className={`${styles.invoiceItem}`}>{this.formatDate(due_date)}</div>

        {checkboxes.get('terms') && <div className={`${styles.invoiceItem}`}>{payment_terms}</div>}
        <SchemaItems schema={schema} childClass={`${styles.invoiceItem}`} items={bucket_values} />
      </div>
    );
  }

  formatDate = date => {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
  };

  viewInvoice = () => {
    const overlayProps = {
      overlayData: { invoiceId: this.props.invoice.id, mode: 'view', type: 'invoice' },
      onModalClose: this.hideViewInvoice,
    };
    showInvoiceOverlay(overlayProps);
  };

  hideViewInvoice = (addPayment = false, paymentData = {}) => {
    addPayment && showPaymentModal({ paymentData, onModalClose: () => {} });
  };
}
