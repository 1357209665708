import { TextCell, NumberCell, CurrencyCell, ToolTipCell, PercentCell } from '../common/cell-components';
import { featureEnabled } from 'feature-toggles!sofe';

const columnKeys = {
  CLIENT: 'client',
  SERVICE_ITEM: 'service_item',
  ASSIGNEE: 'assignee',
  TOTAL_HOURS: 'total_hours',
  BILLED_HOURS: 'billed_hours',
  BILLABLE_AMOUNT: 'billable_amount',
  ADJUSTED_AMOUNT: 'adjusted_amount',
  BILLED_AMOUNT: 'billed_amount',
  LABOR_COST: 'labor_cost',
  REALIZATION: 'realization',
  BALANCE: 'balance',
};

const columnDefs = {
  [columnKeys.CLIENT]: {
    key: 'row_name',
    label: 'Client',
    renderCell: TextCell,
  },
  [columnKeys.CLIENT_GROUP]: {
    key: 'row_name',
    label: 'Client Group',
    renderCell: TextCell,
  },
  [columnKeys.SERVICE_ITEM]: {
    key: 'row_name',
    label: 'Service Item',
    renderCell: TextCell,
  },
  [columnKeys.ASSIGNEE]: {
    key: 'users',
    label: 'Time Tracked By',
    renderCell: ToolTipCell,
  },
  [columnKeys.BILLABLE_AMOUNT]: {
    key: 'billable_amount',
    label: 'Billable Amount',
    renderCell: CurrencyCell,
  },
  [columnKeys.ADJUSTED_AMOUNT]: {
    key: 'adjusted_amount',
    label: 'Adjusted Amount',
    renderCell: CurrencyCell,
  },
  [columnKeys.TOTAL_HOURS]: {
    key: 'total_hours',
    label: 'Total Hours',
    renderCell: TextCell,
  },
  [columnKeys.BILLED_HOURS]: {
    key: 'billed_hours',
    label: 'Billed Hours',
    renderCell: NumberCell,
  },
  [columnKeys.BILLED_AMOUNT]: {
    key: 'billed_amount',
    label: 'Billed Amount',
    renderCell: CurrencyCell,
  },
  [columnKeys.LABOR_COST]: {
    key: 'labor_cost',
    label: 'Labor Cost',
    renderCell: CurrencyCell,
  },
  [columnKeys.REALIZATION]: {
    key: 'realization',
    label: 'Realization',
    renderCell: PercentCell,
  },
  [columnKeys.BALANCE]: {
    key: 'balance',
    label: 'Balance',
    renderCell: CurrencyCell,
  },
};

export const getClientProfitabilityColumns = groupBy => ({
  ...(groupBy === 'Client'
    ? { [columnKeys.CLIENT]: columnDefs[columnKeys.CLIENT] }
    : groupBy === 'Client Group'
    ? { [columnKeys.CLIENT_GROUP]: columnDefs[columnKeys.CLIENT_GROUP] }
    : { [columnKeys.SERVICE_ITEM]: columnDefs[columnKeys.SERVICE_ITEM] }),
  [columnKeys.ASSIGNEE]: columnDefs[columnKeys.ASSIGNEE],
  [columnKeys.TOTAL_HOURS]: columnDefs[columnKeys.TOTAL_HOURS],
  [columnKeys.BILLED_HOURS]: columnDefs[columnKeys.BILLED_HOURS],
  [columnKeys.BILLABLE_AMOUNT]: columnDefs[columnKeys.BILLABLE_AMOUNT],
  [columnKeys.ADJUSTED_AMOUNT]: columnDefs[columnKeys.ADJUSTED_AMOUNT],
  [columnKeys.BILLED_AMOUNT]: columnDefs[columnKeys.BILLED_AMOUNT],
  [columnKeys.LABOR_COST]: columnDefs[columnKeys.LABOR_COST],
  ...(featureEnabled('toggle_gs_realization_column_profitability_report') && {
    [columnKeys.REALIZATION]: columnDefs[columnKeys.REALIZATION],
  }),
  [columnKeys.BALANCE]: columnDefs[columnKeys.BALANCE],
  [columnKeys.BILLED_AMOUNT]: columnDefs[columnKeys.BILLED_AMOUNT],
});
