const duedates = [
  {
    name: 'All due dates',
    id: 'all',
  },
  {
    name: 'Today',
    id: 'today',
  },
  {
    name: 'Current Week',
    id: 'currentWeek',
  },
  {
    name: 'Last week',
    id: 'lastWeek',
  },
  {
    name: 'Current Month',
    id: 'currentMonth',
  },
  {
    name: 'Last month',
    id: 'lastMonth',
  },
  {
    name: 'Current quarter',
    id: 'currentQuarter',
  },
  {
    name: 'Last quarter',
    id: 'lastQuarter',
  },
  {
    name: 'Year-to-date',
    id: 'yearToDate',
  },
  {
    name: 'Last year',
    id: 'lastYear',
  },
  {
    name: 'Custom',
    id: 'custom',
  },
];

export default duedates;
