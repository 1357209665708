// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../common/report.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-styles__ellipsis--B0_7r {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.report-styles__reportItem--nkk0l {
  flex: 2;
}

.report-styles__wideReportItem--Y2bbo {
  flex: 3;
}

.report-styles__sidePadding--qyqiJ {
  padding-left: 32px;
  padding-right: 32px;
}

.report-styles__rowPadding--HKLau {
  padding-top: 16px;
  padding-bottom: 16px;
}

.report-styles__table--GNDno {
}

.report-styles__row--EO4Uj {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--cps-color-athens);
}
`, "",{"version":3,"sources":["webpack://./src/reports/aged-receivables-summary/report/report.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EAEE,OAAO;AACT;;AAEA;EAEE,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EAEE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;AAEA;;AAEA;EAEE,aAAa;EACb,WAAW;EACX,gDAAgD;AAClD","sourcesContent":[".ellipsis {\n  min-width: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.reportItem {\n  composes: ellipsis;\n  flex: 2;\n}\n\n.wideReportItem {\n  composes: ellipsis;\n  flex: 3;\n}\n\n.sidePadding {\n  padding-left: 32px;\n  padding-right: 32px;\n}\n\n.rowPadding {\n  composes: sidePadding;\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n\n.table {\n  composes: table from 'src/reports/common/report.styles.css'\n}\n\n.row {\n  composes: rowPadding;\n  display: flex;\n  width: 100%;\n  border-bottom: 1px solid var(--cps-color-athens);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ellipsis": `report-styles__ellipsis--B0_7r`,
	"reportItem": `report-styles__reportItem--nkk0l report-styles__ellipsis--B0_7r`,
	"wideReportItem": `report-styles__wideReportItem--Y2bbo report-styles__ellipsis--B0_7r`,
	"sidePadding": `report-styles__sidePadding--qyqiJ`,
	"rowPadding": `report-styles__rowPadding--HKLau report-styles__sidePadding--qyqiJ`,
	"table": `report-styles__table--GNDno ${___CSS_LOADER_ICSS_IMPORT_0___.locals["table"]}`,
	"row": `report-styles__row--EO4Uj report-styles__rowPadding--HKLau report-styles__sidePadding--qyqiJ`
};
export default ___CSS_LOADER_EXPORT___;
