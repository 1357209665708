import { DateTime } from 'luxon';
import { CpTable } from 'canopy-styleguide!sofe';
import { getExpenseSearchOptions } from 'src/resources/expense.resources';
import { transformKeys, transformItem, transformBoolFilter } from 'src/billing-helpers';

export const tablesDefs = {
  expenses: 'Expenses',
  archivedExpenses: 'Archived Expenses',
  billedExpenses: 'Billed Expenses',
};

export const keyMap = {
  expense_number: 'expense_numbers',
  client_name: 'client_ids',
  team_member_name: 'team_member_ids',
  invoice_number: 'invoice_numbers',
};

export const formatExpenseFilters = filters => {
  let transformedFilters = {};

  for (const [key, value] of Object.entries(filters)) {
    if (key === 'billable') {
      transformedFilters = { ...transformedFilters, ...transformBoolFilter('billable', 'nonBillable', value.data) };
      continue;
    }
    transformedFilters = { ...transformedFilters, ...transformKeys(transformItem(value), keyMap) };
  }

  return transformedFilters;
};

export const expenseSchema = (showExpenseById, isArchivedTable, isBilledTable, clientId, handleViewInvoice) => {
  return {
    expenseNumber: {
      fieldId: 'expense_number',
      label: 'Expense #',
      dataType: 'string',
      sticky: 'left',
      cell: {
        component: CpTable.LinkCell,
        props: data => ({
          onClick: () => showExpenseById(data.resource.id),
          label: data.value,
        }),
      },
      filter: {
        component: CpTable.InputFilter,
        props: {
          hideConditionOptions: ['include_empty'],
        },
      },
      sort: true,
      width: 130,
    },
    clientName: {
      fieldId: 'client_name',
      label: 'Client Name',
      dataType: 'string',
      cell: {
        component: CpTable.LinkCell,
        props: data => ({
          ...(data.resource.client_active && {
            href: '/#/client/' + data.resource.client_id + '/billing/expenses',
          }),
          label: data.resource.client_id ? data.value : null,
        }),
      },
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: ({ search, filterControl }) => {
            return new Promise(resolve => {
              getExpenseSearchOptions(
                search,
                filterControl.filters,
                filterControl.sortData,
                isArchivedTable,
                isBilledTable,
                null,
                'client'
              ).subscribe(response => resolve(response.clients));
            });
          },
          hideConditionOptions: ['is_blank', 'is_all_of', 'has_any_value'],
        },
      },
      width: 170,
    },
    date: {
      fieldId: 'date',
      label: 'Date',
      dataType: 'date_iso',
      filter: {
        props: {
          hideNoDateAdded: true,
        },
      },
      sort: true,
      width: 170,
    },
    note: {
      fieldId: 'note',
      dataType: 'string',
      label: 'Note',
      filter: true,
      width: 170,
    },
    teamMember: {
      fieldId: 'team_member_name',
      label: 'Team Member',
      dataType: 'string',
      sort: true,
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: ({ search, filterControl }) => {
            return new Promise(resolve => {
              getExpenseSearchOptions(
                search,
                filterControl.filters,
                filterControl.sortData,
                isArchivedTable,
                isBilledTable,
                clientId,
                'team_member'
              ).subscribe(response => resolve(response.team_members.filter(member => member.id !== null)));
            });
          },
          hideConditionOptions: ['is_all_of'],
        },
      },
      width: 170,
    },
    amount: {
      fieldId: 'amount',
      dataType: 'number',
      label: 'Amount',
      cell: { component: CpTable.CurrencyCell },
      filter: {
        component: CpTable.InputFilter,
        props: {
          hideConditionOptions: ['include_empty'],
        },
      },
      sort: true,
      width: 170,
    },
    billable: {
      fieldId: 'billable',
      dataType: 'boolean',
      label: 'Billable',
      cell: { component: CpTable.BoolCell },
      filter: {
        component: CpTable.SelectFilter,
        props: {
          getSelectionOptions: () => {
            return [
              { id: 'billable', name: 'Billable' },
              { id: 'nonBillable', name: 'Non-billable' },
            ];
          },
        },
      },
      sort: true,
      width: 170,
    },
    ...(isBilledTable && {
      invoiceNumber: {
        fieldId: 'invoice_number',
        label: 'Invoice #',
        dataType: 'string',
        cell: {
          component: CpTable.LinkCell,
          props: data => ({
            ...(data.resource.client_active && {
              onClick: () =>
                handleViewInvoice({
                  type: 'invoice',
                  mode: 'view',
                  clientId: data.resource.client_id,
                  invoiceId: data.resource.invoice_id,
                }),
            }),
            label: `${data.value}`,
          }),
        },
        filter: {
          component: CpTable.InputFilter,
        },
        sort: true,
        width: 164,
      },
    }),
  };
};

export const initialExpenseObj = {
  id: '',
  expense_number: '',
  client_id: null,
  client_name: null,
  date: DateTime.local().toISODate(),
  note: '',
  team_member_name: null,
  team_member_id: null,
  amount: '0.00',
  billable: true,
};
