// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-title-styles__wrapper--EnsWy {
  position: relative;
}

.report-title-styles__header--LIkbO {
  margin-top: 24px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.report-title-styles__rightCol--y0JRV {
  position: absolute;
  align-items: center;
  display: flex;
  margin-right: 24px;
  right: 0;
  top: -8px;
}
`, "",{"version":3,"sources":["webpack://./src/reports/common/report-title/report-title.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.header {\n  margin-top: 24px;\n  margin-left: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.rightCol {\n  position: absolute;\n  align-items: center;\n  display: flex;\n  margin-right: 24px;\n  right: 0;\n  top: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `report-title-styles__wrapper--EnsWy`,
	"header": `report-title-styles__header--LIkbO`,
	"rightCol": `report-title-styles__rightCol--y0JRV`
};
export default ___CSS_LOADER_EXPORT___;
