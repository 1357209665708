import moment from 'moment';
import { find } from 'lodash';
import { featureEnabled } from 'feature-toggles!sofe';
import { round } from 'src/billing-helpers';
import { toNumber, invoiceTerms } from 'src/invoices/invoices.helper';
import { getStatus } from 'src/invoices/recurrences.helper';
import { convertToCurrencyText } from 'src/payments/payments.helper';

export const getColumns = (showClassCol = false, showClientCol = false) => {
  return [
    { key: 'service', label: 'Service', width: '185px', canHide: true },
    ...(showClientCol ? [{ key: 'client', label: 'Client', width: '200px', canHide: true }] : []),
    { key: 'description', label: 'Description', width: '300px', canHide: true },
    { key: 'quantity', label: 'Quantity', width: '120px', canHide: true },
    { key: 'rateType', label: 'Rate Type', width: '160px', canHide: false },
    { key: 'rate', label: 'Rate', width: '160px', canHide: true },
    { key: 'subAmount', label: 'Amount', width: '120px', canHide: false },
    { key: 'wuwd', label: 'WUWD', width: '160px', canHide: false },
    { key: 'assignee', label: 'Assignee', width: '160px', canHide: true },
    { key: 'date', label: 'Date', width: '160px', canHide: true },
    { key: 'task', label: 'Task', width: '160px', canHide: true },
    { key: 'subtask', label: 'Subtask', width: '160px', canHide: true },
    { key: 'discount', label: 'Discount', width: '160px', canHide: true },
    { key: 'tax', label: 'Tax', width: '160px', canHide: true },
    ...(showClassCol ? [{ key: 'qboClass', label: 'Class', width: '160px', canHide: false }] : []),
    { key: 'amount', label: 'Total', width: '160px', canHide: false },
  ];
};

export const getNewLineItem = () => {
  return {
    id: Math.ceil(Math.random() * 100000),
    description: '',
    quantity: 1,
    rateType: 'other',
    rate: 0,
    amount: 0,
    wuwd: 0,
    discount: 0,
    discountIsPercent: true,
    taxRate: 0,
    total: 0,
    assignee: '',
    timeEntryDate: '',
    task: '',
    subtask: '',
  };
};

export const getDefaultInvoice = () => {
  return {
    invoiceNumber: '',
    ...(featureEnabled('toggle_gs_concurrent_invoice') && { initialInvoiceNumber: null }),
    invoiceDate: moment(new Date().getTime()),
    terms: { id: 0, name: 'Due on Receipt' },
    columnVisibility: [],
    lineItems: { standard: [getNewLineItem()], fromTime: [], hidden: [], lateFees: [] },
    singleLineDiscount: 0,
    singleLineDiscountIsPercent: true,
  };
};

export const getDefaultRecurrence = () => {
  return {
    description: '',
    startDate: moment(),
    endDate: moment().add(1, 'months'),
    endOption: 'Never',
    endAfter: 1,
    frequencyOption: 'Monthly',
    monthlyOption: 'day',
    dayInMonth: '1st',
    dayInWeek: 'Sunday',
    monthInYear: 'January',
    repeatInterval: 1,
    recurringTerms: { id: 0, name: 'Due on Receipt' },
    fixedTerm: 0,
    status: 'Not Scheduled',
    autoLinkTime: false,
  };
};

export const mapInvoiceDtoToFormData = invoice => {
  const invoiceForm = {
    id: invoice.id,
    client: { id: invoice.relationships.for.id, name: invoice.relationships.for.name },
    clientGroup: invoice.client_group,
    dueDate: invoice.due_date,
    invoiceNumber: invoice.invoice_number,
    ...(featureEnabled('toggle_gs_concurrent_invoice') && { initialInvoiceNumber: null }),
    invoiceDate: moment(invoice.invoice_date),
    status: invoice.status,
    terms: { id: invoice.terms, name: invoice.payment_terms },
    columnVisibility: invoice.display_fields
      .filter(column =>
        getColumns(false, invoice.display_fields.includes('client'))
          .filter(c => c.canHide)
          .map(c => c.key)
          .includes(column)
      )
      .map(column => ({ id: column, name: column.capitalize() })),
    lineItems: formatLineItems(invoice.invoice_line_items),
    creditsApplied: invoice.credits,
    paymentsApplied: invoice.payments_applied,
    hasRecurringPayment: invoice.has_recurring_payment,
    termsConditions: invoice.terms_conditions,
    clientNote: invoice.client_notes,
    history: invoice.history,
    sentAt: invoice.sent_at,
    draft: invoice.draft,
    archived: invoice.archived,
    ...(invoice.single_line_item
      ? {
          singleLineDescription: invoice.single_description,
          singleLineDiscount: toNumber(invoice.single_discount_amount || invoice.single_discount_percent || 0),
          singleLineDiscountIsPercent: !!invoice.single_discount_percent,
        }
      : {
          singleLineDescription: '',
          singleLineDiscount: 0,
          singleLineDiscountIsPercent: true,
        }),
    payments: invoice.relationships.paid_by,
    isGrouped: invoice.is_grouped,
    thirdPartyData: invoice.third_party_data,
    includeSpouseName: invoice.include_spouse_name,
    isBusinessClient: invoice.is_business_client,
  };
  return invoiceForm;
};

const formatLineItems = lineItems => {
  const standard = [];
  const fromTime = [];
  const hiddenTime = [];
  const lateFees = [];

  lineItems.forEach(lineItem => {
    const rateType = lineItem.rate_type;
    const serviceRateType = lineItem.relationships?.service_item?.type;
    const isServicePerItem = rateType === 'service' && serviceRateType === 'item';

    const transformedLineItem = {
      id: lineItem.id || lineItem.item_id,
      expenseId: lineItem.expense_id,
      name: lineItem.name,
      service: lineItem.relationships?.service_item?.id
        ? {
            name: lineItem.relationships?.service_item?.name || lineItem.name,
            id: lineItem.relationships?.service_item?.id || null,
          }
        : null,
      serviceRate: lineItem?.relationships?.service_item?.rate,
      serviceRateType,
      assigneeRate: lineItem?.relationships?.employee?.rate,
      description: lineItem.description,
      hidden: lineItem.hidden,
      quantity: lineItem.quantity,
      late_fee: lineItem.late_fee,
      timeEntryDate: lineItem.time_entry_date,
      rate: lineItem.rate_amount,
      rateType,
      taxRate: lineItem.tax_rate_percent,
      discount: lineItem.discountIsPercent ? lineItem.discount_percent : lineItem.discount_amount,
      discountIsPercent: lineItem.discountIsPercent,
      amount: lineItem.quantity * lineItem.rate_amount,
      wuwd: isServicePerItem ? 0 : lineItem.manual_adjustment_amount,
      total: lineItem.total_amount,
      relationships: lineItem.relationships,
      assignee: lineItem.assignee,
      task: lineItem.task,
      subtask: lineItem.subtask,
      subLineItems: lineItem.sub_items?.map(subLineItem => {
        const quantity = parseFloat(subLineItem.quantity);
        const serviceRate = subLineItem?.relationships?.service_item?.rate;
        const rate = subLineItem.rate_amount;
        const amount = quantity * rate;
        return {
          id: subLineItem.id,
          service: {
            name: subLineItem.name,
            id: subLineItem.item_id,
          },
          wuwd: isServicePerItem ? 0 : subLineItem.manual_adjustment_amount,
          serviceRate,
          serviceRateType: lineItem.relationships?.service_item?.type,
          assigneeRate: subLineItem?.relationships?.employee?.rate,
          description: subLineItem.description,
          quantity: subLineItem.quantity,
          rate: subLineItem.rate_amount,
          amount,
          total: amount,
          relationships: subLineItem.relationships,
          assignee: subLineItem.assignee,
          timeEntryDate: subLineItem.time_entry_date,
          task: subLineItem.task,
          subtask: subLineItem.subtask,
          discountIsPercent: lineItem.discountIsPercent,
        };
      }),
    };
    if (
      lineItem.sub_items?.length ||
      find(lineItem.relationships?.sources, { type: 'time_entry' }) ||
      lineItem.expense_id
    ) {
      lineItem.hidden ? hiddenTime.push(transformedLineItem) : fromTime.push(transformedLineItem);
    } else if (lineItem.late_fee) {
      lateFees.push(transformedLineItem);
    } else {
      standard.push(transformedLineItem);
    }
  });

  return { standard, fromTime, hidden: hiddenTime, lateFees };
};

export const mapInvoiceFormDataToDto = (invoiceForm, options, availableCredits, checkInvoiceNumber = false) => {
  return {
    id: invoiceForm.id,
    client_notes: options.clientNote ? invoiceForm.clientNote : '',
    credits:
      invoiceForm?.creditsApplied?.length > 0
        ? invoiceForm.creditsApplied
        : options.useCredit
        ? availableCredits
            .filter(credit => credit.selected)
            .map(credit => ({ id: credit.id, amount: convertToCurrencyText(credit.amountToApply, false) }))
        : [],
    display_fields: [
      ...(options.singleLine ? [] : invoiceForm.columnVisibility.map(c => c.id)),
      ...(options.termsConditions ? ['terms_conditions'] : []),
    ],
    due_date: invoiceForm.dueDate,
    invoice_date: invoiceForm.invoiceDate,
    invoice_line_items: [
      ...invoiceForm.lineItems.fromTime,
      ...invoiceForm.lineItems.standard,
      ...(invoiceForm.lineItems.hidden || []),
      ...invoiceForm.lineItems.lateFees,
    ]
      .filter(
        lineItem =>
          lineItem.service ||
          lineItem.description ||
          lineItem.amount ||
          lineItem.discount ||
          lineItem.wuwd ||
          lineItem.hidden
      )
      .map(lineItem => ({
        ...mapLineItem(lineItem, null, !lineItem.subLineItems?.length && lineItem.relationships?.sources?.length),
        sub_items: lineItem.subLineItems?.map(subLineItem =>
          mapLineItem(subLineItem, lineItem, true, invoiceForm.wuwdSource)
        ),
      })),
    invoice_number:
      featureEnabled('toggle_gs_concurrent_invoice') &&
      checkInvoiceNumber &&
      invoiceForm.initialInvoiceNumber === invoiceForm.invoiceNumber.trim()
        ? null
        : invoiceForm.invoiceNumber,
    payment_terms: invoiceForm.terms.name,
    relationships: {
      for: { id: invoiceForm.client.id, name: invoiceForm.client.name },
      paid_by: invoiceForm.payments,
    },
    single_description: options.singleLine ? invoiceForm.singleLineDescription : null,
    single_discount_amount: options.singleLine
      ? invoiceForm.singleLineDiscountIsPercent
        ? null
        : invoiceForm.singleLineDiscount
      : null,
    single_discount_percent: options.singleLine
      ? invoiceForm.singleLineDiscountIsPercent
        ? invoiceForm.singleLineDiscount
        : null
      : null,
    single_line_item: options.singleLine,
    terms: invoiceForm.terms.id,
    terms_conditions: invoiceForm.termsConditions || '',
    third_party_data: invoiceForm.thirdPartyData,
    include_spouse_name: !invoiceForm.isBusinessClient && invoiceForm.includeSpouseName,
    client_group_id: invoiceForm.clientGroup?.id,
  };
};

const mapLineItem = (lineItem, parentLineItem, includeSource, wuwdSource) => {
  const percentageOfParentTotal = (Number(lineItem.amount) / Number(parentLineItem?.amount)) * 100;
  const discountRelativeToParentLineItem = (Number(parentLineItem?.discount) / 100) * percentageOfParentTotal;

  return {
    assignee: lineItem.assignee,
    description: lineItem.description || '',
    discount_amount: parentLineItem
      ? parentLineItem.discountIsPercent
        ? null
        : discountRelativeToParentLineItem
      : lineItem.discountIsPercent
      ? null
      : lineItem.discount,
    discount_percent: parentLineItem
      ? parentLineItem.discountIsPercent
        ? parentLineItem.discount
        : null
      : lineItem.discountIsPercent
      ? lineItem.discount
      : null,
    discountIsPercent: parentLineItem ? parentLineItem.discountIsPercent : lineItem.discountIsPercent,
    expense_id: lineItem.expenseId,
    hidden: lineItem.hidden,
    late_fee: lineItem.late_fee,
    id: typeof lineItem?.id === 'string' ? lineItem.id : null,
    name: lineItem.name === 'Expense' ? lineItem.name : lineItem.service?.name,
    quantity: lineItem.quantity.toString(),
    rate_amount: lineItem.rate,
    rate_type: parentLineItem ? parentLineItem.rateType : lineItem.rateType,
    relationships: {
      ...(lineItem.service?.id && { based_on: lineItem.service }),
      ...(includeSource && lineItem.relationships?.sources && { sources: lineItem.relationships.sources }),
      ...(lineItem.relationships?.client && { client: lineItem.relationships?.client }),
      employee: { name: lineItem.assignee, rate: lineItem.assigneeRate },
    },
    subtask: lineItem.subtask,
    task: lineItem.task,
    tax_rate_percent: parentLineItem ? parentLineItem.taxRate : lineItem.taxRate || '',
    time_entry_date: lineItem.timeEntryDate,
    manual_adjustment_amount: lineItem.wuwd,
    ...(parentLineItem && getAdjustedValues(lineItem, parentLineItem, wuwdSource)),
  };
};

const getAdjustedValues = (lineItem, parentLineItem, wuwdSource) => {
  let adjustedAmount, adjustedQuantity;
  if (wuwdSource === 'child') {
    adjustedAmount = round(lineItem.total + +lineItem.wuwd, 2);
    adjustedQuantity = round(adjustedAmount / lineItem.rate, 2);
  } else {
    const totalLineAmount = parentLineItem.subLineItems.reduce((sum, item) => sum + item.quantity * item.rate, 0);
    const percentOfAmount = (lineItem.quantity * lineItem.rate) / totalLineAmount;
    const parentTotal = parentLineItem.amount + parseFloat(parentLineItem.wuwd || 0);
    adjustedAmount = round(percentOfAmount * parentTotal, 2);
    adjustedQuantity = round(adjustedAmount / lineItem.rate, 2);
  }
  return {
    adjusted_amount: adjustedAmount,
    adjusted_quantity: adjustedQuantity,
  };
};

export const mapRecurrenceDtoToFormData = recurrence => {
  const recurrenceForm = {
    id: recurrence.id,
    description: recurrence.description,
    startDate: recurrence.start_date,
    endDate: recurrence.end_date,
    endOption: recurrence.end_option,
    endAfter: recurrence.remaining_invoices,
    frequencyOption: recurrence.frequency,
    monthlyOption: recurrence.frequency_monthly_option,
    dayInMonth:
      recurrence.frequency === 'Yearly' ? recurrence.frequency_yearly_date : recurrence.frequency_monthly_date,
    dayInWeek:
      recurrence.frequency === 'Weekly'
        ? recurrence.frequency_weekly_weekday
        : recurrence.frequency_monthly_nth_weekday,
    monthInYear: recurrence.frequency_yearly_month,
    repeatInterval:
      recurrence.frequency === 'Daily'
        ? recurrence.frequency_daily_interval
        : recurrence.frequency === 'Weekly'
        ? recurrence.frequency_weekly_interval
        : recurrence.frequency_monthly_interval,
    recurringTerms: {
      id: recurrence.payment_terms,
      name: find(invoiceTerms, obj => obj.id === recurrence.payment_terms).name,
    },
    fixedTerm: recurrence.terms,
    relationships: recurrence.relationships,
    status: getStatus(recurrence),
    started: recurrence.started,
    nextOccurrence: recurrence.next_occurrence,
    numInvoices: recurrence.num_invoices,
    remaining: recurrence.remaining,
    payment: {
      cardId: recurrence.recurring_payment_card_id,
      firstPayment: recurrence.recurring_payment_first_payment,
      terms: recurrence.recurring_payment_terms,
    },
    thirdPartyData: recurrence.third_party_data,
    autoLinkTime: recurrence.auto_link_time,
    includeSpouseName: recurrence.include_spouse_name,
    isBusinessClient: recurrence.is_business_client,
  };

  return recurrenceForm;
};

export const mapRecurrenceFormDataToDto = recurrenceForm => {
  return {
    id: recurrenceForm.id,
    description: recurrenceForm.description,
    start_date: recurrenceForm.startDate,
    end_date: recurrenceForm.endDate,
    end_option: recurrenceForm.endOption,
    remaining_invoices: recurrenceForm.endAfter,
    frequency: recurrenceForm.frequencyOption,
    frequency_monthly_option: recurrenceForm.monthlyOption,
    frequency_yearly_date: recurrenceForm.dayInMonth,
    frequency_monthly_date: recurrenceForm.dayInMonth,
    frequency_weekly_weekday: recurrenceForm.dayInWeek,
    frequency_monthly_nth_weekday: recurrenceForm.dayInWeek,
    frequency_yearly_month: recurrenceForm.monthInYear,
    frequency_daily_interval: recurrenceForm.repeatInterval,
    frequency_weekly_interval: recurrenceForm.repeatInterval,
    frequency_monthly_interval: recurrenceForm.repeatInterval,
    payment_terms: recurrenceForm.recurringTerms.id,
    terms: recurrenceForm.recurringTerms.id >= 0 ? recurrenceForm.recurringTerms.id : recurrenceForm.fixedTerm,
    relationships: recurrenceForm.relationships,
    third_party_data: recurrenceForm.thirdPartyData,
    auto_link_time: recurrenceForm.autoLinkTime,
  };
};

export const calculateSubTotal = (lineItems, onlyAdjustments) => {
  return lineItems.reduce(
    ({ sumTax, sumTotal }, lineItem) => {
      const total = parseFloat(onlyAdjustments ? lineItem.amount + lineItem.wuwd : lineItem.total);
      const tax = onlyAdjustments ? 0 : Math.round(total * parseFloat(lineItem.taxRate || 0)) / 100;
      const subTotal = total + tax;

      return { sumTax: sumTax + tax, sumTotal: sumTotal + subTotal };
    },
    { sumTax: 0, sumTotal: 0 }
  );
};

export const calculateCreditsTotal = credits => {
  return credits.reduce((total, credit) => {
    return total + +credit.amountToApply;
  }, 0);
};
