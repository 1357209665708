import { isEmpty } from 'lodash';

export const views = { active: 'active', recurring: 'recurring', archived: 'archived', payments: 'payments' };

export const defaultSortLabelValues = {
  minSortValue: 'A',
  maxSortValue: 'Z',
};

export const filterTypes = {
  None: 'none',
  Dynamic: 'dynamic',
  Fixed: 'fixed',
  Date: 'date',
  DateRange: 'date-range',
  Custom: 'custom',
};

export function hasSortOrFilter(columnSettings) {
  return !!(
    !isEmpty(columnSettings) &&
    (columnSettings.order || !isEmpty(columnSettings.filter_params) || columnSettings.after || columnSettings.before)
  );
}
