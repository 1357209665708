import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import styles from './filter.styles.css';

const InvoiceStatusFilter = ({ applyFilter, selections }) => {
  const displayStatusIcon = color => {
    return (
      <div
        style={{
          height: '8px',
          width: '8px',
          borderRadius: '4px',
          marginRight: '9px',
          display: 'inline-block',
          backgroundColor: color,
        }}
      />
    );
  };

  const paidFilter = [{ id: 'Paid in full' }, { id: 'Partial pay' }, { id: 'Unpaid' }];
  const overdueFilters = [
    { id: 'Current', icon: displayStatusIcon('#777777') },
    { id: '1-30 days past due', icon: displayStatusIcon('#3446fe') },
    { id: '31-60 days past due', icon: displayStatusIcon('#02dbeb') },
    { id: '61-90 days past due', icon: displayStatusIcon('#f1d500') },
    { id: '91+ days past due', icon: displayStatusIcon('#f21829') },
  ];
  const statusFilters = [
    { id: 'Draft' },
    { id: 'Saved' },
    { id: 'Sent' },
    { id: 'Viewed' },
    { id: 'Downloaded' },
    { id: 'Printed' },
    { id: 'Late fee added' },
  ];

  const selectionRules = (value, selections) => {
    if (['Paid in full', 'Partial pay'].includes(value)) {
      return selections.filter(selection => selection !== 'Unpaid');
    } else if (value === 'Unpaid') {
      return selections.filter(selection => !['Paid in full', 'Partial pay'].includes(selection));
    } else {
      return selections;
    }
  };

  const displayFilterSection = (filters, withIcons) => {
    return (
      filters &&
      filters.length > 0 &&
      filters.map((value, index) => {
        const selected = includes(selections.filter_params, value.id);
        return (
          <li key={`${value.id} - ${index}`} className="cps-cursor-pointer">
            <div
              className={`${styles.spreadContents} cp-mh-16 cp-pv-8`}
              onClick={e => applyFilter(e, value.id, true, selectionRules)}
              style={{ lineHeight: '20px' }}>
              <div className={`cps-ellipsis ${selected ? 'cps-color-primary' : ''}`}>
                {withIcons && value.icon}
                {value.id}
              </div>
              <div style={{ minWidth: '25px', margin: '-3px' }}>
                {selected && <i className="cps-color-primary cps-icon cps-icon-sm-check" />}
              </div>
            </div>
          </li>
        );
      })
    );
  };

  return (
    <>
      <li className="cps-divider cp-mh-16 cp-mv-4" />

      <div className={`${styles.filterMenu} ${styles.filterMenuLong}`}>
        {displayFilterSection(paidFilter)}
        <li className="cps-divider cp-mh-16 cp-mv-4" />
        {displayFilterSection(overdueFilters, true)}
        <li className="cps-divider cp-mh-16 cp-mv-4" />
        {displayFilterSection(statusFilters)}
      </div>
    </>
  );
};

InvoiceStatusFilter.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  selections: PropTypes.array,
};

export default InvoiceStatusFilter;
