import moment from 'moment';
import { get } from 'lodash';

const daysToNum = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 };
const day = { first: 1, second: 2, third: 3, fourth: 4 };

const offBy = (startDate, endDate, frequency, interval) => {
  let diff = endDate.diff(startDate, frequency, true);
  interval = parseInt(interval);
  return diff % interval;
};

const lastNthDayOfMonth = (date, day) => {
  let result = date.clone().endOf('month');
  while (result.day() != day) {
    result.subtract(1, 'days');
  }
  return result;
};

const nthDayOfMonth = (startDate, dayOfWeek, weekNumber) => {
  var myMonth = startDate.clone().startOf('month');
  var firstDayOfWeek = myMonth.clone().weekday(dayOfWeek);
  if (firstDayOfWeek.month() != myMonth.month()) {
    weekNumber++;
  }
  return firstDayOfWeek.add(weekNumber - 1, 'weeks');
};

export const calculateInitialInvoiceDate = recurrence => {
  let initial = moment(recurrence.startDate).startOf('day');
  let initialDate;
  let today = recurrence.id ? initial.clone() : moment().startOf('day');
  switch (recurrence.frequencyOption) {
    case 'Daily':
      if (initial.isBefore(today, 'day')) {
        initial = today;
      }
      break;
    case 'Weekly':
      if (initial.isBefore(today, 'day')) {
        initial = today;
      }
      let nextWeek = get(daysToNum, recurrence.dayInWeek) - get(daysToNum, initial.format('dddd')) < 0 ? 7 : 0;
      initial.day(get(daysToNum, recurrence.dayInWeek) + nextWeek);
      break;
    case 'Monthly':
      if (recurrence.monthlyOption === 'day') {
        if (recurrence.dayInMonth === 'last') {
          initialDate = moment(`${initial.month() + 1}-${initial.year()}`, 'M-YYYY').endOf('month');
        } else {
          if (initial.isBefore(today, 'day')) {
            initial = today;
          }
          initialDate = moment(`${initial.month() + 1}-${recurrence.dayInMonth}-${initial.year()}`, 'M-Do-YYYY');
        }
        if (initialDate.isBefore(initial, 'day')) {
          initialDate.add(1, 'months');
        }
        initial = initialDate;
      } else if (recurrence.monthlyOption === 'last') {
        initialDate = lastNthDayOfMonth(initial, get(daysToNum, recurrence.dayInWeek));
        if (initialDate.isBefore(initial, 'day')) {
          initialDate = lastNthDayOfMonth(initial.add(1, 'months'), get(daysToNum, recurrence.dayInWeek));
        }
        initial = initialDate;
      } else {
        initialDate = nthDayOfMonth(initial, get(daysToNum, recurrence.dayInWeek), get(day, recurrence.monthlyOption));
        if (initialDate.isBefore(initial, 'day')) {
          initialDate = nthDayOfMonth(
            initial.add(1, 'months'),
            get(daysToNum, recurrence.dayInWeek),
            get(day, recurrence.monthlyOption)
          );
        }
        initial = initialDate;
      }
      break;
    case 'Yearly':
      if (recurrence.dayInMonth === 'last') {
        initialDate = moment(`${recurrence.monthInYear}-${initial.year()}`, 'MMMM-YYYY').endOf('month');
      } else {
        initialDate = moment(`${recurrence.monthInYear}-${recurrence.dayInMonth}-${initial.year()}`, 'MMMM-Do-YYYY');
      }
      if (initialDate.isBefore(initial, 'day')) {
        initialDate.add(1, 'years');
      }
      initial = initialDate;
      break;
  }
  return initial;
};

export const calculateFinalInvoiceDate = (recurrence, initialInvoiceDate) => {
  switch (recurrence.endOption) {
    case 'On date':
      return calculateFinalInvoiceFromDate(recurrence, initialInvoiceDate.clone());
    case 'After':
      return calculateFinalInvoiceFromNumInvoices(recurrence, initialInvoiceDate.clone());
  }
};

const calculateFinalInvoiceFromDate = (recurrence, initialInvoiceDate) => {
  let end = moment(recurrence.endDate).startOf('day');
  let endDate;
  switch (recurrence.frequencyOption) {
    case 'Daily':
      let daysOffEnd = offBy(initialInvoiceDate, end, 'days', recurrence.repeatInterval);
      endDate = daysOffEnd === 0 ? end : end.subtract(daysOffEnd, 'days');
      break;
    case 'Weekly':
      endDate = end.clone().day(recurrence.dayInWeek);
      let weeksOffEnd = offBy(initialInvoiceDate, endDate, 'weeks', recurrence.repeatInterval);
      if (weeksOffEnd !== 0) {
        endDate.subtract(weeksOffEnd, 'weeks');
      } else if (weeksOffEnd === 0 && end.day() < endDate.day()) {
        endDate.subtract(recurrence.repeatInterval, 'weeks');
      }
      end = endDate;
      break;
    case 'Monthly':
      let interval = parseInt(recurrence.repeatInterval);
      if (recurrence.monthlyOption === 'day') {
        if (recurrence.dayInMonth === 'last') {
          let monthOfYear = end.month();
          let dateOfMonth = end.date();
          if (monthOfYear === 1) {
            //February
            if (dateOfMonth < 28) {
              end.subtract(1, 'months');
            }
          } else {
            if (dateOfMonth < 29) {
              end.subtract(1, 'months');
            }
          }
          end.endOf('month');
          let monthsOff = offBy(initialInvoiceDate, end, 'months', interval);
          if (monthsOff !== 0) {
            end.subtract(monthsOff, 'months');
          }
        } else {
          endDate = moment(`${end.month() + 1}-${recurrence.dayInMonth}-${end.year()}`, 'M-Do-YYYY');
          let monthsOff = offBy(initialInvoiceDate, endDate, 'months', interval);
          if (monthsOff !== 0) {
            endDate.subtract(monthsOff, 'months');
          } else if (monthsOff === 0 && end.date() < endDate.date()) {
            endDate.subtract(interval, 'months');
          }
          end = endDate;
        }
      } else if (recurrence.monthlyOption === 'last') {
        endDate = lastNthDayOfMonth(end, get(daysToNum, recurrence.dayInWeek));
        let monthsOff = offBy(
          initialInvoiceDate.clone().startOf('month'),
          endDate.clone().startOf('month'),
          'months',
          interval
        );

        if (monthsOff !== 0) {
          endDate = lastNthDayOfMonth(end.subtract(monthsOff, 'months'), get(daysToNum, recurrence.dayInWeek));
        } else if (monthsOff === 0 && end.date() < endDate.date()) {
          endDate = lastNthDayOfMonth(end.subtract(interval, 'months'), get(daysToNum, recurrence.dayInWeek));
        }
        end = endDate;
      } else {
        endDate = nthDayOfMonth(end, get(daysToNum, recurrence.dayInWeek), get(day, recurrence.monthlyOption));
        let monthsOff = offBy(
          initialInvoiceDate.clone().startOf('month'),
          endDate.clone().startOf('month'),
          'months',
          interval
        );
        if (monthsOff !== 0) {
          endDate = nthDayOfMonth(
            end.subtract(monthsOff, 'months'),
            get(daysToNum, recurrence.dayInWeek),
            get(day, recurrence.monthlyOption)
          );
        } else if (monthsOff === 0 && end.date() < endDate.date()) {
          endDate = nthDayOfMonth(
            end.subtract(interval, 'months'),
            get(daysToNum, recurrence.dayInWeek),
            get(day, recurrence.monthlyOption)
          );
        }
        end = endDate;
      }
      break;
    case 'Yearly':
      endDate;
      if (recurrence.dayInMonth === 'last') {
        endDate = moment(`${recurrence.monthInYear}-${end.year()}`, 'MMMM-YYYY').endOf('month');
      } else {
        endDate = moment(`${recurrence.monthInYear}-${recurrence.dayInMonth}-${end.year()}`, 'MMMM-Do-YYYY');
      }
      if (end.isBefore(endDate, 'day')) {
        endDate.subtract(1, 'years');
      }
      end = endDate;
      break;
  }

  if (moment(recurrence.endDate).isBefore(recurrence.startDate, 'day')) {
    endDate = moment(recurrence.endDate);
  } else {
    endDate = end;
  }

  return endDate;
};

const calculateFinalInvoiceFromNumInvoices = (recurrence, initialInvoiceDate) => {
  let numInvoices = parseInt(recurrence.endAfter) + get(recurrence, 'relationships.invoices.length', 0) - 1;
  let endDate;
  switch (recurrence.frequencyOption) {
    case 'Daily':
      endDate = initialInvoiceDate.add(recurrence.repeatInterval * numInvoices, 'days');
      break;
    case 'Weekly':
      endDate = initialInvoiceDate.add(recurrence.repeatInterval * numInvoices, 'weeks');
      break;
    case 'Monthly':
      let finalMonth = initialInvoiceDate.add(recurrence.repeatInterval * numInvoices, 'months');
      if (recurrence.monthlyOption === 'day') {
        if (recurrence.dayInMonth === 'last') {
          finalMonth.endOf('month');
        }
        endDate = finalMonth;
      } else if (recurrence.monthlyOption === 'last') {
        endDate = lastNthDayOfMonth(finalMonth, get(daysToNum, recurrence.dayInWeek));
      } else {
        endDate = nthDayOfMonth(finalMonth, get(daysToNum, recurrence.dayInWeek), get(day, recurrence.monthlyOption));
      }
      break;
    case 'Yearly':
      endDate = initialInvoiceDate.add(numInvoices, 'years');
      break;
  }

  return endDate;
};
