import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import AgedReceivableSummary from './aged-receivables-summary/aged-receivables-summary.component';
import AgedReceivableDetail from './aged-receivables-detail/aged-receivables-detail.component';
import RevenueByClientReport from './time/revenue-by-client-report.component';
import RevenueByServiceItemReport from './time/revenue-by-service-item.component';
import RevenueByTeamMemberReport from './time/revenue-by-team-member-report.component';
import { BillingUtilizationReport } from './utilization/billing-utilization-report.component';
import HoursByTeamMemberReport from './time/hours-by-team-member-report.component';
import HoursByServiceItemReport from './time/hours-by-service-item-report.component';
import HoursByClientReport from './time/hours-by-client-report.component';
import { TimeUtilizationReport } from './utilization/time-utilization-report.component';
import { ClientProfitability } from './profitability/client-profitability.component';
import { StaffProductivity } from './productivity/staff-productivity.component';

const BillingReportsRoot = lazy(() =>
  import(/* webpackChunkName: "billing-reports" */ './billing-reports-root.component')
);
const TimeReportsRoot = lazy(() => import(/* webpackChunkName: "billing-reports" */ './time-reports-root.component'));

export default function ReportsRouting() {
  return (
    <>
      <Route exact path="/billing/reports" component={BillingReportsRoot} />
      <Route exact path="/billing/reports/aged-receivable-detail" component={AgedReceivableDetail} />
      <Route exact path="/billing/reports/aged-receivable-summary" component={AgedReceivableSummary} />
      <Route exact path="/billing/reports/revenue-by-client" component={RevenueByClientReport} />
      <Route exact path="/billing/reports/revenue-by-service-item" component={RevenueByServiceItemReport} />
      <Route exact path="/billing/reports/revenue-by-team-member" component={RevenueByTeamMemberReport} />
      <Route exact path="/billing/reports/billing-utilization" component={BillingUtilizationReport} />
      <Route exact path="/billing/reports/client-profitability" component={ClientProfitability} />
      <Route exact path="/time/reports" component={TimeReportsRoot} />
      <Route exact path="/time/reports/hours-by-client" component={HoursByClientReport} />
      <Route exact path="/time/reports/hours-by-service-item" component={HoursByServiceItemReport} />
      <Route exact path="/time/reports/hours-by-team-member" component={HoursByTeamMemberReport} />
      <Route exact path="/time/reports/staff-productivity" component={StaffProductivity} />
      <Route exact path="/time/reports/time-utilization" component={TimeUtilizationReport} />
    </>
  );
}
