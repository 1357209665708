import { find } from 'lodash';
import { pluck, map } from 'rxjs/operators';
import { fetchAsObservable } from 'fetcher!sofe';

export function getTasks(searchVal, clientId, excludeCompleted = false) {
  return fetchAsObservable(
    `/api/tasks?client_id=${clientId ? `${clientId}` : 'BLANK'}${
      searchVal ? `&name_filter=${searchVal.trim()}` : ''
    }&exclude_client_requests=1&exclude_subtasks=1${excludeCompleted ? '&exclude_completed=1' : ''}`
  ).pipe(pluck('tasks'));
}

export function getSubTasksOfTask(taskId) {
  return fetchAsObservable(`/api/tasks/${taskId}`).pipe(
    pluck('tasks', 'subtasks'),
    map(subtasks => subtasks.filter(subtask => subtask.type !== 'Client Request'))
  );
}

export function getAllTasksWithTime(clientId, completed, filters, page = 0) {
  const queryClient = `&client_id=${clientId}`;
  const hasSort = find(filters, 'order');
  const ascSort = { date_type_id: 'DUEDATE', order: 'asc', filter_params: null };
  const body = {
    filters: {
      ...(!hasSort && {
        'date.calculated_date': [ascSort],
      }),
      'task.parent_task_id': { filter_params: ['Task', 'Task w/subs', 'Notice'] },
      ...(completed ? { 'task.status_name': { filter_params: ['Completed'] } } : {}),
      ...filters,
    },
  };

  return fetchAsObservable(
    `/api/task_dashboard?page=${page}&limit=50&include_time=true&include_pin=false${clientId ? queryClient : ''}${
      completed ? '&include=completed' : ''
    }`,
    {
      method: 'POST',
      body,
    }
  ).pipe(
    map(result => ({
      tasks: result.tasks,
      paginator: result.paginator,
    }))
  );
}

export function getTaskHours(clientName, filters) {
  const body = {
    filters: clientName
      ? {
          'task.client_name': { filter_params: [clientName] },
        }
      : filters,
  };
  return fetchAsObservable('/api/task_dashboard/total_hours_worked', {
    method: 'POST',
    body: body,
  }).pipe(pluck('values'));
}

export function getTaskClients() {
  return fetchAsObservable('/api/task_dashboard/get_filter_options?filter_type=task.client_name').pipe(
    pluck('options')
  );
}

export function getTask(taskId) {
  return fetchAsObservable(`/api/tasks/${taskId}`).pipe(pluck('tasks'));
}

export function getTimeForTask(taskId) {
  return fetchAsObservable(`/api/time-entries/time_logged_to_task/${taskId}`).pipe(pluck('tasks'));
}

export function updateTaskBudget(task) {
  return fetchAsObservable(`/api/tasks`, {
    method: 'PATCH',
    body: {
      task_ids: [task.id],
      tasks: {
        budgeted_hours: parseFloat(task.budgeted_hours || 0),
        subtasks: task.subtasks.map(subtask => ({
          ...subtask,
          budgeted_hours: parseFloat(subtask.budgeted_hours || 0),
        })),
      },
    },
  });
}
