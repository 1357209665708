import React from 'react';
// project
import ReportTitle from '../../common/report-title/report-title.component.js';
import SummaryHeader from './header/header.component.js';
import SummaryRow from './row/row.component.js';
import SummaryTotal from './total-row/total-row.component.js';
import { getAgedReceivableSummaryCSV } from '../aged-receivables-summary.resource.js';

import styles from './report.styles.css';

export default class AgedReceivableSummaryReport extends React.Component {
  render() {
    const { bucket_schema, bucket_values, clients } = this.props.results;
    return (
      <>
        <ReportTitle
          title="Aged Receivables Summary"
          setClientOwner={this.handleClientOwnerChanged}
          ownerId={this.props.ownerId}
          exportCSVRequest={this.exportCSVRequest}
        />
        <div className={`${styles.table}`}>
          <SummaryHeader schema={bucket_schema} />
          {clients.map(client => {
            return <SummaryRow key={client.id} schema={bucket_schema} summaryItem={client} />;
          })}
          <SummaryTotal schema={bucket_schema} bucketValues={bucket_values} />
        </div>
      </>
    );
  }

  handleClientOwnerChanged = (_, ownerId) => {
    this.props.setClientOwner(ownerId, () => {
      this.props.fetch(ownerId);
    });
  };
  exportCSVRequest = () => {
    return getAgedReceivableSummaryCSV(this.props.ownerId);
  };
}
