import React from 'react';
import { Link } from 'react-router-dom';
import styles from './detail-item.styles.css';
import Invoices from './invoices/invoices.component.js';
import { phoneNumberLink } from 'src/utils/phone-number.js';

export default class AgedReceivableDetailItem extends React.Component {
  render() {
    const { client, schema, checkboxes, statuses, prepend } = this.props;
    const { name, phone, email, bucket_values, invoices, id, linkable } = client;

    return (
      <div className={`${styles.table}`}>
        <div className={`${styles.header}`}>
          <div className={`${styles.headerItem}`}>
            {linkable ? (
              <Link to={`/client/${id}/billing/dashboard`} target="_blank" rel="noopener noreferrer">
                {name}
              </Link>
            ) : (
              <div>{name}</div>
            )}
          </div>
          {checkboxes.get('primaryPhone') && renderIfValid(phone, phoneNumber)}
          {checkboxes.get('primaryEmail') && renderIfValid([id, email], emailAddress)}
        </div>
        <Invoices
          name={name}
          invoices={invoices}
          schema={schema}
          totalValues={bucket_values}
          checkboxes={checkboxes}
          statuses={statuses}
          prepend={prepend}
        />
      </div>
    );
  }
}

function phoneNumber(phone) {
  const phoneNumber = phoneNumberLink(phone);
  if (phoneNumber) {
    return (
      <div className={`${styles.headerItem}`}>
        <span className="cps-wt-semibold cp-mr-8">Phone:</span>
        <span>
          <a href={phoneNumber.link} target="_blank" rel="noopener noreferrer">
            {phoneNumber.number}
          </a>
        </span>
      </div>
    );
  } else {
    return null;
  }
}

function emailAddress([id, email]) {
  return (
    <div className={`${styles.headerItem}`}>
      <span className="cps-wt-semibold cp-mr-8">Email:</span>
      <span>
        <Link to={`/communications/clients/${id}`} target="_blank" rel="noopener noreferrer">
          {email}
        </Link>
      </span>
    </div>
  );
}

function renderIfValid(values, formatFn = v => <div className={`${styles.headerItem}`}>{v}</div>) {
  let arrayedValues = Array.isArray(values) ? values : [values];
  if (arrayedValues.every(val => val)) {
    return formatFn(values);
  } else {
    return null;
  }
}
