import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { omit } from 'lodash';
import { CpIcon } from 'canopy-styleguide!sofe';
import { convertToCurrencyText } from 'src/payments/payments.helper';
import { defaultSortLabelValues, filterTypes } from 'src/common/components/column_header/columns.helpers';

export const groups = [
  { id: 'assignee', name: 'Assignee' },
  { id: 'service', name: 'Service' },
  { id: 'task', name: 'Task' },
];

export const formatNumberToCurrencyText = value => {
  let formattedValue = convertToCurrencyText(Math.abs(value));
  return value >= 0 ? formattedValue : `(${formattedValue})`;
};

export const updateWipFilters = (filterProperty, newFilters, setFilters, tenantId, fromSelectTime = false) => {
  setFilters(newFilters);
  const currentFilters = JSON.parse(sessionStorage.getItem('wipReportFilters'));
  !fromSelectTime &&
    sessionStorage.setItem(
      'wipReportFilters',
      JSON.stringify({ ...currentFilters, [filterProperty]: newFilters, tenantId })
    );
};

const AssigneeTotal = totals => {
  return <span>{`${totals.assignee_count} Assignee${totals.assignee_count === 1 ? '' : 's'}`}</span>;
};

const AutoLinkCell = autoLink => {
  return autoLink ? <CpIcon name="checkmark-large" /> : <span />;
};

const AutoLinkTotal = totals => {
  return <span>{`${totals.auto_link_count} Entr${totals.auto_link_count === 1 ? 'y' : 'ies'}`}</span>;
};

const ClientCell = (data, filters, groupBy, clientGroupId) => {
  const pathname =
    groupBy.id === 'client_group' && !clientGroupId
      ? `/time/client-groups/wip/${data.id}`
      : `/time/client/wip/${data.id}`;
  const sessionFilters = JSON.parse(sessionStorage.getItem('wipReportFilters'));
  return clientGroupId ? (
    <div className="flex items-center justify-between">
      <div className="cps-ellipsis">{data.name}</div>
    </div>
  ) : (
    <Link
      onClick={() => sessionStorage.setItem('wipReportFilters', JSON.stringify(omit(sessionFilters, 'searchFilters')))}
      to={{ pathname, state: { filters, name: data.name, groupBy } }}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}>
      <div className="flex items-center justify-between">
        <div className="cps-ellipsis">{data.name}</div>
        <div className="cp-ml-12">
          <CpIcon name="caret-large-right" />
        </div>
      </div>
    </Link>
  );
};

const ClientTotalCell = (totals, type) => {
  const totalCount = totals[`${type}_count`];
  return <span>{`${totalCount} Client${type === 'client_group' ? ' Group' : ''}${totalCount === 1 ? '' : 's'}`}</span>;
};

const ClientGroupClientsCell = entry => {
  return (
    <span className="cp-ml-36">
      {entry?.id ? <a href={`/#/client/${entry.id}`}>{entry?.name}</a> : entry?.client?.name || ''}
    </span>
  );
};

const ClientGroupClientsTotalCell = total => {
  return <span>{`${total.client_count} Client${total.client_count === 1 ? '' : 's'}`}</span>;
};

const CurrencyCell = value => value && <span>{convertToCurrencyText(value, true, true)}</span>;

const DateCell = value =>
  value && <span>{DateTime.fromISO(value).setZone('UTC').toLocaleString(DateTime.DATE_SHORT)}</span>;

const DateCellTotal = totals => {
  return <span>{`${totals.date_count} Day${totals.date_count === 1 ? '' : 's'}`}</span>;
};

const DurationCell = (duration, onEdit, timeEntry) => {
  if (timeEntry.wip_type === 'expense') return;
  return onEdit ? <a onClick={() => onEdit(timeEntry.id)}>{duration}</a> : <span>{duration}</span>;
};

const ExpenseNumberCell = (expenseNumber, onEdit, entry) => {
  if (entry.wip_type === 'time_entry') return;
  return onEdit ? <a onClick={() => onEdit(entry.id)}>{expenseNumber}</a> : <span>{expenseNumber}</span>;
};

const DurationTotal = (totals, columnKey) => {
  return <span>{`${totals[columnKey]}h`}</span>;
};

const NoteCell = note => {
  return note && new DOMParser().parseFromString(note, 'text/html').body.textContent;
};

const NumberCell = value => {
  return value && <span>{convertToCurrencyText(value, false)}</span>;
};

const ServiceTotal = totals => {
  return <span>{`${totals.service_item_count} Service${totals.service_item_count === 1 ? '' : 's'}`}</span>;
};

const SubtaskCell = (subtask, client, timeEntry) => {
  const { task } = timeEntry;
  if (!subtask) return null;
  return task?.id && client?.id && subtask?.id ? (
    <a href={`/#/task/${task.id}/client/${client.id}?subtask=${subtask.id}`} target="_blank" rel="noreferrer">
      {subtask?.name}
    </a>
  ) : (
    subtask?.name
  );
};

const SubtaskTotal = totals => {
  return <span>{`${totals.subtask_count} Subtask${totals.subtask_count === 1 ? '' : 's'}`}</span>;
};

const TaskCell = (task, client) => {
  if (!task) return null;
  return task.id && client?.id ? (
    <a href={`/#/task/${task.id}/client/${client.id}`} target="_blank" rel="noreferrer">
      {task.name}
    </a>
  ) : (
    task.name
  );
};

const TaskTotal = totals => {
  return <span>{`${totals.task_count} Task${totals.task_count === 1 ? '' : 's'}`}</span>;
};

const TextCell = value => {
  return value?.toString();
};

export const columnKeys = {
  CLIENT: 'client',
  CLIENT_GROUP: 'client_group',
  CLIENT_GROUP_CLIENTS: 'client_group_clients',
  UNBILLED_HOURS: 'unbilled_hours',
  UNBILLED_EXPENSES: 'unbilled_expenses',
  LAST_INVOICE_DATE: 'last_invoice_date',
  LAST_INVOICE_AMOUNT: 'last_invoice_amount',
  ASSIGNEE: 'assignee',
  WIP_TYPE: 'wip_type',
  DATE: 'date',
  AUTO_LINK_TIME: 'auto_link_time',
  EXPENSE_NUMBER: 'expense_number',
  DURATION: 'duration',
  SERVICE: 'service_item',
  TASK: 'task',
  SUBTASK: 'subtask',
  NOTE: 'description',
  UNBILLED_REVENUE: 'unbilled_revenue',
};

const columnDefs = {
  [columnKeys.CLIENT]: {
    columnLabel: 'Client',
    maxWidth: 300,
    sortParam: 'clients',
    alignment: 'left',
    renderCell: ClientCell,
    renderTotal: ClientTotalCell,
    filterType: filterTypes.None,
  },
  [columnKeys.CLIENT_GROUP]: {
    columnLabel: 'Client Group',
    maxWidth: 300,
    sortParam: 'client_groups',
    alignment: 'left',
    renderCell: ClientCell,
    renderTotal: ClientTotalCell,
    filterType: filterTypes.None,
    sortFilterList: true,
  },
  [columnKeys.CLIENT_GROUP_CLIENTS]: {
    ...defaultSortLabelValues,
    columnLabel: 'Client',
    maxWidth: 300,
    sortParam: 'clients',
    alignment: 'left',
    renderCell: ClientGroupClientsCell,
    renderTotal: ClientGroupClientsTotalCell,
    filterType: filterTypes.Dynamic,
    showSearch: true,
  },
  [columnKeys.UNBILLED_HOURS]: {
    columnLabel: 'Unbilled Hours',
    maxWidth: 200,
    sortParam: 'unbilled_hours',
    alignment: 'right',
    renderCell: NumberCell,
    renderTotal: DurationTotal,
    filterType: filterTypes.None,
  },
  [columnKeys.UNBILLED_EXPENSES]: {
    columnLabel: 'Unbilled Expenses',
    maxWidth: 200,
    sortParam: 'unbilled_expenses',
    alignment: 'right',
    renderCell: CurrencyCell,
    renderTotal: total => CurrencyCell(total['unbilled_expenses']),
    filterType: filterTypes.None,
  },
  [columnKeys.LAST_INVOICE_DATE]: {
    columnLabel: 'Last Invoice Date',
    maxWidth: 200,
    sortParam: 'last_invoice_date',
    alignment: 'left',
    renderCell: DateCell,
    renderTotal: DateCellTotal,
    filterType: filterTypes.None,
  },
  [columnKeys.LAST_INVOICE_AMOUNT]: {
    columnLabel: 'Last Invoice Amount',
    maxWidth: 200,
    sortParam: 'last_invoice_amount',
    alignment: 'right',
    renderCell: CurrencyCell,
    renderTotal: total => CurrencyCell(total['invoice_amount']),
    filterType: filterTypes.None,
  },
  [columnKeys.ASSIGNEE]: {
    ...defaultSortLabelValues,
    columnLabel: 'Assignee',
    maxWidth: '240px',
    sortParam: 'assignees',
    renderCell: assignee => TextCell(assignee?.name),
    renderTotal: AssigneeTotal,
    filterType: filterTypes.Dynamic,
    showSearch: true,
    sortFilterList: false,
  },
  [columnKeys.WIP_TYPE]: {
    ...defaultSortLabelValues,
    columnLabel: 'Type',
    maxWidth: '240px',
    sortParam: 'wip_type',
    renderCell: type => TextCell(type === 'expense' ? 'Expense' : 'Time Entry'),
    renderTotal: () => {},
    filterType: filterTypes.Fixed,
    filterValues: [
      { name: 'Time Entry', id: 'time_entry' },
      { name: 'Expense', id: 'expense' },
    ],
    singleFilterSelect: true,
  },
  [columnKeys.DATE]: {
    columnLabel: 'Date',
    maxWidth: '240px',
    sortParam: 'date',
    renderCell: DateCell,
    renderTotal: DateCellTotal,
    minSortValue: 'Oldest',
    maxSortValue: 'Newest',
    filterType: filterTypes.DateRange,
  },
  [columnKeys.AUTO_LINK_TIME]: {
    columnLabel: 'Auto-link',
    maxWidth: '240px',
    sortParam: 'auto_link_time',
    renderCell: AutoLinkCell,
    renderTotal: AutoLinkTotal,
    showSearch: false,
    filterType: filterTypes.None,
  },
  [columnKeys.EXPENSE_NUMBER]: {
    columnLabel: 'Expense #',
    maxWidth: '240px',
    sortParam: 'expense_number',
    renderCell: ExpenseNumberCell,
    renderTotal: () => {},
    filterType: filterTypes.None,
  },
  [columnKeys.DURATION]: {
    columnLabel: 'Duration',
    maxWidth: '240px',
    sortParam: 'duration',
    renderCell: DurationCell,
    renderTotal: DurationTotal,
    filterType: filterTypes.None,
  },
  [columnKeys.SERVICE]: {
    ...defaultSortLabelValues,
    columnLabel: 'Service Item',
    maxWidth: '240px',
    sortParam: 'service_items',
    renderCell: service => TextCell(service?.name),
    renderTotal: ServiceTotal,
    filterType: filterTypes.Dynamic,
    showSearch: false,
    sortFilterList: true,
  },
  [columnKeys.TASK]: {
    ...defaultSortLabelValues,
    columnLabel: 'Task',
    maxWidth: '240px',
    sortParam: 'tasks',
    renderCell: TaskCell,
    renderTotal: TaskTotal,
    filterType: filterTypes.Dynamic,
    showSearch: true,
    sortFilterList: true,
  },
  [columnKeys.SUBTASK]: {
    ...defaultSortLabelValues,
    columnLabel: 'Subtask',
    maxWidth: '240px',
    sortParam: 'subtasks',
    renderCell: SubtaskCell,
    renderTotal: SubtaskTotal,
    filterType: filterTypes.Dynamic,
    showSearch: true,
    sortFilterList: true,
  },
  [columnKeys.NOTE]: {
    columnLabel: 'Note',
    maxWidth: '240px',
    renderCell: NoteCell,
    renderTotal: () => {},
    showSearch: false,
    filterType: filterTypes.None,
  },
  [columnKeys.UNBILLED_REVENUE]: {
    columnLabel: 'Unbilled Revenue',
    maxWidth: '240px',
    alignment: 'right',
    sortParam: 'unbilled_revenue',
    renderCell: CurrencyCell,
    renderTotal: total => CurrencyCell(total[columnKeys.UNBILLED_REVENUE]),
    filterType: filterTypes.None,
  },
};

export const getDashboardColumns = (sticky = false, groupBy) => {
  return {
    ...(!sticky && {
      ...(groupBy === 'client' && { [columnKeys.CLIENT]: columnDefs[columnKeys.CLIENT] }),
      ...(groupBy === 'client_group' && { [columnKeys.CLIENT_GROUP]: columnDefs[columnKeys.CLIENT_GROUP] }),
      [columnKeys.UNBILLED_HOURS]: columnDefs[columnKeys.UNBILLED_HOURS],
      [columnKeys.UNBILLED_EXPENSES]: columnDefs[columnKeys.UNBILLED_EXPENSES],
      [columnKeys.LAST_INVOICE_DATE]: columnDefs[columnKeys.LAST_INVOICE_DATE],
      [columnKeys.LAST_INVOICE_AMOUNT]: columnDefs[columnKeys.LAST_INVOICE_AMOUNT],
    }),
    ...(sticky && {
      [columnKeys.UNBILLED_REVENUE]: columnDefs[columnKeys.UNBILLED_REVENUE],
    }),
  };
};

export const getGroupColumns = (sticky = false, showAutoLinked, isClientGroup) => {
  return {
    ...(!sticky && {
      ...(isClientGroup && { [columnKeys.CLIENT_GROUP_CLIENTS]: columnDefs[columnKeys.CLIENT_GROUP_CLIENTS] }),
      [columnKeys.ASSIGNEE]: columnDefs[columnKeys.ASSIGNEE],
      [columnKeys.WIP_TYPE]: columnDefs[columnKeys.WIP_TYPE],
      [columnKeys.DATE]: columnDefs[columnKeys.DATE],
      [columnKeys.DURATION]: columnDefs[columnKeys.DURATION],
      ...(showAutoLinked && { [columnKeys.AUTO_LINK_TIME]: columnDefs[columnKeys.AUTO_LINK_TIME] }),
      [columnKeys.EXPENSE_NUMBER]: columnDefs[columnKeys.EXPENSE_NUMBER],
      [columnKeys.SERVICE]: columnDefs[columnKeys.SERVICE],
      [columnKeys.TASK]: columnDefs[columnKeys.TASK],
      [columnKeys.SUBTASK]: columnDefs[columnKeys.SUBTASK],
      [columnKeys.NOTE]: columnDefs[columnKeys.NOTE],
    }),
    ...(sticky && {
      [columnKeys.UNBILLED_REVENUE]: columnDefs[columnKeys.UNBILLED_REVENUE],
    }),
  };
};

export const getBreadcrumbs = ({ clientGroupId, clientGroupName = '', client }) => {
  return [
    { name: 'General WIP Report', id: 1, pathname: '/time/wip' },
    ...(clientGroupId && clientGroupName
      ? [
          { name: clientGroupName, id: 2, pathname: `/time/client-groups/wip/${clientGroupId}` },
          {
            name: `${clientGroupName} WIP Report`,
            id: 3,
            pathname: `/time/client-groups/clients/wip/${clientGroupId}`,
          },
        ]
      : [
          {
            name: client?.name || '',
            id: 2,
            pathname: `/time/client/wip/${client?.id}`,
          },
        ]),
  ];
};
