import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { CreditContext } from 'src/credits/credit-context';
import {
  createWebviewerOptions,
  disableElements,
  customizeHotKeys,
  handlePDFTronErrors,
} from 'src/common/components/webviewer/webviewer.utils';
import { featureEnabled } from 'feature-toggles!sofe';
import { getCreditPDF } from 'src/resources/credits.resources';
import { handleError } from 'src/common/handle-error.helper';

export const CreditPreviewer = ({ setFileViewerError }) => {
  const { credit, creditRemaining, webViewerInstance, actions } = useContext(CreditContext);

  const viewerRef = useRef(null);
  const isMounted = useRef();

  const catchFileViewerError = useCallback(e => {
    setFileViewerError(true);
    handlePDFTronErrors(e);
  }, []);

  useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;

    if (featureEnabled('toggle_pdftron_upgrade')) {
      window.WebViewer.WebComponent(createWebviewerOptions(), viewerRef.current)
        .then(instance => {
          disableElements(instance);
          customizeHotKeys(instance);
          actions.initializeWebViewer(instance);
        })
        .catch(e => catchFileViewerError(e));
    } else {
      window
        .WebViewer(createWebviewerOptions(), viewerRef.current)
        .then(instance => {
          disableElements(instance);
          customizeHotKeys(instance);
          actions.initializeWebViewer(instance);
        })
        .catch(e => catchFileViewerError(e));
    }

    return () => actions.initializeWebViewer();
  }, []);

  useEffect(() => {
    if (!credit?.id || !webViewerInstance) return;

    const subscription = getCreditPDF(credit.id, true).subscribe(response => {
      try {
        webViewerInstance.UI.loadDocument(response, { filename: `Credit #${credit.credit_number}.pdf` });
      } catch (error) {
        catchFileViewerError(error);
      }
    }, handleError);

    return () => subscription.unsubscribe();
  }, [credit.id, creditRemaining, webViewerInstance]);

  return (
    <div>
      <div style={{ height: '1024px' }} ref={viewerRef}></div>
    </div>
  );
};
