// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-history-styles__creditHistory--kXElp {
  margin-top: 16px;
  max-width: 820px;
}

.credit-history-styles__creditHistory--kXElp > div {
  border-bottom: 1px solid var(--cp-color-app-border);
}

.credit-history-styles__creditHistory--kXElp ul {
  list-style: none;
  padding: 0px;
  position: relative;
}

.credit-history-styles__creditHistory--kXElp li {
  line-height: 23px;
}

.credit-history-styles__creditHistory--kXElp li div {
  display: inline-block;
  padding: 16px 23px;
}

.credit-history-styles__creditHistory--kXElp li:nth-child(odd) {
  background-color: var(--cps-color-ash);
}
`, "",{"version":3,"sources":["webpack://./src/credits/components/credit-overlay/credit-history.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".creditHistory {\n  margin-top: 16px;\n  max-width: 820px;\n}\n\n.creditHistory > div {\n  border-bottom: 1px solid var(--cp-color-app-border);\n}\n\n.creditHistory ul {\n  list-style: none;\n  padding: 0px;\n  position: relative;\n}\n\n.creditHistory li {\n  line-height: 23px;\n}\n\n.creditHistory li div {\n  display: inline-block;\n  padding: 16px 23px;\n}\n\n.creditHistory li:nth-child(odd) {\n  background-color: var(--cps-color-ash);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creditHistory": `credit-history-styles__creditHistory--kXElp`
};
export default ___CSS_LOADER_EXPORT___;
