import React, { useState, useEffect, useMemo } from 'react';
import { fromEvent, merge } from 'rxjs';
import { map } from 'lodash';
import { CpButton, CpIcon, CpLoader } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getStaffProductivityColumns } from './productivity-columns';
import { getStaffProductivityReport } from './productivity-resource';
import { StaffProductivityTaskRow } from './staff-productivity-task-row.component';

export const StaffProductivityAssigneeRow = ({
  row,
  dateFilter,
  filterMenuClosed,
  setFilterMenuClosed,
  selectedClientIds,
}) => {
  const pageLimit = 50;
  const columns = useMemo(() => getStaffProductivityColumns(), []);
  const [taskData, setTaskData] = useState();
  const [expanded, setExpanded] = useState(false);
  const [refetchTasks, setRefetchTasks] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const timeEntryEvents = fromEvent(window, 'billing-ui::time-saved');
    const invoiceEvents = fromEvent(window, 'billing-ui::event-saved');
    const subs = merge(timeEntryEvents, invoiceEvents).subscribe(() => {
      setRefetchTasks(true);
    });
    return () => {
      subs.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setExpanded(false);
    setTaskData();
  }, [dateFilter]);

  useEffect(() => {
    if (!filterMenuClosed) return;
    setExpanded(false);
    setTaskData();
  }, [filterMenuClosed]);

  //for initial expansion
  useEffect(() => {
    if (expanded && !taskData) {
      const { after, before } = dateFilter;
      const subscription = getStaffProductivityReport(
        after,
        before,
        selectedClientIds,
        [row.row_id],
        'task',
        1,
        pageLimit
      ).subscribe(updateTable, handleError);

      return () => subscription.unsubscribe();
    }
  }, [expanded]);

  //for when the report gets updated from an external event such as billing of time entries with task from global +
  useEffect(() => {
    if (!refetchTasks) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityReport(
      after,
      before,
      selectedClientIds,
      [row.row_id],
      'task',
      1,
      pageLimit * currentPage
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [refetchTasks]);

  //for clicking load more button
  useEffect(() => {
    if (!loadMore) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityReport(
      after,
      before,
      selectedClientIds,
      [row.row_id],
      'task',
      currentPage + 1,
      pageLimit
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [loadMore]);

  //updating tasks on filter menu close
  useEffect(() => {
    if (expanded && filterMenuClosed) {
      const { after, before } = dateFilter;
      const subscription = getStaffProductivityReport(
        after,
        before,
        selectedClientIds,
        [row.row_id],
        'task',
        1,
        pageLimit
      ).subscribe(
        response => {
          setFilterMenuClosed(false);
          updateTable(response);
        },
        err => {
          setFilterMenuClosed(false);
          handleError(err);
        }
      );
      return () => subscription.unsubscribe();
    }
  }, [expanded, filterMenuClosed]);

  const updateTable = response => {
    const pageInfo = response.meta.paginator;
    const updatedTaskData = loadMore ? [...taskData, ...response.rows] : response.rows;
    setTaskData(updatedTaskData);
    setCurrentPage(refetchTasks ? currentPage : pageInfo.current_page);
    setHasMorePages(!!pageInfo.next_page);
    setLoadMore(false);
    setRefetchTasks(false);
  };

  return (
    <>
      <tr key={row.row_id}>
        {map(columns, column =>
          column.key === 'row_name' ? (
            <td
              key={column.key}
              className="cps-ellipsis cps-wt-semibold"
              style={{ cursor: 'pointer', background: 'var(--cp-color-nav-icon)' }}
              onClick={() => setExpanded(!expanded)}>
              <CpIcon name={`caret-small-${expanded ? 'up' : 'down'}`} /> {row.row_name}
            </td>
          ) : (
            <td
              key={column.key}
              className={`${column.key === 'client_name' ? '' : 'cp-text-right'} cps-wt-semibold`}
              style={{ background: 'var(--cp-color-nav-icon)' }}>
              {column.renderCell(row[column.key])}
            </td>
          )
        )}
      </tr>
      {expanded &&
        (!taskData ? (
          <tr>
            <td colSpan={Object.keys(columns).length}>
              <CpLoader />
            </td>
          </tr>
        ) : (
          <>
            {taskData.map((task, index) => (
              <StaffProductivityTaskRow key={index} task={task} />
            ))}
            {hasMorePages && (
              <tr>
                <td colSpan={Object.keys(columns).length} className="cp-text-center cp-p-8">
                  <CpButton btnType="primary" onClick={() => setLoadMore(true)} showLoader={loadMore}>
                    Load more
                  </CpButton>
                </td>
              </tr>
            )}
          </>
        ))}
    </>
  );
};
