import React, { useMemo } from 'react';
import { CpSelectMulti } from 'canopy-styleguide!sofe';
import { getColumns } from '../invoice-builder.helper';

export const ColumnVisibility = ({ hasGroupBilling, value, update, disabled, none }) => {
  const availableColumns = useMemo(
    () =>
      getColumns(false, hasGroupBilling)
        .filter(c => c.canHide)
        .map(c => ({ id: c.key, name: c.label })),
    []
  );

  return (
    <div className="cp-ml-8">
      <label>Columns</label>
      <div style={{ width: '180px' }}>
        {availableColumns && (
          <CpSelectMulti
            triggerIsBlock
            contentWidth="block"
            fixedContent
            disabled={disabled}
            placeholder="All columns hidden"
            data={availableColumns}
            value={value}
            onChange={update}
            renderValue={selected =>
              none ? 'All columns hidden' : `${selected.length} column${selected.length !== 1 ? 's' : ''} visible`
            }
          />
        )}
      </div>
    </div>
  );
};
