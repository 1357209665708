import React, { useMemo, useContext } from 'react';
import { find } from 'lodash';
import { CpButton, CpIcon } from 'canopy-styleguide!sofe';
import { useHasAccess } from 'cp-client-auth!sofe';
import { LineItem } from './line-item.component';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { getColumns, calculateSubTotal } from './invoice-builder.helper';
import { modes, invoiceTypes, toCurrency } from 'src/invoices/invoices.helper';
import { useServiceItemsIntegrations } from 'src/common/custom-hooks';
import { IntegrationsContext } from 'src/common/integrations/integrations-context';

import styles from './invoice-builder.styles.css';

export const LineItems = ({ showClassCol, qboClasses, clientSyncedToQbo }) => {
  const { invoice, recurrence, invoiceType, actions, options } = useContext(InvoiceContext);
  const integrationContext = useContext(IntegrationsContext);
  const canCreateEditInvoice = useHasAccess('billing_invoices_create_edit');
  const itemsIntegrationHook = useServiceItemsIntegrations(!!integrationContext?.itemsIntegration?.allServiceItems);
  const { categorizedServices, allServiceItems, qboCategories } = !!integrationContext?.itemsIntegration
    ?.allServiceItems
    ? integrationContext.itemsIntegration
    : itemsIntegrationHook;

  const timeEntrySubTotal = useMemo(() => {
    return calculateSubTotal(invoice.lineItems.fromTime);
  }, [invoice.lineItems.fromTime]);

  const lineItemSubTotal = useMemo(() => {
    return calculateSubTotal(invoice.lineItems.standard);
  }, [invoice.lineItems.standard]);

  const lateFeeSubTotal = useMemo(() => {
    return calculateSubTotal(invoice.lineItems.lateFees);
  }, [invoice.lineItems.lateFees]);

  const columnVisible = column => {
    if (options.singleLine) {
      return false;
    }
    if (column.key === 'rateType' || column.key === 'subAmount' || column.key === 'wuwd' || column.key === 'qboClass') {
      return false;
    } else if (!column.canHide) {
      return true;
    } else {
      return find(invoice.columnVisibility, { id: column.key });
    }
  };

  const disabled =
    (invoiceType === invoiceTypes.recurring && recurrence.status === 'Ended') ||
    options.singleLine ||
    !canCreateEditInvoice;

  return (
    <div style={{ minHeight: '256px' }}>
      <div className={styles.invoiceBuilderLineItems}>
        <table>
          <thead>
            <tr className={styles.shadowBottom}>
              {getColumns(showClassCol, invoice.clientGroup?.id).map((column, index) => (
                <th
                  key={column.key}
                  className={
                    index === 0
                      ? `${styles.shadowRight} ${styles.stickyLeft}`
                      : index === getColumns(showClassCol, invoice.clientGroup?.id).length - 1
                      ? `${styles.shadowLeft} ${styles.stickyRight}`
                      : ''
                  }
                  style={{ minWidth: column.width }}>
                  <div className={`${styles.flexSpread} ${!columnVisible(column) ? 'cps-color-af' : ''}`}>
                    <div>
                      {column.key === 'qboClass' && (
                        <img
                          src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
                          height="20rem"
                          alt="QuickBooks Online Logo"
                          className="cp-mr-8"
                        />
                      )}
                      {column.label}
                    </div>
                    {!columnVisible(column) && <CpIcon name="misc-closed-eye" />}
                  </div>
                </th>
              ))}
            </tr>
            <tr className="+thin"></tr>
          </thead>
          <tbody>
            {invoice.lineItems.fromTime.map((lineItem, index) => (
              <LineItem
                key={lineItem.id}
                lineItem={lineItem}
                index={index}
                fromTime={true}
                disabled={disabled}
                qboClasses={qboClasses}
                clientSyncedToQbo={clientSyncedToQbo}
                showClassCol={showClassCol}
                categorizedServices={categorizedServices}
                allServiceItems={allServiceItems}
                qboCategories={qboCategories}
              />
            ))}
            {invoice.lineItems.fromTime.length > 0 && (invoice.client?.id || invoice.clientGroup?.id) && (
              <tr>
                <td className={`${styles.shadowRight} ${styles.stickyLeft}`}>
                  {!options.singleLine && canCreateEditInvoice && (
                    <CpButton className="cp-ml-16" btnType="flat" onClick={() => actions.modeChange(modes.selectTime)}>
                      + Add time and expenses
                    </CpButton>
                  )}
                </td>
                <td colSpan={showClassCol ? '13' : '12'} />
                <td className={`${styles.shadowLeft} ${styles.stickyRight}`}>
                  <div className={styles.subTotalContainer}>
                    <div>Tax</div>
                    <div style={{ fontWeight: 'bold' }}>{toCurrency(timeEntrySubTotal.sumTax)}</div>
                    <div className="cp-mt-4">Subtotal</div>
                    <div style={{ fontWeight: 'bold' }}>{toCurrency(timeEntrySubTotal.sumTotal)}</div>
                  </div>
                </td>
              </tr>
            )}
            {invoice.lineItems.standard.map((lineItem, index) => {
              return (
                <LineItem
                  key={lineItem.id}
                  lineItem={lineItem}
                  index={index}
                  fromTime={false}
                  disabled={disabled}
                  qboClasses={qboClasses}
                  clientSyncedToQbo={clientSyncedToQbo}
                  showClassCol={showClassCol}
                  categorizedServices={categorizedServices}
                  allServiceItems={allServiceItems}
                  qboCategories={qboCategories}
                />
              );
            })}

            <tr>
              <td className={`${styles.shadowRight} ${styles.stickyLeft}`}>
                {invoice.lineItems.fromTime.length === 0 &&
                  invoiceType === invoiceTypes.oneTime &&
                  (invoice.client?.id || invoice.clientGroup?.id) &&
                  !options.singleLine &&
                  canCreateEditInvoice && (
                    <div>
                      <CpButton
                        className="cp-ml-16"
                        btnType="flat"
                        onClick={() => actions.modeChange(modes.selectTime)}>
                        + Add time and expenses
                      </CpButton>
                    </div>
                  )}
                {!disabled && (
                  <div>
                    <CpButton className="cp-ml-16" btnType="flat" onClick={actions.addLineItem}>
                      + Add line item
                    </CpButton>
                  </div>
                )}
              </td>
              <td colSpan={showClassCol ? '13' : '12'} />
              <td className={`${styles.shadowLeft} ${styles.stickyRight} cp-pb-8`}>
                <div className={styles.subTotalContainer}>
                  {invoice.lineItems.standard.length > 0 && (
                    <>
                      <div>Tax</div>
                      <div style={{ fontWeight: 'bold' }}>{toCurrency(lineItemSubTotal.sumTax)}</div>
                      <div className="cp-mt-4">Subtotal</div>
                      <div style={{ fontWeight: 'bold' }}>{toCurrency(lineItemSubTotal.sumTotal)}</div>
                    </>
                  )}
                </div>
              </td>
            </tr>
            {lateFeeSubTotal.sumTotal > 0 && (
              <>
                <tr style={{ height: '55px' }} />
                {invoice.lineItems.lateFees.map((lineItem, index) => (
                  <LineItem
                    key={lineItem.id}
                    isLateFee={true}
                    lineItem={lineItem}
                    index={index}
                    fromTime={false}
                    disabled={true}
                  />
                ))}
                <tr style={{ height: '8px' }} />
              </>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
