import React from 'react';
// project
import styles from './invoice.styles.css';
import rStyles from '../../report.styles.css';
import Invoice from './invoice.component.js';
import SchemaItems from 'src/reports/common/schema-items/schema-items.component.js';

export default class AgedReceivableInvoices extends React.Component {
  render() {
    const { invoices, schema, totalValues, name, checkboxes, statuses } = this.props;
    this.items = [...this.props.prepend, ...schema];

    return (
      <div>
        <div className={`${rStyles.leftPadding}`}>
          <div className={`${styles.invoicesHeader}`}>
            <SchemaItems childClass={`${rStyles.headerItem} cp-mr-4`} items={this.items} />
          </div>
          {invoices.map(invoice => (
            <Invoice schema={schema} key={invoice.id} invoice={invoice} checkboxes={checkboxes} statuses={statuses} />
          ))}
        </div>
        <div className={`${rStyles.totalRow}`}>
          <div className={`${rStyles.rowContents}`}>
            <SchemaItems
              childClass={this.getChildClassTotalRow}
              schema={['', ...schema]}
              items={[`Total ${name}`, ...totalValues]}
            />
          </div>
        </div>
      </div>
    );
  }

  getChildClassTotalRow = (length, index) => {
    if (index === 0) {
      return `${rStyles.rowItemPadding} ${rStyles[`flex${this.props.prepend.length}`]}`;
    } else {
      return `${rStyles.headerItem} ${rStyles.rowItemPadding}`;
    }
  };
}
