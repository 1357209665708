import React from 'react';
import { get, take } from 'lodash';
import AsyncDecorator from 'async-decorator/rx6';

import InvoiceTable from 'src/invoices/components/invoice-table.component';
import { getAllInvoices } from 'src/resources/invoices.resources';
import { getRecurringHistoryColumns } from 'src/invoices/invoices.helper';

import styles from './recurring-history.styles.css';

@AsyncDecorator
export class RecurringHistory extends React.Component {
  state = { invoices: [], columns: [] };

  componentDidMount() {
    const { cancelWhenUnmounted, recurrence } = this.props;
    const invoices = get(recurrence, 'relationships.invoices');

    let invoiceIds = invoices.reduce((acc, val) => {
      acc.push(val.id);
      return acc;
    }, []);

    let filter = {
      ids: { filter_params: invoiceIds },
    };

    cancelWhenUnmounted(
      getAllInvoices(null, filter).subscribe(response => {
        this.setState({ invoices: take(response.invoices, invoiceIds.length) });
      })
    );

    this.setState({ columns: getRecurringHistoryColumns() });
  }

  render() {
    return (
      <div className={styles.recurringHistory}>
        {this.props.showHeader && (
          <div className={styles.header}>
            <span className={`${styles.title} cps-wt-bold`}>Sent invoices</span>
          </div>
        )}
        {this.state.invoices && this.state.invoices.length > 0 ? (
          <div className={styles.table}>
            <InvoiceTable
              invoiceType="active"
              columns={this.state.columns}
              invoices={this.state.invoices}
              allowSelection={false}
              allowFilterSort={false}
              showLoadMoreButton={false}
              filterInvoices={() => {}}
              hideSpacer={true}
              onShowOverlay={this.props.onViewInvoice}
            />
          </div>
        ) : (
          <div className={`${styles.summaryEmptyState} cp-pv-8`}>
            {<div>No invoices have been sent for this recurrence.</div>}
          </div>
        )}
      </div>
    );
  }
}
