// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-styles__filterMenu--OSer4 {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 175px;
}

.filter-styles__menuOption--TrH6u {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 20px;
  justify-content: space-between;
}

.filter-styles__menuOption--TrH6u:hover {
  background-color: var(--cps-color-chrome);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/column_header/filter/filter.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yCAAyC;EACzC,eAAe;AACjB","sourcesContent":[".filterMenu {\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 100%;\n  max-height: 175px;\n}\n\n.menuOption {\n  display: flex;\n  align-items: center;\n  height: 20px;\n  padding: 20px;\n  justify-content: space-between;\n}\n\n.menuOption:hover {\n  background-color: var(--cps-color-chrome);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMenu": `filter-styles__filterMenu--OSer4`,
	"menuOption": `filter-styles__menuOption--TrH6u`
};
export default ___CSS_LOADER_EXPORT___;
