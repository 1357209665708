import React, { useState, useContext } from 'react';
import { DateTime } from 'luxon';
import {
  CpModal,
  CpButton,
  CprDatepicker,
  CpSelectSingle,
  currency,
  CpWell,
  CpTooltip,
  CpIcon,
} from 'canopy-styleguide!sofe';
import { k, useCss } from 'kremling';

import { CurrencyInput } from 'src/payments/components/add-payment/currency-input.component';
import { CreditContext } from 'src/credits/credit-context';

const refundMethods = [
  {
    id: 'cash',
    name: 'Cash',
  },
  {
    id: 'check',
    name: 'Check',
  },
  {
    id: 'other',
    name: 'Other',
  },
];

export const RefundCreditModal = ({ show, onClose, onRefundCredit, integratedCredit }) => {
  const scope = useCss(css);
  const { creditRemaining } = useContext(CreditContext);
  const [refundDate, setRefundDate] = useState(DateTime.local());
  const [refundMethod, setRefundMethod] = useState();
  const [refundAmount, setRefundAmount] = useState(0);

  const handleRefundAmountChange = amount => {
    if (Number(amount) > Number(creditRemaining)) {
      setRefundAmount(creditRemaining);
    } else {
      setRefundAmount(amount);
    }
  };

  const handleReset = () => {
    setRefundDate(DateTime.local());
    setRefundMethod();
    setRefundAmount(0);
  };

  const canSaveRefund = refundDate && refundMethod && refundAmount > 0;

  return (
    <CpModal show={show} onClose={onClose} onAfterClose={handleReset}>
      <CpModal.Header title="Refund Credit" />
      <CpModal.Body>
        <div {...scope} style={{ minHeight: '36rem' }}>
          {integratedCredit?.third_party_credit_id && (
            <CpWell level={2} className="integrationSyncBox">
              <div className="cp-flex-center">
                <img
                  src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
                  height="24px"
                  alt="QuickBooks Online Logo"
                />
                <div className="cp-ml-8">
                  <strong>
                    Refund cannot sync{' '}
                    <CpTooltip text="To ensure consistency, please add refund manually in QBO" position="top">
                      <CpIcon name="information-circle-open-small" fill="var(--cp-color-app-icon)" />
                    </CpTooltip>
                  </strong>
                </div>
              </div>
              {integratedCredit?.third_party_url && (
                <a href={integratedCredit.third_party_url} target="_blank" rel="noreferrer">
                  Access credit
                </a>
              )}
            </CpWell>
          )}
          <div className="cp-mb-16">
            <label>
              Refund Date<span className="cps-color-primary">*</span>
            </label>
            <CprDatepicker date={refundDate} events={{ datechange: date => setRefundDate(date.detail) }} />
          </div>
          <div className="cp-mb-16">
            <label>
              Refund Method<span className="cps-color-primary">*</span>
            </label>
            <CpSelectSingle
              triggerIsBlock
              placeholder="Choose a method"
              data={refundMethods}
              onChange={setRefundMethod}
              value={refundMethod}
            />
          </div>
          <div className="cp-mb-16">
            <label>
              Refund Amount<span className="cps-color-primary">*</span>
            </label>
            <CurrencyInput onChange={handleRefundAmountChange} value={refundAmount} />
          </div>
          <div className="cp-mb-16">
            <label>Credit Balance: {currency(creditRemaining)}</label>
          </div>
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => onRefundCredit(DateTime.fromJSDate(refundDate), refundMethod.name, refundAmount)}
          className="cp-mr-8"
          disabled={!canSaveRefund}>
          Save refund
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};

const css = k`
  .integrationSyncBox {
    padding: 1.2rem;
    margin-bottom: 1.6rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.6rem;
  }
`;
