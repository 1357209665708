import React from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';

const groupOptions = [
  { id: 'grouped', name: 'Grouped' },
  { id: 'separated', name: 'Separated' },
];

export const LineItemGrouped = ({ value, update, disabled, warn }) => {
  return (
    <div className={`cp-ml-8 ${warn ? 'cps-has-error' : ''}`}>
      <label>Line Items</label>
      <div style={{ width: '180px' }}>
        <CpSelectSingle
          triggerIsBlock
          contentWidth="block"
          fixedContent
          disabled={disabled}
          data={groupOptions}
          onChange={val => update(val.id === 'grouped')}
          value={groupOptions[value ? 0 : 1]}
        />
        <span className="cps-error-block">Some fields may lose their changes</span>
      </div>
    </div>
  );
};
