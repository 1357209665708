import React from 'react';
import { CpInput } from 'canopy-styleguide!sofe';

export const CreditNumber = ({ value, update, errors }) => {
  const hasError = errors?.includes('noCreditNumber');
  return (
    <div>
      <CpInput
        label="Credit #"
        required
        value={value}
        onChange={value => update({ creditNumber: value })}
        maxLength="25"
        error={hasError && 'Credit number is required.'}
      />
    </div>
  );
};
