// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../common/report.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-item-styles__table--A6T7j {
  margin: 16px 24px 0px 24px;
}

.detail-item-styles__header--ehZTh {
  margin: 24px 32px 16px 24px;
  display: flex;
  align-items: center;
}

.detail-item-styles__headerItem--J8v1i {
  padding-right: 24px;
}

.detail-item-styles__headerItem--J8v1i:last-child {
  padding-right: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/reports/aged-receivables-detail/report/detail-item/detail-item.styles.css"],"names":[],"mappings":"AAAA;EAEE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".table {\n  composes: table from 'src/reports/common/report.styles.css';\n  margin: 16px 24px 0px 24px;\n}\n\n.header {\n  margin: 24px 32px 16px 24px;\n  display: flex;\n  align-items: center;\n}\n\n.headerItem {\n  padding-right: 24px;\n}\n\n.headerItem:last-child {\n  padding-right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `detail-item-styles__table--A6T7j ${___CSS_LOADER_ICSS_IMPORT_0___.locals["table"]}`,
	"header": `detail-item-styles__header--ehZTh`,
	"headerItem": `detail-item-styles__headerItem--J8v1i`
};
export default ___CSS_LOADER_EXPORT___;
