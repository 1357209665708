import React from 'react';
import { CprDatepicker } from 'canopy-styleguide!sofe';

export const StartDate = ({ value, update, engagement, disabled, showReset, errors }) => (
  <div
    id="startDate"
    style={engagement ? { minWidth: '147px', maxWidth: '147px' } : { minWidth: '100px', maxWidth: '120px' }}
    className={!disabled && errors.includes('invalidStartDate') ? 'cps-has-error' : ''}>
    <label>
      {engagement ? 'Invoice date' : 'Start date'}
      {!engagement && <span className="cps-color-primary">*</span>}
    </label>
    <CprDatepicker
      date={value}
      events={{
        datechange: date => {
          update('startDate', date.detail);
          update('showDateReset', false);
        },
      }}
      disabled={disabled}
    />
    <span style={{ width: '210px', position: 'absolute', marginTop: '4px' }} className="cps-error-block">
      Start date must be on or after today's date.
    </span>
    {showReset && (
      <span
        style={{ width: '210px', position: 'absolute', marginTop: '4px' }}
        className="cps-color-primary cps-caption cp-pt-8">
        Your start date has been changed to today's date.
      </span>
    )}
  </div>
);
