import React, { lazy } from 'react';
import PrivateRoute from '../common/private-route.component';
import { Route, Redirect } from 'react-router-dom';

const PaymentsCollected = lazy(() =>
  import(/* webpackChunkName: "payments-collected" */ './dashboard/payments-collected.component').then(m => ({
    default: m.PaymentsCollected,
  }))
);
const PaymentsUpcoming = lazy(() =>
  import(/* webpackChunkName: "payments-upcoming" */ './dashboard/payments-upcoming.component').then(m => ({
    default: m.PaymentsUpcoming,
  }))
);
const PaymentsRecurring = lazy(() =>
  import(/* webpackChunkName: "payments-recurring" */ './dashboard/payments-recurring.component').then(m => ({
    default: m.PaymentsRecurring,
  }))
);
const PaymentsDeposits = lazy(() =>
  import(/* webpackChunkName: "payments-deposits" */ './dashboard/payments-deposits.component').then(m => ({
    default: m.PaymentsDeposits,
  }))
);
const PaymentsRefunds = lazy(() =>
  import(/* webpackChunkName: "payments-refunds" */ './dashboard/payments-refunds.component').then(m => ({
    default: m.PaymentsRefunds,
  }))
);
const PaymentsAccountSummary = lazy(() =>
  import(/* webpackChunkname: "payments-account-summary" */ './dashboard/payments-account-summary.component').then(
    m => ({ default: m.PaymentsAccountSummary })
  )
);
const PaymentReceiptModal = lazy(() =>
  import(/* webpackChunkName: "client-payment-receipt" */ './components/receipt/payment-receipt-modal.component').then(
    m => ({
      default: m.PaymentReceiptModal,
    })
  )
);

export default function PaymentsRoutes(props) {
  return (
    <>
      <Route exact path="/billing/payments" render={() => <Redirect to="/billing/payments/collected" />} />
      <Route
        exact
        path="/client/:clientId/billing/payments"
        render={({ match }) => {
          return <Redirect to={`/client/${match.params.clientId}/billing/payments/collected`} />;
        }}
      />
      <Route path="/billing/payments/collected" component={PaymentsCollected} />
      <Route path="/client/:clientId/billing/payments/collected" component={PaymentsCollected} />
      <Route exact path="/billing/payments/upcoming" component={PaymentsUpcoming} />
      <Route exact path="/client/:clientId/billing/payments/upcoming" component={PaymentsUpcoming} />
      <Route exact path="/billing/payments/recurring" component={PaymentsRecurring} />
      <Route exact path="/client/:clientId/billing/payments/recurring" component={PaymentsRecurring} />
      <PrivateRoute exact path="/billing/payments/deposits/:deposit?" permissions="clients_not_assigned">
        <PaymentsDeposits />
      </PrivateRoute>
      <Route path="/billing/payments/refunds" component={PaymentsRefunds} />
      <Route path="/client/:clientId/billing/payments/refunds" component={PaymentsRefunds} />
      <PrivateRoute path="/billing/payments/credit-card-summary" permissions="clients_not_assigned">
        <PaymentsAccountSummary account="cc" />
      </PrivateRoute>
      <PrivateRoute path="/billing/payments/ach-summary" permissions="clients_not_assigned">
        <PaymentsAccountSummary {...props} account="ach" />
      </PrivateRoute>

      <Route
        path={[
          '/billing/payments/:paymentId/receipt',
          '/client/:clientId/billing/payments/:paymentId/receipt',
          '/billing/payments/collected/:paymentId/receipt',
          '/client/:clientId/billing/payments/collected/:paymentId/receipt',
          '/billing/payments/refunds/:paymentId/receipt',
          '/client/:clientId/billing/payments/refunds/:paymentId/receipt',
          '/billing/invoices/payment/:paymentId/receipt',
          '/billing/invoices/:paymentId/receipt',
          '/client/:clientId/billing/invoices/:paymentId/receipt',
          '/billing/payments/credit-card-summary/:paymentId/receipt',
          '/billing/payments/ach-summary/:paymentId/receipt',
        ]}
        component={PaymentReceiptModal}
      />
    </>
  );
}
