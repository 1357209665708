import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { CprDateRangePicker, CpIcon } from 'canopy-styleguide!sofe';
import { dateRangeTypes } from './date-filter.helper';
import { defaultDateFormat } from 'src/billing-helpers';

import styles from './filter.styles.css';
import { DateTime } from 'luxon';

const datePickerFormat = 'D';

export const DateRangeFilter = ({ applyFilter, filter, dateRanges }) => {
  const [startDateText, setStartDateText] = useState('');
  const [startDateInvalid, setStartDateInvalid] = useState(false);
  const [endDateText, setEndDateText] = useState('');
  const [endDateInvalid, setEndDateInvalid] = useState(false);

  useEffect(() => {
    setStartDateText(filter.after ? DateTime.fromISO(filter.after).toLocaleString(DateTime.DATE_SHORT) : '');
    setEndDateText(filter.before ? DateTime.fromISO(filter.before).toLocaleString(DateTime.DATE_SHORT) : '');
  }, [filter]);

  const handleRangeChange = e => {
    const startDate = DateTime.fromFormat(e.detail.startDate, datePickerFormat);
    const endDate = DateTime.fromFormat(e.detail.endDate, datePickerFormat);
    if (
      startDate.toLocaleString(DateTime.DATE_SHORT) === startDateText &&
      endDate.toLocaleString(DateTime.DATE_SHORT) === endDateText
    ) {
      return;
    }
    setStartDateInvalid(false);
    setEndDateInvalid(false);
    applyFilter(dateRangeTypes.custom, {
      after: startDate ? startDate.toISODate() : undefined,
      before: endDate ? endDate.toISODate() : undefined,
    });
  };

  const startDateOnBlur = value => {
    if (!value || DateTime.fromFormat(value, datePickerFormat).isValid) {
      setStartDateInvalid(false);
      applyFilter(dateRangeTypes.custom, {
        ...filter,
        after: value ? DateTime.fromFormat(value, datePickerFormat).toISODate() : undefined,
      });
    } else {
      setStartDateInvalid(true);
    }
  };

  const endDateOnBlur = value => {
    if (!value || DateTime.fromFormat(value, datePickerFormat).isValid) {
      setEndDateInvalid(false);
      applyFilter(dateRangeTypes.custom, {
        ...filter,
        before: value ? DateTime.fromFormat(value, datePickerFormat).toISODate() : undefined,
      });
    } else {
      setEndDateInvalid(true);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div>
        {map(dateRanges, (dateRange, dateRangeKey) => {
          return (
            <div
              key={dateRangeKey}
              className={`${styles.menuOption}`}
              onClick={() => applyFilter(dateRangeKey, dateRange.filter)}
              style={{ width: '220px' }}>
              <div className={`${filter && filter.filter_params === dateRangeKey ? 'cps-color-primary' : ''}`}>
                {dateRange.label}
              </div>
              {filter && filter.filter_params === dateRangeKey && (
                <CpIcon name="checkmark-small" fill="var(--cps-color-primary)" />
              )}
            </div>
          );
        })}
      </div>
      <div style={{ marginTop: '-80px', width: '100%', background: 'white' }}>
        <CprDateRangePicker
          startDate={filter.after ? DateTime.fromISO(filter.after).toLocaleString(DateTime.DATE_SHORT) : null}
          endDate={filter.before ? DateTime.fromISO(filter.before).toLocaleString(DateTime.DATE_SHORT) : null}
          events={{
            rangechange: handleRangeChange,
          }}
        />
        <div className="cps-form-group">
          <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
            <label style={{ alignSelf: 'center' }} htmlFor="startDate" className="cp-ml-12 cp-mr-8">
              Start date
            </label>
            <div className={`cp-mr-16 ${startDateInvalid ? 'cps-has-error' : ''}`}>
              <input
                type="text"
                className="cps-form-control"
                value={startDateText}
                onChange={e => setStartDateText(e.target.value)}
                onBlur={e => startDateOnBlur(e.target.value)}
                placeholder={defaultDateFormat}
              />
              <span className="cps-error-block">Invalid date</span>
            </div>
          </div>
          <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
            <label style={{ alignSelf: 'center' }} htmlFor="endDate" className="cp-mh-12">
              End date
            </label>
            <div className={`cp-mr-16 ${endDateInvalid ? 'cps-has-error' : ''}`}>
              <input
                type="text"
                className="cps-form-control cp-mv-8"
                value={endDateText}
                onChange={e => setEndDateText(e.target.value)}
                onBlur={e => endDateOnBlur(e.target.value)}
                placeholder={defaultDateFormat}
                disabled={!startDateText || startDateInvalid}
              />
              <span className="cps-error-block">Invalid date</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
