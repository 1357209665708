// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-content-styles__menuOption--nzLsq {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 20px;
  justify-content: space-between;
}

.menu-content-styles__menuOption--nzLsq:hover {
  background-color: var(--cps-color-chrome);
  cursor: pointer;
}

.menu-content-styles__actions--T1o_C {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/column_header/menu-content.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".menuOption {\n  display: flex;\n  align-items: center;\n  height: 20px;\n  padding: 20px;\n  justify-content: space-between;\n}\n\n.menuOption:hover {\n  background-color: var(--cps-color-chrome);\n  cursor: pointer;\n}\n\n.actions {\n  display: flex;\n  justify-content: space-between;\n  background-color: #f9f9f9;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuOption": `menu-content-styles__menuOption--nzLsq`,
	"actions": `menu-content-styles__actions--T1o_C`
};
export default ___CSS_LOADER_EXPORT___;
