import React from 'react';
import GenericTimeReport from './generic-time-report.component.js';

export default function RevenueByClientReport() {
  return (
    <GenericTimeReport
      title={'Revenue by Client'}
      reportUrl={'client_revenue'}
      isCurrency={true}
      leftColumnTitle={`Client`}
      leftColumnLink={`/billing/invoices`}
    />
  );
}
