import React from 'react';
import { CprDatepicker } from 'canopy-styleguide!sofe';

export const EndDate = ({ value, engagement, update, disabled, errors }) => (
  <div
    id="endDate"
    style={engagement ? { minWidth: '147px', maxWidth: '147px' } : { minWidth: '100px', maxWidth: '120px' }}
    className={`${engagement ? 'cp-ml-16' : 'cp-ml-8'} ${errors.includes('invalidEndDate') ? 'cps-has-error' : ''}`}>
    <label>
      {engagement ? 'Invoice end date' : 'End date'}
      {!engagement && <span className="cps-color-primary">*</span>}
    </label>
    <CprDatepicker date={value} events={{ datechange: date => update('endDate', date.detail) }} disabled={disabled} />
    <span style={{ position: 'absolute', marginTop: '4px' }} className="cps-error-block">
      Adjust your end date to follow the first invoice date.
    </span>
  </div>
);
