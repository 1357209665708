import React, { useState } from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';

export const ServiceSelector = ({
  value,
  update,
  disabled,
  categorizedServices = [],
  allServiceItems = [],
  qboCategories,
}) => {
  const [dropdownSearch, setDropdownSearch] = useState('');

  return (
    <div style={{ width: '240px' }}>
      <CpSelectSingle
        triggerIsBlock
        contentWidth="md"
        fixedContent
        data={categorizedServices ? categorizedServices : allServiceItems}
        isGroupData={!!qboCategories?.length}
        placeholder="Select a service"
        value={value}
        disabled={disabled}
        searchFilter
        searchOnChange={setDropdownSearch}
        searchValue={dropdownSearch}
        onChange={value => {
          update(value);
        }}
      />
    </div>
  );
};
