import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../report.styles.css';
import SchemaItems from 'src/reports/common/schema-items/schema-items.component.js';

export default class SummaryRow extends React.Component {
  render() {
    const { bucket_values, name, id, linkable } = this.props.summaryItem;
    const { schema } = this.props;
    return (
      <div className={`${styles.row}`}>
        <div className={`${styles.wideReportItem}`}>
          {linkable ? (
            <Link to={`/client/${id}/billing/dashboard`} target="_blank" rel="noopener noreferrer">
              {name}
            </Link>
          ) : (
            <div>{name}</div>
          )}
        </div>
        <SchemaItems schema={schema} childClass={`${styles.reportItem}`} items={bucket_values} />
      </div>
    );
  }
}
