import React, { useState, useEffect, useMemo } from 'react';
import { forkJoin } from 'rxjs';
import { isEmpty } from 'lodash';
import { CpOverlay, CpButton, CpDropdown, CpIcon, CpLoader, CpModal, CpTable, CpTooltip } from 'canopy-styleguide!sofe';
import { useHasAccess, useWithUserAndTenant } from 'cp-client-auth!sofe';
import { featureEnabled } from 'feature-toggles!sofe';

import { handleError } from 'src/common/handle-error.helper';
import { InvoiceOverlayActions } from './invoice-overlay-actions.component';
import { InvoiceBuilder } from '../invoice-builder/invoice-builder.component';
import { InvoicePreviewer } from '../invoice-previewer/invoice-previewer.component';
import { InvoiceConfigurationModal } from './invoice-configuration-modal.component';
import { linkedItemSchema } from './invoice-overlay.helper';
import { SelectTime } from '../invoice-builder/select-time.component';
import { DeleteErrorModal } from './delete-error-modal.component';
import { useInvoiceBuilder } from 'src/invoices/invoice-builder.hook';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { getClient } from 'src/resources/clients.resources';
import { invoiceCalc, invoiceTypes, modes, fetchInvoice } from 'src/invoices/invoices.helper';
import { useCanopyPaymentsQuery } from 'src/queries/hooks/use-canopy-payments-query';
import { fetchRecurrence } from 'src/invoices/recurrences.helper';
import { getInvoiceSettings } from 'src/resources/invoices.resources';
import { getServiceItems } from 'src/resources/service-items.resources';
import { getIntegrations } from 'src/resources/integrations.resources';
import { getQboFromIntegrationsObj } from 'src/common/integrations/qbo.helpers';
import { PdfTronFileError } from 'src/common/components/pdf-tron-file-error.component';

export const InvoiceOverlay = ({
  overlayData,
  show,
  onClose,
  onViewInvoiceFromRecurrence,
  onReturnToRecurrence,
  integrations,
}) => {
  const [loading, setLoading] = useState(true);
  const [serviceItems, setServiceItems] = useState([]);
  const [defaultSettings, setDefaultSettings] = useState({});
  const [fileViewerError, setFileViewerError] = useState(false);
  const { hasCanopyPayments, hasAdyen, teamCanKeyInCards } = useCanopyPaymentsQuery();
  const [user, tenant] = useWithUserAndTenant();
  const hasGroupBilling = tenant?.crm_status === 'crm_hierarchy_complete' && featureEnabled('ft_crm');

  const {
    invoice,
    recurrence,
    availableCredits,
    groupLineItems,
    invoicePreview,
    webViewerInstance,
    clientGroupClients,
    invoiceType,
    mode,
    options,
    actions,
    needToSave,
    errors,
    disableActions,
    adyenPaymentDetails,
  } = useInvoiceBuilder(
    show,
    onClose,
    onViewInvoiceFromRecurrence,
    onReturnToRecurrence,
    serviceItems,
    defaultSettings,
    hasCanopyPayments,
    hasAdyen,
    teamCanKeyInCards,
    tenant
  );
  const canCreateEditInvoice = useHasAccess('billing_invoices_create_edit');
  const recurrenceEnded = recurrence.status === 'Ended';
  const isClient = user?.role === 'Client';

  const [initialized, setInitialized] = useState(false);
  const [reloadInvoice, setReloadInvoice] = useState(false);
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [showLinkedTimeOverlay, setShowLinkedTimeOverlay] = useState(false);
  const [linkedTimeEntries, setLinkedTimeEntries] = useState([]);
  const [loadingRemoveTimeEntries, setLoadingRemoveTimeEntries] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);
  const [qboIntegration, setQboIntegration] = useState();
  const [triggerIntegrations, setTriggerIntegrations] = useState(false);
  const selection = CpTable.useSelection({ totalSize: linkedTimeEntries.length });
  const schema = CpTable.useSchema(() => linkedItemSchema(hasGroupBilling), [hasGroupBilling]);

  const timeEntriesToRemove = useMemo(() => {
    const selectionIDs = selection.toArray().map(id => id.toString());
    if (selection.type === 'includes') {
      return selectionIDs;
    } else {
      const linkedEntryIDs = linkedTimeEntries.map(i => i.id);
      return linkedEntryIDs.filter(element => !selectionIDs.includes(element));
    }
  }, [selection.byId]);

  const title = useMemo(() => {
    const isPaid = invoiceCalc.getBalanceDue(invoice) === 0;

    switch (mode) {
      case modes.create:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{invoiceType === invoiceTypes.recurring && 'Recurring '}Invoice</span>
            {canCreateEditInvoice && invoice.lineItems.fromTime.length === 0 && (
              <CpDropdown
                renderTrigger={({ toggle }) => (
                  <CpButton icon="caret-small-down" onClick={toggle} aria-label="Invoice Type" />
                )}
                renderContent={() => (
                  <div className="cp-select-list">
                    <button onClick={() => actions.typeChange(invoiceTypes.oneTime)}>Invoice</button>
                    <button onClick={() => actions.typeChange(invoiceTypes.recurring)}>Recurring Invoice</button>
                  </div>
                )}
              />
            )}
            {(recurrence.isDuplicate || invoice.isDuplicate) && (
              <i className="cps-body-sm cp-ml-8">{`Duplicated ${
                invoiceType === invoiceTypes.recurring ? 'Recurrence' : 'Invoice'
              }`}</i>
            )}
          </div>
        );
      case modes.edit:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>
              {overlayData.recurrenceId ? 'Edit Recurring Invoice' : `Edit Invoice #${invoice.invoiceNumber || ''}`}
            </span>
            {recurrenceEnded && (
              <i className="cps-body-sm cp-ml-32 cp-color-app-secondary-text" style={{ maxWidth: '500px' }}>
                {`This recurrence has ended and cannot be edited or restarted. ${
                  canCreateEditInvoice ? 'You can duplicate this recurrence to restart the event.' : ''
                }`}
              </i>
            )}
          </div>
        );
      case modes.preview:
        return 'Preview Invoice';
      case modes.view:
      case modes.viewOnly:
        return (
          <div>
            <span>Invoice #{invoice.invoiceNumber || ''}</span>
            {isPaid && !isClient && invoice.payments && (
              <CpTooltip text="This invoice has been paid in full and cannot be deleted or edited. Payments must be refunded in order to change an invoice.">
                <span className="cp-p-8">
                  <CpIcon name="information-circle-open-small" />
                </span>
              </CpTooltip>
            )}
          </div>
        );
      case modes.selectTime:
        return (
          <CpButton
            icon="caret-large-left"
            btnType="tertiary"
            onClick={() => actions.modeChange(invoice.id && !invoice.draft ? modes.edit : modes.create)}>
            <span className="cps-subheader-sm cps-wt-semibold" style={{ display: 'flex' }}>
              Select Time and Expenses
            </span>
          </CpButton>
        );
    }
  }, [mode, invoiceType, overlayData.recurrenceId, invoice, recurrence.status]);

  useEffect(() => {
    if (!show || mode === modes.create) return;
    setLoading(true);
    const serviceItemsObservable = getServiceItems({ includeArchived: true });
    const defaultSettingsObservable = getInvoiceSettings();
    const subscription = forkJoin(serviceItemsObservable, defaultSettingsObservable).subscribe(
      response => {
        const [serviceItems, defaultSettings] = response;
        setServiceItems(serviceItems);
        setDefaultSettings(defaultSettings);
        setLoading(false);
      },
      e => {
        setLoading(false);
        handleError(e);
      }
    );
    return () => subscription.unsubscribe();
  }, [show]);

  useEffect(() => {
    if (!show) return;

    actions.typeChange(overlayData.type === invoiceTypes.recurring ? invoiceTypes.recurring : invoiceTypes.oneTime);
  }, [show, overlayData.type]);

  useEffect(() => {
    if (!show) return;

    actions.modeChange(overlayData.mode);
  }, [show, overlayData.mode]);

  useEffect(() => {
    if (!show || !overlayData.clientGroup) return;
    actions.updateInvoiceFormData(
      'clientGroup',
      { id: overlayData.clientGroup.id, name: overlayData.clientGroup.name },
      false
    );
  }, [show, overlayData.clientGroup]);

  useEffect(() => {
    if (!show || !overlayData.clientId) return;
    const clientSubscription = getClient(overlayData.clientId).subscribe(client => {
      if (client.is_active) {
        actions.updateInvoiceFormData('client', { id: client.id, name: client.name }, false);
        actions.updateInvoiceFormData('isBusinessClient', client.is_business, false);
      }
    }, handleError);

    return () => clientSubscription.unsubscribe();
  }, [show, overlayData.clientId]);

  useEffect(() => {
    if (!show || !overlayData.invoiceId) return;
    fetchInvoice(overlayData.invoiceId).then(actions.loadInvoice);
  }, [show, overlayData.invoiceId]);

  useEffect(() => {
    if (!reloadInvoice) return;
    fetchInvoice(overlayData.invoiceId).then(res => {
      actions.loadInvoice(res);
      setReloadInvoice(false);
    });
  }, [reloadInvoice]);

  useEffect(() => {
    if (!show || !overlayData.recurrenceId) return;
    fetchRecurrence(overlayData.recurrenceId).then(({ invoice, recurrence }) =>
      actions.loadRecurrence(invoice, recurrence)
    );
  }, [show, overlayData.recurrenceId]);

  useEffect(() => {
    if (!show) {
      setInitialized(false);
      return;
    }
    if (overlayData.mode === modes.edit && !invoice.id) return;
    if (!initialized && overlayData.timeEntries?.length > 0 && !loading && !isEmpty(defaultSettings)) {
      actions.addTimeEntry(overlayData.timeEntries, false, defaultSettings?.line_items?.id === 'grouped');
      setInitialized(true);
    }
  }, [show, initialized, invoice.id, overlayData.timeEntries, loading, defaultSettings]);

  useEffect(() => {
    if (!!tenant?.qbo_credentials_id && show && (!integrations?.length || triggerIntegrations)) {
      const subscription = getIntegrations().subscribe(integrationsObj => {
        const qboIntegrationInfo = integrationsObj?.find(i => i.type === 'qbo');
        if (qboIntegrationInfo) {
          setQboIntegration({ ...qboIntegrationInfo, checkIntegrationAuth: () => setTriggerIntegrations(true) });
        }
      }, handleError);
      return () => subscription.unsubscribe?.();
    } else if (!!tenant?.qbo_credentials_id && show) {
      const qboIntegrationInfo = getQboFromIntegrationsObj(integrations);
      if (qboIntegrationInfo) {
        setQboIntegration(qboIntegrationInfo);
      }
    } else if (!tenant?.qbo_credentials_id) {
      setQboIntegration();
    }
  }, [show, integrations, tenant?.qbo_credentials_id]);

  useEffect(() => {
    if (!show) return;
    setLinkedTimeEntries(
      invoice.lineItems.hidden.map(i => ({
        ...i,
        clientName: i.relationships?.client?.name,
        type: i.expenseId ? 'Expense' : 'Time Entry',
        quantity: i.expenseId ? null : i.quantity,
        serviceName: i.relationships?.service_item?.name,
      }))
    );
  }, [show, invoice.lineItems.hidden]);

  const handleConfigure = () => {
    setShowConfigureModal(true);
  };

  const handleRequestClose = () => {
    if (needToSave && errors?.validationErrors.length === 0 && mode !== modes.view && mode !== modes.viewOnly) {
      setShowDiscardModal(true);
    } else {
      onClose?.();
    }
  };

  const handleRequestDelete = () => {
    if (invoiceType === invoiceTypes.oneTime) {
      if (['paid', 'partial', 'partial overdue'].includes(invoice.status) && invoice.payments) {
        setShowDeleteError(true);
      } else {
        actions.delete();
      }
    } else {
      setShowConfirmDelete(true);
    }
  };

  const handleRemoveTimeEntries = () => {
    setLoadingRemoveTimeEntries(true);
    actions.removeTimeEntriesFromInvoice(timeEntriesToRemove, () => {
      window.dispatchEvent(
        new CustomEvent('billing-ui::event-saved', {
          detail: {
            clientId: invoice?.client?.id,
            serviceItemIds: [...new Set(invoice?.lineItems?.fromTime?.map(item => item?.service?.id))] || [],
          },
        })
      );
      setLoadingRemoveTimeEntries(false);
      setReloadInvoice(true);
      showLinkedTimeOverlay && setShowLinkedTimeOverlay(false);
      selection.length > 0 && selection.deselectAll();
    });
  };

  const navToPayments = () => {
    onClose && onClose();
    window.location = '#/billing/payments';
  };
  return (
    <InvoiceContext.Provider
      value={{
        invoice,
        recurrence,
        availableCredits,
        groupLineItems,
        invoicePreview,
        webViewerInstance,
        clientGroupClients,
        invoiceType,
        mode,
        options,
        actions,
        needToSave,
        errors,
        defaultSettings,
        disableActions,
        adyenPaymentDetails,
      }}>
      {!fileViewerError ? (
        <CpOverlay show={show} onClose={handleRequestClose}>
          <CpOverlay.Header title={title}>
            <InvoiceOverlayActions
              onLinkedTime={() => setShowLinkedTimeOverlay(true)}
              onConfigure={handleConfigure}
              onRequestDelete={handleRequestDelete}
              hasCanopyPayments={hasCanopyPayments}
              onClose={handleRequestClose}
            />
          </CpOverlay.Header>
          <CpOverlay.Body>
            {loading || !mode ? (
              <CpLoader />
            ) : mode === modes.create || mode === modes.edit ? (
              <InvoiceBuilder tenant={tenant} qboIntegration={qboIntegration} />
            ) : mode === modes.preview || mode === modes.view || mode === modes.viewOnly ? (
              <InvoicePreviewer
                fromRecurrence={overlayData.cameFromRecurrenceId}
                onClose={handleRequestClose}
                setFileViewerError={setFileViewerError}
              />
            ) : (
              <SelectTime />
            )}
          </CpOverlay.Body>
        </CpOverlay>
      ) : (
        <PdfTronFileError showError={fileViewerError} />
      )}
      <InvoiceConfigurationModal
        hasGroupBilling={hasGroupBilling}
        key={showConfigureModal}
        showConfigureModal={showConfigureModal}
        hideConfigureModal={() => setShowConfigureModal(false)}
        disabled={!canCreateEditInvoice || options.singleLine || recurrenceEnded}
      />
      <CpOverlay
        show={showLinkedTimeOverlay}
        width={830}
        onClose={() => {
          selection.deselectAll();
          setShowLinkedTimeOverlay(false);
        }}>
        <CpOverlay.Header title="Linked Time and Expenses">
          <CpButton
            className="cp-mr-8"
            btnType="primary"
            disabled={!timeEntriesToRemove.length || loadingRemoveTimeEntries}
            onClick={handleRemoveTimeEntries}>
            Remove
          </CpButton>
        </CpOverlay.Header>
        <CpOverlay.Body className="cp-p-0">
          <CpTable selection={selection} schema={schema} data={linkedTimeEntries} fullWidth />
        </CpOverlay.Body>
      </CpOverlay>
      <CpModal show={showDiscardModal} onClose={() => setShowDiscardModal(false)}>
        <CpModal.Header title="Discard unsaved changes" />
        <CpModal.Body>
          You haven't saved your changes to this invoice. Are you sure you want to discard your changes?
        </CpModal.Body>
        <CpModal.Footer>
          {invoiceType !== invoiceTypes.recurring && (!invoice.id || invoice.draft) && (
            <CpButton
              btnType="primary"
              onClick={() => {
                setShowDiscardModal(false);
                actions.save(true);
              }}>
              Save as draft
            </CpButton>
          )}
          {invoiceType !== invoiceTypes.recurring && invoice.id && !invoice.draft && (
            <CpButton
              btnType="primary"
              onClick={() => {
                setShowDiscardModal(false);
                actions.save();
              }}>
              Save
            </CpButton>
          )}
          {invoiceType === invoiceTypes.recurring && (
            <CpButton
              btnType="primary"
              onClick={() => {
                setShowDiscardModal(false);
                actions.save(null, recurrence.started || false);
              }}>
              {`Save${recurrence.started ? '' : ' as draft'}`}
            </CpButton>
          )}
          <CpButton
            btnType="flat"
            onClick={() => {
              setShowDiscardModal(false);
              onClose();
            }}>
            Discard
          </CpButton>
        </CpModal.Footer>
      </CpModal>
      <CpModal show={showConfirmDelete} onClose={() => setShowConfirmDelete(false)}>
        <CpModal.Header title="Delete recurring series" />
        <CpModal.Body>
          By deleting this recurring series you will stop all future invoices. Do you want to continue?
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            onClick={() => {
              actions.delete();
              setShowConfirmDelete(false);
            }}>
            Delete
          </CpButton>
          <CpButton btnType="flat" onClick={() => setShowConfirmDelete(false)}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
      <DeleteErrorModal
        showDeleteError={showDeleteError}
        setShowDeleteError={setShowDeleteError}
        navToPayments={navToPayments}
      />
    </InvoiceContext.Provider>
  );
};
