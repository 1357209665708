import { fetchAsObservable } from 'fetcher!sofe';
import { pluck } from 'rxjs/operators';

export const getPaymentMethods = clientId => {
  return fetchAsObservable(`/api/card_management/client/${clientId}`);
};

export const createCard = card => {
  return fetchAsObservable('/api/card_management/card', {
    method: 'POST',
    body: card,
  }).pipe(pluck('card'));
};

export const createBankAccount = account => {
  return fetchAsObservable('/api/card_management/ach', {
    method: 'POST',
    body: account,
  }).pipe(pluck('card'));
};

export const deleteCard = cardId => {
  return fetchAsObservable(`/api/card_management/card/${cardId}`, {
    method: 'DELETE',
  });
};

export const deleteBankAccount = accountId => {
  return fetchAsObservable(`/api/card_management/ach/${accountId}`, {
    method: 'DELETE',
  });
};

export const updateCard = (cardId, card) => {
  return fetchAsObservable(`/api/card_management/card/${cardId}`, {
    method: 'PATCH',
    body: card,
  }).pipe(pluck('card'));
};

export const updateBankAccount = (accountId, account) => {
  return fetchAsObservable(`/api/card_management/ach/${accountId}`, {
    method: 'PATCH',
    body: account,
  }).pipe(pluck('ach'));
};

export function updateSurchargeOptions(surchargeOptions) {
  return fetchAsObservable('/api/canopy_payments/surcharge', {
    method: 'PATCH',
    body: surchargeOptions,
  });
}
