import { fetchAsObservable } from 'fetcher!sofe';

export function getAgedReceivableData(includeInvoices, schemaOverride, filters) {
  return fetchAsObservable(`api/billing/reports/ar`, {
    method: 'post',
    body: {
      ...createBody(includeInvoices, schemaOverride, filters),
    },
  });
}

export function getAgedReceivableDataCSV(includeInvoices, schemaOverride, filters) {
  return `/api/billing/reports/ar/csv?options=${getURIBody(includeInvoices, schemaOverride, filters)}`;
}

function getURIBody(includeInvoices, schemaOverride, filters) {
  const body = createBody(includeInvoices, schemaOverride, filters);
  return window.encodeURIComponent(JSON.stringify(body));
}

function createBody(includeInvoices, schemaOverride, filters) {
  const defaultSchema = ['current', [1, 30], [31, 60], [61, 90], [91], 'total'];
  return {
    include: includeInvoices ? ['invoices'] : undefined,
    bucket_schema: schemaOverride || defaultSchema,
    ...filters,
  };
}
