import React from 'react';
import { CpInput } from 'canopy-styleguide!sofe';
import { wholeNumberFilter } from 'src/billing-helpers.js';

export const FixedTerms = ({ value, update, disabled }) => (
  <div className="cp-ml-8">
    <label>&nbsp;</label>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className="cp-ml-8" style={{ width: '60px' }}>
        <CpInput
          value={value}
          onChange={value => update('fixedTerm', wholeNumberFilter(value))}
          onBlur={e => e.target.value === '' && update('fixedTerm', '0')}
          disabled={disabled}
          maxLength="2"
        />
      </div>
    </div>
  </div>
);
