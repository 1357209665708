import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import { handleError } from 'src/common/handle-error.helper';
import {
  updatePaymentSession,
  updateCanopyPayment,
  processPaymentSession,
  deleteAdyenPaymentMethod,
  saveScheduledPayment,
  getSurchargeFee,
  updateSecureLinkPaymentSession,
  processSecureLinkPaymentSession,
} from 'src/resources/payments.resources';
import { createRecurringPayment } from 'src/resources/invoices.resources';
import { paymentSteps } from 'src/payments/payments.helper';

export const useAdyenPayments = ({ paymentViewMode }) => {
  const [adyenSessionDetails, setAdyenSessionDetails] = useState();
  const [paymentMethod, setPaymentMethod] = useState({ type: 'newCreditCard' });
  const [adyenInstance, setAdyenInstance] = useState();
  const [savedMethod, setSavedMethod] = useState(false);
  const [surchargeAmount, setSurchargeAmount] = useState();

  const [paymentInfo, setPaymentInfo] = useState({
    nickname: paymentMethod?.nickname || '',
    isPreferred: paymentMethod?.is_preferred,
    teamCanUse: paymentMethod?.team_can_use,
  });

  useEffect(() => {
    setPaymentInfo({
      nickname: paymentMethod?.nickname || '',
      isPreferred: paymentMethod?.is_preferred || false,
      teamCanUse: paymentMethod?.team_can_use,
    });
  }, [paymentMethod]);

  const resetPaymentInfo = () => {
    setSavedMethod(false);
    setPaymentInfo({
      nickname: '',
      isPreferred: false,
    });
  };

  const handleAdyenSession = ({
    clientId,
    onComplete,
    step,
    invoices,
    selectedRecurrence,
    accountPaymentDetails,
    recurringPaymentDate,
    isEdit,
  }) => {
    const selectedInvoices =
      invoices
        ?.filter(invoice => invoice.selected)
        ?.map(invoice => ({ invoice_id: invoice.id, amount: invoice.amountToPay })) || [];

    const sessionData = {
      ...(adyenSessionDetails?.payment_session_id && {
        payment_session_id: adyenSessionDetails?.payment_session_id,
      }),
      ...(paymentViewMode === 'payments' &&
        !isEdit &&
        (selectedInvoices.length !== 0 || accountPaymentDetails || selectedRecurrence) && {
          session_details: {
            ...(step === paymentSteps.SelectInvoices && {
              invoice_payments: selectedInvoices,
              account_balance_amount: accountPaymentDetails?.amount,
              ...(accountPaymentDetails?.description && { credit_description: accountPaymentDetails?.description }),
            }),
            ...((step === paymentSteps.SelectRecurrence || selectedRecurrence) && {
              recurrence_id: selectedRecurrence?.id,
              payment_terms:
                recurringPaymentDate === 'due date' || recurringPaymentDate !== 'Due on Receipt'
                  ? parseInt(selectedRecurrence?.terms) || selectedRecurrence?.fixedTerm
                  : 0,
            }),
          },
        }),
      client_id: clientId,
    };

    updatePaymentSession(sessionData).subscribe(response => {
      setAdyenSessionDetails(response);
      onComplete?.();
    }, handleError);
  };

  const handleSavePaymentMethod = ({ onComplete }) => {
    let saveMethod;
    let endpointId;

    const paymentData = {
      ...(adyenInstance && { payment_data: { ...adyenInstance, storePaymentMethod: savedMethod } }),
      ...(paymentInfo.nickname && { nickname: paymentInfo.nickname.trim() }),
      ...(paymentMethod.id && { card_id: paymentMethod.id }),
      team_can_use: true,
      is_preferred: paymentInfo.isPreferred,
    };

    if (adyenSessionDetails?.payment_session_id) {
      saveMethod = processPaymentSession;
      endpointId = adyenSessionDetails.payment_session_id;
    } else {
      saveMethod = updateCanopyPayment;
      endpointId = paymentMethod.id;
    }

    saveMethod(endpointId, paymentData).subscribe(() => {
      setAdyenSessionDetails();
      onComplete?.();
    }, handleError);
  };

  const togglePreferredPayment = (payment, callback) => {
    document.activeElement.blur();
    updateCanopyPayment(payment.id, { is_preferred: !payment.is_preferred }).subscribe(() => {
      setAdyenSessionDetails();
      callback();
    }, handleError);
  };

  const editRecurrencePayment = ({
    payment,
    newAdyenCard,
    onComplete,
    onError,
    selectedRecurrence,
    recurringPaymentDate,
  }) => {
    let recurrences;
    let editMethod;
    let id;

    if (selectedRecurrence) {
      recurrences = {
        payment_details: {
          card_id: paymentMethod?.id,
          terms: recurringPaymentDate === 'due date' ? parseInt(selectedRecurrence?.terms) : 0,
        },
      };
      editMethod = createRecurringPayment;
      id = selectedRecurrence.id;
    } else {
      recurrences = {
        start_date: DateTime.fromISO(payment.date).toISODate(),
        template: {
          card_id: paymentMethod?.id || newAdyenCard,
          invoice_payments: payment.invoice_payments,
          relationships: payment.relationships,
          amount: payment.amount,
          credit_description: payment.credit_description,
          client_notes: payment.notes,
        },
      };
      editMethod = saveScheduledPayment;
      id = { id: payment.id };
    }

    editMethod(id, { recurrences }).subscribe(
      response => {
        if (savedMethod && paymentMethod?.id) {
          updateCanopyPayment(paymentMethod?.id, { is_preferred: paymentInfo?.isPreferred }).subscribe(() => {},
          handleError);
        }

        setAdyenSessionDetails();
        onComplete(response);
      },
      e => {
        onError(e);
        handleError(e);
      }
    );
  };

  const submitPayment = ({
    payment,
    sendReceipt,
    onComplete,
    onError,
    isEdit,
    selectedRecurrence,
    recurringPaymentDate,
    creditDescription,
    syncPaymentsToIntegration,
    hasIntegrationPayments,
  }) => {
    if (isEdit && !adyenInstance) {
      payment.credit_description = creditDescription;
      editRecurrencePayment({ payment, onComplete, onError, selectedRecurrence, recurringPaymentDate });
      return;
    }

    const paymentData = {
      ...((adyenInstance || savedMethod) && {
        payment_data: { ...adyenInstance, storePaymentMethod: savedMethod },
      }),
      ...(savedMethod && { nickname: paymentInfo.nickname.trim(), is_preferred: paymentInfo.isPreferred }),
      client_notes: payment.notes,
      send_payment_receipt: sendReceipt,
      ...(DateTime.fromISO(payment.date).toISODate() > DateTime.local().toISODate() && {
        payment_date: DateTime.fromISO(payment.date).toISODate(),
      }),
      ...(paymentMethod && { card_id: paymentMethod?.id }),
      ...(hasIntegrationPayments && {
        third_party_sync_payment: syncPaymentsToIntegration,
        event_origin: 'practitioner',
      }),
    };

    processPaymentSession(adyenSessionDetails.payment_session_id, paymentData).subscribe(
      response => {
        if (isEdit) {
          editRecurrencePayment({
            payment,
            newAdyenCard: response.card_id,
            onComplete,
            onError,
            selectedRecurrence,
            recurringPaymentDate,
          });
          return;
        }

        setAdyenSessionDetails();
        onComplete(response);
      },
      e => {
        onError(e);
        handleError(e);
      }
    );
  };

  const deletePaymentMethod = onComplete => {
    deleteAdyenPaymentMethod(paymentMethod.id).subscribe(() => {
      onComplete();
    }, handleError);
  };

  // Use when you need the process method to access the response as a promise
  const saveAdyenPaymentMethod = () => {
    const paymentData = {
      ...(paymentMethod && { card_id: paymentMethod?.id }),
      ...(adyenInstance && { payment_data: { ...adyenInstance, storePaymentMethod: savedMethod } }),
      ...(savedMethod && { nickname: paymentInfo.nickname.trim(), is_preferred: paymentInfo.isPreferred }),
    };

    return new Promise((resolve, reject) => {
      processPaymentSession(adyenSessionDetails?.payment_session_id, paymentData).subscribe(
        response => {
          resolve(response);
        },
        error => {
          handleError(error);
          reject(error);
        }
      );
    });
  };

  const calculateSurchargeFee = amount => {
    getSurchargeFee({ amount, payment_type: 'credit_card' }).subscribe(({ fees }) => {
      setSurchargeAmount(fees);
    }, handleError);
  };

  const handleSecureLinkAdyenSession = ({ objectType, clientId, invoice }) => {
    const sessionData = {
      ...(invoice && {
        session_details: {
          invoice_payments: [{ invoice_id: invoice.id, amount: invoice.balance }],
        },
      }),
      client_id: clientId,
    };

    updateSecureLinkPaymentSession(objectType, invoice.id, sessionData).subscribe(response => {
      setAdyenSessionDetails(response);
    }, handleError);
  };

  const submitSecureLinkPayment = ({ onClose }) => {
    if (!adyenInstance || !adyenSessionDetails?.payment_session_id) return;
    const paymentData = {
      payment_data: { ...adyenInstance, storePaymentMethod: savedMethod },
      payment_session_id: adyenSessionDetails?.payment_session_id,
    };

    processSecureLinkPaymentSession(paymentData).subscribe(response => {
      onClose?.(response);
    }, handleError);
  };

  const adyenPaymentDetails = {
    paymentMethod,
    adyenInstance,
    adyenSessionDetails,
    savedMethod,
    paymentInfo,
    surchargeAmount,
  };

  const adyenActions = {
    resetPaymentInfo,
    handleAdyenSession,
    setAdyenSessionDetails,
    setPaymentMethod,
    submitPayment,
    setSavedMethod,
    setAdyenInstance,
    handleSavePaymentMethod,
    setPaymentInfo,
    togglePreferredPayment,
    deletePaymentMethod,
    saveAdyenPaymentMethod,
    calculateSurchargeFee,
    handleSecureLinkAdyenSession,
    submitSecureLinkPayment,
    setSurchargeAmount,
  };

  return { adyenActions, adyenPaymentDetails };
};
