import { constants } from 'canopy-styleguide!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import { handleError } from 'src/common/handle-error.helper';

export const createWebviewerOptions = () => ({
  path: featureEnabled('toggle_pdftron_upgrade') ? '/PDFTron-upgrade/webviewer' : '/PDFTron/webviewer',
  enableAnnotations: false,
  licenseKey: constants.pdfTronLicenseKey,
});

export const disableElements = instance => {
  const elementsToDisable = ['header', 'pageNavOverlay'];

  instance.UI.disableElements(elementsToDisable);
};

export function customizeHotKeys(instance) {
  //Documentation for hotkeys https://docs.apryse.com/api/web/7.3/WebViewerInstance.Hotkeys.html
  const { Keys } = instance.UI.hotkeys;

  instance.UI.hotkeys.off(Keys.CTRL_P);
  instance.UI.hotkeys.off(Keys.COMMAND_P);
  instance.UI.hotkeys.off(Keys.CTRL_O);
  instance.UI.hotkeys.off(Keys.COMMAND_O);
  instance.UI.hotkeys.off(Keys.CTRL_F);
  instance.UI.hotkeys.off(Keys.COMMAND_F);
  instance.UI.hotkeys.off(Keys.CTRL_EQUAL);
  instance.UI.hotkeys.off(Keys.COMMAND_EQUAL);
  instance.UI.hotkeys.off(Keys.CTRL_MINUS);
  instance.UI.hotkeys.off(Keys.COMMAND_MINUS);
  instance.UI.hotkeys.off(Keys.S);
  instance.UI.hotkeys.off(Keys.G);
  instance.UI.hotkeys.off(Keys.E);
  instance.UI.hotkeys.off(Keys.I);
  instance.UI.hotkeys.off(Keys.CTRL_Z);
  instance.UI.hotkeys.off(Keys.COMMAND_Z);
  instance.UI.hotkeys.off(Keys.CTRL_O);
  instance.UI.hotkeys.off(Keys.COMMAND_O);
  instance.UI.hotkeys.off(Keys.A);
  instance.UI.hotkeys.off(Keys.C);
  instance.UI.hotkeys.off(Keys.F);
  instance.UI.hotkeys.off(Keys.L);
  instance.UI.hotkeys.off(Keys.N);
  instance.UI.hotkeys.off(Keys.O);
  instance.UI.hotkeys.off(Keys.R);
  instance.UI.hotkeys.off(Keys.T);
  instance.UI.hotkeys.off(Keys.H);
  instance.UI.hotkeys.off(Keys.K);
  instance.UI.hotkeys.off(Keys.U);
  instance.UI.hotkeys.off(Keys.CTRL_SHIFT_EQUAL);
  instance.UI.hotkeys.off(Keys.COMMAND_SHIFT_EQUAL);
  instance.UI.hotkeys.off(Keys.CTRL_SHIFT_MINUS);
  instance.UI.hotkeys.off(Keys.COMMAND_SHIFT_MINUS);
}

export const handlePDFTronErrors = e => {
  let error;
  if (e) {
    error = e instanceof Error ? e : new Error(e?.message ?? e);
  } else {
    error = new Error('Unknown previewer error');
  }
  error.showToast = false;
  handleError(error);
};
