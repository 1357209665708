import React from 'react';
// sofe
import { CpButton } from 'canopy-styleguide!sofe';
import DueDate from './due-date/due-date.component.js';
import { MultiSelectFilter } from './multiselect-filter.component.js';
import TermOptions from './resources/terms.js';
import StatusOpts from './resources/status.js';
import { Clients } from './clients/clients.component';
import { ClientOwner } from './clients/client-owner.component';
import ColumnsAndRowsSection from './columns-and-rows/columns-and-rows.component.js';
import styles from './customize-modal-content.styles.css';
import { cloneDeep } from 'lodash';

export default class CustomizeModalContents extends React.Component {
  // Coined "ephemeral state", this state is a direct replica of the state maintained in report.component
  // (AgedReceivableDetail) which is handed down as customizeReportSettings in props.
  // The purpose of the duplication is to preserve the most recent state of customization in the filter
  // without having to version control changes in some kind of stack. It might help to think of the buttons provided
  // in the modal:

  // Cancel -> do nothing
  //   (for this we need the ephemeral state. state will be updated but then discarded without affecting parent)
  // Run -> Update parent state
  // reset -> Update parent state to the DEFAULT

  // * Ephemeral state is certified gluten free, free-range, non-gmo, fair trade, dairy-free, and vegan.
  constructor(props) {
    super(props);
    this.state = cloneDeep(this.props.customizeReportSettings);
  }

  updateField = (key, value) => {
    this.setState({ [key]: value });
  };

  cancel = () => {
    this.props.closeModal();
  };

  resetToDefault = () => {
    const defaults = cloneDeep(this.props.stateDefault);
    this.setState({ ...defaults });
  };

  updateCheckbox = (key, value) => {
    this.setState(prevState => {
      const checkboxCopy = { ...prevState.checkboxes };
      if (checkboxCopy.general.has(key)) {
        checkboxCopy.general.set(key, value);
      } else {
        checkboxCopy.numeric.set(key, value);
      }
      return { checkboxes: checkboxCopy };
    });
  };

  handleFormSubmit = e => {
    e.preventDefault();
    this.props.closeModal(this.state);
  };

  render() {
    return (
      <>
        <div>
          <div className="cps-card__header cps-subheader-sm">
            <span className="cps-subheader">Customize Report</span>
            <i className="cps-modal__dialog__close cps-icon cps-icon-close" onClick={this.cancel} />
          </div>
          <div className={`cps-card__body ${styles.noPaddingBottom}`}>
            <div className="cps-subheader-sm cps-wt-semibold">Filters</div>
            <DueDate updateDueDateKey={this.updateField} DueDateKey={this.state.DueDateKey} />
            <Clients
              updateClientsKeys={this.updateField}
              clientsFromResponse={this.props.clientsFromResponse}
              ClientsKeys={this.state.ClientsKeys}
            />
            <ClientOwner updateOwners={this.updateField} OwnerKey={this.state.OwnerKey} />
            <MultiSelectFilter
              keys={this.state.TermsKeys}
              keyType={'TermsKeys'}
              options={TermOptions}
              title={'terms'}
              updateKeys={this.updateField}
            />
            <MultiSelectFilter
              keys={this.state.StatiKeys}
              keyType={'StatiKeys'}
              options={StatusOpts}
              title={'status'}
              updateKeys={this.updateField}
            />
            <div className="cps-subheader-sm cps-wt-semibold">Show/hide columns and rows</div>
            <ColumnsAndRowsSection
              updateCheckbox={this.updateCheckbox}
              checkboxes={this.state.checkboxes}
              bucket_schema={this.props.bucket_schema}
            />
          </div>
          <hr />
          <div className={styles.buttonFlexContainer}>
            <div>
              <CpButton btnType="primary" onClick={this.handleFormSubmit}>
                Run report
              </CpButton>
              <CpButton btnType="flat" className="cp-ml-8" onClick={this.cancel}>
                Cancel
              </CpButton>
            </div>
            <div className={styles.resetButton}>
              <CpButton btnType="flat" onClick={this.resetToDefault}>
                Reset to default
              </CpButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}
