import React from 'react';
import { CpTable, CpInput, CpTooltip, CpIcon } from 'canopy-styleguide!sofe';
import { maybe } from 'kremling';
import { DateTime } from 'luxon';

import { CurrencyInput } from './currency-input.component';
import styles from './add-payment.styles.css';

const AmountCell = ({ resource, isDisabled }) => {
  return (
    <div>
      <div className={maybe('cp-mt-8', resource.activeAutopay)}>
        {isDisabled ? (
          // display disabled cpinput for now so that the disabled state is consistent with the other inputs
          // TODO: remove this ternary after CurrencyInput component is updated to be CpInput
          <CpInput value={resource.amountToPay} placeholder="$0.00" disabled style={{ textAlign: 'right' }} />
        ) : (
          <CurrencyInput
            value={resource.amountToPay}
            placeholder="$0.00"
            onChange={(newValue, validate, onBlurChange) => {
              if (onBlurChange && newValue === resource.amountToPay) {
                // prevent unnecessary onChange calls
                return;
              }
              resource.handleAmountToPayChanged(resource.id, newValue, validate);
            }}
            alignRight
          />
        )}
      </div>
      {resource.activeAutopay && (
        <div className={`cp-color-app-secondary-text cps-caption ${styles.activeAutopay}`}>Active autopay</div>
      )}
    </div>
  );
};
const DueDateCell = ({ value, isDisabled }) => {
  const isExpired =
    DateTime.fromFormat(value, 'M/d/yyyy').startOf('day').toUTC().toISO() <
    DateTime.now().startOf('day').toUTC().toISO();
  return (
    <div
      className={maybe('cp-color-app-warning-text', isExpired).m('cp-color-app-disabled-text', isDisabled)}
      style={{ opacity: isExpired && isDisabled ? '0.6' : '1' }}>
      {value}
    </div>
  );
};

const InvoiceNumCell = ({ resource, value, isDisabled }) => {
  const scheduledPayments = (resource.scheduled_payments || [])
    .filter(p => p.next_occurrence && p.recurrence_id !== resource.paymentId)
    .sort((a, b) => {
      return (
        DateTime.fromFormat(a.next_occurrence, 'yyyy-MM-dd').toUTC() -
        DateTime.fromFormat(b.next_occurrence, 'yyyy-MM-dd').toUTC()
      );
    });

  let tooltipText = scheduledPayments[0]?.next_occurrence ? (
    <span>
      <strong>
        This invoice has a payment scheduled for{' '}
        {DateTime.fromFormat(scheduledPayments[0].next_occurrence, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_SHORT)} of
        ${scheduledPayments[0].amount}.
      </strong>{' '}
      Any payments made may affect the scheduled payments.
    </span>
  ) : (
    ''
  );

  if (scheduledPayments.length > 1) {
    tooltipText = (
      <div>
        <div>
          <strong>This invoice has multiple payments scheduled.</strong> Any payments made may affect the scheduled
          payments.
        </div>
        <div>
          <ul>
            {scheduledPayments.slice(0, 3).map((sp, idx) => {
              return (
                <li key={sp.recurrence_id} className="cp-ml-4">
                  {DateTime.fromFormat(sp.next_occurrence, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_SHORT)} for $
                  {sp.amount}
                  {scheduledPayments.length > 3 && idx === 2 && (
                    <strong> ...and {scheduledPayments.length - 3} more</strong>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className={maybe('cp-color-app-disabled-text', isDisabled)}>
      {value}
      {resource.amountToPay > 0 && scheduledPayments.length > 0 && (
        <CpTooltip text={tooltipText} position="right-start">
          <CpIcon
            className="cp-ml-4"
            style={{ marginBottom: '0.2rem' }}
            name="information-circle-open-small"
            fill="var(--cp-color-app-secondary-text)"
          />
        </CpTooltip>
      )}
    </div>
  );
};

const QboCell = ({ isDisabled, resource }) => {
  const { third_party_number: invoiceNumber, third_party_url: url, third_party_error: error } = resource;

  if (error) {
    let errorText = (
      <div className="cp-color-app-error-text" style={{ opacity: isDisabled ? '0.6' : '1', width: 'fit-content' }}>
        {invoiceNumber ? invoiceNumber : 'Error'}
      </div>
    );

    if (url) {
      errorText = (
        <a
          className="cp-color-app-error-text"
          href={url}
          target="_blank"
          rel="noreferrer"
          style={{ width: 'fit-content' }}>
          {invoiceNumber ? invoiceNumber : 'Error'}
        </a>
      );
    }

    return (
      <CpTooltip
        position="bottom-start"
        text="The sync has failed. You may attempt to re-sync from the QBO column in invoices.">
        {errorText}
      </CpTooltip>
    );
  } else if (invoiceNumber) {
    return url ? (
      <a href={url} target="_blank" rel="noreferrer">
        {invoiceNumber}
      </a>
    ) : (
      <div>{invoiceNumber}</div>
    );
  } else if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        Access
      </a>
    );
  } else {
    return <div>—</div>;
  }
};

export const getInvoiceListSchema = edit => ({
  invoiceNumber: {
    fieldId: 'invoice_number',
    label: 'Invoice #',
    dataType: 'object',
    cell: {
      component: InvoiceNumCell,
      props: ({ resource, value }) => ({ resource, value }),
    },
  },
  qboSync: {
    fieldId: 'third_party_id',
    label: 'QBO Sync',
    dataType: 'object',
    cell: {
      component: QboCell,
      props: ({ resource, value }) => ({ resource, value }),
    },
  },
  dueDate: {
    fieldId: 'due_date',
    label: 'Due date',
    dataType: 'object',
    cell: {
      component: DueDateCell,
      props: ({ resource, value }) => ({ resource, value }),
    },
  },
  total: {
    fieldId: 'total',
    label: 'Total',
    dataType: 'string',
    cell: {
      component: CpTable.CurrencyCell,
    },
  },
  balance: {
    fieldId: 'balance',
    label: 'Balance',
    dataType: 'string',
    cell: {
      component: CpTable.CurrencyCell,
    },
  },
  amountPaid: {
    fieldId: 'showAmountToPay',
    label: `Amount ${!edit ? 'to be ' : ''}Paid`,
    dataType: 'object',
    cell: {
      component: AmountCell,
      props: ({ resource, value }) => ({ resource, value }),
    },
  },
});
