// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../report.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total-row-styles__totalRow--SZiBA {
  background-color: var(--cps-color-background);
  border-bottom: none;
}

.total-row-styles__rowItem--Tnh2i {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/reports/aged-receivables-summary/report/total-row/total-row.styles.css"],"names":[],"mappings":"AAAA;EAEE,6CAA6C;EAC7C,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".totalRow {\n  composes: row from '../report.styles.css';\n  background-color: var(--cps-color-background);\n  border-bottom: none;\n}\n\n.rowItem {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"totalRow": `total-row-styles__totalRow--SZiBA ${___CSS_LOADER_ICSS_IMPORT_0___.locals["row"]}`,
	"rowItem": `total-row-styles__rowItem--Tnh2i`
};
export default ___CSS_LOADER_EXPORT___;
