import React from 'react';
import { CpInput } from 'canopy-styleguide!sofe';

export const Description = ({ value, update, disabled }) => (
  <div className="cp-ml-8" style={{ width: '100%' }}>
    <label>
      Description<span className="cps-color-primary">*</span>
    </label>
    <CpInput
      value={value}
      onChange={description => update('description', description)}
      disabled={disabled}
      placeholder="Add description (limit 65 characters)"
      maxLength="65"
    />
  </div>
);
