import { fetchAsObservable } from 'fetcher!sofe';
import { pluck } from 'rxjs/operators';
import { formatExpenseFilters } from 'src/expenses/expense.helper';

export function getAllExpenses(filters, sortData, page, limit, archived, billed, clientId) {
  const sortList = Object.values(sortData).map(val => ({ field: val.fieldId, direction: val.direction }));
  const filterData = {
    archived,
    billed,
    ...(clientId && { client_ids: { is_any_of: [clientId] } }),
    ...formatExpenseFilters(filters),
  };

  return fetchAsObservable(`/api/expenses:search`, {
    method: 'POST',
    body: {
      limit,
      page,
      sort_data: sortList,
      filters: filterData,
    },
  });
}

export function getExpenseSearchOptions(search, filters, sortData, archived, billed, clientId, type) {
  const sortList = Object.values(sortData).map(val => ({ field: val.fieldId, direction: val.direction }));
  const filterData = {
    archived,
    billed,
    ...(clientId && { client_ids: { is_any_of: [clientId] } }),
    ...formatExpenseFilters(filters),
  };

  return fetchAsObservable(`/api/expenses:search_filter/${type}`, {
    method: 'POST',
    body: {
      search,
      sort_data: sortList,
      filters: filterData,
    },
  });
}

export function exportExpenseCSV(filters, sortData, archived, billed, clientId, selectionData) {
  const sortList = Object.values(sortData).map(val => ({ field: val.fieldId, direction: val.direction }));
  const filterData = {
    archived,
    billed,
    ...(clientId && { client_ids: { is_any_of: [clientId] } }),
    ...formatExpenseFilters(filters),
  };

  return fetchAsObservable(`/api/expenses/csv`, {
    method: 'POST',
    body: {
      sort_data: sortList,
      filters: filterData,
      ...selectionData,
    },
  });
}

export function getNextExpenseNumber() {
  return fetchAsObservable(`/api/expenses/expense_number`).pipe(pluck('expense_number'));
}

export function createExpense(expense) {
  return fetchAsObservable(`/api/expenses`, {
    method: 'POST',
    body: expense,
  });
}

export function getExpense(expenseId) {
  return fetchAsObservable(`/api/expenses/${expenseId}`);
}

export function saveExpense(expense) {
  return fetchAsObservable(`/api/expenses/${expense.id}`, {
    method: 'PUT',
    body: expense,
  });
}

export function bulkUpdateExpenses(action, filters, archived, billed, clientId, selectionData) {
  const filterData = {
    archived,
    billed,
    ...(clientId && { client_ids: { is_any_of: [clientId] } }),
    ...formatExpenseFilters(filters),
  };

  return fetchAsObservable('/api/expenses/bulk', {
    method: 'PATCH',
    body: {
      action,
      ...selectionData,
      filters: filterData,
    },
  });
}
