import React from 'react'
// project
import styles from './total-row.styles.css'
import rStyles from '../report.styles.css'
import SchemaItems from 'src/reports/common/schema-items/schema-items.component.js'

export default class SummaryTotal extends React.Component {

  render () {
    const { bucketValues, schema } = this.props
    return (
      <div className={`${styles.totalRow}`}>
        <div className={`${styles.rowItem} ${rStyles.wideReportItem}`}>
          Grand Total
        </div>
        <SchemaItems
          schema={schema}
          childClass={`${rStyles.reportItem} ${styles.rowItem}`}
          items={bucketValues}
        />
      </div>
    )
  }
}
