import React from 'react';
import GenericTimeReport from './generic-time-report.component.js';

export default function HoursByServiceItemReport() {
  return (
    <GenericTimeReport
      title={'Hours Tracked by Service Item'}
      reportUrl={'service_item_hours'}
      isCurrency={false}
      leftColumnTitle={`Service Item`}
    />
  );
}
