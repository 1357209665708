import React, { useContext } from 'react';
import { WIPReportGroup } from 'src/time-entries/wip/wip-report-group.component';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { modes } from 'src/invoices/invoices.helper';
import { differenceBy } from 'lodash';

export const SelectTime = () => {
  const { invoice, actions } = useContext(InvoiceContext);
  const container = document.getElementsByClassName('cp-modal')[0];
  let preSelectedEntries = [];
  invoice.lineItems?.fromTime?.forEach(item => {
    item?.subLineItems?.forEach(subLineItem => {
      if (subLineItem.timeEntryId) {
        preSelectedEntries = preSelectedEntries.concat({
          id: subLineItem.timeEntryId,
        });
      } else {
        subLineItem?.relationships?.sources.forEach(source => {
          preSelectedEntries = preSelectedEntries.concat(source);
        });
      }
    });
    item?.relationships?.sources?.forEach(source => {
      preSelectedEntries = preSelectedEntries.concat(source);
    });
  });
  invoice.lineItems?.hidden?.forEach(item => {
    item?.relationships?.sources?.forEach(source => {
      preSelectedEntries = preSelectedEntries.concat(source);
    });
  });
  return (
    <WIPReportGroup
      client={invoice.client}
      clientGroupId={invoice.clientGroup?.is_active ? invoice.clientGroup?.id : null}
      containerHeight={container?.clientHeight}
      containerWidth={container?.clientWidth}
      fromSelectTime
      onInvoiceEntries={timeEntries => {
        actions.modeChange(invoice.id && !invoice.draft ? modes.edit : modes.create);
        actions.addTimeEntry(differenceBy(timeEntries, preSelectedEntries, 'id'));
      }}
      preSelectedEntries={preSelectedEntries}
    />
  );
};
