import React from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { invoiceTerms } from 'src/invoices/invoices.helper';

export const RecurringTerms = ({ value, engagement, update, isUponCompletion, disabled }) => (
  <div className={isUponCompletion ? '' : engagement ? 'cp-ml-16' : 'cp-ml-8'}>
    <label>
      Terms
      {!engagement && <span className="cps-color-primary">*</span>}
    </label>
    <div style={{ width: engagement ? '147px' : '200px' }}>
      <CpSelectSingle
        triggerIsBlock
        contentWidth="sm"
        fixedContent
        disabled={disabled}
        data={invoiceTerms}
        value={value}
        onChange={recurringTerms => update('recurringTerms', recurringTerms)}
      />
    </div>
  </div>
);
