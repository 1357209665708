import { fetchAsObservable } from 'fetcher!sofe';

export function getTimeReport(startDate, endDate, interval, reportUrl, ownerId) {
  if (!startDate || !endDate) {
    throw new Error('need to have dates!');
  }

  return fetchAsObservable(
    `/api/billing/reports/${reportUrl}?start_date=${startDate}&end_date=${endDate}&${
      interval ? 'interval=' + interval : ''
    }${!!ownerId ? `&client_owner_id=${ownerId}` : ''}`
  );
}

export function exportReportCSV(startDate, endDate, interval, reportUrl, ownerId) {
  return fetchAsObservable(
    `/api/billing/reports/${reportUrl}/csv?start_date=${startDate}&end_date=${endDate}&${
      interval ? 'interval=' + interval : ''
    }${!!ownerId ? `&client_owner_id=${ownerId}` : ''}`
  );
}
