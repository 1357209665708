import React from 'react';

import { DaysInMonth, DaysInWeek, Frequency, MonthlyOptions, MonthsInYear, RepeatInterval } from './form-components';

const RecurringInvoiceTiming = ({ isEngagement, recurrence, setRecurrence, disabled }) => {
  const { dayInMonth, dayInWeek, frequencyOption, monthInYear, monthlyOption, repeatInterval, lockInterval } =
    recurrence;

  const handleUpdateRecurrence = (field, value) => {
    setRecurrence(prevRecurrence => ({ ...prevRecurrence, [field]: value }));
  };

  const update = isEngagement ? handleUpdateRecurrence : setRecurrence;

  return (
    <div className="cp-flex">
      <Frequency engagement={isEngagement} value={frequencyOption} update={update} disabled={disabled} />
      {frequencyOption === 'Monthly' && (
        <MonthlyOptions engagement={isEngagement} value={monthlyOption} update={update} disabled={disabled} />
      )}
      {(frequencyOption === 'Daily' || frequencyOption === 'Weekly') && (
        <RepeatInterval
          engagement={isEngagement}
          value={repeatInterval}
          update={update}
          frequencyOption={frequencyOption}
          disabled={disabled}
        />
      )}
      {frequencyOption === 'Yearly' && (
        <MonthsInYear engagement={isEngagement} value={monthInYear} update={update} disabled={disabled} />
      )}
      {((frequencyOption === 'Monthly' && monthlyOption === 'day') || frequencyOption === 'Yearly') && (
        <DaysInMonth engagement={isEngagement} value={dayInMonth} update={update} disabled={disabled} />
      )}
      {(frequencyOption === 'Weekly' || (frequencyOption === 'Monthly' && monthlyOption !== 'day')) && (
        <DaysInWeek engagement={isEngagement} value={dayInWeek} update={update} disabled={disabled} />
      )}
      {frequencyOption === 'Monthly' && (
        <RepeatInterval
          engagement={isEngagement}
          value={repeatInterval}
          update={update}
          frequencyOption={frequencyOption}
          disabled={disabled || lockInterval}
        />
      )}
    </div>
  );
};

export default RecurringInvoiceTiming;
