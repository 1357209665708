import { pluck } from 'rxjs/operators';
import { fetchAsObservable } from 'fetcher!sofe';

export function getStaffProductivityReport(after, before, clientIds, userIds, groupBy, page = 1, limit = 50) {
  return fetchAsObservable(`/api/billing/reports/staff-profitability?page=${page}&limit=${limit}`, {
    method: 'POST',
    body: {
      filters: {
        start_date: after,
        end_date: before,
        client_ids: clientIds.includes('null') ? [null, ...clientIds].filter(id => id !== 'null') : clientIds,
        user_ids: userIds,
        group_by: groupBy,
      },
    },
  });
}

export function getStaffProductivityReportCSV(after, before, clientIds, userIds) {
  return fetchAsObservable('/api/billing/reports/staff-profitability/csv', {
    method: 'POST',
    body: {
      filters: {
        start_date: after,
        end_date: before,
        client_ids: clientIds.includes('null') ? [null, ...clientIds].filter(id => id !== 'null') : clientIds,
        user_ids: userIds,
      },
    },
  });
}

export function getStaffProductivityClientList(after, before, userIds, searchString, page = 1, limit = 50) {
  return fetchAsObservable(
    `/api/billing/reports/staff-profitability/search-filter/clients?page=${page}&limit=${limit}`,
    {
      method: 'POST',
      body: {
        filters: {
          start_date: after,
          end_date: before,
          client_ids: [],
          user_ids: userIds,
          search_string: searchString,
        },
      },
    }
  ).pipe(pluck('clients'));
}

export function getStaffProductivityTeamMemberList(after, before, clientIds, searchString, page = 1, limit = 50) {
  return fetchAsObservable(
    `/api/billing/reports/staff-profitability/search-filter/team-members?page=${page}&limit=${limit}`,
    {
      method: 'POST',
      body: {
        filters: {
          start_date: after,
          end_date: before,
          client_ids: clientIds.includes('null') ? [null, ...clientIds].filter(id => id !== 'null') : clientIds,
          user_ids: [],
          search_string: searchString,
        },
      },
    }
  ).pipe(pluck('team_members'));
}
