import React, { useMemo } from 'react';
import { map } from 'lodash';
import { getStaffProductivityColumns } from './productivity-columns';

export const StaffProductivityTaskRow = ({ task }) => {
  const columns = useMemo(() => getStaffProductivityColumns(), []);

  const renderTaskCell = (task, column) => {
    return (
      <td key={column.key} style={{ maxWidth: '380px', background: 'white', fontWeight: '500' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="cps-ellipsis" style={{ marginLeft: '46px' }}>
            {task.row_id && task.row_name && !task.archived ? (
              <a
                href={`/#/task/${task.row_id}${task.client_id ? `/client/${task.client_id}` : ''}`}
                target="_blank"
                rel="noreferrer">
                {task.row_name}
              </a>
            ) : (
              task.row_name || '-No Task-'
            )}
          </span>
        </div>
      </td>
    );
  };

  const renderDataCell = (row, column, fontWeight, renderBorder = true) => {
    return (
      <td
        key={column.key}
        className={`cps-ellipsis ${column.key === 'client_name' ? '' : 'cp-text-right'}`}
        style={{
          ...{ maxWidth: '150px', background: 'white', fontWeight },
          ...(renderBorder ? {} : { borderTop: 'none' }),
        }}>
        {column.renderCell(row[column.key], column.key === 'realization')}
      </td>
    );
  };

  return (
    <tr key={task.row_id} style={{ background: 'white' }}>
      {map(columns, column =>
        column.key === 'row_name' ? renderTaskCell(task, column) : renderDataCell(task, column, '500')
      )}
    </tr>
  );
};
