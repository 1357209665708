// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary-nav-styles__title--QTqgC a {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
}

.secondary-nav-styles__subTabs--J0vhI {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.secondary-nav-styles__subTabs--J0vhI ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.secondary-nav-styles__subTabs--J0vhI li {
  height: 30px;
}

.secondary-nav-styles__subTabs--J0vhI .secondary-nav-styles__active--te2au {
  border-bottom: 3px solid var(--cps-color-primary);
  font-weight: bold;
}

.secondary-nav-styles__subTabs--J0vhI a {
  padding: 12px;
  color: var(--cps-color-cool-gray);
}

.secondary-nav-styles__subTabs--J0vhI a:hover,
.secondary-nav-styles__subTabs--J0vhI a:focus {
  -webkit-text-decoration: none;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/navigation/secondary-nav/secondary-nav.styles.css"],"names":[],"mappings":"AAAA;EACE,6BAAqB;EAArB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iDAAiD;EACjD,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;;EAEE,6BAAqB;EAArB,qBAAqB;AACvB","sourcesContent":[".title a {\n  text-decoration: none;\n  color: inherit;\n}\n\n.subTabs {\n  height: 100%;\n  display: flex;\n  flex-direction: column-reverse;\n  align-items: center;\n}\n\n.subTabs ul {\n  list-style: none;\n  padding: 0;\n  display: flex;\n}\n\n.subTabs li {\n  height: 30px;\n}\n\n.subTabs .active {\n  border-bottom: 3px solid var(--cps-color-primary);\n  font-weight: bold;\n}\n\n.subTabs a {\n  padding: 12px;\n  color: var(--cps-color-cool-gray);\n}\n\n.subTabs a:hover,\n.subTabs a:focus {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `secondary-nav-styles__title--QTqgC`,
	"subTabs": `secondary-nav-styles__subTabs--J0vhI`,
	"active": `secondary-nav-styles__active--te2au`
};
export default ___CSS_LOADER_EXPORT___;
