import React, { useEffect } from 'react';
import { find, findKey, get } from 'lodash';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';
import { CpIcon } from 'canopy-styleguide!sofe';
import { cardIconNames, convertToCurrencyText, paymentMethods, paysafeCardTypes } from 'src/payments/payments.helper';
import { getFrequencySentence } from './select-payment-frequency.component';

import styles from './add-payment.styles.css';

export const PaymentConfirmation = props => {
  const [user] = useWithUserAndTenant();
  const isClient = user?.role === 'Client';

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('billing-ui::payment-complete'));
  }, []);

  const { value: paymentMethod, type: paymentType } = find(paymentMethods, ['key', props.paymentMethod.split(' ')[0]]);

  return (
    <div>
      <div className={`cps-body cps-color-monsoon ${styles.verifyPayment}`}>
        {!props.isFuturePayment && !props.recurrence && !props.isEdit && (
          <div className="cps-row cps-wt-bold">
            <div className="cps-col-xs-4">Payment number:</div>
            <div className="cps-col-xs-7">{props.paymentNumber}</div>
          </div>
        )}
        {!isClient && (
          <div className="cps-row">
            <div className="cps-col-xs-4">Client:</div>
            <div className="cps-col-xs-7 cps-color-cool-gray">{props.clientName}</div>
          </div>
        )}
        {!props.recurrence && (
          <>
            <div className="cps-row cps-wt-bold">
              <div className="cps-col-xs-4">Payment date:</div>
              <div className="cps-col-xs-7 cps-color-monsoon">{props.paymentDate}</div>
            </div>
            {props.paidInvoices?.length > 0 && (
              <div className="cps-row">
                <div className="cps-col-xs-4">Invoice number(s) paid:</div>
                <div className="cps-col-xs-7 cps-color-cool-gray">{props.paidInvoices}</div>
              </div>
            )}
            {props.paymentCreditNumber && (
              <div className="cps-row">
                <div className="cps-col-xs-4">Credit number created:</div>
                <div className="cps-col-xs-7 cps-color-cool-gray">{props.paymentCreditNumber}</div>
              </div>
            )}
          </>
        )}
        {props.recurrence && (
          <>
            <div className="cps-row">
              <div className="cps-col-xs-4">Recurring payment:</div>
              <div className="cps-col-xs-7 cps-color-monsoon">{convertToCurrencyText(props.recurrence.total)}</div>
            </div>
            <div className="cps-row">
              <div className="cps-col-xs-4">Recurring series:</div>
              <div className="cps-col-xs-7 cps-color-cool-gray">{props.recurrence.description}</div>
            </div>
          </>
        )}
        <div className="cps-row">
          <div className="cps-col-xs-4">Payment method:</div>
          <div className="cps-col-xs-7 cps-color-cool-gray">
            {!paymentType.includes('manual') && (
              <CpIcon
                name={
                  paymentMethod === 'Credit Card'
                    ? get(
                        cardIconNames,
                        findKey(paysafeCardTypes, type => type === props.cardType),
                        'billing-credit-card'
                      )
                    : 'billing-check'
                }
                className={styles.cardTypeIcon}
              />
            )}
            {props.paymentNickname || (isClient && paymentMethod === 'ACH' ? 'Bank Account' : paymentMethod)}
          </div>
        </div>
        <div className="cps-row">
          <div className="cps-col-xs-4">Payment amount:</div>
          <div className="cps-col-xs-7 cps-color-cool-gray">${props.paymentTotal}</div>
        </div>
        {['cash', 'check', 'other'].includes(props.paymentMethod) && props.refNumber && (
          <div className="cps-row">
            <div className="cps-col-xs-4">{props.paymentMethod === 'check' ? 'Check' : 'Reference'} number:</div>
            <div className="cps-col-xs-7 cps-color-cool-gray">{props.refNumber}</div>
          </div>
        )}
        {!props.recurrence && (
          <div className="cps-row cp-wt-bold">
            <div className="cps-col-xs-4">Total:</div>
            <div className="cps-col-xs-7 cps-color-monsoon">${props.paymentTotal}</div>
          </div>
        )}
        {props.recurrence && (
          <div className="cps-row cp-pt-8" style={{ lineHeight: '24px' }}>
            <div className="cps-col-xs-4">Recurring frequency:</div>
            <div className="cps-col-xs-7 cps-color-monsoon">
              {getFrequencySentence(null, props.recurrence, props.recurringPaymentDate)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
