import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { CpSelectSingle, CpInlineNotification, CpButton, CpModal } from 'canopy-styleguide!sofe';

import { formatNestedQboData } from 'src/common/integrations/qbo.helpers';

import styles from './invoice-builder.styles.css';

const IntegrationSyncErrorBanner = lazy(() =>
  SystemJS.import('integrations-ui!sofe').then(module => module.getIntegrationSyncErrorBanner())
);

export const QboSyncOptions = ({
  qboIntegration,
  qboClasses = [],
  qboLocations = [],
  update,
  invoice,
  clientSyncedToQbo,
}) => {
  const [selectedQboClass, setSelectedQboClass] = useState('');
  const [classSearch, setClassSearch] = useState('');
  const [selectedQboLocation, setSelectedQboLocation] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [showUnmatchedClientModal, setShowUnmatchedClientModal] = useState(false);
  const formattedQboClassList = useMemo(() => formatNestedQboData(qboClasses), [qboClasses]);
  const formattedQboLocationList = useMemo(() => formatNestedQboData(qboLocations), [qboLocations]);

  useEffect(() => {
    const classId = invoice?.thirdPartyData?.qbo?.external_txn_class_id;
    setSelectedQboClass(formattedQboClassList.find(item => item.id === classId));
  }, [invoice.thirdPartyData?.qbo?.external_txn_class_id, formattedQboClassList]);

  useEffect(() => {
    const locationId = invoice?.thirdPartyData?.qbo?.external_location_id;
    setSelectedQboLocation(formattedQboLocationList.find(item => item.id === locationId));
  }, [invoice.thirdPartyData?.qbo?.external_location_id, formattedQboLocationList]);

  const handleClassChange = value => {
    update('thirdPartyData', {
      ...invoice.thirdPartyData,
      qbo: { ...invoice.thirdPartyData?.qbo, external_txn_class_id: value?.id },
    });
  };

  const handleLocationChange = value => {
    update('thirdPartyData', {
      ...invoice.thirdPartyData,
      qbo: { ...invoice.thirdPartyData?.qbo, external_location_id: value?.id },
    });
  };

  return (
    <div className={styles.qboSyncOptions}>
      {(qboIntegration.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxn ||
        qboIntegration.preferences?.AccountingInfoPrefs?.TrackDepartments) && (
        <div className="cp-flex-center cp-mr-32">
          <div className={styles.qboSyncTitle}>
            <img
              src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
              height="20rem"
              alt="QuickBooks Online Logo"
            />
            <strong className="cp-ml-8">QBO Sync</strong>
          </div>
          {qboIntegration.preferences?.AccountingInfoPrefs?.TrackDepartments && (
            <div className="cp-ml-32">
              <div>{qboIntegration.preferences.AccountingInfoPrefs.DepartmentTerminology || 'Location'}</div>
              <CpSelectSingle
                searchFilter
                searchOnChange={setLocationSearch}
                searchValue={locationSearch}
                data={formattedQboLocationList.filter(qboLocation => qboLocation.Active)}
                placeholder="- Select -"
                onChange={handleLocationChange}
                value={selectedQboLocation}
                clearable
                disabled={!clientSyncedToQbo}
              />
            </div>
          )}
          {qboIntegration.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxn && (
            <div className="cp-ml-32">
              <div>Class</div>
              <CpSelectSingle
                searchFilter
                searchOnChange={setClassSearch}
                searchValue={classSearch}
                data={formattedQboClassList.filter(qboClass => qboClass.Active)}
                placeholder="- Select -"
                onChange={handleClassChange}
                value={selectedQboClass}
                clearable
                disabled={!clientSyncedToQbo}
              />
            </div>
          )}
        </div>
      )}
      {!clientSyncedToQbo && !qboIntegration?.disconnect_error && (
        <div className={styles.disconnectedClient}>
          <CpInlineNotification
            message="The QBO selection fields are not available because this client is not linked to a client in QBO."
            cta={
              <CpButton btnType="secondary" onClick={() => setShowUnmatchedClientModal(true)}>
                Learn more
              </CpButton>
            }
            icon="alert-triangle-open-large"
          />
        </div>
      )}
      {qboIntegration?.disconnect_error && (
        <div className={styles.disconnectedClient}>
          <Suspense fallback={<></>}>
            <IntegrationSyncErrorBanner
              integrationInfo={qboIntegration}
              checkIntegrationAuth={qboIntegration.checkIntegrationAuth}
            />
          </Suspense>
        </div>
      )}
      <CpModal show={showUnmatchedClientModal} onClose={() => setShowUnmatchedClientModal(false)} width={568}>
        <CpModal.Header title="Unmatched Client" />
        <CpModal.Body>
          <div className="cp-flex">
            <div className={styles.unmatchedClientLeft}>
              <img
                height={73.6}
                width={76.8}
                src="https://cdn.canopytax.com/static/empty-states/es_clients_removed.svg"
              />
              <div className="cp-mt-16">
                <strong>Canopy client not found in QBO. </strong>
                <div>Please match the client in order to sync the invoice.</div>
              </div>
              <div className="cp-mt-32 cps-caption">
                <i>If problems continue to persist please contact support by calling 855-616-7305.</i>
              </div>
            </div>
            <div className={styles.unmatchedClientRight}>
              <div className="cps-wt-semibold cps-body">How to fix unmatched clients</div>
              <div>Choose one of the following:</div>
              <div className="cps-wt-semibold cps-body cp-mt-40">Option 1:</div>
              <div>Edit the client in Canopy</div>
              <div className="cps-wt-semibold cps-body cp-mt-40">Option 2:</div>
              <div>Delete the client in Canopy and create a new one.</div>
            </div>
          </div>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton onClick={() => setShowUnmatchedClientModal(false)}>Close</CpButton>
        </CpModal.Footer>
      </CpModal>
    </div>
  );
};
