import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { map, sortBy } from 'lodash';
import { CpButton, CpCard, CpDropdown, CpIcon, CpLoader, CpSelectMulti } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import ReportWrapper from '../common/report-wrapper/report-wrapper.component';
import { DateRangeFilter } from 'src/common/components/filters/date-range-filter.component';
import { getUtilizationColumns } from './utilization-columns';
import { paymentDateRanges } from 'src/payments/payments.helper';
import { getReport, getReportCSV } from './utilization.resource';
import { getTeamMembers } from 'src/resources/clients.resources';

import styles from '../common/report.styles.css';

export const BillingUtilizationReport = () => {
  const [fetchReport, setFetchReport] = useState(true);
  const [exportReport, setExportReport] = useState(false);
  const [columns] = useState(getUtilizationColumns());
  const [reportData, setReportData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [assignees, setAssignees] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    filter_params: 'currentMonth',
    after: DateTime.local().startOf('month').toISODate(), //first day of the month
    before: DateTime.local().endOf('month').toISODate(), //last day of the month
  });
  const [sortColumn, setSortColumn] = useState({ order: 'asc', column: 'user_name' });

  useEffect(() => {
    const subscription = getTeamMembers().subscribe(users => {
      const team = sortBy(
        users.filter(user => user.role === 'TeamMember'),
        ['name']
      );
      setAssignees(team);
      setIsLoading(false);
    }, handleError);

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    let subscription;
    if (fetchReport) {
      const { after, before } = dateFilter;
      subscription = getReport(selectedAssignees, after, before, sortColumn).subscribe(response => {
        setReportData(response);
        setFetchReport(false);
      }, handleError);
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [fetchReport]);

  useEffect(() => {
    let subscription;
    if (exportReport) {
      const { after, before } = dateFilter;
      getReportCSV(selectedAssignees, after, before, sortColumn).subscribe(response => {
        setExportReport(false);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response).replace(/#/g, '%23');
        hiddenElement.target = '_blank';
        hiddenElement.download = `${after}-${before}-billing-utilization.csv`;
        hiddenElement.click();
      }, handleError);
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [exportReport]);

  const handleDateFilterChanged = dateFilter => {
    setDateFilter(dateFilter);
    setFetchReport(true);
  };

  const handleExportCSV = () => {
    setExportReport(true);
  };

  const renderColumnHeader = column => {
    return (
      <div
        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setSortColumn({
            column: column.key,
            order: sortColumn.column === column.key && sortColumn.order === 'asc' ? 'desc' : 'asc',
          });
          setFetchReport(true);
        }}>
        <span className="cp-pr-8">{column.label}</span>
        {sortColumn.column === column.key && (
          <CpIcon name={`caret-small-${sortColumn.order === 'asc' ? 'up' : 'down'}`} />
        )}
      </div>
    );
  };

  return (
    <ReportWrapper>
      <CpCard>
        <CpCard.Header>
          <div className="cps-subheader">Billing Utilization Report</div>
        </CpCard.Header>

        <CpCard.Header>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <CpSelectMulti
                data={assignees}
                loading={isLoading}
                placeholder="Team Member"
                clearable
                onChange={setSelectedAssignees}
                onClose={() => setFetchReport(true)}
                renderValue={value => (value.length === 1 ? value[0].name : `${value.length} Team Members`)}
                value={selectedAssignees}
              />
              <div className="cp-ml-16">
                <DateRangeFilter
                  dateFilter={dateFilter}
                  onFilterChanged={handleDateFilterChanged}
                  sortParam={'reports'}
                  dateRanges={paymentDateRanges}
                  hideClear={true}
                />
              </div>
            </div>
            <CpDropdown
              position="bottom-end"
              renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" onClick={toggle} aria-label="Menu" />}
              renderContent={() => (
                <div className="cp-select-list">
                  <button onClick={handleExportCSV}>
                    <CpIcon className="cp-select-list__icon-left" name="af-line-square-up" />
                    Export to CSV
                  </button>
                </div>
              )}
            />
          </div>
        </CpCard.Header>
        {!columns || !reportData.rows ? (
          <CpLoader />
        ) : (
          <>
            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th className={`${styles.shadowRight} ${styles.stickyLeft}`}></th>
                    <th></th>
                    <th colSpan={2} className={styles.reportHeaderGroup}>
                      <div>Billable Hours</div>
                    </th>
                    <th colSpan={4} className={styles.reportHeaderGroup}>
                      <div>Adjustments</div>
                    </th>
                    <th colSpan={4} className={styles.reportHeaderGroup}>
                      <div>Utilization</div>
                    </th>
                  </tr>
                  <tr>
                    {map(columns, column => (
                      <th
                        key={column.key}
                        style={{ textAlign: 'left', minWidth: '100px' }}
                        className={column.key === 'user_name' ? `${styles.shadowRight} ${styles.stickyLeft}` : ''}>
                        {renderColumnHeader(column)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {reportData.rows.map(row => (
                    <tr key={row.user_id}>
                      {map(columns, column => (
                        <td
                          key={column.key}
                          style={{ textAlign: column.key === 'user_name' ? 'left' : 'right' }}
                          className={column.key === 'user_name' ? `${styles.shadowRight} ${styles.stickyLeft}` : ''}>
                          {column.renderCell(row[column.key])}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    {map(columns, column => (
                      <th
                        key={column.key}
                        style={{ textAlign: column.key === 'user_name' ? 'left' : 'right' }}
                        className={column.key === 'user_name' ? `${styles.shadowRight} ${styles.stickyLeft}` : ''}>
                        {column.renderCell(
                          column.key === 'user_name'
                            ? `${reportData.rows.length} Team Member${reportData.rows.length !== 1 ? 's' : ''}`
                            : reportData.totals[column.key]
                        )}
                      </th>
                    ))}
                  </tr>
                </tfoot>
              </table>
            </div>
          </>
        )}
      </CpCard>
    </ReportWrapper>
  );
};
