import React, { useMemo, useState, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { invoiceCalc } from 'src/invoices/invoices.helper';
import { convertToCurrencyText } from 'src/payments/payments.helper';
import { defaultDateFormat } from 'src/billing-helpers';
import {
  calculateInitialPaymentDate,
  calculateInitialInvoiceDate,
  calculateFinalInvoiceDate,
} from 'src/invoices/recurrences.helper';

const recurringPaymentDateOptions = [
  { name: 'Due Date', id: 'due date' },
  { name: 'Invoice Date', id: 'invoice date' },
];

export const getFrequencySentence = (invoice, recurrence, paymentDate, paymentDetails) => {
  if (!recurrence) return '';
  const { schedule } = recurrence;

  const frequency = {
    Daily: `${schedule.frequency_daily_interval <= 1 ? `day` : `${schedule.frequency_daily_interval} days`}`,
    Weekly: `${schedule.frequency_weekly_interval <= 1 ? `week` : `${schedule.frequency_weekly_interval} weeks`}`,
    Monthly: `${schedule.frequency_monthly_interval <= 1 ? `month` : `${schedule.frequency_monthly_interval} months`}`,
    Yearly: 'year',
  };

  const remaining = recurrence.remaining || (schedule.end_option === 'After' ? schedule.remaining_invoices : undefined);

  const paymentsStarted = moment().diff(moment(schedule.recurring_payment_first_payment), 'days') > 0;

  let startDate;
  if (schedule && schedule.recurring_payment_first_payment) {
    startDate = moment(schedule.recurring_payment_first_payment);
  } else if (recurrence && recurrence.next_occurrence) {
    startDate = moment(recurrence.next_occurrence);
  } else if (paymentDetails && paymentDetails.first_payment_occurrence) {
    startDate = moment(recurrence.paymentDetails.first_payment_occurrence);
  } else if (schedule.next_occurrence) {
    startDate = calculateInitialPaymentDate(schedule);
  } else {
    startDate = calculateInitialInvoiceDate(schedule);
  }

  let endDate = calculateFinalInvoiceDate({ ...schedule, remaining_invoices: remaining }, startDate);

  if (paymentDate === 'due date' && !paymentsStarted) {
    startDate = startDate.add(recurrence.terms, 'days');
    if (endDate) {
      endDate = endDate.add(recurrence.terms, 'days');
    }
  }

  const total = recurrence.total || invoiceCalc.getTotal(invoice.invoice_line_items);

  return (
    <span>
      A payment of <b>{convertToCurrencyText(total)}</b> will be processed{' '}
      <b>every {get(frequency, schedule.frequency)}</b> on the <b>{paymentDate}</b>
      {paymentsStarted ? '. Started on ' : ', starting on '}
      <b>{startDate.format(defaultDateFormat)}</b>.
    </span>
  );
};

export const SelectPaymentFrequency = ({
  disabled = false,
  invoice,
  recurrence,
  paymentDate,
  setPaymentDate,
  paymentDetails,
}) => {
  const [dateObj, setDateObj] = useState(recurringPaymentDateOptions.find(option => option.id === paymentDate));

  useEffect(() => {
    setDateObj(recurringPaymentDateOptions.find(option => option.id === paymentDate));
  }, [paymentDate]);

  const processingSentence = useMemo(
    () => getFrequencySentence(invoice, recurrence, paymentDate, paymentDetails),
    [invoice, recurrence, paymentDate]
  );

  return (
    <div className={`cps-well__light cp-p-16 cp-mb-24`}>
      <div className="cps-subheader-sm">Select payment date</div>
      <div>Invoices will be paid in full on each payment date.</div>
      <div className="cp-mt-24">Pay on</div>
      <div className="cp-pb-8" style={{ display: 'flex' }}>
        <CpSelectSingle
          data={recurringPaymentDateOptions}
          disabled={disabled}
          value={dateObj}
          onChange={value => {
            setDateObj(value);
            setPaymentDate(value.id);
          }}
          className="cp-mt-8 cp-mr-32"
          style={{ width: '137px' }}
          triggerIsBlock
          contentWidth="block"
        />
        <div>{processingSentence}</div>
      </div>
    </div>
  );
};
