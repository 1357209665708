// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-styles__filterMenu--wVg3h {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 175px;
  max-width: 300px;
}

.filter-styles__filterMenuLong--nhQND {
  max-height: initial;
}

.filter-styles__spreadContents--yPxg3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/column_header/custom-filter/filter.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".filterMenu {\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 100%;\n  max-height: 175px;\n  max-width: 300px;\n}\n\n.filterMenuLong {\n  max-height: initial;\n}\n\n.spreadContents {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMenu": `filter-styles__filterMenu--wVg3h`,
	"filterMenuLong": `filter-styles__filterMenuLong--nhQND`,
	"spreadContents": `filter-styles__spreadContents--yPxg3`
};
export default ___CSS_LOADER_EXPORT___;
