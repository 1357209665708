import React, { useContext } from 'react';
import { CpButton } from 'canopy-styleguide!sofe';
import { LineItem } from './line-item.component';
import { getColumns } from './credit-builder.helper';
import { toCurrency } from 'src/invoices/invoices.helper';
import { useServiceItemsIntegrations } from 'src/common/custom-hooks';
import { IntegrationsContext } from 'src/common/integrations/integrations-context';

import styles from './credit-builder.styles.css';

export const LineItems = ({ credit, addLineItem, updateLineItem, removeLineItem, lineItemTotals }) => {
  const integrationContext = useContext(IntegrationsContext);
  const itemsIntegrationHook = useServiceItemsIntegrations(!!integrationContext?.itemsIntegration?.allServiceItems);
  const { categorizedServices, allServiceItems, qboCategories } = !!integrationContext?.itemsIntegration
    ?.allServiceItems
    ? integrationContext.itemsIntegration
    : itemsIntegrationHook;
  return (
    <div style={{ minHeight: '256px' }}>
      <div className={styles.creditBuilderLineItems}>
        <table>
          <thead>
            <tr>
              {getColumns().map(column => (
                <th key={column.key}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {credit.lineItems.map((lineItem, index) => {
              return (
                <LineItem
                  key={lineItem.id}
                  disabled={credit.payment_id}
                  lineItem={lineItem}
                  index={index}
                  updateLineItem={updateLineItem}
                  removeLineItem={removeLineItem}
                  categorizedServices={categorizedServices}
                  allServiceItems={allServiceItems}
                  qboCategories={qboCategories}
                />
              );
            })}

            <tr>
              <td>
                {!credit.payment_id && (
                  <div>
                    <CpButton className="cp-ml-16" btnType="flat" onClick={addLineItem}>
                      + Add line item
                    </CpButton>
                  </div>
                )}
              </td>
              <td colSpan="3"></td>
              <td className={styles.totalCell}>Sub Total</td>
              <td className={styles.totalCell}>
                <div className={styles.subTotalContainer}>{toCurrency(lineItemTotals.sumSubTotal)}</div>
              </td>
            </tr>
            <tr>
              <td colSpan="4"></td>
              <td className={styles.totalCell}>Taxes</td>
              <td className={styles.totalCell}>
                <div className={styles.subTotalContainer}>{toCurrency(lineItemTotals.sumTaxes)}</div>
              </td>
            </tr>
            <tr>
              <td colSpan="4"></td>
              <td className={styles.totalCell}>Total</td>
              <td className={styles.totalCell}>
                <div className={styles.subTotalContainer}>{toCurrency(lineItemTotals.sumTotal)}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
