// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-table-styles__invoiceTable--de_EL thead tr th:first-child {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
  background: white;
}

.invoice-table-styles__invoiceTable--de_EL thead tr th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 48px;
  z-index: 4;
  background: white;
  border-right: 1px solid var(--cp-color-app-border);
}

.invoice-table-styles__invoiceTable--de_EL tbody tr td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
}

.invoice-table-styles__invoiceTable--de_EL tbody tr td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 48px;
  z-index: 1;
  background: white;
  border-right: 1px solid var(--cp-color-app-border);
}

.invoice-table-styles__invoiceTable--de_EL tbody tr:last-child td {
  border-bottom: 1px solid var(--cp-color-app-border);
}

.invoice-table-styles__invoiceCell--zhvGN span {
  color: var(--cps-color-cool-gray);
}
`, "",{"version":3,"sources":["webpack://./src/invoices/components/invoice-table.styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAgB;EAAhB,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,wBAAgB;EAAhB,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,UAAU;EACV,iBAAiB;EACjB,kDAAkD;AACpD;;AAEA;EACE,wBAAgB;EAAhB,gBAAgB;EAChB,OAAO;EACP,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,wBAAgB;EAAhB,gBAAgB;EAChB,UAAU;EACV,UAAU;EACV,iBAAiB;EACjB,kDAAkD;AACpD;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".invoiceTable thead tr th:first-child {\n  position: sticky;\n  top: 0;\n  left: 0;\n  z-index: 4;\n  background: white;\n}\n\n.invoiceTable thead tr th:nth-child(2) {\n  position: sticky;\n  top: 0;\n  left: 48px;\n  z-index: 4;\n  background: white;\n  border-right: 1px solid var(--cp-color-app-border);\n}\n\n.invoiceTable tbody tr td:first-child {\n  position: sticky;\n  left: 0;\n  z-index: 1;\n  background: white;\n}\n\n.invoiceTable tbody tr td:nth-child(2) {\n  position: sticky;\n  left: 48px;\n  z-index: 1;\n  background: white;\n  border-right: 1px solid var(--cp-color-app-border);\n}\n\n.invoiceTable tbody tr:last-child td {\n  border-bottom: 1px solid var(--cp-color-app-border);\n}\n\n.invoiceCell span {\n  color: var(--cps-color-cool-gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoiceTable": `invoice-table-styles__invoiceTable--de_EL`,
	"invoiceCell": `invoice-table-styles__invoiceCell--zhvGN`
};
export default ___CSS_LOADER_EXPORT___;
