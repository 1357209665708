import React, { useEffect, useState } from 'react';
import { get, findKey } from 'lodash';
import { CpIcon, CpTooltip, CpSelectSingle } from 'canopy-styleguide!sofe';
import { wholeNumberFilter } from 'src/billing-helpers';
import { cardIconNames, paysafeCardTypes, usePaysafe } from '../../payments.helper.js';
import { states } from '../on-boarding/on-boarding.helpers';
import { PractitionerAuthorization } from '../../../payment-settings/practitioner-authorization.component';

export const PaymentFormPaysafe = ({
  ccInfo = {},
  ccInfoChanged,
  invalidFields = [],
  setInvalidFields,
  clearValidationError,
  validateCCData,
  onPaysafeInstance,
  onPaysafeUpdate,
  validCards,
  isFuturePayment,
}) => {
  const [addressState, setAddressState] = useState(states.find(state => state.key === ccInfo.state));

  const [cardNumberInvalid, expiryMonthInvalid, expiryYearInvalid, , cvvInvalid, cardType] = usePaysafe(
    onPaysafeInstance,
    'cp',
    onPaysafeUpdate
  );

  useEffect(() => {
    ccInfoChanged({ ...ccInfo, cardType: cardType, validCardType: cardType ? validCards.includes(cardType) : true });
  }, [cardType]);

  useEffect(() => {
    if (isFuturePayment) {
      handleSaveCardChanged({ target: { checked: true }, persist: () => {} });
    }
  }, [isFuturePayment]);

  const handleNameChanged = e => {
    e.persist();
    const name = e.target.value.trimLeft();
    ccInfoChanged({ ...ccInfo, name });
  };

  const handleZipChanged = e => {
    e.persist();
    const zip = wholeNumberFilter(e.target.value, true);
    ccInfoChanged({ ...ccInfo, zip });
  };

  const handleSaveCardChanged = e => {
    e.persist();
    const saveCard = e.target.checked;
    ccInfoChanged({ ...ccInfo, saveCard });
  };

  const cvvToolipTemplate = (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '8px' }}>
          <img src="https://cdn.canopytax.com/static/billing-ui/payments/billing-cvv.svg" />
        </div>
        <div style={{ textAlign: 'left', padding: '8px' }}>
          The 3-digit number for Visa, Mastercard, or Discover is located on the back of the card next to the signature
          panel.
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '8px' }}>
          <img src="https://cdn.canopytax.com/static/billing-ui/payments/billing-cvv-amex.svg" />
        </div>
        <div style={{ textAlign: 'left', padding: '8px' }}>
          The 4-digit number American Express is located on the front of the card above the card number.
        </div>
      </div>
    </div>
  );

  return (
    <form name="form" className="cp-mb-24">
      {ccInfo.isExpired && (
        <div className="cp-mt-12 cps-wt-bold">
          This credit card is expired. Please enter your new credit card information or select another payment method.
        </div>
      )}
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={cardNumberInvalid || !ccInfo.validCardType ? 'cps-has-error' : ''}>
          <label className="cps-body-sm cp-mt-8">
            Card number <span className="cps-input-required" />
          </label>
          <div id="cp-card-number" style={{ position: 'relative', width: '176px' }} className="cps-form-control" />
          <span className="cps-error-block" style={{ position: 'absolute' }}>
            {!ccInfo.validCardType ? 'Card type is not supported' : 'Invalid credit card number.'}
          </span>
        </div>
        <CpIcon
          name={get(
            cardIconNames,
            findKey(paysafeCardTypes, type => type === cardType),
            'billing-credit-card'
          )}
          fill="var(--cps-color-monsoon)"
          className="cp-ml-4 cp-mt-16"
        />
        <div className={`cp-ml-16 ${expiryMonthInvalid || expiryYearInvalid ? 'cps-has-error' : ''}`}>
          <label>
            Expiration date <span className="cps-input-required" />
          </label>
          <div style={{ display: 'flex' }}>
            <div id="cp-expiration-date-month" style={{ width: '50px' }} className="cps-form-control" />
            <div id="cp-expiration-date-year" style={{ width: '50px' }} className="cps-form-control cp-ml-8" />
          </div>
          <span className="cps-error-block" style={{ position: 'static' }}>
            Invalid expiration date.
          </span>
        </div>
        <div className={`cp-ml-24 ${cvvInvalid ? 'cps-has-error' : ''}`} style={{ position: 'static' }}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            CVV <span className="cps-input-required" />
            <CpTooltip text={cvvToolipTemplate} position={'top'}>
              <CpIcon name="information-circle-open-small" fill="var(--cps-color-monsoon)" />
            </CpTooltip>
          </label>
          <div id="cp-cvv" style={{ width: '62px' }} className="cps-form-control" />
          <span className="cps-error-block" style={{ position: 'static' }}>
            Invalid CVV.
          </span>
        </div>
      </div>

      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={invalidFields.includes('name') ? 'cps-has-error' : ''}>
          <label>
            Name on card <span className="cps-input-required" />
          </label>
          <input
            id="name"
            className="cps-form-control"
            style={{ width: '437px', fontFamily: 'sans-serif' }}
            maxLength="30"
            type="text"
            value={ccInfo.name}
            onFocus={clearValidationError}
            onChange={handleNameChanged}
            onBlur={validateCCData}
          />
          <span className="cps-error-block">Name is required</span>
        </div>
      </div>
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={invalidFields.includes('street') ? 'cps-has-error' : ''}>
          <label className="cps-body-sm cp-mt-8">
            Street <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="street"
              required
              className="cps-form-control"
              style={{ width: '250px' }}
              maxLength={'50'}
              type="text"
              value={ccInfo.street}
              onFocus={e => {
                clearValidationError(e);
              }}
              onChange={e => ccInfoChanged({ ...ccInfo, street: e.target.value })}
              onBlur={() => {
                if (!ccInfo.street) {
                  setInvalidFields([...invalidFields, 'street']);
                }
              }}
            />
            <span className="cps-error-block">Street is required</span>
          </div>
        </div>
        <div className="cp-ml-24">
          <label className="cps-body-sm cp-mt-8">Apt./Suite</label>
          <div style={{ position: 'relative' }}>
            <input
              id="street2"
              className="cps-form-control"
              style={{ width: '250px' }}
              maxLength={'30'}
              type="text"
              value={ccInfo.street2}
              onChange={e => ccInfoChanged({ ...ccInfo, street2: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={invalidFields.includes('city') ? 'cps-has-error' : ''}>
          <label className="cps-body-sm cp-mt-8">
            City <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="city"
              required
              className="cps-form-control"
              style={{ width: '250px' }}
              maxLength={'50'}
              type="text"
              value={ccInfo.city}
              onFocus={e => {
                clearValidationError(e);
              }}
              onChange={e => ccInfoChanged({ ...ccInfo, city: e.target.value })}
              onBlur={() => {
                if (!ccInfo.city) {
                  setInvalidFields([...invalidFields, 'city']);
                }
              }}
            />
            <span className="cps-error-block">City is required</span>
          </div>
        </div>
        <div className="cp-ml-24">
          <label className="cps-body-sm cp-mt-8">
            State <span className="cps-input-required" />
          </label>
          <CpSelectSingle
            data={states}
            transformData={item => ({
              id: item.key,
              name: item.value,
            })}
            value={addressState}
            onChange={value => {
              setAddressState(value);
              ccInfoChanged({ ...ccInfo, state: value.key });
            }}
            contentWidth="block"
            style={{ width: '140px' }}
            triggerIsBlock
          />
          <div style={{ position: 'relative' }}>
            <span className="cps-error-block">State is required</span>
          </div>
        </div>
        <div className={`cp-ml-24 ${invalidFields.includes('zip') ? 'cps-has-error' : ''}`}>
          <label>
            Billing zip code <span className="cps-input-required" />
          </label>
          <input
            id="zip"
            className="cps-form-control"
            style={{ width: '108px', fontFamily: 'sans-serif' }}
            maxLength="5"
            type="text"
            value={ccInfo.zip}
            onFocus={clearValidationError}
            onChange={handleZipChanged}
            onBlur={validateCCData}
          />
          <span className="cps-error-block" style={{ position: 'fixed' }}>
            {ccInfo.zip === '' ? 'Zip code is required' : 'Invalid zip code'}
          </span>
        </div>
      </div>
      {!ccInfo.isExpired && (
        <>
          <div style={{ display: 'flex', paddingTop: '36px' }}>
            <div>
              <label className="cps-checkbox">
                <input
                  type="checkbox"
                  disabled={isFuturePayment}
                  checked={ccInfo.saveCard}
                  onChange={handleSaveCardChanged}
                />
                <span className="cps-color-monsoon cps-body-sm">Save credit card information for future payments</span>
              </label>
            </div>
            {ccInfo.saveCard && (
              <div className="cp-ml-32">
                <label>
                  Account nickname <i className="cps-color-monsoon cps-caption">(optional)</i>
                </label>
                <input
                  id="ccNickname"
                  className="cps-form-control"
                  style={{ width: '250px' }}
                  maxLength="30"
                  type="text"
                  placeholder="30 characters max."
                  value={ccInfo.nickname}
                  onChange={e => {
                    ccInfoChanged({ ...ccInfo, nickname: e.target.value });
                  }}
                />
              </div>
            )}
          </div>
          {ccInfo.saveCard && (
            <>
              <div style={{ marginTop: '-12px' }}>
                <label className="cps-checkbox">
                  <input
                    type="checkbox"
                    checked={ccInfo.isPreferred}
                    onChange={e => ccInfoChanged({ ...ccInfo, isPreferred: e.target.checked })}
                  />
                  <span className="cps-color-monsoon cps-body-sm">Make default payment method</span>
                </label>
              </div>
              <div style={{ marginTop: '24px', marginLeft: '-24px' }}>
                <PractitionerAuthorization
                  teamCanUse={ccInfo.teamCanUse}
                  setTeamCanUse={value => ccInfoChanged({ ...ccInfo, teamCanUse: value })}
                  paymentType="credit card"
                />
              </div>
            </>
          )}
        </>
      )}
    </form>
  );
};
