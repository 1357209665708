import { differenceWith, reduce, isEqual} from 'lodash'

export const getCheckboxesChanged = (newCheckboxes, originalCheckboxes) => {
  return differenceWith(Array.from(newCheckboxes), Array.from(originalCheckboxes), isEqual)
}

export const getFiltersChanged = (newFilters, originalFilters) => {
  return reduce(newFilters, function(result, value, key){
    if (key === 'checkboxes') return result
    return isEqual(value, originalFilters[key]) ?
      result: result.concat(key);
  }, [])
}

export const filterSchemaByStatus = (statuses, schema) => {
  let new_schema = schema.slice()
  for (let i=5; i >= 0; i--) {
    if (!statuses[i]) {
      new_schema[i] = null
    }
  }
  return new_schema
}
