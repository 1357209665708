import React, { useState, useEffect, useMemo } from 'react';
import { map } from 'lodash';
import { CpButton, CpIcon, CpLoader } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getClientProfitabilityColumns } from './profitability-columns';
import { getClientProfitabilityReport } from './profitability.resource';
import { ProfitabilityTopRow } from './profitability-top-row.component';

export const ProfitabilityClientGroupRow = ({
  row,
  dateFilter,
  groupId,
  ownerId,
  sortColumn,
  changedClientGroupId,
  setChangedClientGroupId,
}) => {
  const pageLimit = 50;
  const columns = useMemo(() => getClientProfitabilityColumns(), []);
  const [rowData, setRowData] = useState();
  const [expanded, setExpanded] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setExpanded(false);
    setRowData();
  }, [dateFilter]);

  //for initial expansion
  useEffect(() => {
    if (expanded && !rowData) {
      const { after, before } = dateFilter;
      const subscription = getClientProfitabilityReport(
        {
          after,
          before,
          clientGroupId: groupId,
          groupBy: 'client',
          ownerId,
          sortColumn,
        },
        1,
        pageLimit
      ).subscribe(updateTable, handleError);

      return () => subscription.unsubscribe();
    }
  }, [expanded]);

  //for when the report gets updated from an external event such as billing of time entries from global +
  useEffect(() => {
    if (changedClientGroupId !== groupId) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      {
        after,
        before,
        clientGroupId: groupId,
        groupBy: 'client',
        ownerId,
        sortColumn,
      },
      1,
      pageLimit * currentPage
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [changedClientGroupId]);

  //for clicking load more button
  useEffect(() => {
    if (!loadMore) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      {
        after,
        before,
        clientGroupId: groupId,
        groupBy: 'client',
        ownerId,
        sortColumn,
      },
      currentPage + 1,
      pageLimit
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [loadMore]);

  const updateTable = response => {
    const pageInfo = response.meta.paginator;
    const updatedRowData = loadMore ? [...rowData, ...response.rows] : response.rows;
    setRowData(updatedRowData);
    setCurrentPage(changedClientGroupId ? currentPage : pageInfo.current_page);
    setHasMorePages(!!pageInfo.next_page);
    setLoadMore(false);
    setChangedClientGroupId();
  };

  return (
    <>
      <tr key={row.row_id}>
        {map(columns, column =>
          column.key === 'row_name' ? (
            <td
              key={column.key}
              className="cp-ellipsis cps-wt-bold"
              style={{ cursor: 'pointer', background: 'white' }}
              onClick={() => setExpanded(!expanded)}>
              <CpIcon name={`caret-small-${expanded ? 'up' : 'down'}`} />
              {row.row_name}
            </td>
          ) : (
            <td
              key={column.key}
              className={`${column.key === 'user' ? '' : 'cp-text-right'} cps-wt-bold`}
              style={{ background: 'white' }}>
              {column.renderCell(row[column.key])}
            </td>
          )
        )}
      </tr>
      {expanded &&
        (!rowData ? (
          <tr>
            <td colSpan={Object.keys(columns).length}>
              <CpLoader />
            </td>
          </tr>
        ) : (
          <>
            {rowData.map(row => (
              <ProfitabilityTopRow
                key={row.row_id}
                row={row}
                dateFilter={dateFilter}
                groupedBy="client"
                groupId={row.row_id}
                ownerId={ownerId}
                sortColumn={sortColumn}
                sortedByClientGroup
              />
            ))}
            {hasMorePages && (
              <tr>
                <td colSpan={Object.keys(columns).length} className="cp-text-center cp-p-8">
                  <CpButton btnType="primary" onClick={() => setLoadMore(true)} showLoader={loadMore}>
                    Load more
                  </CpButton>
                </td>
              </tr>
            )}
          </>
        ))}
    </>
  );
};
