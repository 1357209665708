import { DateTime } from 'luxon';

// these map from keys found here /src/reports/common/report-title/customize-option/customize-modal/resources/due-date.js
export const dueDateFunctionMap = {
  today: getToday,
  currentWeek: getCurrentWeek,
  lastWeek: getLastWeek,
  currentMonth: getCurrentMonth,
  lastMonth: getLastMonth,
  currentQuarter: getCurrentQuarter,
  lastQuarter: getLastQuarter,
  yearToDate: getYearToDate,
  lastYear: getLastYear,
};

function getToday() {
  return [[DateTime.utc().toISODate()]];
}

function getCurrentWeek() {
  return [[DateTime.local().set({ weekday: 0 }).toISODate(), DateTime.local().set({ weekday: 6 }).toISODate()]];
}

function getLastWeek() {
  return [[DateTime.local().set({ weekday: -7 }).toISODate(), DateTime.local().set({ weekday: -1 }).toISODate()]];
}

function getCurrentMonth() {
  return [[DateTime.local().startOf('month').toISODate(), DateTime.local().endOf('month').toISODate()]];
}

function getLastMonth() {
  return [
    [
      DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
      DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
    ],
  ];
}

function getCurrentQuarter() {
  return [[DateTime.local().startOf('quarter').toISODate(), DateTime.local().endOf('quarter').toISODate()]];
}

function getLastQuarter() {
  return [
    [
      DateTime.local().minus({ quarters: 1 }).startOf('quarter').toISODate(),
      DateTime.local().minus({ quarters: 1 }).endOf('quarter').toISODate(),
    ],
  ];
}

function getYearToDate() {
  return [[DateTime.local().startOf('year').toISODate(), DateTime.local().toISODate()]];
}

function getLastYear() {
  return [
    [
      DateTime.local().minus({ years: 1 }).startOf('year').toISODate(),
      DateTime.local().minus({ years: 1 }).endOf('year').toISODate(),
    ],
  ];
}

// src/reports/common/report-title/customize-option/customize-modal/resources/terms.js
export const termsMap = {
  receipt: 'Due on Receipt',
  net10: 'Net 10',
  net15: 'Net 15',
  net30: 'Net 30',
  net45: 'Net 45',
  net60: 'Net 60',
  custom: 'All custom terms',
};

// src/reports/common/report-title/customize-option/customize-modal/resources/status.js
export const statiMap = {
  partial: 'partial',
  viewed: 'viewed',
  sent: 'sent',
  saved: 'saved',
  printed: 'printed',
  downloaded: 'downloaded',
};
