import React from 'react';
import { CpModal, CpButton } from 'canopy-styleguide!sofe';

interface Props {
  showDeleteError: boolean;
  setShowDeleteError: (showDeleteError: boolean) => void;
  navToPayments: () => void;
}

export const DeleteErrorModal = ({ showDeleteError, setShowDeleteError, navToPayments }: Props) => {
  return (
    <CpModal show={showDeleteError} onClose={() => setShowDeleteError(false)}>
      <CpModal.Header title="Whoops! There's a Payment Attached" />
      <CpModal.Body>
        <div>
          <span>
            There are payments attached to this invoice. Delete the payment(s) first, then come back to delete this
            invoice.
          </span>
          <CpButton btnType="flat" className="cp-mt-16" onClick={navToPayments}>
            Take me to payments »
          </CpButton>
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            setShowDeleteError(false);
          }}>
          Okay, got it
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};
