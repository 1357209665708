import React, { useEffect, useRef, useState } from 'react';
import { CpEmptyState, CpWell, CpTable } from 'canopy-styleguide!sofe';
import { always } from 'kremling';

import { getInvoiceListSchema } from './select-invoices.helper';

import styles from './add-payment.styles.css';

export const SelectInvoices = ({
  invoices,
  onInvoicesChanged,
  loaded,
  edit,
  balanceTotal,
  syncPaymentsToIntegration,
  showQboCol,
  initialInvoiceId,
  paymentId,
  setInitialInvoiceId,
}) => {
  const [persistedInvoices, setPersistedInvoices] = useState([]);
  // only disable invoice items if qbo sync toggle is on and the invoice is not connected to qbo or has an error
  const disabledResourceIds = syncPaymentsToIntegration
    ? invoices.filter(invoice => !invoice.third_party_id || !!invoice.third_party_error).map(invoice => invoice.id)
    : [];
  const selection = CpTable.useSelection({ totalSize: invoices.length, disabledResourceIds });

  const handleInvoiceSelected = (selectedInvoices, type) => {
    let updatedInvoices = [...invoices].map(invoice => {
      const selected = selectedInvoices?.includes(invoice.id);
      const disabled = disabledResourceIds.includes(invoice.id);

      if ((type === 'includes' && selected && !disabled) || (type === 'excludes' && !selected && !disabled)) {
        invoice.selected = true;
        if (!invoice.amountToPay) {
          invoice.amountToPay = invoice.balance;
        }
      } else {
        invoice.selected = false;
        invoice.amountToPay = '';
      }
      return invoice;
    });

    onInvoicesChanged(updatedInvoices);
  };

  const handleAllInvoicesSelected = isChecked => {
    let updatedInvoices = [...invoices].map(invoice => {
      const disabled = disabledResourceIds.includes(invoice.id);
      const selected = isChecked && !disabled;
      invoice.selected = selected;

      if (selected && !invoice.amountToPay) {
        invoice.amountToPay = invoice.balance;
      } else if (!selected) {
        invoice.amountToPay = '';
      }

      return invoice;
    });

    onInvoicesChanged(updatedInvoices);
  };

  const handleAmountToPayChanged = (id, amount, validate) => {
    let updatedInvoices = [...invoices].map(invoice => {
      if (invoice.id === id) {
        if (validate) {
          if (amount > invoice.balance) {
            invoice.amountToPay = invoice.balance;
            if (invoice.amountToPay > 0) {
              if (!selection.isSelected(invoice.id)) {
                selection.toggleSelection(invoice.id);
              }
              invoice.selected = true;
            }
          } else if (amount > 0) {
            invoice.amountToPay = amount;
            if (!selection.isSelected(invoice.id)) {
              selection.toggleSelection(invoice.id);
            }
            invoice.selected = true;
          } else {
            invoice.amountToPay = '';
            selection.toggleSelection(invoice.id);
            invoice.selected = false;
          }
        } else {
          if (!amount) {
            selection.toggleSelection(invoice.id);
            invoice.selected = false;
          } else {
            invoice.amountToPay = amount;
          }
        }
      }
      return invoice;
    });

    onInvoicesChanged(updatedInvoices);
  };

  const isMounted = useRef(false);
  const toggleSelection = useRef();
  toggleSelection.current = selection.toggleSelection;

  useEffect(() => {
    // dont run on first mount
    if (!isMounted.current) {
      const persistedInvoices = invoices.filter(i => i.selected).map(i => i.id);
      setPersistedInvoices(persistedInvoices);
      isMounted.current = true;
      return;
    }

    // handle selection changes so we can update the invoice data
    // Then UI can immediately reflect the changes like total payment and amount to pay
    if (syncPaymentsToIntegration && disabledResourceIds.length === invoices.length) {
      handleAllInvoicesSelected(false);
      selection.deselectAll();
    } else if (selection.allSelected) {
      handleAllInvoicesSelected(true);
    } else if (selection.totalSelected === 0) {
      handleAllInvoicesSelected(false);
    } else {
      handleInvoiceSelected(
        selection.toArray().map(val => parseInt(val)),
        selection.type
      );
    }
  }, [selection.totalSelected, selection.allSelected]);

  useEffect(() => {
    if (persistedInvoices.length) {
      // if there are persisted invoices, then we need to select them in the table
      selection.selectMultiple(persistedInvoices);
    }
  }, [persistedInvoices.length]);

  // format invoice data for the table schema
  const formattedInvoiceData = invoices.map(invoice => ({
    ...invoice,
    paymentId,
    handleAmountToPayChanged,
    showAmountToPay: true,
  }));

  useEffect(() => {
    // if sync payments to integration is on, then we need to deselect any invoices that are not connected to qbo
    if (syncPaymentsToIntegration && disabledResourceIds.length) {
      disabledResourceIds.forEach(id => {
        if (selection.isSelected(id)) {
          selection.toggleSelection(id);
        }
      });
    }
  }, [syncPaymentsToIntegration, disabledResourceIds.length]);

  useEffect(() => {
    // if there is an initial invoice id, then we need to select it on mount if it's in the list
    if (initialInvoiceId && !!invoices.find(i => i.id === initialInvoiceId)) {
      toggleSelection.current(initialInvoiceId);
      // clear the initial invoice id for the table after it's set on mount to prevent weird behavior when clicking back
      setInitialInvoiceId(null);
    }
  }, [initialInvoiceId]);

  const schema = CpTable.useSchema(() => getInvoiceListSchema(edit), [formattedInvoiceData]);

  return (
    <div>
      {loaded && invoices.length > 0 ? (
        <CpWell className="cp-pb-4">
          <div className={styles.invoiceSelectHeader}>
            <div className="cp-subheader-sm cp-wt-semibold">{!edit && 'Outstanding '}Invoices</div>
            {syncPaymentsToIntegration && (
              <em className="cp-caption cp-color-app-secondary-text">
                Payments on an invoice can only sync in QBO if the associated invoice is also synced
              </em>
            )}
          </div>
          <CpTable
            className={always(styles.invoiceSelectTable).m(styles.invoiceSelectTableQbo, showQboCol)}
            data={formattedInvoiceData}
            schema={schema}
            columnOrder={['invoiceNumber', 'qboSync', 'dueDate', 'total', 'balance', 'amountPaid']}
            columnWidths={{
              invoiceNumber: showQboCol ? 100 : 'sm',
              qboSync: showQboCol ? 100 : 'sm',
              dueDate: 'sm',
              total: 'sm',
              balance: 'sm',
              amountPaid: showQboCol ? 148 : 'md',
            }}
            selection={selection}
            visibleColumns={[
              'invoiceNumber',
              ...(showQboCol ? ['qboSync'] : []),
              'dueDate',
              'total',
              'balance',
              'amountPaid',
            ]}
            fullWidth
            disabledResourceIds={disabledResourceIds}
            renderFooterRow={() => {
              return (
                <>
                  <td colSpan={2}>
                    <b>Subtotal:</b>
                  </td>
                  {showQboCol && <td></td>}
                  <td></td>
                  <td>
                    <CpTable.CurrencyCell
                      value={invoices.reduce((acc, val) => {
                        return acc + val.total;
                      }, 0)}
                    />
                  </td>
                  <td>
                    <CpTable.CurrencyCell value={balanceTotal.replace(/[, ]+/g, '')} />
                  </td>
                  <td style={{ textAlign: 'end', paddingRight: '2.4rem' }}>
                    <CpTable.CurrencyCell
                      value={invoices.reduce((acc, val) => {
                        return acc + Number(val.amountToPay || 0);
                      }, 0)}
                    />
                  </td>
                </>
              );
            }}
          />
        </CpWell>
      ) : (
        loaded && (
          <div>
            <CpEmptyState className="cp-pv-32" img="es_thumbs_up" text="Account paid in full" />
          </div>
        )
      )}
    </div>
  );
};
