import * as React from 'react';
import { CprDatepicker, CpSelectSingle } from 'canopy-styleguide!sofe';
import DueDateOptions from '../resources/due-date.js';
import styles from './due-date.styles.css';
import { DateTime } from 'luxon';
import { isEqual } from 'lodash';

export default class DueDate extends React.Component {
  state = {
    selected: 'all',
    selectedObject: {},
    startDate: '',
    endDate: '',
  };

  componentDidMount() {
    this.applyPropsToState();
  }

  applyPropsToState() {
    let selected = this.props.DueDateKey;
    let { startDate, endDate } = this.state;
    if (selected.constructor === Array) {
      startDate = selected[0] ? DateTime.fromISO(selected[0]).toISODate() : DateTime.local().toISODate();
      endDate = selected[1] ? DateTime.fromISO(selected[1]).toISODate() : DateTime.local().toISODate();
      selected = 'custom';
    }
    this.setState({ selected, startDate, endDate }, () => {
      this.applySelectedObject();
      if (selected === 'custom') {
        this.updateDueDateKeyWithCustom();
      }
    });
  }

  applySelectedObject() {
    const selectedObject = DueDateOptions.filter(item => item.id === this.state.selected)[0];
    this.setState({ selectedObject });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.DueDateKey, prevProps.DueDateKey)) {
      this.applyPropsToState();
    }
  }

  itemsChanged = ({ id }) => {
    this.setState({ selected: id }, () => {
      if (id === 'custom') {
        this.updateDueDateKeyWithCustom();
      } else {
        this.props.updateDueDateKey('DueDateKey', id);
      }
    });
  };

  startDateChanged = e => {
    this.setState(
      { startDate: (e.detail ? DateTime.fromJSDate(new Date(e.detail)) : DateTime.local()).toISODate() },
      this.updateDueDateKeyWithCustom
    );
  };

  endDateChanged = e => {
    this.setState(
      { endDate: (e.detail ? DateTime.fromJSDate(new Date(e.detail)) : DateTime.local()).toISODate() },
      this.updateDueDateKeyWithCustom
    );
  };

  updateDueDateKeyWithCustom = () => {
    this.props.updateDueDateKey('DueDateKey', [this.state.startDate, this.state.endDate]);
  };

  render() {
    return (
      <div className={`${styles.flexContainer}`}>
        <div className={`${styles.labelContainer}`}>
          <div className={`${styles.label}`}>Due Date</div>
        </div>
        <CpSelectSingle
          triggerIsBlock
          data={DueDateOptions}
          placeholder="All due dates"
          onChange={this.itemsChanged}
          value={this.state.selectedObject}
          style={{ width: '152px' }}
          contentWidth={152}
        />
        {this.state.selected === 'custom' && (
          <>
            <CprDatepicker
              name="invoiceDate"
              date={this.state.startDate}
              events={{ datechange: this.startDateChanged }}
              className={`${styles.datePicker}`}
            />
            <CprDatepicker
              name="invoiceDate"
              date={this.state.endDate}
              events={{ datechange: this.endDateChanged }}
              className={`${styles.datePicker}`}
            />
          </>
        )}
      </div>
    );
  }
}
