import React from 'react';
import { CpInput } from 'canopy-styleguide!sofe';

export const InvoiceNumber = ({ value, update, disabled, errors }) => (
  <div className={`cp-ml-16 ${errors.includes('noInvoiceNumber') ? 'cps-has-error' : ''}`}>
    <label>
      Invoice #<span className="cps-color-primary">*</span>
    </label>
    <CpInput value={value} onChange={value => update('invoiceNumber', value)} maxLength="25" disabled={disabled} />
    <span style={{ position: 'absolute', marginTop: '4px' }} className="cps-error-block">
      Invoice number is required.
    </span>
  </div>
);
