import { parsePhoneNumber } from 'libphonenumber-js'

export function phoneNumberLink(phone) {
  try {
    const phoneNumber = parsePhoneNumber(phone, 'US')
    return {
      number: phoneNumber.formatNational(),
      link: phoneNumber.getURI()
    }
  } catch(e) {
    return undefined
  }
}
