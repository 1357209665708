import React from 'react';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';

export const PractitionerAuthorization = props => {
  const { teamCanUse, setTeamCanUse, paymentType, hasPractitionerPayments, mobile } = props;
  const [user, tenant] = useWithUserAndTenant();
  const isClient = user?.role === 'Client';

  return isClient || mobile ? (
    <div
      className="cps-well"
      style={{ padding: '16px 24px', maxWidth: '874px', margin: '0px 24px 24px', display: 'flex' }}>
      <label className="cps-checkbox" style={{ marginTop: '2px' }}>
        <input
          type="checkbox"
          checked={teamCanUse}
          disabled={hasPractitionerPayments}
          onChange={e => setTeamCanUse(e.target.checked)}
        />
        <span />
      </label>
      <div>
        I authorize {tenant?.company_name} to charge my {paymentType} above for agreed-upon transactions. I understand
        that my information will be saved to file for future transactions on my account.
      </div>
    </div>
  ) : null;
};
