import React from 'react';
import { CpTextarea } from 'canopy-styleguide!sofe';

export const ClientNotes = ({ value, update, disabled }) => (
  <div>
    <label className="cp-label">Client Notes</label>
    <CpTextarea rows="3" value={value} onChange={clientNote => update({ clientNote })} disabled={disabled} />
    <div className="cp-caption cps-wt-light">This note will appear on the credit note.</div>
  </div>
);
