import React from 'react';
import moment from 'moment';
import { CprDatepicker } from 'canopy-styleguide!sofe';

export const InvoiceDate = ({ value, update, showReset, disabled }) => (
  <div className={`${moment().isBefore(value, 'day') ? 'cps-has-error' : ''} cp-ml-16`}>
    <label>
      Invoice Date<span className="cps-color-primary">*</span>
    </label>
    <CprDatepicker
      date={value}
      events={{
        datechange: date => {
          update('invoiceDate', date.detail);
          update('showDateReset', false);
        },
      }}
      disabled={disabled}
    />
    <span style={{ width: '210px', position: 'absolute' }} className="cps-error-block">
      Invoice date must be on or before today's date.
    </span>
    {showReset && (
      <span style={{ width: '210px', position: 'absolute' }} className="cps-color-primary cps-caption cp-pt-8">
        Your invoice date has been changed to today's date.
      </span>
    )}
  </div>
);
