const terms = [
  {
    id: 'all',
    name: 'All terms',
  },
  {
    id: 'receipt',
    name: 'Due on Receipt',
  },
  {
    id: 'net10',
    name: 'Net 10',
  },
  {
    id: 'net15',
    name: 'Net 15',
  },
  {
    id: 'net30',
    name: 'Net 30',
  },
  {
    id: 'net45',
    name: 'Net 45',
  },
  {
    id: 'net60',
    name: 'Net 60',
  },
  {
    id: 'custom',
    name: 'All custom terms',
  },
];
export default terms;
