// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../report.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-styles__header--MVegT {
  display: flex;
  padding-top: 16px;
  border-bottom: 1px solid var(--cps-color-athens);
  padding-bottom: 16px;
  background-color: var(--cps-color-background);
}

.header-styles__item--mSo3W {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/reports/aged-receivables-summary/report/header/header.styles.css"],"names":[],"mappings":"AAAA;EAEE,aAAa;EACb,iBAAiB;EACjB,gDAAgD;EAChD,oBAAoB;EACpB,6CAA6C;AAC/C;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".header {\n  composes: sidePadding from '../report.styles.css';\n  display: flex;\n  padding-top: 16px;\n  border-bottom: 1px solid var(--cps-color-athens);\n  padding-bottom: 16px;\n  background-color: var(--cps-color-background);\n}\n\n.item {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header-styles__header--MVegT ${___CSS_LOADER_ICSS_IMPORT_0___.locals["sidePadding"]}`,
	"item": `header-styles__item--mSo3W`
};
export default ___CSS_LOADER_EXPORT___;
