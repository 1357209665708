import React from 'react';
import { CpWell } from 'canopy-styleguide!sofe';

import styles from './add-payment.styles.css';

export const TotalPaymentCard = props => {
  return (
    <CpWell
      className={`cp-p-16 ${styles.totalPaymentCard} ${
        props.paymentTotal != '0.00' ? styles.totalPaymentCardActive : styles.totalPaymentCardZero
      }`}>
      <div className="cp-flex-spread-center">
        <span className="cp-subheader-sm">Total payment</span>
        <span className="cp-subheader-sm cp-pr-8">${props.paymentTotal}</span>
      </div>
    </CpWell>
  );
};
