import React, { lazy, Suspense } from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import { ErrorBoundary } from 'error-logging!sofe';
import { CpLoader } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';

const CreateEditCreditCard = lazy(() =>
  import(/* webpackChunkName: 'mobile-payments' */ '../payment-settings/create-edit-credit-card.component').then(m => ({
    default: m.CreateEditCreditCard,
  }))
);

const mobileReactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ErrorBoundary({ featureName: 'mobile-payments', noStrictMode: true })(() => (
    <Suspense fallback={<CpLoader size="lg" />}>
      <CreateEditCreditCard mobile={true} />
    </Suspense>
  )),
  domElementGetter,
  errorBoundary() {
    return <div>Error</div>;
  },
});

export const mobilePaymentsLifecycles = {
  bootstrap: [mobileReactLifecycles.bootstrap, loadPaysafe],
  mount: mobileReactLifecycles.mount,
  unmount: mobileReactLifecycles.unmount,
};

function domElementGetter() {
  let el = document.getElementById('billing-ui');
  if (!el) {
    el = document.createElement('div');
    el.setAttribute('id', 'billing-ui');
    document.body.insertBefore(el, document.body.childNodes[0]);
  }
  return el;
}

function loadPaysafe() {
  return new Promise((resolve, reject) => {
    var el = document.createElement('script');
    el.setAttribute('src', `https://hosted.paysafe.com/js/v1/latest/paysafe.min.js`);
    el.setAttribute('type', 'text/javascript');
    el.onload = resolve;
    var head = document.getElementsByTagName('head')[0];
    try {
      head.appendChild(el);
    } catch (e) {
      handleError(e);
      reject(e);
    }
  });
}
