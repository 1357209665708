import React, { useState, useEffect, useMemo, useRef, useCallback, Fragment } from 'react';
import { fromEvent, merge } from 'rxjs';
import { DateTime } from 'luxon';
import { map, debounce } from 'lodash';
import { featureEnabled } from 'feature-toggles!sofe';
import { CpButton, CpCard, CpDropdown, CpIcon, CpLoader, CpSelectSingle, CpTooltip } from 'canopy-styleguide!sofe';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';
import { handleError } from 'src/common/handle-error.helper';
import ReportWrapper from '../common/report-wrapper/report-wrapper.component';
import { DateRangeFilter } from 'src/common/components/filters/date-range-filter.component';
import { ProfitabilityClientGroupRow } from './profitability-client-group-row.component';
import { ProfitabilityTopRow } from './profitability-top-row.component';
import { paymentDateRanges } from 'src/payments/payments.helper';
import { getClientProfitabilityColumns } from './profitability-columns';
import { getClientOwners, getClient } from 'src/resources/clients.resources';
import { getClientProfitabilityReport, getClientProfitabilityReportCSV } from './profitability.resource';

import styles from './client-profitability.styles.css';
import { scrolledToBottomOfElement } from '../../billing-helpers';

export const ClientProfitability = () => {
  const pageLimit = 50;
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);
  const tableRef = useRef();
  const [, tenant] = useWithUserAndTenant();
  const [refetchReport, setRefetchReport] = useState(false);
  const [exportReport, setExportReport] = useState(false);
  const [groupBy, setGroupBy] = useState({ id: 'client', name: 'Client' });
  const columns = useMemo(() => getClientProfitabilityColumns(groupBy.name), [groupBy]);
  const [reportData, setReportData] = useState();
  const [reportTotals, setReportTotals] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    filter_params: 'yearToDate',
    after: DateTime.local().startOf('year').toISODate(),
    before: DateTime.local().toISODate(),
  });
  const [sortColumn, setSortColumn] = useState({ order: 'asc', column: 'group' });
  const [selectedOwner, setSelectedOwner] = useState();
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMemberSearch, setTeamMemberSearch] = useState('');
  const [teamMemberSearchTrigger, setTeamMemberSearchTrigger] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [eventClientId, setEventClientId] = useState();
  const [changedClientGroupId, setChangedClientGroupId] = useState();

  const hasGroupBilling = tenant?.crm_status === 'crm_hierarchy_complete' && featureEnabled('ft_crm');

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    const timeEntryEvents = fromEvent(window, 'billing-ui::time-saved');
    const invoiceEvents = fromEvent(window, 'billing-ui::event-saved');
    const subs = merge(timeEntryEvents, invoiceEvents).subscribe(({ detail }) => {
      setEventClientId(detail.clientId);
      setRefetchReport(true);
    });
    return () => {
      subs.unsubscribe();
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!eventClientId) return;
    const subscription = getClient(eventClientId, ['client_groups']).subscribe(client => {
      setChangedClientGroupId(client?.client_groups?.id);
      setEventClientId();
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [eventClientId]);

  //for initial load as well as any time filters change
  useEffect(() => {
    setIsLoading(true);
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      { after, before, groupBy: groupBy?.id, ownerId: selectedOwner?.id, sortColumn },
      1,
      pageLimit
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [groupBy, dateFilter, selectedOwner, sortColumn]);

  //for when the report gets updated from an external event such as billing of time entries with tasks from global +
  useEffect(() => {
    if (!refetchReport) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      { after, before, groupBy: groupBy?.id, ownerId: selectedOwner?.id, sortColumn },
      1,
      pageLimit * currentPage
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [refetchReport]);

  //for when user scrolls to bottom of page
  useEffect(() => {
    if (!loadMore) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      { after, before, groupBy: groupBy?.id, ownerId: selectedOwner?.id, sortColumn },
      currentPage + 1,
      pageLimit
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [loadMore]);

  useEffect(() => {
    if (!exportReport) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReportCSV(
      after,
      before,
      groupBy?.id,
      selectedOwner?.id,
      sortColumn
    ).subscribe(response => {
      setExportReport(false);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response).replace(/#/g, '%23');
      hiddenElement.target = '_blank';
      hiddenElement.download = `${after}-${before}-profitability.csv`;
      hiddenElement.click();
    }, handleError);
    return () => subscription.unsubscribe();
  }, [exportReport]);

  useEffect(() => {
    if (!teamMemberSearchTrigger) return;
    const subscription = getClientOwners(teamMemberSearch).subscribe(res => {
      setTeamMembers(res);
      setTeamMemberSearchTrigger(false);
    }, handleError);
    return () => subscription.unsubscribe();
  }, [teamMemberSearchTrigger]);

  const searchTeamMembers = () => setTeamMemberSearchTrigger(true);
  const debouncedTeamMemberSearch = useCallback(debounce(searchTeamMembers, 1000), []);

  const handleWindowResize = () => {
    setMaxHeight(window.innerHeight - (tableRef.current.getBoundingClientRect().top + 24));
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (scrolledToBottomOfElement(tableRef.current) && hasMorePages) {
        setLoadMore(true);
      }
    }, 100),
    [tableRef, hasMorePages]
  );

  const updateTable = response => {
    const pageInfo = response.meta.paginator;
    const updatedReportData = loadMore ? [...reportData, ...response.rows] : response.rows;
    setReportData(updatedReportData);
    setReportTotals(response.totals);
    setCurrentPage(refetchReport ? currentPage : pageInfo.current_page);
    setHasMorePages(!!pageInfo.next_page);
    setIsLoading(false);
    setLoadMore(false);
    setRefetchReport(false);
    handleWindowResize();
  };

  const handleDateFilterChanged = dateFilter => {
    setDateFilter(dateFilter);
  };

  const handleExportCSV = () => {
    setExportReport(true);
  };

  const handleGroupByChange = value => {
    setGroupBy(value);
    value.id === 'client_group' && setSelectedOwner();
  };

  return (
    <ReportWrapper>
      <CpCard>
        <CpCard.Header>
          <div className="cps-subheader cp-flex-center">
            Profitability Report
            <div className="cp-pl-16 cp-flex">
              <CpTooltip
                interactive
                text={
                  <div className="cp-body cp-wt-regular">
                    To see details about how this <br /> report is calcluated, click{' '}
                    <a
                      style={{ color: 'white', textDecoration: 'underline' }}
                      href="https://support.getcanopy.com/hc/en-us/articles/9192975647131-Create-a-Billing-Report"
                      target="_blank"
                      rel="noopener noreferrer">
                      here
                    </a>
                    .
                  </div>
                }>
                <CpIcon name="information-circle-open-large" fill="#52555A" />
              </CpTooltip>
            </div>
          </div>
        </CpCard.Header>

        <CpCard.Header>
          <div className="cp-flex-spread" style={{ width: '100%' }}>
            <div className="cp-flex-center">
              <label className="cp-mr-8">Group By</label>
              <div style={{ width: '189px' }}>
                <CpSelectSingle
                  triggerIsBlock
                  contentWidth="block"
                  data={[
                    {
                      id: 'client',
                      name: 'Client',
                    },
                    ...(hasGroupBilling ? [{ id: 'client_group', name: 'Client Group' }] : []),
                    {
                      id: 'service_item',
                      name: 'Service Item',
                    },
                  ]}
                  onChange={handleGroupByChange}
                  value={groupBy}
                />
              </div>
              <label className="cp-ml-32 cp-mr-8">Filter by</label>
              <DateRangeFilter
                dateFilter={dateFilter}
                onFilterChanged={handleDateFilterChanged}
                sortParam={'reports'}
                dateRanges={paymentDateRanges}
                hideClear={true}
              />
              {groupBy.id !== 'client_group' && (
                <div style={{ width: '212px' }}>
                  <CpSelectSingle
                    className="cp-pl-16"
                    clearable
                    contentWidth={300}
                    data={teamMembers}
                    onChange={setSelectedOwner}
                    placeholder="Owner"
                    searchFilter
                    searchOnChange={value => {
                      setTeamMemberSearch(value);
                      debouncedTeamMemberSearch();
                    }}
                    searchValue={teamMemberSearch}
                    triggerIsBlock
                    value={selectedOwner}
                  />
                </div>
              )}
            </div>
            <CpDropdown
              position="bottom-start"
              renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" aria-label="export" onClick={toggle} />}
              renderContent={() => (
                <div className="cp-select-list">
                  <button onClick={handleExportCSV}>
                    <CpIcon className="cp-select-list__icon-left" name="af-line-square-up" />
                    Export to CSV
                  </button>
                </div>
              )}
            />
          </div>
        </CpCard.Header>

        <div className={`${styles.profit}`} ref={tableRef} style={{ maxHeight }} onScroll={handleScroll}>
          {isLoading ? (
            <CpLoader />
          ) : (
            <table>
              <thead>
                <tr>
                  {map(columns, column => (
                    <th
                      key={column.key}
                      onClick={() => {
                        if (column.key === 'balance') {
                          setSortColumn({
                            column: column.key,
                            order: sortColumn.column === column.key && sortColumn.order === 'asc' ? 'desc' : 'asc',
                          });
                        }
                      }}
                      className={`${
                        column.key === 'row_name' || column.key === 'users' ? 'cp-text-left' : 'cps-text-right'
                      } cps-wt-semibold`}
                      style={
                        column.key === 'row_name'
                          ? {
                              paddingLeft: '16px',
                              minWidth: '215px',
                            }
                          : column.key === 'users'
                          ? {
                              minWidth: '145px',
                            }
                          : column.key === 'balance'
                          ? { minWidth: '145px', cursor: 'pointer' }
                          : { minWidth: '145px' }
                      }>
                      {column.label}
                      {column.key === 'labor_cost' && (
                        <CpTooltip
                          text={
                            <>
                              <div>
                                Cost is calculated using the assigned team member's hourly rate multiplied by the number
                                of hours tracked.
                              </div>
                              <div className="cp-mt-16">
                                If these figures look incorrect, please make sure you have entered an hourly rate for
                                your team member on their Team Member Profile.
                              </div>
                            </>
                          }>
                          <CpIcon name="information-circle-open-small" className="cp-color-app-icon" />
                        </CpTooltip>
                      )}
                      {sortColumn.column === column.key && (
                        <CpIcon name={`caret-small-${sortColumn.order === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reportData.map(row =>
                  groupBy.id === 'client_group' ? (
                    <ProfitabilityClientGroupRow
                      key={row.row_id}
                      row={row}
                      dateFilter={dateFilter}
                      groupedBy={groupBy.id}
                      groupId={row.row_id}
                      ownerId={selectedOwner?.id}
                      sortColumn={sortColumn}
                      changedClientGroupId={changedClientGroupId}
                      setChangedClientGroupId={setChangedClientGroupId}
                    />
                  ) : (
                    <ProfitabilityTopRow
                      key={row.row_id}
                      row={row}
                      dateFilter={dateFilter}
                      groupedBy={groupBy.id}
                      groupId={row.row_id}
                      ownerId={selectedOwner?.id}
                      sortColumn={sortColumn}
                    />
                  )
                )}
                {loadMore && (
                  <tr>
                    <td colSpan={Object.keys(columns).length}>
                      <CpLoader />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  {map(columns, column => (
                    <Fragment key={column.key}>
                      {column.key === 'row_name' ? (
                        <th className="cp-text-left" key={column.key}>
                          <span>Total</span>
                        </th>
                      ) : (
                        <th style={{ fontWeight: '700' }} key={column.key}>
                          {column.key !== 'users' &&
                            column.renderCell(reportTotals[column.key], column.key === 'balance')}
                        </th>
                      )}
                    </Fragment>
                  ))}
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </CpCard>
    </ReportWrapper>
  );
};
