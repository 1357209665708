// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../common/report.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grand-total-styles__table--opYHp {
  margin: 24px 24px 36px 24px;
}

.grand-total-styles__valueRow--ltpA2 {
  color: var(--cps-color-monsoon);
}

.grand-total-styles__fixedTotals--p33Cr {
  /* position: static; */
  /* position: sticky; */
  /* bottom: 0px; */
  padding-bottom: 8px;
  background: var(--cps-color-white);
}
`, "",{"version":3,"sources":["webpack://./src/reports/aged-receivables-detail/report/grand-total/grand-total.styles.css"],"names":[],"mappings":"AAAA;EAEE,2BAA2B;AAC7B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,iBAAiB;EACjB,mBAAmB;EACnB,kCAAkC;AACpC","sourcesContent":[".table {\n  composes: table from 'src/reports/common/report.styles.css';\n  margin: 24px 24px 36px 24px;\n}\n\n.valueRow {\n  color: var(--cps-color-monsoon);\n}\n\n.fixedTotals {\n  /* position: static; */\n  /* position: sticky; */\n  /* bottom: 0px; */\n  padding-bottom: 8px;\n  background: var(--cps-color-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `grand-total-styles__table--opYHp ${___CSS_LOADER_ICSS_IMPORT_0___.locals["table"]}`,
	"valueRow": `grand-total-styles__valueRow--ltpA2`,
	"fixedTotals": `grand-total-styles__fixedTotals--p33Cr`
};
export default ___CSS_LOADER_EXPORT___;
