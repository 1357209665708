// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-styles__invoicesHeader--dbaAD {
  display: flex;
  border-bottom: 1px solid var(--cps-color-athens);
  padding: 8px 0px;
  font-weight: 600;
}

.invoice-styles__invoicesHeaderFlex--Iglpg {
  display: flex;
}

.invoice-styles__invoice--VuT7O {
  display: flex;
  border-bottom: 1px solid var(--cps-color-athens);
}

.invoice-styles__invoice--VuT7O:last-child {
  border-bottom: none;
}

.invoice-styles__invoiceItem--nNAIr {
  margin-right: 4px;
  flex: 1;
  padding: 16px 0px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.invoice-styles__flex1--B1ZWq {
  flex: 1;
}

.invoice-styles__flex2--VmFit {
  flex: 2;
}

.invoice-styles__flex3--R110u {
  flex: 3;
}

.invoice-styles__flex4--Ys83f {
  flex: 4;
}
`, "",{"version":3,"sources":["webpack://./src/reports/aged-receivables-detail/report/detail-item/invoices/invoice.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gDAAgD;EAChD,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gDAAgD;AAClD;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,OAAO;EACP,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,OAAO;AACT","sourcesContent":[".invoicesHeader {\n  display: flex;\n  border-bottom: 1px solid var(--cps-color-athens);\n  padding: 8px 0px;\n  font-weight: 600;\n}\n\n.invoicesHeaderFlex {\n  display: flex;\n}\n\n.invoice {\n  display: flex;\n  border-bottom: 1px solid var(--cps-color-athens);\n}\n\n.invoice:last-child {\n  border-bottom: none;\n}\n\n.invoiceItem {\n  margin-right: 4px;\n  flex: 1;\n  padding: 16px 0px;\n  min-width: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.flex1 {\n  flex: 1;\n}\n\n.flex2 {\n  flex: 2;\n}\n\n.flex3 {\n  flex: 3;\n}\n\n.flex4 {\n  flex: 4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoicesHeader": `invoice-styles__invoicesHeader--dbaAD`,
	"invoicesHeaderFlex": `invoice-styles__invoicesHeaderFlex--Iglpg`,
	"invoice": `invoice-styles__invoice--VuT7O`,
	"invoiceItem": `invoice-styles__invoiceItem--nNAIr`,
	"flex1": `invoice-styles__flex1--B1ZWq`,
	"flex2": `invoice-styles__flex2--VmFit`,
	"flex3": `invoice-styles__flex3--R110u`,
	"flex4": `invoice-styles__flex4--Ys83f`
};
export default ___CSS_LOADER_EXPORT___;
