// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-profitability-task-row-styles__subtaskExpander--LQLEM {
  border: 1px solid var(--cp-color-well-border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 18px;
  cursor: pointer;
  font-size: 11px;
  padding: 2px 0px 2px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/reports/profitability/client-profitability-task-row.styles.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":[".subtaskExpander {\n  border: 1px solid var(--cp-color-well-border);\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  height: 18px;\n  cursor: pointer;\n  font-size: 11px;\n  padding: 2px 0px 2px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtaskExpander": `client-profitability-task-row-styles__subtaskExpander--LQLEM`
};
export default ___CSS_LOADER_EXPORT___;
