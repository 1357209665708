import React, { useContext } from 'react';
import { useHasAccess } from 'cp-client-auth!sofe';
import { CpCheckbox, CpIcon, CpTooltip } from 'canopy-styleguide!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import {
  ClientName,
  Description,
  StartDate,
  EndDate,
  EndOption,
  EndAfter,
  RecurringTerms,
  FixedTerms,
  InvoiceNumber,
  InvoiceDate,
  InvoiceTerms,
  DueDate,
  SendToClient,
} from './form-components';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { invoiceTypes } from 'src/invoices/invoices.helper';
import RecurringInvoiceTiming from './recurring-invoice-timing.component';

import styles from './invoice-builder.styles.css';

export const Header = ({ tenant }) => {
  const { invoice, recurrence, invoiceType, actions, errors } = useContext(InvoiceContext);
  const canCreateEditInvoice = useHasAccess('billing_invoices_create_edit');
  const canEditRecurrence = recurrence.status === 'Not Scheduled';
  const recurrenceEnded = recurrence.status === 'Ended';
  const ft_crm = tenant?.crm_status === 'crm_hierarchy_complete' && featureEnabled('ft_crm');

  return invoiceType === invoiceTypes.recurring ? (
    <>
      <div
        key="recurring"
        className={`${styles.invoiceBuilderHeader} ${!ft_crm && !invoice.isBusinessClient ? 'cp-pb-0' : 'cp-pb-20'}`}>
        <ClientName
          value={invoice.client}
          update={actions.updateInvoiceFormData}
          disabled={!canCreateEditInvoice || !canEditRecurrence}
          ft_crm={ft_crm}
          invoiceType={invoiceType}
        />
        <Description
          value={recurrence.description}
          update={actions.updateRecurrenceFormData}
          disabled={!canCreateEditInvoice || recurrenceEnded}
        />
        <RecurringTerms
          value={recurrence.recurringTerms}
          update={actions.updateRecurrenceFormData}
          disabled={!canCreateEditInvoice || !canEditRecurrence}
        />
        {recurrence.recurringTerms.id === -1 && (
          <FixedTerms
            value={recurrence.fixedTerm}
            update={actions.updateRecurrenceFormData}
            disabled={!canCreateEditInvoice || !canEditRecurrence}
          />
        )}
      </div>
      {!invoice.isBusinessClient && !ft_crm && (
        <CpCheckbox
          onChange={val => actions.updateInvoiceFormData('includeSpouseName', val)}
          checked={invoice.includeSpouseName}
          className={`cp-mt-8 cp-mb-20 ${styles.fitContent}`}
          disabled={recurrenceEnded}>
          <span className="cp-caption">
            Include spouse name <span style={{ fontStyle: 'italic' }}>(when applicable)</span>
          </span>
        </CpCheckbox>
      )}
      <div className={`${styles.invoiceBuilderHeader}`}>
        <div style={{ display: 'flex', whiteSpace: 'nowrap', width: '484px' }}>
          <StartDate
            value={recurrence.startDate}
            update={actions.updateRecurrenceFormData}
            disabled={!canCreateEditInvoice || !canEditRecurrence}
            showReset={recurrence.showDateReset}
            errors={errors?.validationErrors || []}
          />
          <EndOption
            value={recurrence.endOption}
            update={actions.updateRecurrenceFormData}
            disabled={!canCreateEditInvoice || recurrenceEnded}
          />
          {recurrence.endOption === 'After' && (
            <EndAfter
              value={recurrence.endAfter}
              update={actions.updateRecurrenceFormData}
              started={!canEditRecurrence}
              disabled={!canCreateEditInvoice || recurrenceEnded}
            />
          )}
          {recurrence.endOption === 'On date' && (
            <EndDate
              value={recurrence.endDate}
              update={actions.updateRecurrenceFormData}
              disabled={!canCreateEditInvoice || recurrenceEnded}
              errors={errors?.validationErrors || []}
            />
          )}
        </div>
        <div className={styles.fieldGroupSeparator} />
        <RecurringInvoiceTiming
          recurrence={recurrence}
          setRecurrence={actions.updateRecurrenceFormData}
          disabled={!canCreateEditInvoice || !canEditRecurrence}
        />
      </div>
      <div className={`cp-flex-center ${recurrence.showDateReset ? 'cp-mt-20' : 'cp-mt-4'}`}>
        <CpCheckbox
          onChange={val => actions.updateRecurrenceFormData('autoLinkTime', val)}
          checked={recurrence.autoLinkTime}
          disabled={recurrenceEnded}>
          Automatically link time
        </CpCheckbox>
        <CpTooltip
          text={
            <div className="cp-text-center cp-ph-8">
              Time entries dated before the invoice
              <br /> date will be linked to matching service <br />
              items automatically. Invoice amount and
              <br /> line items are unaffected.
            </div>
          }>
          <CpIcon className="cp-ml-8" name="information-circle-open-large" fill="var(--cp-color-app-icon)" />
        </CpTooltip>
      </div>
    </>
  ) : (
    <>
      <div key="oneTime" className={`${styles.invoiceBuilderHeader}  ${!invoice.isBusinessClient ? 'cp-pb-0' : ''}`}>
        <ClientName
          value={invoice.clientGroup || invoice.client}
          filterEntryItems={actions.filterEntryItems}
          update={actions.updateInvoiceFormData}
          disabled={!canCreateEditInvoice || !!invoice.id}
          ft_crm={ft_crm}
          invoiceType={invoiceType}
        />
        <InvoiceNumber
          value={invoice.invoiceNumber}
          update={actions.updateInvoiceFormData}
          disabled={!canCreateEditInvoice}
          errors={errors?.validationErrors || []}
        />
        <InvoiceDate
          value={invoice.invoiceDate}
          update={actions.updateInvoiceFormData}
          showReset={invoice.showDateReset}
          disabled={!canCreateEditInvoice}
        />
        <InvoiceTerms value={invoice.terms} update={actions.updateInvoiceFormData} disabled={!canCreateEditInvoice} />
        <DueDate
          value={invoice.dueDate}
          update={actions.updateInvoiceFormData}
          terms={invoice.terms}
          disabled={!canCreateEditInvoice}
          errors={errors?.validationErrors || []}
        />
        {invoice.clientGroup && ft_crm && (
          <SendToClient
            clientGroupId={invoice.clientGroup?.id}
            disabled={!canCreateEditInvoice || !!invoice.id}
            update={actions.updateInvoiceFormData}
            value={invoice.client}
          />
        )}
      </div>
      {!invoice.isBusinessClient && !ft_crm && (
        <CpCheckbox
          onChange={val => actions.updateInvoiceFormData('includeSpouseName', val)}
          checked={invoice.includeSpouseName}
          className={`cp-mt-8 cp-mb-20 ${styles.fitContent}`}>
          <span className="cp-caption">
            Include spouse name <span style={{ fontStyle: 'italic' }}>(when applicable)</span>
          </span>
        </CpCheckbox>
      )}
    </>
  );
};
