import React from 'react';
// project
import { getAgedReceivableDetailCSV } from '../aged-receivables-detail.resource.js';
import ReportTitle from '../../common/report-title/report-title.component.js';
import DetailItem from './detail-item/detail-item.component.js';
import GrandTotal from './grand-total/grand-total.component.js';
import { getCheckboxesChanged, getFiltersChanged, filterSchemaByStatus } from './report-helper';
import { CpEmptyState } from 'canopy-styleguide!sofe';

export default class AgedReceivableDetailReport extends React.Component {
  render() {
    const { bucket_schema, bucket_values, clients } = this.props.results;
    const { ClientsKeys, checkboxes } = this.props.state.filtering;
    const filteredClients = ClientsKeys.length ? clients.filter(item => ClientsKeys.includes(item.id)) : clients;
    const statuses = Array.from(checkboxes.numeric.values());
    const new_bucket_schema = filterSchemaByStatus(statuses, bucket_schema);

    const prepend = [
      'invoice #',
      ...(checkboxes.general.get('invoiceDate') ? ['Invoice Date'] : []),
      'Due Date',
      ...(checkboxes.general.get('terms') ? ['Terms'] : []),
    ];

    return (
      <>
        <ReportTitle
          title="Aged Receivables Detail"
          showCustomize={true}
          exportCSVRequest={this.exportCSVRequest}
          refetch={this.maybeRefetch}
          customizeReportSettings={this.props.state.filtering}
          bucket_schema={bucket_schema}
          stateDefault={this.props.stateDefault.filtering}
          clientsFromResponse={this.props.state.initialClients}
        />

        {!filteredClients.length ? (
          <CpEmptyState
            className="cp-pv-32"
            img="es_billing_invoice"
            text="No results found"
            subText="Please refine your filters."
          />
        ) : (
          <>
            <div>
              {filteredClients.map(client => (
                <DetailItem
                  key={client.id}
                  schema={new_bucket_schema}
                  client={client}
                  checkboxes={checkboxes.general}
                  statuses={statuses}
                  prepend={prepend}
                />
              ))}
            </div>
            <GrandTotal schema={new_bucket_schema} values={bucket_values} prepend={prepend} />
          </>
        )}
      </>
    );
  }

  maybeRefetch = options => {
    const currentSettings = this.props.state.filtering;
    let newCheckboxes = { ...this.props.state.filtering.checkboxes };

    // check if any checkboxes have changed and if they have, update state
    const changedCheckboxesGeneral = getCheckboxesChanged(
      options.checkboxes.general,
      currentSettings.checkboxes.general
    );
    newCheckboxes.general = new Map([...newCheckboxes.general, ...changedCheckboxesGeneral]);

    const changedCheckboxesNumeric = getCheckboxesChanged(
      options.checkboxes.numeric,
      currentSettings.checkboxes.numeric
    );
    newCheckboxes.numeric = new Map([...newCheckboxes.numeric, ...changedCheckboxesNumeric]);

    // check if any filters have changed and if so, trigger a new request
    const changedProps = getFiltersChanged(options, currentSettings);

    let newFilterState = changedProps.reduce((acc, item) => {
      acc[item] = options[item];
      return acc;
    }, {});

    const changedPropsWithoutClients = changedProps.filter(item => item !== 'ClientsKeys');

    currentSettings.checkboxes = newCheckboxes;
    this.props.setARDState({ filtering: { ...currentSettings, ...newFilterState } }, () => {
      if (changedPropsWithoutClients.length) {
        this.props.fetch({ filters: options });
      }
    });
  };

  exportCSVRequest = () => {
    return getAgedReceivableDetailCSV({ schema: undefined, filters: this.props.state.filtering });
  };
}
