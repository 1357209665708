// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columns-and-rows-styles__outerContainer--fQ_Au {
  margin-left: 24px;
}

.columns-and-rows-styles__outerContainer--fQ_Au input[type='checkbox'] + span {
  font-size: 1.4rem;
}

.columns-and-rows-styles__outerContainer--fQ_Au label {
  margin-left: 0.2rem;
}

.columns-and-rows-styles__clientInformation--XF0Jn {
  width: 19rem;
}

.columns-and-rows-styles__section--hOPIO > div {
  margin: 0.8rem 0;
}

.columns-and-rows-styles__checkboxesContainer--zdKDC > * {
  margin: 0.8rem 0;
}

.columns-and-rows-styles__section--hOPIO {
  margin-top: 0.5rem;
}

.columns-and-rows-styles__firstRow--brCKm .columns-and-rows-styles__checkboxesContainer--zdKDC {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 7.4rem;
}

.columns-and-rows-styles__secondRow--tKAc6 .columns-and-rows-styles__checkboxesContainer--zdKDC {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 12rem;
}

.columns-and-rows-styles__firstRow--brCKm,
.columns-and-rows-styles__secondRow--tKAc6 {
  display: flex;
}

.columns-and-rows-styles__invoiceInformation--MJqWt label {
  margin-right: 3rem;
}

.columns-and-rows-styles__status--Q87MO label {
  width: 19rem;
}
`, "",{"version":3,"sources":["webpack://./src/reports/common/report-title/customize-option/customize-modal/columns-and-rows/columns-and-rows.styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,aAAa;AACf;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".outerContainer {\n  margin-left: 24px;\n}\n\n.outerContainer input[type='checkbox'] + span {\n  font-size: 1.4rem;\n}\n\n.outerContainer label {\n  margin-left: 0.2rem;\n}\n\n.clientInformation {\n  width: 19rem;\n}\n\n.section > div {\n  margin: 0.8rem 0;\n}\n\n.checkboxesContainer > * {\n  margin: 0.8rem 0;\n}\n\n.section {\n  margin-top: 0.5rem;\n}\n\n.firstRow .checkboxesContainer {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  height: 7.4rem;\n}\n\n.secondRow .checkboxesContainer {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  height: 12rem;\n}\n\n.firstRow,\n.secondRow {\n  display: flex;\n}\n\n.invoiceInformation label {\n  margin-right: 3rem;\n}\n\n.status label {\n  width: 19rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `columns-and-rows-styles__outerContainer--fQ_Au`,
	"clientInformation": `columns-and-rows-styles__clientInformation--XF0Jn`,
	"section": `columns-and-rows-styles__section--hOPIO`,
	"checkboxesContainer": `columns-and-rows-styles__checkboxesContainer--zdKDC`,
	"firstRow": `columns-and-rows-styles__firstRow--brCKm`,
	"secondRow": `columns-and-rows-styles__secondRow--tKAc6`,
	"invoiceInformation": `columns-and-rows-styles__invoiceInformation--MJqWt`,
	"status": `columns-and-rows-styles__status--Q87MO`
};
export default ___CSS_LOADER_EXPORT___;
