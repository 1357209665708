// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column-header-styles__columnHeader--vVu2O {
  white-space: nowrap;
}

.column-header-styles__columnHeader--vVu2O:hover {
  background-color: var(--cps-color-chrome);
  cursor: pointer;
}

.column-header-styles__columnHeaderNoHover--STIMd:hover {
  background-color: inherit;
  cursor: default;
}

.column-header-styles__padPaymentStatus--b0hmH {
  padding-left: 32px !important;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/column_header/column-header.styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".columnHeader {\n  white-space: nowrap;\n}\n\n.columnHeader:hover {\n  background-color: var(--cps-color-chrome);\n  cursor: pointer;\n}\n\n.columnHeaderNoHover:hover {\n  background-color: inherit;\n  cursor: default;\n}\n\n.padPaymentStatus {\n  padding-left: 32px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnHeader": `column-header-styles__columnHeader--vVu2O`,
	"columnHeaderNoHover": `column-header-styles__columnHeaderNoHover--STIMd`,
	"padPaymentStatus": `column-header-styles__padPaymentStatus--b0hmH`
};
export default ___CSS_LOADER_EXPORT___;
