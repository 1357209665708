import React from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { frequencyOptions } from 'src/invoices/recurrences.helper';

export const Frequency = ({ engagement, value, update, disabled }) => {
  const data = engagement
    ? [...frequencyOptions.map(o => o.value), 'Upon acceptance', 'Upon completion']
    : frequencyOptions.map(o => o.value);
  return (
    <div className={engagement ? '' : 'cp-ml-8'}>
      <label>
        {engagement ? 'Billing frequency' : 'Frequency'}
        {!engagement && <span className="cps-color-primary">*</span>}
      </label>
      <div style={{ width: engagement ? '147px' : '140px' }}>
        <CpSelectSingle
          triggerIsBlock
          contentWidth="sm"
          fixedContent
          disabled={disabled || engagement}
          data={data}
          transformData={item => ({
            id: item,
            name: item,
          })}
          value={value}
          onChange={frequencyOption => update('frequencyOption', frequencyOption)}
        />
      </div>
    </div>
  );
};
