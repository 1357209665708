// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-styles__inputGroup--Guukg {
  position: relative;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
}

.input-group-styles__inputGroup_input--Sv4xo {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  display: table-cell;
}

.input-group-styles__inputGroup_input--Sv4xo:last-child input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-styles__inputGroup_input--Sv4xo:first-child input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-styles__inputGroup_addon--DcPws {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  background-color: var(--cp-color-well-l3-bg);
  border: 1px solid var(--cp-color-app-border);
  border-radius: 5px;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.input-group-styles__inputGroup_addon--DcPws:first-child {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-styles__inputGroup_addon--DcPws:last-child {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/input_group/input-group.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,4CAA4C;EAC5C,4CAA4C;EAC5C,kBAAkB;EAClB,SAAS;EACT,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,4BAA4B;AAC9B","sourcesContent":[".inputGroup {\n  position: relative;\n  display: table;\n  border-collapse: separate;\n  border-spacing: 0;\n}\n\n.inputGroup_input {\n  position: relative;\n  float: left;\n  width: 100%;\n  margin-bottom: 0;\n  display: table-cell;\n}\n\n.inputGroup_input:last-child input {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n\n.inputGroup_input:first-child input {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.inputGroup_addon {\n  padding: 6px 12px;\n  font-size: 14px;\n  font-weight: normal;\n  line-height: 1;\n  text-align: center;\n  background-color: var(--cp-color-well-l3-bg);\n  border: 1px solid var(--cp-color-app-border);\n  border-radius: 5px;\n  width: 1%;\n  white-space: nowrap;\n  vertical-align: middle;\n  display: table-cell;\n}\n\n.inputGroup_addon:first-child {\n  border-right: 0;\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.inputGroup_addon:last-child {\n  border-left: 0;\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputGroup": `input-group-styles__inputGroup--Guukg`,
	"inputGroup_input": `input-group-styles__inputGroup_input--Sv4xo`,
	"inputGroup_addon": `input-group-styles__inputGroup_addon--DcPws`
};
export default ___CSS_LOADER_EXPORT___;
