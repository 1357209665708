import { CpTable } from 'canopy-styleguide!sofe';

export const linkedItemSchema = isGroupBilled => {
  return {
    ...(isGroupBilled && {
      client: {
        fieldId: 'clientName',
        label: 'Client',
        dataType: 'string',
        sticky: 'left',
        width: 145,
      },
    }),
    assignee: {
      fieldId: 'assignee',
      label: 'Assignee',
      dataType: 'string',
      sticky: !isGroupBilled ? 'left' : '',
      width: 145,
    },
    type: {
      fieldId: 'type',
      label: 'Type',
      dataType: 'string',
      width: 102,
    },
    date: {
      fieldId: 'timeEntryDate',
      label: 'Date',
      dataType: 'date_iso',
      width: 102,
    },
    duration: {
      fieldId: 'quantity',
      label: 'Duration',
      dataType: 'string',
      width: 102,
    },
    service: {
      fieldId: 'serviceName',
      label: 'Service',
      dataType: 'string',
      width: 176,
    },
    note: {
      fieldId: 'description',
      label: 'Note',
      dataType: 'string',
      width: 148,
    },
    amount: {
      fieldId: 'amount',
      dataType: 'number',
      label: 'Unbilled Revenue',
      header: {
        props: {
          align: 'right',
        },
      },
      cell: { component: CpTable.CurrencyCell, props: data => ({ ...data, align: 'right' }) },
      width: 174,
    },
  };
};
