import React, { useState, useEffect } from 'react';
import { concat, sortBy, without } from 'lodash';
import { CpButton, CpIcon } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { filterTypes } from './columns.helpers';
import { DynamicFilter } from './filter/dynamic-filter.component';
import { DateRangeFilter } from './filter/date-range-filter.component';
import { defaultDateFilterRanges } from './filter/date-filter.helper';

import styles from './menu-content.styles.css';

export const MenuContent = ({ filterContext, column, getDynamicFilters, onCancel, onApply, isOpen }) => {
  const [availableFilterValues, setAvailableFilterValues] = useState([]);
  const [allFilterValues, setAllFilterValues] = useState(null);
  const [selections, setSelections] = useState({ ...filterContext.filters[column.sortParam] });
  const [filterSearchValue, setFilterSearchValue] = useState('');

  useEffect(() => {
    if (!isOpen) return;
    setSelections({ ...filterContext.filters[column.sortParam] });
    if (column.filterType === filterTypes.Fixed) {
      setAvailableFilterValues(column.filterValues);
      setAllFilterValues(column.filterValues);
    }
    if (column.filterType === filterTypes.Dynamic) {
      const filterSubscription = getDynamicFilters().subscribe(result => {
        if (result) {
          let allFilterValues = [...result];
          if (column.sortFilterList) {
            allFilterValues = sortBy(allFilterValues, value => value.name.toLowerCase());
          }
          setAvailableFilterValues(allFilterValues);
          setAllFilterValues(allFilterValues);
        }
      }, handleError);

      return () => filterSubscription.unsubscribe();
    }
  }, [isOpen]);

  const filterSelections = searchValue => {
    setFilterSearchValue(searchValue);
    if (!allFilterValues) return;
    setAvailableFilterValues(
      allFilterValues.filter(value => value.name.toString().toLowerCase().includes(searchValue))
    );
  };

  const applySelections = () => {
    filterContext.applyFilter(column.sortParam, selections || 0);
    onApply();
  };

  const clearSelections = () => {
    setSelections({});
    setAvailableFilterValues(allFilterValues);
    setFilterSearchValue('');
  };

  const applySort = order => {
    setSelections({
      ...selections,
      order: selections && selections.order === order ? undefined : order,
    });
  };

  const applyFilter = filter => {
    const newSelections = { ...selections };
    if (!newSelections.filter_params) {
      newSelections.filter_params = [];
    }

    const isSelected = newSelections.filter_params.includes(filter);

    if (column.singleFilterSelect) {
      newSelections.filter_params = isSelected ? [] : [filter];
    } else {
      isSelected
        ? (newSelections.filter_params = without(newSelections.filter_params, filter))
        : (newSelections.filter_params = concat(newSelections.filter_params, filter));
    }

    setSelections(newSelections);
  };

  const applyDateRangeFilter = (selectedFilter, filter) => {
    setSelections(
      selectedFilter
        ? {
            ...selections,
            filter_params: selectedFilter,
            before: filter.before,
            after: filter.after,
          }
        : {}
    );
  };

  let selectedFilters = selections.filter_params;

  return (
    <>
      <div
        className={`${styles.menuOption}`}
        style={column.filterType === filterTypes.DateRange ? { width: '220px' } : {}}
        onClick={() => applySort('asc')}>
        <div
          style={{ whiteSpace: 'nowrap' }}
          className={`cps-ellipsis ${selections.order === 'asc' ? 'cps-color-primary' : ''}`}>
          {`Sort ${column.minSortValue} - ${column.maxSortValue}`}
        </div>
        {selections.order === 'asc' && <CpIcon name="checkmark-small" fill="var(--cps-color-primary)" />}
      </div>
      <div
        className={`${styles.menuOption}`}
        style={column.filterType === filterTypes.DateRange ? { width: '220px' } : {}}
        onClick={() => applySort('desc')}>
        <div
          style={{ whiteSpace: 'nowrap' }}
          className={`cps-ellipsis ${selections.order === 'desc' ? 'cps-color-primary' : ''}`}>
          {`Sort ${column.maxSortValue} - ${column.minSortValue}`}
        </div>
        {selections.order === 'desc' && <CpIcon name="checkmark-small" fill="var(--cps-color-primary)" />}
      </div>

      {[filterTypes.Dynamic, filterTypes.Fixed].includes(column.filterType) && (
        <DynamicFilter
          filterValues={availableFilterValues}
          filterSelections={filterSelections}
          applyFilter={applyFilter}
          selections={selectedFilters}
          showSearch={column.showSearch}
          filterSearchValue={filterSearchValue}
          searchPlaceholder={column.searchPlaceholder}
        />
      )}
      {column.filterType === filterTypes.DateRange && (
        <DateRangeFilter
          applyFilter={applyDateRangeFilter}
          filter={selections}
          dateRanges={column.dateRanges || defaultDateFilterRanges}
        />
      )}
      <div className={`${styles.actions}`}>
        <div style={{ display: 'inline-flex' }}>
          <CpButton btnType="flat" onClick={applySelections}>
            Apply
          </CpButton>
          <CpButton btnType="tertiary" onClick={onCancel}>
            Cancel
          </CpButton>
        </div>
        <CpButton btnType="tertiary" onClick={clearSelections}>
          Clear
        </CpButton>
      </div>
    </>
  );
};
