import React, { useEffect, useRef } from 'react';
import { useCss } from 'kremling';

export const RoutingAccountImage = ({ routingNumActive, accountNumActive }) => {
  const svgRoutingNumber = useRef();
  const svgAccountNumber = useRef();

  useEffect(() => {
    const defaultColor = getComputedStyle(document.documentElement).getPropertyValue('--cps-color-monsoon');
    const hilightColor = getComputedStyle(document.documentElement).getPropertyValue('--cps-color-vibrant-ocean');

    if (routingNumActive) {
      svgRoutingNumber.current.style.fill = hilightColor;
      svgAccountNumber.current.style.fill = defaultColor;
    } else if (accountNumActive) {
      svgRoutingNumber.current.style.fill = defaultColor;
      svgAccountNumber.current.style.fill = hilightColor;
    } else {
      svgRoutingNumber.current.style.fill = defaultColor;
      svgAccountNumber.current.style.fill = defaultColor;
    }
  }, [routingNumActive, accountNumActive]);

  const scope = useCss(css);
  return (
    <div {...scope}>
      <div className="bank-img-container">
        <svg id="svgCheck" width="128px" height="67px" viewBox="0 0 128 67" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-984.000000, -268.000000)">
              <g transform="translate(984.000000, 268.000000)">
                <rect fill="#FFFFFF" x="0" y="0" width="128" height="67" />
                <g transform="translate(3.000000, 3.000000)">
                  <rect fill="#DFDFDF" fillRule="nonzero" x="7" y="7" width="35" height="4" rx="1" />
                  <rect fill="#DFDFDF" fillRule="nonzero" x="80" y="7" width="35" height="4" rx="1" />
                  <rect fill="#DFDFDF" fillRule="nonzero" x="92" y="25" width="23" height="4" rx="1" />
                  <rect fill="#DFDFDF" fillRule="nonzero" x="7" y="27" width="81" height="2" rx="1" />
                  <rect fill="#DFDFDF" fillRule="nonzero" x="7" y="35" width="108" height="2" rx="1" />
                  <rect fill="#DFDFDF" fillRule="nonzero" x="7" y="13" width="35" height="4" rx="1" />
                  <path
                    d="M5.07692308,52 C5.62920783,52 6.07692308,52.4477153 6.07692308,53 C6.07692308,53.5522847 5.62920783,54 5.07692308,54 L3,54 C1.34314575,54 5.32907052e-15,52.6568542 0,51 L0,3 C0,1.34314575 1.34314575,-3.55271368e-14 3,-3.55271368e-14 L119,-3.55271368e-14 C120.656854,-3.55271368e-14 122,1.34314575 122,3 L122,51 C122,52.6568542 120.656854,54 119,54 L116.897436,54 C116.345151,54 115.897436,53.5522847 115.897436,53 C115.897436,52.4477153 116.345151,52 116.897436,52 L119,52 C119.552285,52 120,51.5522847 120,51 L120,3 C120,2.44771525 119.552285,2 119,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,51 C2,51.5522847 2.44771525,52 3,52 L5.07692308,52 Z"
                    fill="#AFAFAF"
                    fillRule="nonzero"
                  />
                  <path
                    d="M51,47.5384615 C51,47.2410774 51.2238576,47 51.5,47 C51.7761424,47 52,47.2410774 52,47.5384615 L52,53.4615385 C52,53.7589226 51.7761424,54 51.5,54 L10.5,54 C10.2238576,54 10,53.7589226 10,53.4615385 L10,47.5384615 C10,47.2410774 10.2238576,47 10.5,47 C10.7761424,47 11,47.2410774 11,47.5384615 L11,53 L51,53 L51,47.5384615 Z"
                    fill="#979797"
                    fillRule="nonzero"
                  />
                  <path
                    d="M111,48 C111,47.7238576 111.223858,47.5 111.5,47.5 C111.776142,47.5 112,47.7238576 112,48 L112,53.5 C112,53.7761424 111.776142,54 111.5,54 L58.5,54 C58.2238576,54 58,53.7761424 58,53.5 L58,48 C58,47.7238576 58.2238576,47.5 58.5,47.5 C58.7761424,47.5 59,47.7238576 59,48 L59,53 L111,53 L111,48 Z"
                    fill="#979797"
                    fillRule="nonzero"
                  />
                  <text
                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                    fontSize="8"
                    fontWeight="normal"
                    fill="#52555A">
                    <tspan x="18" y="62">
                      Routing
                    </tspan>
                  </text>
                  <text
                    id="svgRoutingNumber"
                    ref={svgRoutingNumber}
                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                    fontSize="8"
                    fontWeight="normal"
                    fill="#777777">
                    <tspan x="13" y="51">
                      123456789
                    </tspan>
                  </text>
                  <text
                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                    fontSize="8"
                    fontWeight="normal"
                    fill="#52555A">
                    <tspan x="71" y="62">
                      Account
                    </tspan>
                  </text>
                  <text
                    id="svgAccountNumber"
                    ref={svgAccountNumber}
                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                    fontSize="8"
                    fontWeight="normal"
                    fill="#777777">
                    <tspan x="61" y="51">
                      123123456789
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

const css = `
& .bank-img-container {
  margin-left: 24px;
  width: 122px;
  height: 54px;
  position: relative;
}
`;
