import React, { useState, useMemo } from 'react';
import { map } from 'lodash';
import { CpIcon } from 'canopy-styleguide!sofe';
import { getClientProfitabilityColumns } from './profitability-columns';
import styles from './client-profitability-task-row.styles.css';

export const ClientProfitabilityTaskRow = ({ task, clientID, sortedByClientGroup }) => {
  const columns = useMemo(() => getClientProfitabilityColumns(), []);
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <tr key={task.row_id} style={{ background: 'white' }}>
        {map(columns, column =>
          column.key === 'row_name' ? (
            <TaskCell
              task={task}
              column={column}
              clientID={clientID}
              expanded={expanded}
              setExpanded={setExpanded}
              sortedByClientGroup={sortedByClientGroup}
            />
          ) : (
            <DataCell row={task} column={column} fontWeight="500" sortedByClientGroup={sortedByClientGroup} />
          )
        )}
      </tr>
      {expanded &&
        task.subtasks?.map((subtask, index) => (
          <tr key={index} style={{ background: 'white' }}>
            {map(columns, column =>
              column.key === 'row_name' ? (
                <SubtaskCell
                  subtask={subtask}
                  column={column}
                  parentTaskID={task?.row_id}
                  clientID={clientID}
                  sortedByClientGroup={sortedByClientGroup}
                />
              ) : (
                <DataCell
                  row={subtask}
                  column={column}
                  fontWeight="normal"
                  renderBorder={false}
                  sortedByClientGroup={sortedByClientGroup}
                />
              )
            )}
          </tr>
        ))}
    </>
  );
};

const TaskCell = ({ task, column, clientID, expanded, setExpanded, sortedByClientGroup }) => {
  return (
    <td
      key={column.key}
      style={{
        maxWidth: '380px',
        background: sortedByClientGroup ? 'var(--cp-color-table-header-active-bg)' : 'white',
        fontWeight: '500',
      }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="cps-ellipsis" style={{ marginLeft: sortedByClientGroup ? '56px' : '40px' }}>
          {task.row_id && !task.archived ? (
            <a href={`/#/task/${task.row_id}/client/${clientID}`} target="_blank" rel="noreferrer">
              {task.row_name}
            </a>
          ) : (
            task.row_name || '-No Task-'
          )}
        </span>
        {!!task.subtasks?.length && (
          <span className={`cp-ml-8 ${styles.subtaskExpander}`} onClick={() => setExpanded(!expanded)}>
            <span>{task.subtasks.length}</span>
            <span className="cp-ml-4">
              <CpIcon name={expanded ? 'caret-small-up' : 'caret-small-down'} />
            </span>
          </span>
        )}
      </div>
    </td>
  );
};

const SubtaskCell = ({ subtask, column, parentTaskID, clientID, sortedByClientGroup }) => {
  return (
    <td
      key={column.key}
      className="cps-ellipsis"
      style={{
        maxWidth: '380px',
        background: sortedByClientGroup ? 'var(--cp-color-table-header-active-bg)' : 'white',
        borderTop: 'none',
      }}>
      <a
        style={{ marginLeft: sortedByClientGroup ? '92px' : '76px' }}
        href={`/#/task/${parentTaskID}/client/${clientID}?subtask=${subtask.subtask_id}`}
        target="_blank"
        rel="noreferrer">
        {subtask.subtask_name}
      </a>
    </td>
  );
};

const DataCell = ({ row, column, fontWeight, renderBorder = true, sortedByClientGroup }) => {
  return (
    <td
      key={column.key}
      className={`cps-ellipsis ${column.key === 'users' ? '' : 'cp-text-right'}`}
      style={{
        ...{
          maxWidth: '150px',
          background: sortedByClientGroup ? 'var(--cp-color-table-header-active-bg)' : 'white',
          fontWeight,
        },
        ...(renderBorder ? {} : { borderTop: 'none' }),
      }}>
      {column.renderCell(row[column.key], column.key === 'balance')}
    </td>
  );
};
