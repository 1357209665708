import { uniqueId } from 'lodash';
import { billingCalc } from 'src/invoices/invoices.helper';

export const getColumns = () => {
  return [
    { key: 'service', label: 'Service', width: '185px', canHide: true },
    { key: 'description', label: 'Description', width: '300px', canHide: true },
    { key: 'quantity', label: 'Quantity', width: '120px', canHide: true },
    { key: 'rate', label: 'Rate', width: '160px', canHide: true },
    { key: 'tax', label: 'Tax', width: '160px', canHide: true },
    { key: 'amount', label: 'Total', width: '160px', canHide: false },
  ];
};

export const calculateLineItemTotals = lineItems => {
  return lineItems.reduce(
    ({ sumSubTotal, sumTaxes, sumTotal }, lineItem) => {
      const { subTotal, tax, total } = lineItem;
      return {
        sumSubTotal: sumSubTotal + (parseFloat(subTotal) || 0),
        sumTaxes: sumTaxes + (parseFloat(tax) || 0),
        sumTotal: sumTotal + (parseFloat(total) || 0),
      };
    },
    { sumSubTotal: 0, sumTaxes: 0, sumTotal: 0 }
  );
};

export const getEmptyLineItem = () => {
  return {
    service: { id: '', name: '' },
    description: '',
    quantity: '1',
    rate: '0',
    taxRate: '0',
    subTotal: 0,
    tax: 0,
    total: 0,
    id: uniqueId(),
  };
};

export const prepareCreditDTO = (credit, lineItemTotals, qboConnected, syncCreditToIntegration) => {
  //transform credit to API contract
  const {
    clientNote,
    client,
    creditNumber,
    date,
    description,
    id,
    invoice_payments,
    lineItems,
    payment_id,
    refunds,
    status,
    termsConditions,
  } = credit;
  billingCalc.convertToFixedString(lineItemTotals, 'sumSubTotal');
  billingCalc.convertToFixedString(lineItemTotals, 'sumTotal');
  billingCalc.convertToFixedString(lineItemTotals, 'sumTaxes');
  return {
    id,
    credit_number: creditNumber,
    relationships: { for: { id: client.id, name: client.name, type: 'client' } },
    date,
    terms_conditions: termsConditions,
    client_notes: clientNote,
    description,
    credit_subtotal: lineItemTotals.sumSubTotal,
    credit_total: lineItemTotals.sumTotal,
    tax_total: lineItemTotals.sumTaxes,
    status: status || 'open',
    refunds,
    invoice_payments,
    payment_id,
    ...(qboConnected && {
      event_origin: 'practitioner',
      third_party_sync_credit: syncCreditToIntegration,
    }),
    credit_line_items: lineItems
      .filter(item => item.total)
      .map(item => {
        billingCalc.convertToFixedString(item, 'rate');
        billingCalc.convertToFixedString(item, 'taxRate');
        billingCalc.convertToFixedString(item, 'total');
        const creditDto = {
          description: item.description,
          name: item.service.name,
          quantity: item.quantity,
          rate_amount: item.rate,
          tax_rate_percent: item.taxRate,
          total_amount: item.total,
        };
        if (item.service.id) {
          creditDto.relationships = { based_on: item.relationships.based_on };
        }
        return creditDto;
      }),
  };
};

export const creditDtoToFormData = credit => {
  const {
    client_notes,
    credit_line_items,
    credit_number,
    date,
    description,
    id,
    invoice_payments,
    payment_id,
    relationships,
    refunds,
    status,
    terms_conditions,
  } = credit;
  return {
    client: { id: relationships.for.id, name: relationships.for.name },
    creditNumber: credit_number,
    relationships,
    date: date,
    termsConditions: terms_conditions,
    clientNote: client_notes,
    description,
    status,
    id,
    payment_id,
    refunds,
    invoice_payments,
    lineItems: credit_line_items.map(item => {
      const { description, name, item_id, quantity, rate_amount, tax_rate_percent, total_amount, id } = item;
      const formData = {
        description: description,
        service: { name: name, id: item_id },
        quantity: quantity,
        rate: rate_amount,
        subTotal: quantity * rate_amount,
        tax: tax_rate_percent ? (quantity * rate_amount * tax_rate_percent) / 100 : 0,
        taxRate: tax_rate_percent,
        total: total_amount,
        id: id,
      };
      if (item_id) {
        formData.relationships = { based_on: { id: item_id, name, type: 'item' } };
      }
      return formData;
    }),
  };
};
