import React, { useEffect, useState, useCallback, useRef } from 'react';
import juration from 'juration';
import { startsWith, toNumber } from 'lodash';
import { getDurationText, purelyNumericRegEx, timeFormatRegEx } from './edit-time.helper';
import styles from './time-entry.styles.css';
import { secondsInOneDay } from './timer/timer.helper';

export default function TimeEntryDuration(props) {
  const { disableEdit, duration, onDurationChange } = props;
  const [durationText, setDurationText] = useState(getDurationText(duration || 1800));
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current && !!duration) {
      setDurationText(getDurationText(duration));
      isMounted.current = true;
    }
  }, [duration]);

  const handleDurationEnterKey = useCallback(
    e => {
      if (e.keyCode === 13) {
        setDurationText(getDurationText(duration));
      }
    },
    [duration]
  );

  const handleDurationBlur = useCallback(() => {
    setDurationText(getDurationText(duration));
  }, [duration]);

  const handleDurationChange = useCallback(
    e => {
      e.persist();
      let origValue = e.target.value;
      let valueToParse = e.target.value;

      if (origValue.indexOf(':') !== -1) {
        if (startsWith(origValue, ':')) {
          origValue = `0${origValue}`;
        }

        let potentialMatches = timeFormatRegEx.exec(origValue);

        if (potentialMatches) {
          const [hours, minutes] = potentialMatches;

          valueToParse = `${hours} hours${minutes ? ` ${minutes} minutes` : ''}`;
        }
      } else if (purelyNumericRegEx.test(origValue)) {
        if (origValue.indexOf('.' !== -1)) {
          origValue = parseFloat(origValue);
        }

        valueToParse = `${origValue} ${toNumber(origValue) < 6 ? 'hours' : 'minutes'}`;
      }

      let duration = 0;
      try {
        duration = juration.parse(valueToParse || '1m');
      } catch (e) {
        // we intentionally swallow errors here. juration throws anytime the evaluated string doesn't parse successfully
      }
      if (duration > secondsInOneDay) {
        duration = secondsInOneDay;
      }
      onDurationChange(duration);
      setDurationText(e.target.value);
    },
    [onDurationChange]
  );

  return (
    <div className={`cps-form-group cp-ml-24 ${styles.editLabelAndInput}`}>
      <div className={styles.editLabel}>
        <label htmlFor="durationInput">Time spent</label>
        <span className={duration > 0 ? 'cps-color-primary' : 'cps-color-warning'}>*</span>
      </div>
      <div className={`${styles.dateTimeInput} cp-mb-0`}>
        {disableEdit ? (
          <input
            className="cps-form-control"
            id="durationInput"
            disabled={true}
            type="text"
            value={getDurationText(duration)}
          />
        ) : (
          <input
            className="cps-form-control"
            id="durationInput"
            onBlur={handleDurationBlur}
            onChange={handleDurationChange}
            onKeyUp={handleDurationEnterKey}
            placeholder="0h 0m"
            type="text"
            value={durationText}
          />
        )}
      </div>
    </div>
  );
}
