import React from 'react';
import { CpCard, currency } from 'canopy-styleguide!sofe';
import { CurrencyInput } from '../../payments/components/add-payment/currency-input.component';
import { formatNumberToCurrencyText } from './wip-report.helper';

import styles from './wip-report-create-invoice-modal.styles.css';

export const WipReportTargetedWuWd = ({ timeEntries, setTimeEntries, billingRate, grandTotal, billedTotal }) => {
  const handleBilledAmountChange = (id, value) => {
    setTimeEntries(
      timeEntries.map(entry => {
        if (entry.id === id) {
          return { ...entry, billedAmount: value };
        }
        return entry;
      })
    );
  };

  return (
    <CpCard className={styles.wipWuWdTargeted}>
      <CpCard.Header
        className={styles.wipWuWdTargetedHeader}
        style={{ display: 'grid', height: '40px', borderBottom: 'none' }}>
        <span className={styles.wipWuWdTargetedHeaderTitles}>Assignee/Service</span>
        <span className={styles.wipWuWdTargetedHeaderTitles}>Amount</span>
        <span className={styles.wipWuWdTargetedHeaderTitles}>WU/WD</span>
        <span className={styles.wipWuWdTargetedHeaderTitles}>Amount to be Billed</span>
      </CpCard.Header>
      <CpCard.Body className={styles.wipWuWdTargetedBody}>
        {timeEntries?.map(entry => {
          let totalAmount = billingRate === 'employee' ? entry.assignee_amount : entry.service_item_amount;
          return (
            <div key={entry.id} className={styles.wipWuWdTimeEntries}>
              <div style={{ textAlign: 'left' }}>
                <div>{entry.assignee?.name}</div>
                <div className={styles.wipWuWdGrayTextSmall}>{`${entry.duration}h${
                  entry.service_item ? ` on ${entry.service_item.name}` : ''
                }`}</div>
              </div>
              <span className={styles.wipWuWdTargetedBodyGrayText}>{currency(totalAmount)}</span>
              <span className={styles.wipWuWdTargetedBodyGrayText}>
                {formatNumberToCurrencyText(entry.billedAmount - totalAmount)}
              </span>
              <span />
              <CurrencyInput
                onChange={value => handleBilledAmountChange(entry.id, value)}
                value={entry.billedAmount}
                alignRight
              />
            </div>
          );
        })}
      </CpCard.Body>
      <CpCard.Footer className={styles.wipWuWdTargetedFooter} style={{ padding: '0 16px' }}>
        <span className={styles.wipWuWdTargetedBodyGrayText} style={{ textAlign: 'left' }}>
          Total:
        </span>
        <span className={styles.wipWuWdTargetedBodyGrayText}>{currency(grandTotal)}</span>
        <span className={styles.wipWuWdTargetedBodyGrayText}>
          {formatNumberToCurrencyText(billedTotal - grandTotal)}
        </span>
        <span className={styles.wipWuWdTargetedTotalAmount}>{currency(billedTotal)}</span>
      </CpCard.Footer>
    </CpCard>
  );
};
