import { TextCell, NumberCell, CurrencyCell, PercentCell } from '../common/cell-components';

const columnKeys = {
  ASSIGNEE: 'assignee',
  TOTAL_HOURS: 'total_hours',
  NON_BILLABLE_HOURS: 'non_billable_hours',
  BILLABLE_HOURS: 'billable_hours',
  BILLABLE_AMOUNT: 'billable_amount',
  ADJUSTED_HOURS: 'adjusted_hours',
  ADJUSTED_AMOUNT: 'adjusted_amount',
  DISCOUNTS: 'discounts',
  TOTAL_ADJUSTMENTS: 'total_adjustments',
  BILLED_AMOUNT: 'billed_amount',
  EFFECTIVE_RATE: 'effective_rate',
  PERCENT_INVOICED: 'percent_invoiced',
  PERCENT_BILLABLE: 'percent_billable',
};

const columnDefs = {
  [columnKeys.ASSIGNEE]: {
    key: 'user_name',
    label: 'Team Member',
    renderCell: TextCell,
  },
  [columnKeys.TOTAL_HOURS]: {
    key: 'total_hours',
    label: 'Total Hours',
    renderCell: NumberCell,
  },
  [columnKeys.NON_BILLABLE_HOURS]: {
    key: 'non_billable_hours',
    label: 'Non-billable Hours',
    renderCell: NumberCell,
  },
  [columnKeys.BILLABLE_HOURS]: {
    key: 'billable_hours',
    label: 'Billable Hours',
    renderCell: NumberCell,
  },
  [columnKeys.BILLABLE_AMOUNT]: {
    key: 'billable_amount',
    label: 'Billable Amount',
    renderCell: CurrencyCell,
  },
  [columnKeys.ADJUSTED_HOURS]: {
    key: 'adjusted_hours',
    label: 'Adjusted Hours',
    renderCell: NumberCell,
  },
  [columnKeys.ADJUSTED_AMOUNT]: {
    key: 'adjusted_amount',
    label: 'Adjusted Amount',
    renderCell: CurrencyCell,
  },
  [columnKeys.DISCOUNTS]: {
    key: 'discounts',
    label: 'Discounts',
    renderCell: CurrencyCell,
  },
  [columnKeys.TOTAL_ADJUSTMENTS]: {
    key: 'total_adjustments',
    label: 'Total Adjustments',
    renderCell: CurrencyCell,
  },
  [columnKeys.BILLED_AMOUNT]: {
    key: 'billed_amount',
    label: 'Billed Amount',
    renderCell: CurrencyCell,
  },
  [columnKeys.EFFECTIVE_RATE]: {
    key: 'effective_rate',
    label: 'Effective Rate',
    renderCell: CurrencyCell,
  },
  [columnKeys.PERCENT_INVOICED]: {
    key: 'percent_invoiced',
    label: 'Percent Invoiced',
    renderCell: PercentCell,
  },
  [columnKeys.PERCENT_BILLABLE]: {
    key: 'percent_billable',
    label: 'Percent Billable',
    renderCell: PercentCell,
  },
};

export const getUtilizationColumns = () => ({
  [columnKeys.ASSIGNEE]: columnDefs[columnKeys.ASSIGNEE],
  [columnKeys.TOTAL_HOURS]: columnDefs[columnKeys.TOTAL_HOURS],
  [columnKeys.BILLABLE_HOURS]: columnDefs[columnKeys.BILLABLE_HOURS],
  [columnKeys.BILLABLE_AMOUNT]: columnDefs[columnKeys.BILLABLE_AMOUNT],
  [columnKeys.ADJUSTED_HOURS]: columnDefs[columnKeys.ADJUSTED_HOURS],
  [columnKeys.ADJUSTED_AMOUNT]: columnDefs[columnKeys.ADJUSTED_AMOUNT],
  [columnKeys.DISCOUNTS]: columnDefs[columnKeys.DISCOUNTS],
  [columnKeys.TOTAL_ADJUSTMENTS]: columnDefs[columnKeys.TOTAL_ADJUSTMENTS],
  [columnKeys.BILLED_AMOUNT]: columnDefs[columnKeys.BILLED_AMOUNT],
  [columnKeys.EFFECTIVE_RATE]: columnDefs[columnKeys.EFFECTIVE_RATE],
  [columnKeys.PERCENT_INVOICED]: columnDefs[columnKeys.PERCENT_INVOICED],
});

export const getTimeUtilizationColumns = () => ({
  [columnKeys.ASSIGNEE]: columnDefs[columnKeys.ASSIGNEE],
  [columnKeys.TOTAL_HOURS]: columnDefs[columnKeys.TOTAL_HOURS],
  [columnKeys.NON_BILLABLE_HOURS]: columnDefs[columnKeys.NON_BILLABLE_HOURS],
  [columnKeys.BILLABLE_HOURS]: columnDefs[columnKeys.BILLABLE_HOURS],
  [columnKeys.PERCENT_BILLABLE]: columnDefs[columnKeys.PERCENT_BILLABLE],
  [columnKeys.BILLABLE_AMOUNT]: columnDefs[columnKeys.BILLABLE_AMOUNT],
});
