import { fetchAsObservable } from 'fetcher!sofe';

export function getReport(assignees, startDate, endDate, sortColumn, timeReport = false) {
  if (!startDate || !endDate) {
    throw new Error('start and end dates are required');
  }
  const userIds = assignees.map(assignee => assignee.id);
  return fetchAsObservable(`/api/billing/reports/${timeReport ? 'time' : 'billing'}-utilization?limit=400`, {
    method: 'POST',
    body: {
      filters: {
        ...(userIds.length && { user_ids: userIds }),
        start_date: startDate,
        end_date: endDate,
        order_by: sortColumn.order,
        sort_by: sortColumn.column,
      },
    },
  });
}

export function getReportCSV(assignees, startDate, endDate, sortColumn, timeReport = false) {
  if (!startDate || !endDate) {
    throw new Error('start and end dates are required');
  }
  const userIds = assignees.map(assignee => assignee.id);
  return fetchAsObservable(`/api/billing/reports/${timeReport ? 'time' : 'billing'}-utilization/csv`, {
    method: 'POST',
    body: {
      filters: {
        ...(userIds.length && { user_ids: userIds }),
        start_date: startDate,
        end_date: endDate,
        order_by: sortColumn.order,
        sort_by: sortColumn.column,
      },
    },
  });
}
