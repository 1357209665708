// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recurring-history-styles__recurringHistory--Y82zY {
  max-width: 736px;
}

.recurring-history-styles__header--iCKzP {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 4px;
}

.recurring-history-styles__title--DVv11 {
  border-bottom: 2.5px solid var(--cps-color-primary);
  padding: 8px 8px 4px 8px;
}

.recurring-history-styles__table--quarn {
  padding-left: 45px;
}

.recurring-history-styles__table--quarn th {
  color: #afafaf;
  font-size: 14px;
  font-weight: normal;
}
`, "",{"version":3,"sources":["webpack://./src/invoices/components/invoice-builder/recurring-history.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,mDAAmD;EACnD,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".recurringHistory {\n  max-width: 736px;\n}\n\n.header {\n  border-bottom: 1px solid #e9e9e9;\n  padding-bottom: 4px;\n}\n\n.title {\n  border-bottom: 2.5px solid var(--cps-color-primary);\n  padding: 8px 8px 4px 8px;\n}\n\n.table {\n  padding-left: 45px;\n}\n\n.table th {\n  color: #afafaf;\n  font-size: 14px;\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recurringHistory": `recurring-history-styles__recurringHistory--Y82zY`,
	"header": `recurring-history-styles__header--iCKzP`,
	"title": `recurring-history-styles__title--DVv11`,
	"table": `recurring-history-styles__table--quarn`
};
export default ___CSS_LOADER_EXPORT___;
