import React from 'react';
import { cloneDeep } from 'lodash';
// project
import { getAgedReceivableSummary } from './aged-receivables-summary.resource.js';
import NetworkRequest from '../common/network-request/network-request.component.js';
import AgedReceivableSummaryReport from './report/report.component.js';
import ReportWrapper from '../common/report-wrapper/report-wrapper.component.js';

const stateDefault = {
  ownerId: '',
};

export default class AgedReceivableSummary extends React.Component {
  constructor() {
    super();
    this.state = cloneDeep(stateDefault);
  }

  setClientOwner = (value, callback) => {
    this.setState({ ownerId: value }, callback?.());
  };

  render() {
    return (
      <ReportWrapper navigation={{ link: this.props.match.path, text: 'Aged Receivables Summary' }}>
        <div className="cps-flexible-focus">
          <div className="cps-card">
            <NetworkRequest observable={getAgedReceivableSummary} refreshEvent="billing-ui::payment-complete">
              <AgedReceivableSummaryReport setClientOwner={this.setClientOwner} ownerId={this.state.ownerId} />
            </NetworkRequest>
          </div>
        </div>
      </ReportWrapper>
    );
  }
}
