import { getAgedReceivableData, getAgedReceivableDataCSV } from '../common/receivables.resource.js';

export function getAgedReceivableSummary(ownerId) {
  const includeInvoices = false;
  const filters = {};
  if (ownerId) {
    filters.filter_by_owner_ids = [ownerId];
  }
  return getAgedReceivableData(includeInvoices, getSchemaOverrides(), filters);
}

export function getAgedReceivableSummaryCSV(ownerId) {
  const includeInvoices = false;
  const filters = {};
  if (ownerId) {
    filters.filter_by_owner_ids = [ownerId];
  }
  return getAgedReceivableDataCSV(includeInvoices, getSchemaOverrides(), filters);
}

function getSchemaOverrides() {
  return undefined;
}
