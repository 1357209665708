import React from 'react';
import AsyncDecorator from 'async-decorator/rx6';
import { fromEvent } from 'rxjs';
// sofe
import { CpLoader } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';

@AsyncDecorator
export default class NetworkRequest extends React.Component {
  state = {
    loading: false,
    error: false,
    results: undefined,
  };

  componentDidMount() {
    this.fetch();

    this.props.refreshEvent &&
      this.props.cancelWhenUnmounted(
        fromEvent(window, this.props.refreshEvent).subscribe(() => {
          this.fetch();
        })
      );
  }

  render() {
    let render;
    if (this.state.error) {
      render = <div className="cp-p-32 cps-subheader">Error making network request</div>;
    } else if (this.state.loading) {
      render = (
        <div className="cp-m-8">
          <CpLoader />
        </div>
      );
    } else if (this.state.results == undefined) {
      render = <div className="cp-p-32 cps-subheader">No Data</div>;
    } else {
      render = React.Children.map(this.props.children, child =>
        React.cloneElement(child, { results: this.state.results, ...child.props, fetch: this.fetch })
      );
    }
    return render;
  }

  fetch = options => {
    this.setState({ loading: true }, () => {
      this.props.observable(options).subscribe(
        results => this.setState({ results, loading: false }),
        e => {
          this.setState({ error: true });
          handleError(e);
        }
      );
    });
  };
}
