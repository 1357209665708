import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useCss, k } from 'kremling';
import { CpButton, CpInlineNotification, CpLoader, CpOverlay, CpToggle, CpIcon, CpWell } from 'canopy-styleguide!sofe';
import { cloneDeep } from 'lodash';
import { string, func, number } from 'prop-types';
import toasts from 'toast-service!sofe';

import { handleError } from 'src/common/handle-error.helper';
import { ClientNotes, CreditNumber, ClientName, DatePicker, Description, TermsConditions } from './form-components';
import { LineItems } from './line-items.component';
import { getNextCreditNumber, createCredit, getCredit, saveCredit } from 'src/resources/credits.resources';
import {
  getEmptyLineItem,
  calculateLineItemTotals,
  prepareCreditDTO,
  creditDtoToFormData,
} from './credit-builder.helper';
import { getClient } from 'src/resources/clients.resources';

export function CreditBuilder(props) {
  const { clientId, creditId, onClose, refreshKey, qboIntegrationInfo, thirdPartyId } = props;

  const scope = useCss(css);
  const [credit, setCredit] = useState({
    client: null,
    date: Date.now(),
    lineItems: [getEmptyLineItem()],
    description: '',
    creditNumber: '',
    clientNote: '',
    termsConditions: '',
  });
  const [loading, setLoading] = useState(false);
  const [loadingCredit, setLoadingCredit] = useState(creditId);
  const [saving, setSaving] = useState(false);
  const [syncCreditToIntegration, setSyncCreditToIntegration] = useState(false);
  const [syncedClient, setSyncedClient] = useState({ synced: false, loaded: false });

  const saveSubscription = useRef();
  const getClientSub = useRef();

  const hasIntegrationCredits =
    qboIntegrationInfo?.connected &&
    !!qboIntegrationInfo.payments?.sync_status?.first_synced_at &&
    !!qboIntegrationInfo.payments?.sync_status?.synced_at;

  useEffect(() => {
    return () => {
      saveSubscription.current?.unsubscribe();
      getClientSub.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (creditId) return;

    const subscription = getNextCreditNumber().subscribe(creditNumber => {
      setCredit({ ...credit, client: clientId ? { id: clientId } : null, creditNumber });
      setLoading(false);
    }, handleError);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!creditId || !credit.creditNumber) return;

    if (qboIntegrationInfo?.connected) {
      setSyncCreditToIntegration(!!thirdPartyId);
    }
  }, [creditId, qboIntegrationInfo?.connected, credit.id, credit.payment_id, thirdPartyId]);

  useEffect(() => {
    if (!creditId) return;
    const subscription = getCredit(creditId).subscribe(credit => {
      setCredit(creditDtoToFormData(credit));
      setLoadingCredit(false);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const fetchClient = clientId => {
    getClientSub.current = getClient(clientId).subscribe(client => {
      if (client) {
        setCredit({ ...credit, client: { id: client.id, name: client.name } });
        if (hasIntegrationCredits && !creditId) {
          setSyncCreditToIntegration(!!client.third_party_id);
        }
        setSyncedClient({ synced: !!client.third_party_id, loaded: true });
      }
    }, handleError);
  };

  useEffect(() => {
    if (credit?.client?.id) {
      fetchClient(credit.client.id);
    }
  }, [+credit?.client?.id, hasIntegrationCredits]);

  const updateCredit = newCreditData => {
    setCredit(prevCredit => ({ ...prevCredit, ...newCreditData }));
  };

  const addLineItem = () => {
    setCredit({ ...credit, lineItems: [...credit.lineItems, getEmptyLineItem()] });
  };

  const updateLineItem = (index, lineItem) => {
    const newLineItems = cloneDeep(credit.lineItems);
    const subTotal = lineItem.quantity * lineItem.rate;
    const tax = lineItem.taxRate ? (subTotal * lineItem.taxRate) / 100 : 0;
    const total = subTotal + tax;
    lineItem.subTotal = subTotal;
    lineItem.tax = tax;
    lineItem.total = total;
    newLineItems[index] = lineItem;
    setCredit({ ...credit, lineItems: newLineItems });
  };

  const removeLineItem = index => {
    if (credit.lineItems.length === 1) {
      setCredit({ ...credit, lineItems: [getEmptyLineItem()] });
      return;
    }
    const newLineItems = [...credit.lineItems];
    newLineItems.splice(index, 1);
    setCredit({ ...credit, lineItems: newLineItems });
  };

  const lineItemTotals = useMemo(() => {
    return calculateLineItemTotals(credit.lineItems);
  }, [credit.lineItems]);

  const isValidCredit = useMemo(() => {
    const { client, date, creditNumber } = credit;
    return client && date && creditNumber && lineItemTotals.sumTotal > 0;
  }, [credit]);

  const save = () => {
    setSaving(true);
    const creditDTO = prepareCreditDTO(credit, lineItemTotals, qboIntegrationInfo?.connected, syncCreditToIntegration);
    const apiCall = creditId ? saveCredit : createCredit;
    //TODO check for invoice_payments and refunds and convert to fixed strings when updating existing credits
    saveSubscription.current = apiCall(creditDTO).subscribe(response => {
      if (credit.creditNumber !== response.credit_number) {
        toasts.infoToast('Credit number was updated to the next usable number');
      }
      setSaving(false);
      onClose(true);
    });
  };

  return (
    <>
      <CpOverlay.Header title={creditId ? 'Edit Credit' : 'Create a Credit'}>
        <CpButton btnType="flat" onClick={() => onClose()} className="cp-mr-8">
          Cancel
        </CpButton>
        <CpButton btnType="primary" disabled={!isValidCredit} onClick={save} showLoader={saving}>
          Save credit
        </CpButton>
      </CpOverlay.Header>
      <CpOverlay.Body key={refreshKey}>
        <div {...scope} className="credit-builder-body">
          {loading || loadingCredit ? (
            <div>
              <CpLoader />
            </div>
          ) : (
            <>
              {(!creditId || credit.payment_id) &&
                !qboIntegrationInfo?.preferences?.SalesFormsPrefs?.AutoApplyCredit && (
                  <div className="cp-mb-16">
                    <CpInlineNotification
                      icon="information-circle-open-large"
                      message={
                        credit.payment_id
                          ? 'Line items for this credit cannot be modified because it was created from a payment'
                          : 'If there is a payment associated with the credit, it should be created as an additional payment.'
                      }
                      cta={
                        !credit.payment_id && (
                          <CpButton
                            btnType="secondary"
                            onClick={() => {
                              onClose('openPaymentModal', credit?.client?.id);
                            }}>
                            Create payment
                          </CpButton>
                        )
                      }
                    />
                  </div>
                )}
              {!creditId &&
                qboIntegrationInfo?.connected &&
                qboIntegrationInfo?.payments?.sync_status?.synced_at &&
                qboIntegrationInfo?.preferences?.SalesFormsPrefs?.AutoApplyCredit && (
                  <div className="cp-mb-16">
                    <CpInlineNotification
                      message="To avoid balance mismatches, log into QBO and navigate to Settings > Advanced. Turn OFF the automation setting titled “Automatically apply credits”"
                      cta={
                        <CpButton
                          btnType="secondary"
                          anchor
                          href="https://app.qbo.intuit.com/app/settings?p=advanced"
                          target="_blank">
                          Adjust setting
                        </CpButton>
                      }
                      type="error"
                    />
                  </div>
                )}
              <div className="credit-top">
                <div className="credit-top__details">
                  <div className="credit-builder-detail-row">
                    <ClientName value={credit.client} disabled={credit.payment_id} update={updateCredit} />
                    <DatePicker value={credit.date} disabled={credit.payment_id} update={updateCredit} />
                  </div>
                  <div className="credit-builder-detail-row">
                    <Description value={credit.description} update={updateCredit} />
                    <CreditNumber value={credit.creditNumber} update={updateCredit} />
                  </div>
                </div>

                <div className="credit-top__other">
                  {qboIntegrationInfo?.connected && !!qboIntegrationInfo.payments?.sync_status?.first_synced_at && (
                    <CpWell level={2} className="integration-sync-box">
                      <div className="cp-flex-center">
                        <img
                          src="https://cdn.canopytax.com/images/qbo_logo_small_circle.svg"
                          height="24px"
                          alt="QuickBooks Online Logo"
                        />
                        {creditId ? (
                          <div className="cp-ml-8">
                            <strong>{syncCreditToIntegration ? 'Credit is synced' : 'Credit is NOT synced'}</strong>
                          </div>
                        ) : (
                          <strong className="cp-ml-8">Sync Credit</strong>
                        )}
                      </div>
                      <div className="cp-flex-center">
                        {creditId ? (
                          <>
                            {!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                              <div className="cp-caption cp-flex-center">
                                <div style={{ width: '31rem' }}>
                                  <strong>Credit syncing is currently paused.</strong> Edits made to the credit during
                                  the "pause" will not sync to QBO.
                                </div>
                                <CpIcon name="misc-padlock" fill="var(--cp-color-app-icon)" className="cp-ml-8" />
                              </div>
                            )}
                            {!!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                              <div className="cp-caption cp-flex-center">
                                Sync status cannot be edited
                                <CpIcon name="misc-padlock" fill="var(--cp-color-app-icon)" className="cp-ml-8" />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="cp-flex-center">
                            {qboIntegrationInfo.payments?.sync_status?.synced_at &&
                              syncedClient.loaded &&
                              !syncedClient.synced && (
                                <div className="cp-caption cp-mr-8" style={{ width: '29.5rem' }}>
                                  This credit will not be able to sync to QBO because the selected client is currently
                                  not synced
                                </div>
                              )}
                            {!qboIntegrationInfo.payments?.sync_status?.synced_at && (
                              <div className="cp-caption cp-mr-8" style={{ width: '30rem' }}>
                                <strong>Credit syncing is currently paused.</strong> An admin may resume syncing in{' '}
                                <a href="/#/global-settings/billing/integrations" target="_blank">
                                  billing settings.
                                </a>
                              </div>
                            )}
                            {qboIntegrationInfo.payments?.sync_status?.synced_at && syncCreditToIntegration && (
                              <div className="cp-caption cp-mr-8" style={{ width: '30rem' }}>
                                Credits will be created in QBO as a credit memo
                              </div>
                            )}
                            <CpToggle
                              checked={syncCreditToIntegration}
                              disabled={
                                !syncedClient.loaded ||
                                !syncedClient.synced ||
                                !qboIntegrationInfo.payments?.sync_status?.synced_at
                              }
                              onChange={checked => {
                                setSyncCreditToIntegration(checked);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </CpWell>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="cp-divider cp-mb-16" />

          <div className="line-items">
            <LineItems
              credit={credit}
              addLineItem={addLineItem}
              updateLineItem={updateLineItem}
              removeLineItem={removeLineItem}
              lineItemTotals={lineItemTotals}
            />
          </div>

          <div className="notes-and-terms">
            <ClientNotes value={credit.clientNote} update={updateCredit} />
            <TermsConditions value={credit.termsConditions} update={updateCredit} />
          </div>
        </div>
      </CpOverlay.Body>
    </>
  );
}

CreditBuilder.propTypes = {
  clientId: string,
  creditId: string,
  onClose: func,
  refreshKey: number,
};

const css = k`
  .credit-builder-detail-row {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-bottom: 1.6rem;
  }

  .credit-builder-detail-row > *:first-child {
    flex-grow: 2;
  }

  .credit-builder-detail-row > *:last-child {
    width: 20rem;
  }
  
  .creditNavContent {
    margin-left: 268px !important;
    width: calc(100% - 268px) !important;
  }

  .credit-title {
    font-size: 2.0rem;
    font-weight: 600;
  }
  
  .credit {
    max-width: 102.4rem;
    margin: 0 auto;
    min-width: 92.4rem;
  }

  section {
    border-bottom: 1px solid var(--cp-color-app-border);
  }
  section:last-of-type {
    border-bottom: none;
  }
  
  .notes-and-terms {
    display: flex;
    align-items: flex-start;
    gap: 1.6rem;
  }
  
  .credit-top {
    display: flex;
    gap: 1.6rem;
  }
  
  .credit-top__details {
    flex-grow: 1;
  }
  
  .credit-top__other {
    width: 60%;
  }
  
  .credit-builder-body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
  .line-items {
    flex-grow: 1;
  }

  .notes-and-terms > * {
    flex-grow: 1;
  }

  .integration-sync-box {
    height: 6.6rem;
    padding: 0 2.4rem;
    margin-bottom: 1.6rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
