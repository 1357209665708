import React, { useMemo } from 'react';
import { every } from 'lodash';
import { DateTime } from 'luxon';
import { CurrencyInput } from 'src/payments/components/add-payment/currency-input.component';
import { convertToCurrencyText } from 'src/payments/payments.helper';
import { calculateCreditsTotal } from './invoice-builder.helper';

import styles from './available-credits.styles.css';

export const AvailableCredits = ({ credits, onCreditsChanged }) => {
  const allCreditsSelected = useMemo(() => every(credits, 'selected'), [credits]);
  const totalAppliedCredits = calculateCreditsTotal(credits);

  const handleAllCreditsSelected = isChecked => {
    let updatedCredits = [...credits].map(credit => {
      credit.selected = isChecked;
      if (isChecked && !credit.amountToApply) {
        credit.amountToApply = credit.available;
      } else if (!isChecked) {
        credit.amountToApply = '';
      }

      return credit;
    });

    onCreditsChanged(updatedCredits, true);
  };

  const handleCreditSelected = (id, isChecked) => {
    let updatedCredits = [...credits].map(credit => {
      if (credit.id === id) {
        credit.selected = isChecked;
        if (isChecked) {
          credit.amountToApply = credit.available;
        } else {
          credit.amountToApply = '';
        }
      }
      return credit;
    });

    onCreditsChanged(updatedCredits, true);
  };

  const handleAmountToApplyChanged = (id, amount, validate) => {
    let updatedCredits = [...credits].map(credit => {
      if (credit.id === id) {
        if (validate) {
          if (amount > credit.available) {
            credit.amountToApply = credit.available;
            if (credit.amountToApply > 0) {
              credit.selected = true;
            }
          } else if (amount > 0) {
            credit.amountToApply = amount;
            credit.selected = true;
          } else {
            credit.amountToApply = '';
            credit.selected = false;
          }
        } else {
          credit.amountToApply = amount;
        }
      }
      return credit;
    });

    onCreditsChanged(updatedCredits, validate);
  };

  return (
    <div className={styles.availableCredits}>
      <table>
        <thead>
          <tr style={{ lineHeight: '24px' }}>
            <td style={{ width: '30px' }}>
              <label className="cps-checkbox">
                <input
                  type="checkbox"
                  onChange={e => handleAllCreditsSelected(e.target.checked)}
                  checked={allCreditsSelected}
                />
                <span />
              </label>
            </td>
            <td style={{ width: '20%' }}>Credit #</td>
            <td style={{ width: '20%' }}>Date</td>
            <td style={{ width: '20%' }}>Description</td>
            <td style={{ width: '20%' }}>Available</td>
            <td style={{ width: '20%' }}>Amount to apply</td>
          </tr>
        </thead>
        <tbody>
          {credits.map(credit => {
            return (
              <tr
                key={credit.id}
                style={{
                  fontSize: '12px',
                  lineHeight: '36px',
                  verticalAlign: 'top',
                }}>
                <td>
                  <label className="cps-checkbox">
                    <input
                      type="checkbox"
                      onChange={e => handleCreditSelected(credit.id, e.target.checked)}
                      checked={credit.amountToApply > 0}
                    />
                    <span />
                  </label>
                </td>
                <td className="cps-ellipsis">{credit.credit_number}</td>
                <td>{DateTime.fromISO(credit.date).toLocaleString(DateTime.DATE_SHORT)}</td>
                <td>{credit.description}</td>
                <td>{convertToCurrencyText(credit.available)}</td>
                <td>
                  <div>
                    <CurrencyInput
                      value={credit.amountToApply}
                      onChange={(newValue, validate) => handleAmountToApplyChanged(credit.id, newValue, validate)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ width: '160px', paddingTop: '8px', textAlign: 'right' }}>
        <div>Credits Applied</div>
        <div className="cp-wt-bold">({convertToCurrencyText(totalAppliedCredits)})</div>
      </div>
    </div>
  );
};
