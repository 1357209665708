import juration from 'juration';
import { get, startsWith } from 'lodash';

export const timeFormatRegEx = /^([01]?[0-9]|2[0-3]):([0-9][0-9])(:([0-5][0-9]))?/g;
export const purelyNumericRegEx = /^(?!(?:\d|[.])*[A-Za-z]+)\d*[.]?\d*/;

export const roundingValues = {
  minute: 1,
  twentiethHour: 3,
  tenthHour: 6,
  quarterHour: 15,
  halfHour: 30,
  hour: 60,
};

export const roundToTheNearest = (numToRound, roundingKey = 'minute') => {
  let multiple = get(roundingValues, roundingKey) * 60;
  if (!multiple) {
    return numToRound;
  }

  let remainder = numToRound % multiple;
  if (!remainder) {
    return numToRound;
  }

  return numToRound + multiple - remainder;
};

export const getDurationText = duration => {
  let stringifiedDuration = '';
  try {
    stringifiedDuration = juration.stringify(duration, {
      format: 'micro',
    });
  } catch (e) {
    stringifiedDuration = 'Invalid value(s)';
  }
  return startsWith(stringifiedDuration, '-') ? 'Invalid value(s)' : stringifiedDuration;
};
