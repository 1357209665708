import React from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { invoiceTerms } from 'src/invoices/invoices.helper';

export const InvoiceTerms = ({ value, update, disabled }) => (
  <div className="cp-ml-16">
    <label>
      Terms<span className="cps-color-primary">*</span>
    </label>
    <div style={{ width: '160px' }}>
      <CpSelectSingle
        triggerIsBlock
        contentWidth="sm"
        fixedContent
        data={invoiceTerms}
        value={value}
        onChange={newTerms => update('terms', newTerms)}
        disabled={disabled}
      />
    </div>
  </div>
);
