import { TextCell, NumberCell, PercentCell } from '../common/cell-components';

const columnKeys = {
  TASK: 'task',
  CLIENT_NAME: 'client_name',
  TOTAL_HOURS: 'total_hours',
  BILLED_HOURS: 'billed_hours',
  BILLABLE_HOURS: 'billable_hours',
  PRODUCTIVITY: 'productivity',
  REALIZATION: 'realization',
};

const columnDefs = {
  [columnKeys.TASK]: {
    key: 'row_name',
    label: 'Task',
    renderCell: TextCell,
  },
  [columnKeys.CLIENT_NAME]: {
    key: 'client_name',
    label: 'Client',
    renderCell: TextCell,
  },
  [columnKeys.TOTAL_HOURS]: {
    key: 'total_hours',
    label: 'Total Hours',
    renderCell: NumberCell,
  },
  [columnKeys.BILLABLE_HOURS]: {
    key: 'billable_hours',
    label: 'Billable Hours',
    renderCell: NumberCell,
  },
  [columnKeys.BILLED_HOURS]: {
    key: 'billed_hours',
    label: 'Billed Hours',
    renderCell: NumberCell,
  },
  [columnKeys.PRODUCTIVITY]: {
    key: 'productivity',
    label: 'Productivity',
    renderCell: PercentCell,
  },
  [columnKeys.REALIZATION]: {
    key: 'realization',
    label: 'Realization',
    renderCell: PercentCell,
  },
};

export const getStaffProductivityColumns = () => ({
  [columnKeys.TASK]: columnDefs[columnKeys.TASK],
  [columnKeys.CLIENT_NAME]: columnDefs[columnKeys.CLIENT_NAME],
  [columnKeys.TOTAL_HOURS]: columnDefs[columnKeys.TOTAL_HOURS],
  [columnKeys.BILLABLE_HOURS]: columnDefs[columnKeys.BILLABLE_HOURS],
  [columnKeys.BILLED_HOURS]: columnDefs[columnKeys.BILLED_HOURS],
  [columnKeys.PRODUCTIVITY]: columnDefs[columnKeys.PRODUCTIVITY],
  [columnKeys.REALIZATION]: columnDefs[columnKeys.REALIZATION],
});
