import React from 'react';
import { CpInput } from 'canopy-styleguide!sofe';
import { wholeNumberFilter } from 'src/billing-helpers.js';

export const EndAfter = ({ value, engagement, update, disabled, started }) => (
  <div className={engagement ? 'cp-ml-16' : 'cp-ml-8'}>
    <label>&nbsp;</label>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ whiteSpace: 'nowrap' }}>{`# of invoices${started ? ' remaining' : ''}`}</span>
      <div className="cp-ml-8" style={{ width: engagement ? '40px' : '60px' }}>
        <CpInput
          value={value}
          onChange={value => update('endAfter', wholeNumberFilter(value))}
          onBlur={e => e.target.value === '' && update('endAfter', '1')}
          disabled={disabled}
          maxLength="2"
        />
      </div>
    </div>
  </div>
);
