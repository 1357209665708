import React from 'react';
import { CprDatepicker } from 'canopy-styleguide!sofe';

export const DueDate = ({ value, update, terms, disabled, errors }) => (
  <div className={`${errors.includes('invalidDueDate') ? 'cps-has-error' : ''} cp-ml-16`}>
    <label>
      Due Date<span className="cps-color-primary">*</span>
    </label>
    <CprDatepicker
      date={value}
      events={{ datechange: date => update('dueDate', date.detail) }}
      disabled={disabled || terms.id >= 0}
    />
    <span style={{ position: 'absolute', marginTop: '4px' }} className="cps-error-block">
      Due date must be on or after the invoice date.
    </span>
  </div>
);
