import React from 'react';
// project
import CustomizeModal from './customize-modal/customize-modal.component.js';
import CustomizeModalContents from './customize-modal/customize-modal-contents.component.js';
// sofe
import { isEqual } from 'lodash';
import { CpButton, CpTooltip } from 'canopy-styleguide!sofe';

export default class CustomizeArReportOption extends React.Component {
  state = {
    showModalContents: false,
  };

  render() {
    const filtered = !isEqual(this.props.customizeReportSettings, this.props.stateDefault);
    return (
      <div>
        {filtered && (
          <div
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '4px',
              background: 'var(--cps-color-primary)',
              border: '1px solid white',
              position: 'absolute',
              marginTop: '4px',
              marginLeft: '4px',
            }}
          />
        )}
        <CpTooltip text="Customize">
          <CpButton aria-label="Customize" icon="misc-funnel" onClick={this.showModal} />
        </CpTooltip>
        <CustomizeModal show={this.state.showModalContents} onClose={this.onModalHide}>
          <CustomizeModalContents
            customizeReportSettings={this.props.customizeReportSettings}
            bucket_schema={this.props.bucket_schema}
            stateDefault={this.props.stateDefault}
            checkIfEqual={this.checkIfEqual}
            clientsFromResponse={this.props.clientsFromResponse}
          />
        </CustomizeModal>
      </div>
    );
  }

  showModal = () => {
    this.setState({ showModalContents: !this.state.showModalContents });
  };

  onModalHide = filterChanges => {
    this.setState({ showModalContents: false }, () => {
      if (filterChanges) {
        // user did not click cancel
        this.props.refetch(filterChanges);
      }
    });
  };
}
