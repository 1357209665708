import React from 'react';
import { CpInput } from 'canopy-styleguide!sofe';
import { wholeNumberFilter } from 'src/billing-helpers.js';

export const RepeatInterval = ({ value, engagement, update, frequencyOption, disabled }) => (
  <div className={engagement ? 'cp-ml-16' : 'cp-ml-8'}>
    <label>&nbsp;</label>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {frequencyOption === 'Monthly' ? <span style={{ whiteSpace: 'nowrap' }}>of every</span> : <span>every</span>}
      <div className="cp-ml-8" style={{ width: engagement ? '40px' : '60px' }}>
        <CpInput
          value={value}
          onChange={value => update('repeatInterval', wholeNumberFilter(value))}
          onBlur={e => e.target.value === '' && update('repeatInterval', '1')}
          disabled={disabled}
          maxLength="2"
        />
      </div>
      {frequencyOption === 'Daily' && <span className="cp-ml-8">day(s)</span>}
      {frequencyOption === 'Weekly' && <span className="cp-ml-8">week(s) on</span>}
      {frequencyOption === 'Monthly' && <span className="cp-ml-8">month(s)</span>}
    </div>
  </div>
);
