import { pluck } from 'rxjs/operators';
import { fetchAsObservable } from 'fetcher!sofe';
import { DateTime } from 'luxon';

export function getWIPDashboard(filters, page, limit = 50, groupBy = 'client') {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const sort = Object.keys(filters).find(key => filters[key]?.order);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      assignee_ids: filters.userIds,
      client_ids: filters.clientIds,
      ...(groupBy === 'client' && filters.clientGroupId && { client_group_ids: [filters.clientGroupId] }),
      ...(groupBy === 'client_group' && { client_groups: filters.clientGroupIds }),
      client_owner_ids: filters.clientOwnerIds,
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
      sort_by: sort,
      order: filters[sort]?.order || 'asc',
    },
  };

  return fetchAsObservable(
    `/api/billing/wip_dashboard${groupBy === 'client_group' ? '/client_groups' : ''}?page=${page}&limit=${limit}`,
    {
      method: 'POST',
      body,
    }
  ).pipe(pluck('wip_dashboard'));
}

export function getWIPTeamMembers(filters, search) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      search_string: search,
      client_ids: filters.clientIds,
      client_group_ids: filters.clientGroupIds,
      client_owner_ids: filters.clientOwnerIds,
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
    },
  };
  return fetchAsObservable('/api/billing/wip_dashboard/filters/team-members?page=1&limit=50', {
    method: 'POST',
    body,
  }).pipe(pluck('team_members'));
}

export function getWIPClients(filters, search) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      search_string: search,
      assignee_ids: filters.userIds,
      client_owner_ids: filters.clientOwnerIds,
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
    },
  };
  return fetchAsObservable('/api/billing/wip_dashboard/filters/clients?page=1&limit=50', {
    method: 'POST',
    body,
  }).pipe(pluck('clients'));
}

export function getWIPClientGroups(filters, search) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      search_string: search,
      assignee_ids: filters.userIds,
      client_ids: filters.clientIds,
      client_owner_ids: filters.clientOwnerIds,
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
    },
  };
  return fetchAsObservable('/api/billing/wip_dashboard/filters/client_groups?page=1&limit=50', {
    method: 'POST',
    body,
  }).pipe(pluck('client_groups'));
}

export function getWIPClientOwners(filters, search) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      search_string: search,
      assignee_ids: filters.userIds,
      client_ids: filters.clientIds,
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
    },
  };
  return fetchAsObservable('/api/billing/wip_dashboard/filters/client_owners?page=1&limit=50', {
    method: 'POST',
    body,
  }).pipe(pluck('client_owners'));
}
export function getWIPServiceItems(filters, search) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      search_string: search,
      assignee_ids: filters.userIds,
      client_ids: filters.clientIds,
      client_group_ids: filters.clientGroupIds,
      client_owner_ids: filters.clientOwnerIds,
      show_auto_linked: filters.showAutoLinked,
    },
  };
  return fetchAsObservable('/api/billing/wip_dashboard/filters/service_items?page=1&limit=50', {
    method: 'POST',
    body,
  }).pipe(pluck('service_items'));
}

export function exportWIPCSV(filters, clientGroupId, groupBy) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const sort = Object.keys(filters).find(key => filters[key].order);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      assignee_ids: filters.userIds,
      client_ids: filters.clientIds,
      client_group_ids: clientGroupId ? [clientGroupId] : filters.clientGroupIds,
      client_owner_ids: filters.clientOwnerIds,
      group_by: groupBy?.id === 'client_group' || clientGroupId ? 'client_group' : 'client',
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
      sort_by: sort,
      order: filters[sort]?.order || 'asc',
    },
  };

  return fetchAsObservable('/api/billing/wip_dashboard/csv', {
    method: 'POST',
    body,
  });
}

export function printWIP(filters, clientGroupId, groupBy) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const sort = Object.keys(filters).find(key => filters[key].order);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      assignee_ids: filters.userIds,
      client_ids: filters.clientIds,
      client_group_ids: clientGroupId ? [clientGroupId] : filters.clientGroupIds,
      client_owner_ids: filters.clientOwnerIds,
      group_by: groupBy?.id === 'client_group' || clientGroupId ? 'client_group' : 'client',
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
      sort_by: sort,
      order: filters[sort]?.order || 'asc',
    },
  };

  return fetchAsObservable('/api/billing/wip_dashboard/pdf', {
    method: 'POST',
    body,
    responseType: 'blob',
  });
}

export function scheduleWIP(filters, clientGroupId, groupBy) {
  const dateFilter = adjustBeforeDate(filters.dateRange);
  const sort = Object.keys(filters).find(key => filters[key].order);
  const body = {
    filters: {
      start_date: dateFilter.after,
      end_date: dateFilter.before,
      assignee_ids: filters.userIds,
      client_ids: filters.clientIds,
      client_group_ids: clientGroupId ? [clientGroupId] : filters.clientGroupIds,
      client_owner_ids: filters.clientOwnerIds,
      group_by: groupBy?.id === 'client_group' || clientGroupId ? 'client_group' : 'client',
      service_item_ids: filters.serviceItemIds,
      show_auto_linked: filters.showAutoLinked,
      sort_by: sort,
      order: filters[sort]?.order || 'asc',
    },
  };

  return fetchAsObservable('/api/billing/wip_dashboard/schedule_pdf', {
    method: 'POST',
    body,
  });
}

export function getWIPTimeEntries(searchParams, page, limit = 50) {
  return fetchAsObservable(`/api/billing/wip_time_entries?page=${page}&limit=${limit}`, {
    method: 'POST',
    body: { filters: adjustEndDate(searchParams) },
  }).pipe(pluck('wip_time_entries'));
}

export function exportWIPTimeEntriesCSV(searchParams) {
  return fetchAsObservable('/api/billing/wip_time_entries/csv', {
    method: 'POST',
    body: { filters: adjustEndDate(searchParams) },
  });
}

export function getWIPColumnFilter(column, searchParams) {
  return fetchAsObservable(`/api/billing/wip_time_entries_search_filter/${column}`, {
    method: 'POST',
    body: { filters: searchParams },
  }).pipe(pluck(column));
}

export function getWIPInvoices(clientId, clientGroupId, dateRange, page = 1) {
  const dateFilter = adjustBeforeDate(dateRange);
  return fetchAsObservable(
    `/api/wip/invoices/${clientGroupId ? `client_group/${clientGroupId}` : clientId}?page=${page}&start_date=${
      dateFilter.after
    }&end_date=${dateFilter.before}&limit=50&recurrence_only=false`
  );
}

function adjustEndDate(searchParams) {
  const params = { ...searchParams };
  if (params.start_date) {
    if (!params.end_date) {
      params.end_date = params.start_date;
    }
    params.end_date = DateTime.fromISO(params.end_date).plus({ days: 1 }).toISODate();
  }
  return params;
}

export function adjustBeforeDate(dateFilter) {
  const params = { ...dateFilter };
  if (params.after) {
    if (!params.before) {
      params.before = params.after;
    }
    params.before = DateTime.fromISO(params.before).plus({ days: 1 }).toISODate();
  }
  return params;
}
