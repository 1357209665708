import { pluck } from 'rxjs/operators';
import { fetchAsObservable } from 'fetcher!sofe';

export function getServiceItems({ includeArchived = false, onlyTrashed = false, rateType } = {}) {
  return fetchAsObservable(
    `/api/invoices/service-codes${includeArchived ? '?includeArchived=true' : ''}${
      onlyTrashed ? '?onlyTrashed=true' : ''
    }${rateType ? `?type=${rateType}` : ''}`
  ).pipe(pluck('items'));
}

export function createServiceItem(serviceItem) {
  return fetchAsObservable('/api/invoices/service-codes', {
    method: 'POST',
    body: {
      items: {
        ...serviceItem,
        amount: serviceItem.amount || 0,
        tax_rate_percent: parseFloat(serviceItem.tax_rate_percent) || 0,
      },
    },
  });
}

export function updateServiceItem(serviceItem) {
  return fetchAsObservable(`/api/invoices/service-codes/${serviceItem.id}`, {
    method: 'PUT',
    body: {
      items: {
        ...serviceItem,
        amount: serviceItem.amount || 0,
        tax_rate_percent: parseFloat(serviceItem.tax_rate_percent) || 0,
      },
    },
  });
}

export function archiveServiceItems(items) {
  const ids = { items: items.map(item => item.id) };

  return fetchAsObservable('/api/invoices/service-codes/archive', {
    method: 'POST',
    body: ids,
  });
}

export function restoreServiceItems(items) {
  const ids = { items: items.map(item => item.id) };

  return fetchAsObservable('/api/invoices/service-codes/unarchive', {
    method: 'POST',
    body: ids,
  });
}

export function checkServiceItemUnique(param, value) {
  return fetchAsObservable(`/api/invoices/service-codes/check?${param}=${encodeURIComponent(value)}`);
}
