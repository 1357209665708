import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getTasks } from 'src/resources/tasks.resources.js';

export default function TimeTaskSearch({ client, task, onTaskChanged, disabled, fixedContent }) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedTask, setSelectedTask] = useState(task);
  const [tasks, setTasks] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsSearching(true);
  }, [client]);

  useEffect(() => {
    setSelectedTask(task);
  }, [task]);

  const onSelectedTaskChanged = task => {
    setSelectedTask(task);
    onTaskChanged(task ? { id: task.id, name: task.name } : null);
  };

  useEffect(() => {
    if (!isSearching || !isOpen) return;
    const taskSubs = getTasks(searchValue, client?.id, true).subscribe(results => {
      setTasks(results);
      setIsSearching(false);
    }, handleError);

    return () => {
      taskSubs.unsubscribe();
    };
  }, [isSearching, isOpen]);

  const debounceSearchForTask = useCallback(
    debounce(() => setIsSearching(true), 400),
    []
  );

  const searchOnChange = useCallback(search => {
    setSearchValue(search);
    debounceSearchForTask();
  }, []);

  return (
    <CpSelectSingle
      clearable
      contentWidth="block"
      data={tasks}
      disabled={disabled}
      loading={isSearching}
      fixedContent={fixedContent}
      onChange={onSelectedTaskChanged}
      onOpen={() => {
        setIsOpen(true);
        setIsSearching(true);
      }}
      onClose={() => setIsOpen(false)}
      placeholder="Search for a task"
      searchFilter
      searchOnChange={searchOnChange}
      searchValue={searchValue}
      triggerIsBlock
      value={selectedTask}
    />
  );
}
