import React from 'react';
import GenericTimeReport from './generic-time-report.component.js';

export default function HoursByClientReport() {
  return (
    <GenericTimeReport
      title={'Hours Tracked by Client'}
      reportUrl={'client_hours'}
      isCurrency={false}
      leftColumnTitle={`Client`}
      leftColumnLink={`/time/entries`}
    />
  );
}
