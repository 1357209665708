import * as React from 'react';
import styles from './columns-and-rows.styles.css';
import computeSchemaValue from 'src/reports/common/schema-items/schema-items.helper';

export default class ColumnsAndRowsSection extends React.Component {
  state = {};

  handleClick = (key, value) => {
    this.props.updateCheckbox(key, value);
  };

  render() {
    const { bucket_schema, checkboxes } = this.props;
    const { general, numeric } = checkboxes;

    const Checkbox = props => {
      let [key, value] = Object.entries(props.value)[0];
      return (
        <label className="cps-checkbox">
          <input type="checkbox" value={value} checked={value} onChange={() => this.handleClick(key, !value)} />
          <span>{props.label}</span>
        </label>
      );
    };

    return (
      <div className={`${styles.outerContainer}`}>
        <div className={`${styles.firstRow}`}>
          <div className={`${styles.clientInformation} ${styles.section}`}>
            <div className={`cps-wt-semibold`}>Client information</div>
            <div className={`${styles.checkboxesContainer}`}>
              <Checkbox value={{ primaryEmail: general.get('primaryEmail') }} label="Primary email" />
              <Checkbox value={{ primaryPhone: general.get('primaryPhone') }} label="Primary phone" />
            </div>
          </div>
          <div className={`${styles.invoiceInformation} ${styles.section}`}>
            <div className={`cps-wt-semibold`}>Invoice Information</div>
            <div className={`${styles.checkboxesContainer}`}>
              <Checkbox value={{ invoiceDate: general.get('invoiceDate') }} label="Invoice date" />
              <Checkbox value={{ invoiceTotal: numeric.get('invoiceTotal') }} label="Invoice total" />
              <Checkbox value={{ terms: general.get('terms') }} label="Terms" />
            </div>
          </div>
        </div>
        <div className={`${styles.secondRow}`}>
          <div className={`${styles.status} ${styles.section}`}>
            <div className={`cps-wt-semibold`}>Status</div>
            <div className={`${styles.checkboxesContainer}`}>
              <Checkbox value={{ current: numeric.get('current') }} label={computeSchemaValue(bucket_schema[0])} />
              <Checkbox
                value={{ col1: numeric.get('col1') }}
                label={`${computeSchemaValue(bucket_schema[1])} past due`}
              />
              <Checkbox
                value={{ col2: numeric.get('col2') }}
                label={`${computeSchemaValue(bucket_schema[2])} past due`}
              />
              <Checkbox
                value={{ col3: numeric.get('col3') }}
                label={`${computeSchemaValue(bucket_schema[3])} past due`}
              />
              <Checkbox
                value={{ col4: numeric.get('col4') }}
                label={`${computeSchemaValue(bucket_schema[4])} past due`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
