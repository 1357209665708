import { DateTime } from 'luxon';

export const timerStates = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const getElapsedFromDurationStart = durationStart => {
  const totalSeconds = DateTime.local().diff(DateTime.fromISO(durationStart), 'seconds').seconds;
  return totalSeconds > secondsInOneDay ? secondsInOneDay : totalSeconds;
};

export const getDurationStartFromElapsed = elapsed => {
  return DateTime.utc().minus({ seconds: elapsed }).toISO();
};

export const sortTimers = (a, b) => {
  if (a.isNew) {
    return -1;
  }
  if (b.isNew) {
    return 1;
  }
  if (a.active) {
    return -1;
  }
  if (b.active) {
    return 1;
  }
  const lastAModified = DateTime.fromISO(a.durationStart).plus({ seconds: a.elapsed });
  const lastBModified = DateTime.fromISO(b.durationStart).plus({ seconds: b.elapsed });
  return lastBModified.diff(lastAModified);
};

export const timerObjFromTimerDto = timerDto => {
  return {
    id: timerDto.id,
    active: timerDto.data.timerState === timerStates.ACTIVE,
    durationStart: timerDto.data.durationStart,
    elapsed: timerDto.data.elapsedTime,
    description: timerDto.data.timerDesc,
    client: timerDto.data.client,
    service: timerDto.data.service_item,
    task: timerDto.data.task,
    subtask: timerDto.data.subtask,
  };
};

export const timerDtoFromTimerObj = timer => {
  return {
    durationStart: timer.durationStart,
    timerState: timer.active ? timerStates.ACTIVE : timerStates.INACTIVE,
    timerDesc: timer.description,
    elapsedTime: Math.round(timer.elapsed),
    client: timer.client || undefined,
    service_item: timer.service || undefined,
    task: timer.task || undefined,
    subtask: timer.subtask || undefined,
  };
};

export const secondsInOneDay = 86400;

export const helpText = 'This timer was automatically stopped at 24 hours.';

export const getTimeDisplayString = timeInHours => {
  const hours = parseInt(timeInHours || 0);
  const minutes = Math.round((parseFloat(timeInHours || 0) % 1) * 60);
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};
