// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.due-date-styles__outerClass--dAJKI {
  display: inline-block;
}

.due-date-styles__flexContainer--gdA7F {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.due-date-styles__labelContainer--bs6qR {
  width: 100px;
}

.due-date-styles__label--dYMfx {
  margin-left: 24px;
}

.due-date-styles__datePicker--KebAL {
  width: 98px;
  margin-left: 19px;
}`, "",{"version":3,"sources":["webpack://./src/reports/common/report-title/customize-option/customize-modal/due-date/due-date.styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".outerClass {\n  display: inline-block;\n}\n\n.flexContainer {\n  display: flex;\n  align-items: center;\n  margin: 15px 0;\n}\n\n.labelContainer {\n  width: 100px;\n}\n\n.label {\n  margin-left: 24px;\n}\n\n.datePicker {\n  width: 98px;\n  margin-left: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerClass": `due-date-styles__outerClass--dAJKI`,
	"flexContainer": `due-date-styles__flexContainer--gdA7F`,
	"labelContainer": `due-date-styles__labelContainer--bs6qR`,
	"label": `due-date-styles__label--dYMfx`,
	"datePicker": `due-date-styles__datePicker--KebAL`
};
export default ___CSS_LOADER_EXPORT___;
