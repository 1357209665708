import React, { useContext } from 'react';
import { CpTooltip, CpDropdown, CpIcon, CpButton } from 'canopy-styleguide!sofe';
import { FilterContext } from 'src/common/filter-context';
import { hasSortOrFilter, filterTypes } from './columns.helpers';
import { MenuContent } from './menu-content.component';

import styles from './table-header.styles.css';

export const TableHeader = ({
  column,
  getDynamicFilters,
  getFixedFilters,
  maxWidth,
  isClientGroup,
  onSelectAll,
  disabled,
  checked,
}) => {
  const filterContext = useContext(FilterContext);
  const allowFilterSort = !!column.sortParam;
  const columnFilter = allowFilterSort ? filterContext.filters[column.sortParam] : null;

  const onMenuClick = toggleMenu => {
    if (!allowFilterSort) {
      return;
    }
    if (column.filterType === filterTypes.None) {
      column.sortParam && sortColumn();
    } else {
      toggleMenu();
    }
  };

  const sortColumn = () => {
    filterContext.applyFilter(column.sortParam, {
      order: columnFilter && columnFilter.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const resetFilters = e => {
    e.stopPropagation();
    filterContext.applyFilter(column.sortParam, {});
  };

  return (
    <th
      style={{ maxWidth: `${maxWidth}px`, whiteSpace: 'nowrap' }}
      className={` ${styles.columnHeader} ${!allowFilterSort ? styles.columnHeaderNoHover : ''} ${
        hasSortOrFilter(columnFilter) ? 'cps-bg-color-chrome' : ''
      }`}>
      <CpDropdown
        allowContentClicks
        fixedContent
        triggerIsBlock
        contentWidth={column.filterType === filterTypes.DateRange ? 496 : 'md'}
        renderTrigger={({ toggle, close }) => (
          <div
            className={`${styles.dropdownTrigger}`}
            style={{
              justifyContent: ['Unbilled Revenue'].includes(column.columnLabel)
                ? 'flex-end'
                : column.columnLabel === 'Auto-link'
                ? 'flex-start'
                : '',
            }}
            onClick={() => onMenuClick(toggle)}>
            {isClientGroup && column.columnLabel === 'Client' ? (
              <div className="flex items-center">
                <label className="cps-checkbox cp-ml-12" onClick={e => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    onChange={onSelectAll}
                    checked={checked}
                    disabled={disabled}
                    onClick={e => e.stopPropagation()}
                  />
                  <span />
                </label>
                <span className="cp-ml-36">{column.columnLabel}</span>
              </div>
            ) : (
              <span>{column.columnLabel}</span>
            )}
            {allowFilterSort && columnFilter?.order && (
              <CpIcon name={`caret-large-${columnFilter.order === 'asc' ? 'up' : 'down'}`} />
            )}
            {allowFilterSort && hasSortOrFilter(columnFilter) && (
              <CpTooltip text="Clear sort and filter">
                <CpButton
                  icon="close-circle-filled"
                  aria-label="Clear"
                  onClick={e => {
                    close();
                    resetFilters(e);
                  }}
                />
              </CpTooltip>
            )}
            {column.columnLabel === 'Unbilled Revenue' && (
              <CpTooltip
                text={
                  <div className="cp-text-center">
                    Unbilled Revenue is based on the hourly <br /> rates you have supplied in Settings <br /> and
                    includes billable expenses
                  </div>
                }
                position="bottom-end">
                <CpIcon className="cp-ml-12" name="information-circle-open-large" />
              </CpTooltip>
            )}
            {column.columnLabel === 'Auto-link' && (
              <CpTooltip
                text={
                  <div className="cp-text-center">
                    Adding auto-linked time to <br />
                    an invoice will prevent the <br />
                    time from auto-linking to <br />
                    its scheduled invoice.
                  </div>
                }>
                <CpIcon className="cp-ml-12" name="information-circle-open-large" fill="var(--cp-color-app-icon)" />
              </CpTooltip>
            )}
          </div>
        )}
        renderContent={({ close, isOpen }) => (
          <MenuContent
            filterContext={filterContext}
            column={column}
            getDynamicFilters={getDynamicFilters}
            getFixedFilters={getFixedFilters}
            onCancel={close}
            onApply={close}
            isOpen={isOpen}
          />
        )}
      />
    </th>
  );
};
