export const clientTabKeys = {
  dashboard: 'dashboard',
  time: 'time',
  invoices: 'invoices',
  payments: 'payments',
  credits: 'credits',
  expenses: 'expenses',
  statements: 'statements',
  paymentSettings: 'payment-settings',
};

export const subTabKeys = {
  time: 'time/entries',
  productivity: 'time/productivity',
  collected: 'payments/collected',
  upcoming: 'payments/upcoming',
  recurring: 'payments/recurring',
  refunds: 'payments/refunds',
};

export const getTimeTab = (allowProductivityDashboard, userHasTasksOrNotices) => ({
  [clientTabKeys.time]: {
    label: 'Time Entries',
    icon: 'misc-timer',
    subTabs: {
      [subTabKeys.time]: { label: 'Time Entries' },
      ...(allowProductivityDashboard &&
        userHasTasksOrNotices && { [subTabKeys.productivity]: { label: 'Productivity' } }),
    },
  },
});

export const getPaymentsTab = (hasCanopyPayments, paymentsMeta, hasAdyen, teamCanKeyInCards) => ({
  [clientTabKeys.payments]: {
    label: 'Payments',
    icon: 'billing-hand-payments',
    subTabs: {
      [subTabKeys.collected]: { label: 'Collected' },
      ...(hasCanopyPayments && {
        [subTabKeys.upcoming]: { label: 'Upcoming' },
        ...(((hasAdyen && teamCanKeyInCards) || !hasAdyen) && {
          [subTabKeys.recurring]: { label: 'Recurring' },
        }),
      }),
      ...(paymentsMeta.has_refunds && { [subTabKeys.refunds]: { label: 'Refunds' } }),
    },
  },
});
