import React from 'react';
import { CpInput } from 'canopy-styleguide!sofe';

export const Description = ({ value, update, disabled }) => (
  <div>
    <CpInput
      label="Description"
      value={value}
      onChange={description => update({ description })}
      disabled={disabled}
      placeholder="Add description"
      maxLength="150"
    />
  </div>
);
