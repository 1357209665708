import React from 'react';
import GenericTimeReport from './generic-time-report.component.js';

export default function RevenueByServiceItemReport() {
  return (
    <GenericTimeReport
      title={'Revenue by Service Item'}
      reportUrl={'service_item_revenue'}
      isCurrency={true}
      leftColumnTitle={`Service Item`}
    />
  );
}
