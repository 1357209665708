import React from 'react';
// sofe
import { NavContent } from 'primary-navbar!sofe';

const ReportWrapper = props => {
  return (
    <NavContent hasTopnavSecondary={true} clientMenuPossible={false}>
      <div className="cp-pt-16 cp-pb-16">{props.children}</div>
    </NavContent>
  );
};

export default ReportWrapper;
