import React from 'react';
import { CpModal, CpEmptyState } from 'canopy-styleguide!sofe';

export const PdfTronFileError = ({ showError }) => {
  return (
    <CpModal show={showError} className="cp-pv-24 cp-ph-40" onClose={() => {}}>
      <CpEmptyState
        img="es_caution"
        text="Something went wrong while previewing this file."
        subText="Please try reloading the page."
      />
    </CpModal>
  );
};
