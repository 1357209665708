import React, { useState, useMemo } from 'react';
import { DateTime } from 'luxon';
import { CpIcon } from 'canopy-styleguide!sofe';
import { convertToCurrencyText } from 'src/payments/payments.helper';

export const getReconciledPayments = (invoices, paymentId) => {
  let reconcilerList = [];
  for (const invoice of invoices) {
    const sortedScheduledPayments = (invoice.scheduled_payments || [])
      .filter(p => p.next_occurrence && p.recurrence_id !== paymentId)
      .sort((a, b) => DateTime.fromISO(a.next_occurrence) - DateTime.fromISO(b.next_occurrence));

    if (sortedScheduledPayments.length === 0) break;

    let remainingBalance = invoice.balance - parseFloat(invoice.amountToPay);
    let paidOff = remainingBalance <= 0;
    let paymentIndex = 0;

    while (!paidOff) {
      let payment = sortedScheduledPayments[paymentIndex];
      const paymentAmount = parseFloat(payment.amount);
      if (paymentAmount > remainingBalance) {
        reconcilerList.push({
          invoiceNum: invoice.invoice_number,
          payDate: payment.next_occurrence,
          originalAmount: paymentAmount,
          newAmount: remainingBalance,
        });
      }
      paymentIndex++;
      if (paymentIndex >= sortedScheduledPayments.length) break;
      remainingBalance -= paymentAmount;
      paidOff = remainingBalance <= 0;
    }

    for (let i = paymentIndex; i < sortedScheduledPayments.length; i++) {
      let payment = sortedScheduledPayments[i];
      reconcilerList.push({
        invoiceNum: invoice.invoice_number,
        payDate: payment.next_occurrence,
        originalAmount: parseFloat(payment.amount),
        newAmount: 0,
      });
    }
  }
  return reconcilerList;
};

export const ScheduledPaymentReconciler = ({ selectedInvoices, paymentId }) => {
  const [showDetails, setShowDetails] = useState(false);
  const reconciler = useMemo(() => getReconciledPayments(selectedInvoices, paymentId), [selectedInvoices]);

  return reconciler.length > 0 ? (
    <div
      className="cps-card__header cps-bg-color-vibrant-ocean cps-color-bumble cp-p-8"
      onClick={() => setShowDetails(!showDetails)}>
      <div className="cp-flex-spread-center">
        {reconciler.length === 1 && (
          <div className="cp-mt-8 cp-mb-8 cps-wt-semibold">
            <CpIcon name="information-circle-open-small" className="cp-m-8" />
            By making this payment, the scheduled payment of {convertToCurrencyText(
              reconciler[0].originalAmount
            )} on {DateTime.fromISO(reconciler[0].payDate).toLocaleString(DateTime.DATE_MED)} will be{' '}
            {reconciler[0].newAmount === 0
              ? 'removed'
              : `adjusted to ${convertToCurrencyText(reconciler[0].newAmount)}`}
            .
          </div>
        )}
        {reconciler.length > 1 && (
          <>
            <div className="cp-mt-8 cp-mb-8 cps-wt-semibold">
              <CpIcon name="information-circle-open-small" className="cp-m-8" />
              By making this payment, the following scheduled payments will be adjusted:
            </div>
            <CpIcon name={showDetails ? 'caret-large-up' : 'caret-large-down'} />
          </>
        )}
      </div>
      {showDetails && reconciler.length > 1 && (
        <ul style={{ paddingLeft: '86px', marginTop: '-8px' }}>
          {reconciler.map((payment, index) => {
            return (
              <li key={index}>
                Invoice {payment.invoiceNum} - {DateTime.fromISO(payment.payDate).toLocaleString(DateTime.DATE_MED)} for{' '}
                {convertToCurrencyText(payment.originalAmount)} will be{' '}
                {payment.newAmount === 0 ? 'removed' : `adjusted to ${convertToCurrencyText(payment.newAmount)}`}.
              </li>
            );
          })}
        </ul>
      )}
    </div>
  ) : null;
};
