import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { SelectPaymentFrequency } from 'src/payments/components/add-payment/select-payment-frequency.component';
import { mapInvoiceFormDataToDto, mapRecurrenceFormDataToDto } from '../invoice-builder.helper';
import { PaymentDetails } from 'src/payments/components/add-payment/payment-details.component';
import { paymentMethods, paymentTypes } from 'src/payments/payments.helper';

export const RecurringPayment = ({
  adyenActions,
  adyenPaymentDetails,
  amexEnabled,
  client,
  disabled,
  hasAdyen,
  hasCanopyPayments,
  invoice,
  mode,
  onPaysafeUpdate,
  onRecurringPaymentChanged,
  options,
  recurrence,
  submitError,
  teamCanKeyInCards,
}) => {
  const [paysafeInstance, setPaysafeInstance] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [recurringPaymentDate, setRecurringPaymentDate] = useState('due date');
  const [paymentDetails, setPaymentDetails] = useState({});
  const [payment, setPayment] = useState({
    amount: 0,
    check_number: '',
    date: moment().startOf('day'),
    display_method: '',
    invoice_payments: [],
    method: '',
    notes: '',
    relationships: {},
  });

  useEffect(() => {
    if (mode === 'edit' && recurrence.payment?.cardId) {
      setPaymentMethod(`cpStored ${recurrence.payment.cardId}`);
    }
  }, [mode, recurrence.payment]);

  useEffect(() => {
    if (recurrence.payment?.terms === 0) {
      setRecurringPaymentDate('invoice date');
    } else {
      setRecurringPaymentDate('due date');
    }
  }, [recurrence.recurringTerms]);

  useEffect(() => {
    onRecurringPaymentChanged(paysafeInstance, recurringPaymentDate, paymentDetails);
  }, [paysafeInstance, recurringPaymentDate, paymentDetails]);

  const mappedInvoice = useMemo(() => mapInvoiceFormDataToDto(invoice, options, []), [invoice]);

  const mappedRecurrence = useMemo(() => {
    const schedule = { ...mapRecurrenceFormDataToDto(recurrence) };

    return {
      next_occurrence: schedule.next_occurrence,
      terms: schedule.terms,
      remaining: schedule.remainging,
      schedule: schedule,
    };
  }, [recurrence]);

  const paymentDetailsChanged = details => {
    setPaymentDetails(details);

    if (details.ccInfo) {
      setPayment({
        ...payment,
        check_number: details.refNumber,
        ...getPaymentMethodValues(details),
      });
    }
  };

  const getPaymentMethodValues = details => {
    if (!details || !details.ccInfo) return {};

    const display = find(paymentMethods, ['key', details.method]);

    if (display) {
      return {
        display_method: display.value,
        method: ['cpCreditCard', 'cpAch'].includes(details.method) ? details.method : 'manual',
      };
    } else {
      return {};
    }
  };

  return (
    <div>
      <SelectPaymentFrequency
        disabled={disabled || recurrence.status === 'Ended'}
        invoice={mappedInvoice}
        recurrence={mappedRecurrence}
        paymentDate={recurringPaymentDate}
        setPaymentDate={setRecurringPaymentDate}
        paymentDetails={paymentDetails}
      />
      <PaymentDetails
        achInfo={paymentDetails.achInfo}
        adyenActions={adyenActions}
        adyenPaymentDetails={adyenPaymentDetails}
        amexEnabled={amexEnabled}
        ccInfo={paymentDetails.ccInfo}
        clientId={client.id}
        detailsChanged={paymentDetailsChanged}
        disabled={disabled || recurrence.status === 'Ended'}
        edit={mode === 'edit'}
        handlePaymentDateChanged={() => {}}
        hasAdyen={hasAdyen}
        hasCanopyPayments={hasCanopyPayments}
        isFuturePayment={true}
        key={client.id}
        method={paymentMethod}
        onPaysafeInstance={setPaysafeInstance}
        onPaysafeUpdate={onPaysafeUpdate}
        paymentType={paymentTypes.recurring}
        show={true}
        submitError={submitError}
        teamCanKeyInCards={teamCanKeyInCards}
      />
    </div>
  );
};
