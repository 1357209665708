import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { always } from 'kremling';
import ClickOutside from 'react-click-outside';
import { CpTableHeaderMenu } from 'canopy-styleguide!sofe';
import { FilterContext } from 'src/common/filter-context';
import { hasSortOrFilter, filterTypes } from './columns.helpers';

import styles from './column-header.styles.css';

const ColumnHeader = ({ allowFilterSort, column, getDynamicFilters, getFixedFilters, maxWidth, selector }) => {
  const [columnMenuShown, setColumnMenuShown] = useState(false);
  const filterContext = useContext(FilterContext);

  const columnFilter = allowFilterSort ? filterContext.filters[column.sortParam] : null;

  const handleColumnHeaderClick = e => {
    e.stopPropagation();

    if (!allowFilterSort) {
      return;
    }

    if (column.filterType === filterTypes.None) {
      column.sortParam && sortColumn();
    } else {
      setColumnMenuShown(!columnMenuShown);
    }
  };

  const sortColumn = () => {
    let newColumnFilter = {
      order: columnFilter && columnFilter.order === 'asc' ? 'desc' : 'asc',
    };

    filterContext.applyFilter(column.sortParam, newColumnFilter);
  };
  return (
    <th
      style={{ maxWidth: maxWidth && column.sortParam !== 'status' ? `${maxWidth}px` : 'inherit' }}
      className={always('cp-pr-12')
        .always(styles.columnHeader)
        .maybe(styles.columnHeaderNoHover, !allowFilterSort || column.noSort)
        .maybe('cps-bg-color-chrome', hasSortOrFilter(columnFilter))
        .toggle('cp-pl-32', 'cp-pl-12', column.first)}
      key={column.sortParam}
      onClick={handleColumnHeaderClick}>
      {column.columnLabel && (
        <div>
          <ClickOutside
            className="cp-flex-center"
            style={{ width: '100%' }}
            onClickOutside={() => setColumnMenuShown(false)}>
            {selector && selector()}
            <CpTableHeaderMenu
              filterContext={filterContext}
              column={column}
              getDynamicFilters={getDynamicFilters}
              getFixedFilters={getFixedFilters}
              allowFilterSort={allowFilterSort}
              hideSortOptions={column.hideSortOptions}
              menuShown={columnMenuShown}
              setColumnMenuShown={setColumnMenuShown}
              onCancel={() => {
                setColumnMenuShown(false);
              }}
            />
          </ClickOutside>
        </div>
      )}
    </th>
  );
};

ColumnHeader.propTypes = {
  allowFilterSort: PropTypes.bool,
  column: PropTypes.object,
  getDynamicFilters: PropTypes.func,
  getFixedFilters: PropTypes.func,
  maxWidth: PropTypes.number,
};

export default ColumnHeader;
