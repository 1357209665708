const status = [
  {
    id: 'all',
    name: 'All statuses',
  },
  {
    id: 'saved',
    name: 'Saved',
  },
  {
    id: 'printed',
    name: 'Printed',
  },
  {
    id: 'downloaded',
    name: 'Downloaded',
  },
  {
    id: 'sent',
    name: 'Sent',
  },
  {
    id: 'viewed',
    name: 'Viewed',
  },
  {
    id: 'partial',
    name: 'Partial pay',
  },
];

export default status;
