import React from 'react'
import computeSchemaValue from './schema-items.helper.js'

export default class SchemaItems extends React.Component {

  render () {
    
    const { childClass, schema, items } = this.props
    if (schema && items) {
      if (schema.length !== items.length) {
        throw new Error(`schema: "${schema}" is not the same length as items: "${items}". To use schema-items they must be the same length if they are both defined`)
      }
    }
    const schemaItems = items.map(
      (schemaItem, index) => {
        const schemaValue = computeSchemaValue(schemaItem)
        let key = schema ? schema[index] : schemaItem
        if (key === null) {key = index}
        const cClass = this.getChildClass(childClass, index)
        // headers come in with schema undefined and schemaitem null if set so
        if ((schema && schema[index] !== null) || ((typeof schema === 'undefined') && schemaItem !== null)) {
          return (
            <div
              key={key}
              className={cClass}
            >
              {schemaValue}
            </div>
          )
        } else { return null }

      }
    )
    return schemaItems
  }

  getChildClass = (childClass, index) => {
    if(typeof(childClass) === 'function') {
      return childClass(this.props.items.length, index)
    } else if(typeof(childClass) === 'string') {
      return childClass
    } else {
      return undefined
    }
  }
}
