import React, { useState, useContext } from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { InvoiceContext } from 'src/invoices/invoice-context';

export const SendToClient = ({ clientGroupId, disabled, hideLabel, update, value }) => {
  const { clientGroupClients } = useContext(InvoiceContext);
  const [search, setSearch] = useState('');

  return (
    <div className={!hideLabel ? 'cp-ml-16' : ''}>
      {!hideLabel && (
        <label>
          Send invoice to
          <span className="cps-color-primary">*</span>
        </label>
      )}
      <div style={{ width: '18.4rem' }}>
        <CpSelectSingle
          triggerIsBlock
          contentWidth="block"
          fixedContent
          disabled={disabled}
          data={clientGroupClients}
          value={value}
          placeholder="Search for client"
          onChange={client => update('client', { ...client, client_group_id: clientGroupId })}
          searchOnChange={setSearch}
          searchValue={search}
          searchFilter={CpSelectSingle.filterAlpha(search)}
        />
      </div>
    </div>
  );
};
