import React from 'react';
import { isEmpty } from 'lodash';
import { CpLoader, CpInput, CpIcon } from 'canopy-styleguide!sofe';

import styles from './filter.styles.css';

export const DynamicFilter = ({
  filterValues,
  filterSearchValue,
  filterSelections,
  applyFilter,
  selections,
  showSearch,
}) => {
  return (
    <>
      {showSearch ? (
        <div className="cp-pl-16 cp-pr-16 cp-pt-8 cp-pb-8">
          <CpInput value={filterSearchValue} onChange={value => filterSelections(value.toLowerCase())} isSearch />
        </div>
      ) : (
        <div className="cp-pl-20 cp-pr-20">
          <hr />
        </div>
      )}
      <div className={`${styles.filterMenu}`}>
        {!filterValues && showSearch ? (
          <CpLoader />
        ) : (
          filterValues.map((value, index) => {
            const selected = selections?.includes(value.id);
            return (
              <div key={index} className={`${styles.menuOption}`} onClick={() => applyFilter(value.id)}>
                <div className={`cps-ellipsis ${selected ? 'cps-color-primary' : ''}`}>{value.name}</div>
                {selected && <CpIcon name="checkmark-small" fill="var(--cps-color-primary)" />}
              </div>
            );
          })
        )}
      </div>
      {showSearch && isEmpty(filterValues) && <div className="cp-p-16">No Results</div>}
    </>
  );
};
