import React from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { daysInWeek } from 'src/invoices/recurrences.helper';

export const DaysInWeek = ({ value, engagement, update, disabled }) => (
  <div className={engagement ? 'cp-ml-16' : 'cp-ml-8'}>
    <label>
      Day
      {!engagement && <span className="cps-color-primary">*</span>}
    </label>
    <div style={{ width: engagement ? '147px' : '160px' }}>
      <CpSelectSingle
        triggerIsBlock
        contentWidth="sm"
        fixedContent
        disabled={disabled}
        data={daysInWeek.map(o => o.value)}
        transformData={item => ({
          id: item,
          name: item,
        })}
        value={value}
        onChange={dayInWeek => update('dayInWeek', dayInWeek)}
      />
    </div>
  </div>
);
