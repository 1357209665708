import { fetchAsObservable } from 'fetcher!sofe';
import { addOneDay } from '../../billing-helpers';

export function getClientProfitabilityReport(
  { after, before, clientId, clientGroupId, serviceItemId, groupBy, ownerId, sortColumn },
  page = 1,
  limit = 50
) {
  return fetchAsObservable(`/api/billing/reports/task-profitability?page=${page}&limit=${limit}`, {
    method: 'POST',
    body: {
      filters: {
        start_date: after,
        end_date: addOneDay(before),
        client_group_id: clientGroupId,
        client_id: clientId,
        service_item_id: serviceItemId,
        group_by: groupBy,
        client_owner_id: ownerId,
        sort: {
          order_by: sortColumn.order,
          sort_by: sortColumn.column,
        },
      },
    },
  });
}

export function getClientProfitabilityReportCSV(after, before, groupBy, ownerId, sortColumn) {
  return fetchAsObservable('/api/billing/reports/task-profitability/csv', {
    method: 'POST',
    body: {
      filters: {
        start_date: after,
        end_date: addOneDay(before),
        group_by: groupBy,
        client_owner_id: ownerId,
        sort: { order_by: sortColumn.order, sort_by: sortColumn.column },
      },
    },
  });
}
