import React from 'react';
import { tap } from 'rxjs/operators';
// project
import { getAgedReceivableDetail } from './aged-receivables-detail.resource.js';
import NetworkRequest from '../common/network-request/network-request.component.js';
import AgedReceivableDetailReport from './report/report.component.js';
import ReportWrapper from '../common/report-wrapper/report-wrapper.component.js';
import { cloneDeep } from 'lodash';

const stateDefault = {
  filtering: {
    DueDateKey: 'all',
    TermsKeys: ['all'],
    StatiKeys: ['all'],
    ClientsKeys: [],
    checkboxes: {
      general: new Map([
        ['primaryEmail', true],
        ['primaryPhone', true],
        ['invoiceDate', true],
        ['terms', true],
      ]),
      numeric: new Map([
        ['current', true],
        ['col1', true],
        ['col2', true],
        ['col3', true],
        ['col4', true],
        ['invoiceTotal', true],
      ]),
    },
  },
  initialClients: [],
};

export default class AgedReceivableDetail extends React.Component {
  constructor() {
    super();
    this.state = cloneDeep(stateDefault);
  }

  setARDState = (newState, callback) => {
    this.setState(newState, callback);
  };

  render() {
    return (
      <ReportWrapper navigation={{ link: this.props.match.path, text: 'Aged Receivables Detail' }}>
        <div className="cps-flexible-focus">
          <div className="cps-card">
            <NetworkRequest observable={this.getObservableWithSideEffects} refreshEvent="billing-ui::payment-complete">
              <AgedReceivableDetailReport
                stateDefault={stateDefault}
                state={this.state}
                setARDState={this.setARDState}
              />
            </NetworkRequest>
          </div>
        </div>
      </ReportWrapper>
    );
  }

  getObservableWithSideEffects = (...args) => {
    return getAgedReceivableDetail(...args).pipe(
      tap(({ clients }) => {
        if (this.state.initialClients.length == 0 && clients.length !== 0) {
          this.setState({ initialClients: clients });
        }
      })
    );
  };
}
