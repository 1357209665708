import React from 'react';
import { CpDropdown, CpButton, CpIcon } from 'canopy-styleguide!sofe';
import CustomizeOption from './customize-option/customize-option.component.js';
import { ClientOwner } from './customize-option/customize-modal/clients/client-owner.component';

import styles from './report-title.styles.css';

export default class ARReportTitle extends React.Component {
  render() {
    return (
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.header}`}>
          <div className={`cps-subheader`}>{this.props.title}</div>
        </div>
        <div className={`${styles.rightCol}`}>
          {this.props.showCustomize ? (
            <div>
              <CustomizeOption
                refetch={this.props.refetch}
                customizeReportSettings={this.props.customizeReportSettings}
                bucket_schema={this.props.bucket_schema}
                stateDefault={this.props.stateDefault}
                clientsFromResponse={this.props.clientsFromResponse}
              />
            </div>
          ) : (
            <ClientOwner
              OwnerKey={this.props.ownerId}
              updateOwners={this.props.setClientOwner}
              hideLabel={true}
              clientOwnerWidth={196}
            />
          )}
          <div className="cp-ml-8">
            <div className="cp-ml-16">
              <CpDropdown
                renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" onClick={toggle} aria-label="Menu" />}
                renderContent={() => (
                  <div className="cp-select-list">
                    <a href={this.props.exportCSVRequest()} onClick={this.handleExportCSVClick} download={true}>
                      <CpIcon name="af-line-square-up" className="cps-icon cps-dropdown-menu__icon" />
                      Export CSV
                    </a>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
