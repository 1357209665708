import { useState, useEffect } from 'react';
import { fromEvent } from 'rxjs';

export const emptyFieldMessage = 'Field cannot be blank';

export const useBankAccountVerified = () => {
  const [bankAccountVerified, setBankAccountVerified] = useState(false);

  useEffect(() => {
    let subscription = fromEvent(window, 'billing-ui::bank-account-verified').subscribe(() => {
      setBankAccountVerified(true);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return bankAccountVerified;
};

export const states = [
  { key: 'AL', value: 'AL' },
  { key: 'AK', value: 'AK' },
  { key: 'AS', value: 'AS' },
  { key: 'AZ', value: 'AZ' },
  { key: 'AR', value: 'AR' },
  { key: 'AA', value: 'AA' },
  { key: 'AE', value: 'AE' },
  { key: 'AP', value: 'AP' },
  { key: 'CA', value: 'CA' },
  { key: 'CO', value: 'CO' },
  { key: 'CT', value: 'CT' },
  { key: 'DE', value: 'DE' },
  { key: 'DC', value: 'DC' },
  { key: 'FL', value: 'FL' },
  { key: 'GA', value: 'GA' },
  { key: 'HI', value: 'HI' },
  { key: 'ID', value: 'ID' },
  { key: 'IL', value: 'IL' },
  { key: 'IN', value: 'IN' },
  { key: 'IA', value: 'IA' },
  { key: 'KS', value: 'KS' },
  { key: 'KY', value: 'KY' },
  { key: 'LA', value: 'LA' },
  { key: 'ME', value: 'ME' },
  { key: 'MD', value: 'MD' },
  { key: 'MA', value: 'MA' },
  { key: 'MI', value: 'MI' },
  { key: 'MN', value: 'MN' },
  { key: 'MS', value: 'MS' },
  { key: 'MO', value: 'MO' },
  { key: 'MT', value: 'MT' },
  { key: 'NE', value: 'NE' },
  { key: 'NV', value: 'NV' },
  { key: 'NH', value: 'NH' },
  { key: 'NJ', value: 'NJ' },
  { key: 'NM', value: 'NM' },
  { key: 'NY', value: 'NY' },
  { key: 'NC', value: 'NC' },
  { key: 'ND', value: 'ND' },
  { key: 'MP', value: 'MP' },
  { key: 'OH', value: 'OH' },
  { key: 'OK', value: 'OK' },
  { key: 'OR', value: 'OR' },
  { key: 'PW', value: 'PW' },
  { key: 'PA', value: 'PA' },
  { key: 'PR', value: 'PR' },
  { key: 'RI', value: 'RI' },
  { key: 'SC', value: 'SC' },
  { key: 'SD', value: 'SD' },
  { key: 'TN', value: 'TN' },
  { key: 'TX', value: 'TX' },
  { key: 'VI', value: 'VI' },
  { key: 'UT', value: 'UT' },
  { key: 'VT', value: 'VT' },
  { key: 'VA', value: 'VA' },
  { key: 'WA', value: 'WA' },
  { key: 'WV', value: 'WV' },
  { key: 'WI', value: 'WI' },
  { key: 'WY', value: 'WY' },
];

export const stateNames = [
  { id: 'AL', name: 'Alabama' },
  { id: 'AK', name: 'Alaska' },
  { id: 'AZ', name: 'Arizona' },
  { id: 'AR', name: 'Arkansas' },
  { id: 'CA', name: 'California' },
  { id: 'CO', name: 'Colorado' },
  { id: 'CT', name: 'Connecticut' },
  { id: 'DE', name: 'Delaware' },
  { id: 'DC', name: 'District of Columbia' },
  { id: 'FL', name: 'Florida' },
  { id: 'GA', name: 'Georgia' },
  { id: 'HI', name: 'Hawaii' },
  { id: 'ID', name: 'Idaho' },
  { id: 'IL', name: 'Illinois' },
  { id: 'IN', name: 'Indiana' },
  { id: 'IA', name: 'Iowa' },
  { id: 'KS', name: 'Kansas' },
  { id: 'KY', name: 'Kentucky' },
  { id: 'LA', name: 'Louisiana' },
  { id: 'ME', name: 'Maine' },
  { id: 'MD', name: 'Maryland' },
  { id: 'MA', name: 'Massachusetts' },
  { id: 'MI', name: 'Michigan' },
  { id: 'MN', name: 'Minnesota' },
  { id: 'MS', name: 'Mississippi' },
  { id: 'MO', name: 'Missouri' },
  { id: 'MT', name: 'Montana' },
  { id: 'NE', name: 'Nebraska' },
  { id: 'NV', name: 'Nevada' },
  { id: 'NH', name: 'New Hampshire' },
  { id: 'NJ', name: 'New Jersey' },
  { id: 'NM', name: 'New Mexico' },
  { id: 'NY', name: 'New York' },
  { id: 'NC', name: 'North Carolina' },
  { id: 'ND', name: 'North Dakota' },
  { id: 'OH', name: 'Ohio' },
  { id: 'OK', name: 'Oklahoma' },
  { id: 'OR', name: 'Oregon' },
  { id: 'PA', name: 'Pennsylvania' },
  { id: 'PR', name: 'Puerto Rico' },
  { id: 'RI', name: 'Rhode Island' },
  { id: 'SC', name: 'South Carolina' },
  { id: 'SD', name: 'South Dakota' },
  { id: 'TN', name: 'Tennessee' },
  { id: 'TX', name: 'Texas' },
  { id: 'UT', name: 'Utah' },
  { id: 'VT', name: 'Vermont' },
  { id: 'VA', name: 'Virginia' },
  { id: 'WA', name: 'Washington' },
  { id: 'WV', name: 'West Virginia' },
  { id: 'WI', name: 'Wisconsin' },
  { id: 'WY', name: 'Wyoming' },
];
