// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customize-modal-content-styles__buttonFlexContainer--FTfwB {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
}

.customize-modal-content-styles__noPaddingBottom--JnbuN {
  padding-bottom: 0px;
}

.customize-modal-content-styles__resetButton--H5rSM button {
  color: var(--cps-color-monsoon);
}`, "",{"version":3,"sources":["webpack://./src/reports/common/report-title/customize-option/customize-modal/customize-modal-content.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".buttonFlexContainer {\n  display: flex;\n  justify-content: space-between;\n  padding: 12px 24px;\n}\n\n.noPaddingBottom {\n  padding-bottom: 0px;\n}\n\n.resetButton button {\n  color: var(--cps-color-monsoon);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonFlexContainer": `customize-modal-content-styles__buttonFlexContainer--FTfwB`,
	"noPaddingBottom": `customize-modal-content-styles__noPaddingBottom--JnbuN`,
	"resetButton": `customize-modal-content-styles__resetButton--H5rSM`
};
export default ___CSS_LOADER_EXPORT___;
