import React from 'react';
import { object, string } from 'prop-types';
import { CpInput } from 'canopy-styleguide!sofe';

import styles from './input-group.styles.css';

export function InputGroup({ addon, side = 'left', ...props }) {
  const Addon = () =>
    React.cloneElement(addon, {
      className: styles.inputGroup_addon,
    });

  return (
    <div className={styles.inputGroup}>
      {side === 'left' && <Addon />}
      <div className={styles.inputGroup_input}>
        <CpInput {...props} />
      </div>
      {side === 'right' && <Addon />}
    </div>
  );
}

InputGroup.propTypes = {
  addon: object,
  side: string,
};
