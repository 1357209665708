import React from 'react'
// project
import styles from './header.styles.css'
import rStyles from '../report.styles.css'
import SchemaItems from 'src/reports/common/schema-items/schema-items.component.js'

export default class SummaryHeader extends React.Component {

  render () {
    return (
      <div className={`${styles.header}`}>
        <div className={`${rStyles.wideReportItem} ${styles.item}`}>
          Client
        </div>
        <SchemaItems
          childClass={`${rStyles.reportItem} ${styles.item}`}
          items={this.props.schema}
        />
      </div>
    )
  }
}
