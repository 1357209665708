// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-styles__dateRangeSelector--DJjYi {
  border: 1px solid var(--cps-color-border);
  border-radius: var(--cp-form-border-radius);
  height: 32px;
  padding: 8px 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters-styles__dateRanges--xT9DB {
  width: 162px;
  list-style-type: none;
  padding-left: 5px;
}

.filters-styles__spreadContents--FdOKb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters-styles__alignRight--droBO {
  left: inherit;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/filters/filters.styles.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,2CAA2C;EAC3C,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":[".dateRangeSelector {\n  border: 1px solid var(--cps-color-border);\n  border-radius: var(--cp-form-border-radius);\n  height: 32px;\n  padding: 8px 14px;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.dateRanges {\n  width: 162px;\n  list-style-type: none;\n  padding-left: 5px;\n}\n\n.spreadContents {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.alignRight {\n  left: inherit;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateRangeSelector": `filters-styles__dateRangeSelector--DJjYi`,
	"dateRanges": `filters-styles__dateRanges--xT9DB`,
	"spreadContents": `filters-styles__spreadContents--FdOKb`,
	"alignRight": `filters-styles__alignRight--droBO`
};
export default ___CSS_LOADER_EXPORT___;
