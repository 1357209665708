import { useState } from 'react';
import toasts from 'toast-service!sofe';
import { saveCredit, deleteCredit, openCredit, voidCredit } from 'src/resources/credits.resources';

export const useCreditBuilder = (show, onClose, setEditing) => {
  const [mode, setMode] = useState();
  const [credit, setCredit] = useState({});
  const [creditRemaining, setCreditRemaining] = useState();
  const [webViewerInstance, setWebViewerInstance] = useState();

  const handleLoadCredit = (creditToLoad, amount_remaining) => {
    if (!creditRemaining) {
      setCreditRemaining(amount_remaining);
    }
    setCredit(creditToLoad);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleDownload = () => {
    webViewerInstance.UI.downloadPdf();
  };

  const handlePrint = () => {
    webViewerInstance.UI.useEmbeddedPrint();
    webViewerInstance.UI.print();
  };

  const handleDelete = () => {
    deleteCredit(credit.id).subscribe(() => {
      onClose(null, null, true);
    });
  };

  const handleVoid = () => {
    voidCredit(credit).subscribe(updatedCredit => {
      setCredit(updatedCredit);
      toasts.successToast('Credit successfully voided');
    });
  };

  const handleOpen = () => {
    openCredit(credit).subscribe(updatedCredit => {
      setCredit(updatedCredit);
      toasts.successToast('Credit successfully re-opened');
    });
  };

  const handleRefund = (refundDate, refundMethod, refundAmount) => {
    const newRefund = {
      amount: refundAmount.toString(),
      date: refundDate.toISODate(),
      display_method: refundMethod,
      method: 'manual',
    };
    const refunds = [...credit.refunds, newRefund];
    const credits = { ...credit, refunds };

    return new Promise((resolve, reject) => {
      saveCredit(credits).subscribe(
        () => {
          setCreditRemaining((creditRemaining - refundAmount).toFixed(2));
          toasts.successToast('Refund successfully added');
          resolve();
        },
        error => reject(error)
      );
    });
  };

  const handleApplyCredit = (creditAmount, selectedInvoice) => {
    const newCredit = {
      amount: String(creditAmount),
      relationships: {
        applied_to: {
          id: selectedInvoice.id,
          invoice_number: selectedInvoice.invoice_number,
          type: 'invoice',
        },
      },
    };
    const updatedInvoicePayments = [...(credit.invoice_payments || []), newCredit];
    const credits = { ...credit, invoice_payments: updatedInvoicePayments };

    return new Promise((resolve, reject) => {
      saveCredit(credits).subscribe(
        () => {
          setCreditRemaining((creditRemaining - creditAmount).toFixed(2));
          toasts.successToast('Credit successfully applied to invoice');
          resolve();
        },
        error => reject(error)
      );
    });
  };

  const actions = {
    modeChange: setMode,
    initializeWebViewer: setWebViewerInstance,
    loadCredit: handleLoadCredit,
    edit: handleEdit,
    download: handleDownload,
    print: handlePrint,
    delete: handleDelete,
    void: handleVoid,
    open: handleOpen,
    refund: handleRefund,
    applyCredit: handleApplyCredit,
  };

  return {
    credit,
    creditRemaining,
    mode,
    webViewerInstance,
    actions,
  };
};
