import React from 'react'
// project
import styles from './grand-total.styles.css'
import rStyles from '../report.styles.css'
import SchemaItems from 'src/reports/common/schema-items/schema-items.component.js'

export default class DetailGrandTotal extends React.Component {

  render () {
    const { schema, values } = this.props
    const fullSchema = ['', ...schema]
    return (
      <div className={`${styles.fixedTotals}`}>
        <div className={`${styles.table}`}>
          <div className={`${styles.valueRow}`}>
            <div className={`${rStyles.rowContents}`}>
              <SchemaItems
                childClass={this.getChildClass}
                schema={fullSchema}
                items={fullSchema}
              />
            </div>
          </div>
          <div className={`${rStyles.totalRow}`}>
            <div className={`${rStyles.rowContents}`}>
              <SchemaItems
                childClass={this.getChildClass}
                schema={fullSchema}
                items={[`Grand Total`, ...values]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getChildClass = (length, index) => {
    if (index === 0) {
      return `${rStyles.rowItemPadding} ${rStyles[`flex${this.props.prepend.length}`]}`
    } else {
      return `${rStyles.headerItem} ${rStyles.rowItemPadding}`
    }
  }
}
