import React, { useState } from 'react';
import { CpWell, CpIcon, CpInput, CpToggle, CpTooltip, CpInlineNotification, CpButton } from 'canopy-styleguide!sofe';
import { CurrencyInput } from './currency-input.component';

import styles from './add-payment.styles.css';

export const AccountPaymentCard = ({
  accountPaymentDetails,
  onChange,
  readOnly,
  syncPaymentsToIntegration,
  qboIntegrationInfo,
}) => {
  const { amount, description, enabled } = accountPaymentDetails;
  const [prevAccountPaymentDetails, setPrevAccountPaymentDetails] = useState();
  return (
    <CpWell className={styles.accountPaymentCard} level={2}>
      <div className="cp-flex-spread-center">
        <div className="cp-flex-center">
          <div className="cp-title cp-wt-semibold cp-mr-4">Additional Payment</div>
          <CpTooltip
            position="top"
            text={
              <div className="cp-text-center">
                This payment will not be applied to any invoices. It will automatically create a credit on your account.
              </div>
            }>
            <CpIcon fill="var(--cp-color-app-icon)" name="information-circle-open-small" />
          </CpTooltip>
        </div>
        <CpToggle
          className="cp-mr-12"
          checked={enabled}
          onChange={value => {
            onChange('enabled', value);

            if (!value) {
              setPrevAccountPaymentDetails(accountPaymentDetails);
              onChange('amount', 0);
              onChange('description', '');
            }
            if (value && prevAccountPaymentDetails) {
              onChange('amount', prevAccountPaymentDetails.amount);
              onChange('description', prevAccountPaymentDetails.description);
            }
          }}
        />
      </div>
      {enabled && (
        <div>
          <div className="cp-flex cp-mt-16">
            <div className={styles.descriptionContainer}>
              <CpInput
                placeholder="Description"
                value={description}
                onChange={value => onChange('description', value)}
                maxLength="150"
              />
            </div>
            <div className={styles.amountContainer}>
              <CurrencyInput
                value={amount}
                onChange={value => onChange('amount', value || 0)}
                disabled={readOnly}
                alignRight
                allowNegative={false}
              />
            </div>
          </div>
          {syncPaymentsToIntegration && qboIntegrationInfo?.preferences?.SalesFormsPrefs?.AutoApplyCredit && (
            <div className="cp-mt-16">
              <CpInlineNotification
                message="To avoid balance mismatches, log into QBO and navigate to Settings > Advanced. Turn OFF the automation setting titled “Automatically apply credits”"
                cta={
                  <CpButton
                    btnType="secondary"
                    anchor
                    href="https://app.qbo.intuit.com/app/settings?p=advanced"
                    target="_blank">
                    Adjust setting
                  </CpButton>
                }
                type="error"
              />
            </div>
          )}
        </div>
      )}
    </CpWell>
  );
};
