import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { CreditOverlay } from './credit-overlay.component';
import { showPaymentModal } from '../../../payments/components/add-payment/add-payment.component';

function CreditOverlayWrapper(props) {
  const [overlayData, setOverlayData] = useState({});
  const [showCredit, setShowCredit] = useState(false);

  useEffect(() => {
    setOverlayData(props.overlayData);
  }, [props.overlayData]);

  useEffect(() => {
    setShowCredit(true);
  }, []);

  return (
    <CreditOverlay
      {...props}
      show={showCredit}
      onClose={(addPayment, paymentData = {}) => {
        if (addPayment) {
          showPaymentModal({ onModalClose: () => {}, paymentData });
        }
        setShowCredit(false);
        setOverlayData({});
      }}
      overlayData={overlayData}
    />
  );
}

export function showCreditOverlay(props) {
  return new Promise(resolve => {
    const div = document.createElement('div');
    document.body.appendChild(div);

    const root = createRoot(div);

    root.render(
      <CreditOverlayWrapper
        {...props}
        onClose={() => {
          root.unmount();
          div.parentNode.removeChild(div);
          resolve();
        }}
      />,
      div
    );
  });
}
