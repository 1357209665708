import React from 'react';
import GenericTimeReport from './generic-time-report.component.js';

export default function HoursByUserReport() {
  return (
    <GenericTimeReport
      title={'Hours Tracked by Team Member'}
      reportUrl={'user_hours'}
      isCurrency={false}
      leftColumnTitle={`Team Member`}
    />
  );
}
