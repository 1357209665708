import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useWithUserAndTenant, hasAccess } from 'cp-client-auth!sofe';

export default function PrivateRoute({ children, permissions, ...rest }) {
  const [user] = useWithUserAndTenant();
  return user ? (
    <Route
      {...rest}
      render={({ location, match }) =>
        hasAccess(user)(permissions) ? (
          // cloning to provide react router props which will be used by children
          React.cloneElement(children, { location, match })
        ) : (
          <Redirect to="/403" />
        )
      }
    />
  ) : null;
}
