import React from 'react';
import { CprDatepicker } from 'canopy-styleguide!sofe';

export const DatePicker = ({ value, update, disabled }) => (
  <div>
    <div className="cp-label">
      Date<span className="cps-color-primary">*</span>
    </div>
    <CprDatepicker
      date={value}
      orientation="bottom left"
      events={{
        datechange: date => {
          update({ date: date.detail });
        },
      }}
      disabled={disabled}
    />
  </div>
);
