import React from 'react';
import { cloneDeep, uniq } from 'lodash';
import { cdnImage } from 'canopy-styleguide!sofe';

export const getQboFromIntegrationsObj = integrations => integrations?.find(i => i?.type === 'qbo');

export const formatNestedQboData = (qboData = []) => {
  const clonedQboData = cloneDeep(qboData);
  const formattedList = [];
  // sort the list from top level to bottom level
  clonedQboData.sort((a, b) => {
    // FullyQualifiedName is a colon separated string that shows hierarchy ex: Parent:child1:child2
    // QBO doesn't sort based on case so we convert everything to lower case before running the sort
    const aList = a.FullyQualifiedName?.split(':').map(name => name.toLowerCase()) || [];
    const bList = b.FullyQualifiedName?.split(':').map(name => name.toLowerCase()) || [];

    // order list from parent items at top to most nested items at bottom
    // when two items are at the top level, sort them alphabetically
    if (aList.length < bList.length || (aList.length === 1 && bList.length === 1 && aList[0] < bList[0])) {
      return -1;
    } else if (aList.length > bList.length || (aList.length === 1 && bList.length === 1 && aList[0] > bList[0])) {
      return 1;
    } else {
      // if two items are on the same nested level then sort them in reverse alphabetical order
      // since the forEach below will place them in the reverse order this list produces
      if (aList[aList.length - 1] < bList[bList.length - 1]) {
        return 1;
      } else if (aList[aList.length - 1] > bList[bList.length - 1]) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  clonedQboData.forEach(item => {
    const splitName = item.FullyQualifiedName?.split(':') || [];
    const formattedObj = { ...item, id: item.Id, name: item.Name, indent: `${splitName.length - 1}` };
    if (!item.SubClass && !item.SubDepartment && !item.SubItem) {
      return formattedList.push(formattedObj);
    }
    const parentIndex = formattedList.findIndex(parent => parent?.Id === item.ParentRef?.value);
    // insert item below its parent class in the list
    formattedList.splice(parentIndex + 1, 0, formattedObj);
  });

  return formattedList;
};

export const formatCategorizedQboItems = (items = [], qboCategories = []) => {
  const data = cloneDeep(items);
  let categoriesUsedList = [];
  let uncategorizedList = [];
  let unsyncedList = [];

  const sortedCategories = formatNestedQboData(qboCategories);
  // create groups from the top level categories and assign their children categories to them in "data"
  let categoriesList = sortedCategories
    .map(category => {
      if (!category.ParentRef) {
        return {
          ...category,
          icon: <img src={cdnImage('qbo_logo_small_circle.svg')} style={{ height: 24, width: 24 }} />,
          showCount: false,
          data: sortedCategories
            .map(cat => {
              if (cat.ParentRef && cat.FullyQualifiedName?.split(':')[0] === category.name) {
                return { ...cat, subHeader: true };
              }
            })
            .filter(Boolean),
        };
      }
    })
    .filter(Boolean);

  data
    .sort()
    .reverse()
    .forEach(item => {
      const splitName = item.FullyQualifiedName?.split(':') || [];
      const formattedObj = { ...item, indent: `${splitName.length - 1}` };

      if (!item.syncedToThirdParty) {
        // put item in list of unsynced service items
        unsyncedList.unshift(item);
      } else if (item.ParentRef) {
        // if item has a parent then it belongs to a category, put it in the list under its parent category
        const topLvlIndex = categoriesList.findIndex(topLvl => topLvl.name === splitName[0]);
        if (categoriesList[topLvlIndex]?.id === item.ParentRef?.value) {
          categoriesList[topLvlIndex]?.data?.unshift({ ...item, indent: `${splitName.length - 1}` });
        } else {
          const parentIndex =
            categoriesList[topLvlIndex]?.data?.findIndex(parent => parent?.Id === item.ParentRef?.value) ?? -1;
          // insert item below its parent class in the list
          if (parentIndex !== -1) {
            categoriesList[topLvlIndex]?.data?.splice(parentIndex + 1, 0, formattedObj);
          }
        }
        // create list of possible hierarchy paths for this service item and put it in hierarchy paths array
        const itemCategoryPaths = item.ParentRef?.name?.split(':').reduce((acc, item) => {
          const newARR = [...acc, !acc.length ? item : `${acc[acc.length - 1]}:${item}`];
          return newARR;
        }, []);
        categoriesUsedList = categoriesUsedList.concat(itemCategoryPaths);
      } else {
        // put item in list of uncategorized service items
        uncategorizedList.unshift(item);
      }
    });

  categoriesUsedList = uniq(categoriesUsedList);

  if (uncategorizedList?.length) {
    // add list to a uncategorized group
    uncategorizedList = [
      {
        id: 'UncategorizedServiceItems',
        name: 'General',
        icon: <img src={cdnImage('qbo_logo_small_circle.svg')} style={{ height: 24, width: 24 }} />,
        showCount: false,
        data: uncategorizedList,
      },
    ];
  }

  if (unsyncedList?.length) {
    // add list to a unsynced group
    unsyncedList = [
      {
        id: 'UnsyncedServiceItems',
        name: 'Unsynced',
        icon: 'broken-link',
        showCount: false,
        data: unsyncedList,
      },
    ];
  }

  if (categoriesList?.length) {
    // filter out categories in the list that don't have a service item under them
    categoriesList = categoriesList.map(category => ({
      ...category,
      data: category.data?.filter(
        cat => cat.Type !== 'Category' || categoriesUsedList.includes(cat.FullyQualifiedName)
      ),
    }));
  }

  return [...unsyncedList, ...uncategorizedList, ...categoriesList];
};
