// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-entry-styles__editLabelAndInput--GvvYF {
  display: flex;
  flex-direction: column;
}

.time-entry-styles__editLabel--ZDnz8 {
  display: flex;
  align-items: center;
}

.time-entry-styles__dateTimeInput--TFI7g {
  width: 220px;
}

#serviceCodeSelect ul.cp-select__menu.cps-dropdown-menu {
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/time-entries/time-entry.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".editLabelAndInput {\n  display: flex;\n  flex-direction: column;\n}\n\n.editLabel {\n  display: flex;\n  align-items: center;\n}\n\n.dateTimeInput {\n  width: 220px;\n}\n\n:global(#serviceCodeSelect) :global(ul.cp-select__menu.cps-dropdown-menu) {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editLabelAndInput": `time-entry-styles__editLabelAndInput--GvvYF`,
	"editLabel": `time-entry-styles__editLabel--ZDnz8`,
	"dateTimeInput": `time-entry-styles__dateTimeInput--TFI7g`
};
export default ___CSS_LOADER_EXPORT___;
