export default function computeSchemaValue(value) {
  const parsed = parseFloat(value)
  if (isNaN(parsed) && typeof(value) === 'string') {
    return capitalizeString(value)
  } else if (Array.isArray(value)) {
    return arrayValue(value)
  } else if (typeof(parsed) === 'number' && !isNaN(parsed)) {
    return formatStringNumber(parsed)
  } else if (value === null) {
    return null
  } else {
    return ''
  }
}

function formatStringNumber(value = 0.00) {
  if (value === 0) {
    return `—`
  } else {
    const options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    return value.toLocaleString('en-us', options)
  }
}

function capitalizeString(string = '') {
  if (string.length === 0) {
    return string
  } else {
    const arr = string.split('')
    arr[0] = arr[0].toUpperCase()
    return arr.join('')
  }
}

function arrayValue(arrayValue = []) {
  if (arrayValue.length === 0) {
    return ''
  } else if (arrayValue.length === 2) {
    return `${arrayValue[0]} - ${arrayValue[1]} Days`
  } else if (arrayValue.length === 1) {
    return `${arrayValue[0]}+ Days`
  } else {
    return ''
  }
}
