// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-header-styles__columnHeader--h3CTD {
  display: table-cell !important;
}

.table-header-styles__columnHeader--h3CTD {
  white-space: nowrap;
}

.table-header-styles__columnHeader--h3CTD:hover {
  background-color: var(--cps-color-chrome);
  cursor: pointer;
}

.table-header-styles__columnHeaderNoHover--NE5D8:hover {
  background-color: inherit;
  cursor: default;
}

.table-header-styles__dropdownTrigger--EVMmX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0px 12px;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/column_header/table-header.styles.css"],"names":[],"mappings":"AAAA;EAEE,8BAA8B;AAChC;;AAHA;EACE,mBAAmB;AAErB;;AAEA;EACE,yCAAyC;EACzC,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".columnHeader {\n  white-space: nowrap;\n  display: table-cell !important;\n}\n\n.columnHeader:hover {\n  background-color: var(--cps-color-chrome);\n  cursor: pointer;\n}\n\n.columnHeaderNoHover:hover {\n  background-color: inherit;\n  cursor: default;\n}\n\n.dropdownTrigger {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 44px;\n  padding: 0px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnHeader": `table-header-styles__columnHeader--h3CTD`,
	"columnHeaderNoHover": `table-header-styles__columnHeaderNoHover--NE5D8`,
	"dropdownTrigger": `table-header-styles__dropdownTrigger--EVMmX`
};
export default ___CSS_LOADER_EXPORT___;
