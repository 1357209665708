import { pluck, map } from 'rxjs/operators';
import { fetchAsObservable } from 'fetcher!sofe';

export function getTeamMembers(withTasks = false) {
  return fetchAsObservable(`/users?role=TeamMember${withTasks ? '&permission=tasks' : ''}`).pipe(pluck('users'));
}

export function getTeamMembersV2(search) {
  return fetchAsObservable(`/api/v2/clients/filter_values?field=team_members${search ? '&search=' + search : ''}`).pipe(
    pluck('values')
  );
}

export function getClientOwners(search) {
  return fetchAsObservable(`/api/billing/reports/team_members${!!search ? `?search_string=${search}` : ''}`).pipe(
    pluck('team_members')
  );
}

export function getUsersAndAdmins(clientId) {
  if (!clientId) {
    throw new Error(`Cannot get users without id`);
  }
  return fetchAsObservable(`/api/clients/${clientId}/users-and-admins`).pipe(pluck('users'));
}

export function getClient(clientId, queryParamsList) {
  if (!clientId) {
    throw new Error(`Cannot get client without id`);
  }
  let queryParamString = '';
  if (queryParamsList) {
    queryParamString = `?include=${queryParamsList.join(',')}`;
  }

  return fetchAsObservable(`/api/clients/${clientId}${queryParamString}`).pipe(pluck('clients'));
}

export function getClients(search = '', searchDisplayName = false) {
  return fetchAsObservable(
    `/api/users/0/client-names?limit=20${search ? `&name=${encodeURIComponent(search)}` : ''}${
      searchDisplayName ? '&&search_display_name=true' : ''
    }`
  ).pipe(pluck('clients'));
}

export function getClientLists(data) {
  return fetchAsObservable('/api/client_lists:split_by_portals?without_portals_includes_inactive=true', {
    method: 'POST',
    body: { data },
  });
}

export function getClientGroups(clientGroupId) {
  return fetchAsObservable(`/api/client-groups/${clientGroupId}`).pipe(
    map(response => ({
      ...response,
      clients: response.clients?.filter(({ has_access }) => has_access !== false) || [],
    }))
  );
}

export function getClientTags() {
  return fetchAsObservable('/api/tags', {
    method: 'GET',
  }).pipe(pluck('tags'));
}

export function getClientsAndClientGroups(search = '') {
  return fetchAsObservable(`/api/client-groups/group_and_client:search${search ? `?search_string=${search}` : ''}`);
}
